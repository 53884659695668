import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../utils/axiosConfig";

export const getFilterMaster = createAsyncThunk(
  "listing/filters",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosConfig.get("/cbvtm");
      return await response.data;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

export const getPremiumListings = createAsyncThunk(
  "listing/premiumcars",
  async ({ limit, page, model, cat_id , city_id}, { rejectWithValue }) => {

    try {
      let listing_url = `gvbcrwtmb/${limit}/${page}/-/0/Premium/${cat_id}/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-`;
      const response = await axiosConfig.get(listing_url);
      
      return await response.data;
    } catch (error) {
     
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

export const getAdvanceListings = createAsyncThunk(
  "listing_adv/cars",
  async ({ limit, page, filters, cat_id}, { rejectWithValue }) => {
    try {
      let listing_url = `/gvbcrf/${limit}/${page}/${cat_id}/0/1/${filters}`;
      const response = await axiosConfig.get(listing_url);
      return await response.data;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

export const getListings = createAsyncThunk(
  "listing/cars",
  async ({ limit, page, filters, search , cat_id}, { rejectWithValue }) => {
    
    try {
      // let listing_url = `gvbcrwtm/${limit}/${page}`;
      let listing_url = `gvbcrwtmb/${limit}/${page}`;
      let fil = { ...filters }; // Creating a shallow copy of filters
      if(cat_id){
        fil['category'] = cat_id;
      }
      for (const key in fil) {
        // if(key === "category"){
        //   listing_url += `/${cat_id}`;  
        // }else{
          listing_url += `/${fil[key]}`;
        // }
        
      }
      listing_url += `/${search === "" ? "-" : search}`;
      
      const response = await axiosConfig.get(listing_url);
      return await response.data;
    } catch (error) {
      
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

export const clearSelected = createAsyncThunk(
  "clearlisting/cars",
  async ({ }, { rejectWithValue }) => {
      return null;
  }
);


export const getBanners = createAsyncThunk(
  "listing/banners",
  async ({ type }, { rejectWithValue }) => {
    try {
      let listing_url = `getbanners/${type}`;

      const response = await axiosConfig.get(listing_url);
      return { banners: await response.data, type };
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

export const saveCount = createAsyncThunk(
  "listing/count",
  async ({ item, type, user }, { rejectWithValue }) => {
    const payload = {
      cid: type, //Click ID  (0 for Details , 1 for Whatsapp , 3 request for callback)
      deviceid: "Browser", // this is either "Browser" or "APP" no need for device ID here
      uid: user?.ubd?.guid || "",
      username: user?.fn || "",
      userphone: user?.ubd?.phone || "", // this is user ID
      tuid: item?.tuid || "",
      tluid: item?.tluid || "",
      vtypecode: item?.vtname?.en || "",
      catcode: item?.category?.en || "",
      tenant_name : item?.tenant?.tname?.en,
      tenant_cellNumber : item?.tenant?.cellNumberc,
      tenant_email : item?.tenant?.enqEmail,
      brand:  item?.bname?.en,
      model : item?.mname?.en
    };

    try {
      const response = await axiosConfig.post("/cdet", payload);
      
      return await response.data;

    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

export const handleChangeFilterValue = createAsyncThunk(
  "listing/filters/handler",
  async ({ key, value }, { rejectWithValue }) => {
    return { key, value };
  }
);

export const handleListingValChange = createAsyncThunk(
  "layout/listing",
  async ({ key, value }, { rejectWithValue }) => {
    
    return { key, value };
  }
);

export const handleClearAllFilters = createAsyncThunk(
  "clear/filters",
  async ({}, { rejectWithValue }) => {
    return "";
  }
);

export const handleClearSearch = createAsyncThunk(
  "clear/search",
  async ({}, { rejectWithValue }) => {
    return "";
  }
);

export const handleClearitems = createAsyncThunk(
  "clear/items",
  async ({}, { rejectWithValue }) => {
    return "";
  }
);

export const saveFilterQuery = createAsyncThunk(
  "save/query",
  async ({filters}, { rejectWithValue }) => {
    try {
      let payload = {
        "fquery" : JSON.stringify(filters)
      }
      const response = await axiosConfig.post("/vnmssf", payload);
      return await response.data;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);



export const favProduct = createAsyncThunk(
  "fav/product",
  async ({tluid, typed }, { rejectWithValue }) => {
    try {
      let payload = {
        "tluid" : tluid,
        "typed" : typed
      }
      axiosConfig.post("/vnmmyfav", payload);
      return {tluid, typed}; 
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);



export const implementSavedTemplate = createAsyncThunk(
  "use/query",
  async ({  }, { rejectWithValue }) => {
    try {
      let listing_url = `vnmssf`;

      const response = await axiosConfig.get(listing_url);
      return await response.data?.message;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);



export const getUserFav = createAsyncThunk(
  "user/fav",
  async ({  }, { rejectWithValue }) => {
    try {
      let listing_url = `vnmmyfav`;

      const response = await axiosConfig.get(listing_url);
      return await response.data ;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);



export const getLastViewed = createAsyncThunk(
  "last_viewed/query",
  async ({  }, { rejectWithValue }) => {
    try {
      let listing_url = `vnmulastviews`;

      const response = await axiosConfig.get(listing_url);
      return await response.data ;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

//version two getting filters
export const getdistvbcall = createAsyncThunk(
  "listing/getdistvb",
  async ({ cat_id}, { rejectWithValue }) => {
    
    try {
      // let listing_url = `gvbcrwtm/${limit}/${page}`;
      let listing_url = `getdistvb/${cat_id}`;
      const response = await axiosConfig.get(listing_url);
      
      return await response.data[0];
    } catch (error) {
      
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

