import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import listingReducer from "./listings/listingSlice";
import langReducer from "./lang/langSlice";
import currReducer from "./currency/currencySlice";
import { injectStore } from "../utils/axiosConfig";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";





const rootReducer = combineReducers({
  listing: persistReducer({ key: "listings", storage }, listingReducer),
  lang: persistReducer({ key: "lang", storage }, langReducer),
  curr: persistReducer({ key: "curr", storage }, currReducer),
  auth: persistReducer({ key: "auth", storage }, authReducer),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  whitelist: ["session"],
  devTools: process.env.NODE_ENV !== "production",
});

injectStore(store);

export const persistor = persistStore(store);
