import { Route, Routes } from "react-router-dom";
import { Home, Item } from "../../pages/home";
import  Homemobile  from "../../pages/home/Homemobile";
import { Login } from "../../pages/login";
import { Register } from "../../pages/register";
import { PrivateRoute } from "../privateRoute";
import { PublicRoute } from "../publicRoute";
import Styles from "./app.module.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Activation from "../../pages/auth/Activation";
import Expire from "../../pages/auth/Expire";
import Upr from "../../pages/auth/Upr";
import Termscon from "../../pages/home/Termscon";
import Blogs from "../../pages/home/Blogs";
import Privacyp from "../../pages/home/Privacypolicy";
import Profile from "../../pages/profile";
import Vnmlisting from "../../pages/home/homes/Vnmlisting";
import Aboutus from "../../pages/home/homes/Aboutus";

import NoInternet from "../../pages/no-internet";
import { useMediaQuery } from "react-responsive";

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { selected_lang } = useSelector((state) => state.lang);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selected_lang);
    
  }, [selected_lang]);



  return (
    <div>
     
      <Layout>
        <Routes>
          {isTabletOrMobile ? 
          <Route path="/:type?" element={<PublicRoute component={Homemobile} />} />
          :
          <Route path="/:type?" element={<PublicRoute component={Home} />} />
          }
          <Route path="/:type?" element={<PublicRoute component={Home} />} />
          
          <Route
            path="/activation/:id"
            element={<PublicRoute component={Activation} />}
          />
          <Route
            path="/expire/:id"
            element={<PublicRoute component={Expire} />}
          />
          <Route path="/upr/:id" element={<PublicRoute component={Upr} />} />
          <Route path="/listings/:type/:xtr" element={<PublicRoute component={Vnmlisting} />} />
          <Route path="/item/:id" element={<PublicRoute component={Item} />} />
          <Route
            path="/login"
            element={<PublicRoute restricted={true} component={Login} />}
          />
          <Route
            path="/profile"
            element={<PrivateRoute restricted={true} component={Profile} />}
          />
          <Route
            path="/register"
            element={<PublicRoute restricted={true} component={Register} />}
          />
          <Route
            path="/privacy_policy"
            element={<PublicRoute restricted={false} component={Privacyp} />}
          />
          <Route
            path="/terms_of_usage"
            element={<PublicRoute restricted={false} component={Termscon} />}
          />
          <Route
            path="/blogs"
            element={<PublicRoute restricted={false} component={Blogs} />}
          />
          <Route
            path="/blogs/:title"
            element={<PublicRoute restricted={false} component={Blogs} />}
          />
          <Route
            path="/about-us"
            element={<PublicRoute restricted={false} component={Aboutus} />}
          />
          <Route
            path="/no-internet"
            element={<PublicRoute restricted={false} component={NoInternet} />}
          />
        </Routes>
      </Layout>
      
    </div>
  );
}

const Layout = ({ children }) => {
  const { rtl } = useSelector((state) => state.lang);

  return (
    <div
      className={Styles.mainContainer}
      style={{ direction: rtl ? "rtl" : "ltr" }}
    >
      <div className={Styles.contentContainer}>{children}</div>
    </div>
  );
};

export { App };