import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import {
  getLastViewed,
  
} from "../../store/listings/listingActions";
import { errorToast, successToast } from "../../utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import { getUserProfile, logoutUser } from "../../store/auth/authActions";
import { FaEdit } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import moment from "moment";
import { resetPassword, changeinfo } from "../../store/auth/authActions";
import { Toaster } from "react-hot-toast";
import HorizentalCard from "../../components/horizentalCard";
import Menu from "../home/homecomps/Menu_vtwo";
import Footer from "../home/new_main/Footer";
import Footerm from "../home/new_main/Footerm";

import { handleValCurrencyChange } from "../../store/currency/currencyActions";
import { handleValLangChange } from "../../store/lang/langActions";

function Profile(props) {
  
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const navigate = useNavigate();
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [passwdOpen, setPasswdOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [ldata , setLdata] = useState([])
  let cell = useRef("");
  let country = useRef("");
  let dob = useRef("");

  let appRef = useRef()
  
  const {
    filter_master,
  } = useSelector((state) => state.listing);


  const _handleChangeTranslate = () => {
    let selectedLanguage = selected_lang === langs[0] ? langs[1] : langs[0];
    dispatch(
      handleValLangChange({ key: "rtl", value: selectedLanguage === langs[1] })
    );
    dispatch(
      handleValLangChange({ key: "selected_lang", value: selectedLanguage })
    );
    successToast(t(`Language Changed to ${selectedLanguage}`));
  };


  const { langs, selected_lang  } = useSelector((state) => state.lang);

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        zIndex: 999999,
      },
      overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
    };

    const _handleChangeCurrency = () => {
         
      let selectedCurrency = 
        selected_curr === currencies[0] ? currencies[1] : currencies[0];

        let valuec = 1
        if(selected_curr === "AED"){
          valuec = filter_master.currencyinfo.find(t => t.name === "Dollar").value
        }else{
          valuec = filter_master.currencyinfo.find(t => t.name === "Dirham").value
        }
        
        dispatch(
        handleValCurrencyChange({
          key: "conversion_rate",
          value: String(valuec),
        })
      );
      dispatch(
        handleValCurrencyChange({ key: "selected_curr", value: selectedCurrency })
      );
      successToast(t(`Currency Changed to ${selectedCurrency}`));
    };

  const { user, loading, user_profile } = useSelector(
    (state) => state.auth
    
  );

  const { selected_curr, currencies } =
  useSelector((state) => state.curr);

  const {
    search,
    user_last_viewed,
  } = useSelector((state) => state.listing);

  useEffect(() => {
    if (user) {
      dispatch(getUserProfile({ email: user.Email })).unwrap().then(y => {
        dispatch(getLastViewed({})).unwrap();
        showlikes('Enq')
        cell = user_profile?.cell
      })
      .catch((errorData) => {
        dispatch(logoutUser());
        errorToast("Login Session Expired, Please relog");
        
      });;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { t } = useTranslation();

  const navlink1 = useRef(null);
  const navlink2 = useRef(null);
  const navlink3 = useRef(null);

  let oldPassword = useRef("");
  let newPassword = useRef("");
  

  const _handleUpdatePassword = () => {
    if (!oldPassword.value.trim() || !newPassword.value.trim()) {
      errorToast("All fields are required");
      return;
    }
    dispatch(
      resetPassword({
        guid: user.ubd.guid,
        opass: oldPassword.value,
        cpass: newPassword.value,
      })
    )
      .unwrap()
      .then(() => {
        setPasswdOpen(false);
        successToast("Password Updated Successfully");
      })
      .catch((errorData) => {
        errorToast(errorData.error);
      });
  };

  const _handleUpdateinfo = () => {
    
    if (!cell.value.trim() || !country.value.trim()) {
      errorToast("Please update at least your cell number and country.");
      return;
    }
    
    dispatch(
      changeinfo({
        guid: user.ubd.guid,
        cell: cell.value,
        country: country.value,
        dob: new Date(dob.value).toLocaleDateString(),
      })
    )
      .unwrap()
      .then(() => {
        successToast("Your information is Updated")
      }).catch((errorData) => {
        errorToast(errorData.error);
      });
      
  };

 

  const showlikes = (rxy) => {
    
      if(rxy === "Enq"){
        setLdata(
          user_last_viewed.Enq?.map((item, index) => (
            <HorizentalCard key={`user_last_viewed`+index} item={item} />
          ))
        )
      }else if (rxy === "likes"){
        setLdata(
          user_last_viewed.Likes?.map((item, index) => (
            <HorizentalCard key={`user_last_viewed`+index} item={item}  />
          ))
        )
      }
      else{
          setLdata(
            user_last_viewed.Likes?.map((item, index) => (
              <HorizentalCard key={`user_last_viewed`+index} item={item} />
            ))
          )
      }

  }

  return (
    <>


    <Toaster />
     <Modal
        isOpen={passwdOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setPasswdOpen(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <section className="z-50">
        <div class="w-full md:w-12/12 mx-2 ">
                  <div class="bg-white p-3 shadow-sm rounded-sm">
                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                      <span clas="text-green-500">
                        <svg
                          class="h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke-width="2"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </span>
                      <span class="tracking-wide">Update Password</span>
                    </div>
                    <div class="text-gray-700 mt-4">
                      <div class="mb-5">
                        <label
                          for="email"
                          class="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Current Password
                        </label>
                        <input
                          type="password"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                          required
                          ref={(e) => {
                            oldPassword = e;
                          }}
                        />
                      </div>
                      <div class="mb-5">
                        <label
                          type="password"
                          class="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          New Password
                        </label>
                        <input
                          type="password"
                          id="name"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                          required
                          ref={(e) => {
                            newPassword = e;
                          }}
                        />
                      </div>
                      <center>      
                      <button
                        type="submit"
                        onClick={_handleUpdatePassword}
                        class="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                      >
                        Update Password
                      </button>
                      </center>
                    </div>
                  </div>
                </div>
            
        </section>
      </Modal>
      
      <Modal
        isOpen={infoOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setInfoOpen(false);
        }}
        style={customStyles}
        contentLabel="Change Info"
      >
        <section className="z-50">
        <div class="w-full md:w-12/12 mx-2 ">
          <form>
                  <div class="bg-white p-3 shadow-sm rounded-sm">
                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                      <span clas="text-green-500">
                        <svg
                          class="h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke-width="2"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </span>
                      <span class="tracking-wide">Update Info</span>
                    </div>
                    <div class="text-gray-700 mt-4">
                      <div class="mb-5">
                        <label
                          class="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          {t("Phone")}
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                          required
                          type="number"
                          min="1000000"
                          max="9999999999999999"
                          value = {cell.value}
                          ref={(e) => {
                            cell = e;
                          }}
                        />
                      </div>
                      <div class="mb-5">
                        <label
                          
                          class="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          {t("Country")}
                        </label>
                        <input
                          id="name"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                          required
                          value={country.value}
                          minLength="3"
                          maxLength="18"
                          ref={(e) => {
                            country = e;
                          }}
                        />
                      </div>
                      <div class="mb-5">
                        <label
                          type="password"
                          class="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          {t("Birthday")}
                        </label>
                        <input
                          id="name"
                          type="date"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                          required
                          value={dob.value}
                          ref={(e) => {
                            dob = e;
                          }}
                        />
                      </div>
                      <center>
                      <button
                        onClick={t => {t.preventDefault(); _handleUpdateinfo()}}
                        className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto text-center"
                          style={{padding: "6px"}}
                      >
                        Update Info
                      </button>
                      {isTabletOrMobile ? <hr /> : <span>&nbsp;</span>}
                      <button
                        onClick={c => setInfoOpen(false)}
                        className="hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto text-center"
                          style={{padding: "6px"}}
                      >
                        {loading ? `${t("Loading")}...` : t("Close")}
                      </button>
                     
                      </center>
                    </div>
                  </div>
            </form>
                </div>
            
        </section>
      </Modal>


      {!isTabletOrMobile ? 
           <div style={{backgroundColor : "#f1f1f1"}}>
           <div className={selected_lang === "en" ? "vttop_bar marginenn" : "vttop_bar marginarr" }> 
                 <a
                     target="_blank"
                     href={process.env.REACT_APP_FACEBOOK_URL}
                     rel="noreferrer"
                   >
                 <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/facebook_.webp`} alt="facebook_icon" />
                 </a>
                 <a
                 target="_blank"
                 href={process.env.REACT_APP_INSTAGRAM_URL}
                 rel="noreferrer"
               >
                 <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/instagram_.webp`} alt="instagram_icon" />
                 </a>
                 <a
                   target="_blank"
                   href={process.env.REACT_APP_TIKTOK_URL}
                   rel="noreferrer"
                 >
                     <img style={{width : "1.75vw", height :"3.5vh", marginTop : "2px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/tiktok.webp`} alt="tiktok_icon"  />
                 </a>
                 <a
                   target="_blank"
                   href={process.env.REACT_APP_SNAPCHAT_URL}
                   rel="noreferrer"
                 >
                 <img  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/snapchat_.webp`} alt="snapchat_icon" />
                 </a>
             </div>
         
             <div className={selected_lang === "en" ? "vttop_bar_r marginen" : "vttop_bar_r marginar" } style={{float : selected_lang === "en" ? "right" : "left"}}> 
                 <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/globe.png`} alt="Globe"  /><span className={selected_lang === "en" ? "marginen25" : "margin25"}>{t("topbar_uae")}</span></div>
                 <div onClick={() => _handleChangeCurrency()}><img  style={{width : "25px", height:  "25px", marginTop : "2px" }} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Currency.png`} alt="Currency icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25" }>
                 {selected_curr === "AED" ? 
                 t("AED")
                : 
                t("USD")
               }
                   </span></div>
                 <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span></div>
                 <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/App.png`} alt="App Icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>DOWNLOAD OUR MOBILE APP</span>
                 
                 </div>
                 
             </div>
         </div>
            :
            <div>
                {/** <div style={{backgroundColor : "black", height : "40px"}}></div> */}
              <div className="vttop_bar_r_m" style={{width : "100%", minWidth : "100%", backgroundColor : "#f1f1f1", borderBottom : "3px solid orange"}}> 
              <div className="qs"><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/globe.png`} alt="Globe"  /><span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "margin25"}>
                    {t("topbar_uae")}
                
                </span>
              </div>
              <div onClick={() => _handleChangeCurrency()} >
                <img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Currency.png`} alt="Currency icon" />
                <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>
              {selected_curr === "AED" ? 
                      t("AED")
                    : 
                    t("USD")
                    }
                </span></div>
              <div >
                <img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span>
              </div>

              <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/App.png`} alt="App Icon" />
              <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>MOBILE APP</span></div>
              </div>
            </div>  
          }

        
      {!isTabletOrMobile  ? 
        <Menu user={user} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} isTabletOrMobile={isTabletOrMobile} search={search}
        _handleSearch={()=> {}} setLoginModel={() => {}} navlink1={navlink1} navlink2={navlink2} navlink3={navlink3} t={t}
        setCommingSoon={() => {}} showsearch={"0"}/>
        :
        <div style={{display : "flex", width : "100%"}}>
              <div style={{width  :"50%", minWidth : "50%"}} >
                          <a 
                          href = "/car" >
                          <img
                                  className="ml-2 mt-1"
                                    width = "130"
                                    height = "90"
                                  style={{ float :"left", padding : "0.5vw" }}
                                  src={
                                      process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.webp"
                                  }
                                  alt="logo"
                                  />
                          </a>
                        </div>          
          {/** Mobile Menu */}            
                        <div style={{display : "flex" , justifyContent : "right", width : "50%", minWidth :"50%"}} >
                              <div>
                                <a href = "/car"  >
                                 <RxHamburgerMenu className="text-2xl mt-3.5"  />       
                                 </a>
                              
                              </div>
                              <div style={{marginTop :  "15px"}} className="mr-7" >
                              {!user ? (
                                  <button
                                      type="button "
                                      onClick={() => {
                                      
                                      setIsMenuOpen(false);
                                      }}
                                      style={{  fontSize : "0.7rem" , height : "4vh", width : "20vw" , backgroundColor : "orange" , fontWeight : "bold", borderRadius : "10px" }}
                                  >
                                      <center >
                                          {t("LOGIN")}{" "}
                                      </center>
                                  </button>
                                  ) : (
                                  <>
                                      <button
                                      type="button"
                                      onClick={() => {
                                          
                                          if(window.location.href.includes("profile")){
                                          dispatch(logoutUser());
                                          }else{
                                          navigate("/profile");
                                          setIsMenuOpen(false);
                                          }
                                          
                                      }}
                                      style={{ marginTop: -5, fontSize : "0.7rem" , height: "40px", borderRadius : "25px"}}
                                      className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10"
                                      >
                                      { window.location.href.includes("profile") ? "Logout" :user?.fn}
                                      </button>
                                  </>
                                )}
                              </div>             
                        
                        </div>
      </div>
      }   
      
        <div
          className="w-screen bg-cover bg-center relative md:px-24 z-0"
          style={{
            
            backgroundImage: `url(
          "${process.env.REACT_APP_PUBLIC_URL}/images/blank-space-soft-gradient-wall-graphic-design-backdrop-add-text-room-interior-background.png"
        )`,
          }}
        >
        
          

          <div class="container mx-auto mt-5" >
            <div class="container mx-auto my-5 p-5">
              <div class="md:flex no-wrap md:-mx-2 " >
                <div class="w-full md:w-3/12 md:mx-2" style={{fontSize : "0.8rem"}}>
                  <div class="bg-white p-3 border-t-4 border-primary">
                    <div class="image overflow-hidden">
                      <img
                        className="h-auto w-full mx-auto"
                        src="../../assets/images/avatar.jpg"
                        alt=""
                      />
                    </div>
                    <h5>
                       <button onClick={x => setPasswdOpen(true)}>&#x26BF;</button> {user_profile?.displayName}
                       {isTabletOrMobile && <FaEdit style={{float : "right", marginTop : "5px"}} onClick={i => setInfoOpen(true)} />}
                    </h5>
                
                    <ul class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                      <li class="flex items-center py-3">
                        <span>{t("Status")}</span>
                        {user_profile?.disabledRef === "Active" ? (
                          <span class="ml-auto">
                            <span class="bg-green-500 py-1 px-2 rounded text-white text-sm">
                              {t("Active")}
                            </span>
                          </span>
                        ) : (
                          <span class="ml-auto">
                            <span class="bg-danger-500 py-1 px-2 rounded text-white text-sm">
                              Disabled
                            </span>
                          </span>
                        )}
                      </li>
                      <li class="flex items-center py-3">
                        <span>{t("MemberSince")}</span>
                        <span class="ml-auto">
                          {moment(user_profile?.createDate).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </li>
                      {isTabletOrMobile &&
                      <li class="flex items-center py-3">
                      <span>{t("ContactNo")}</span>
                      <span class="ml-auto">
                      {user_profile?.cell}
                      </span>
                    </li>
                    }
                    </ul>
                  </div>
                </div>
                {!isTabletOrMobile &&
                <div class="w-full md:w-9/12 mx-2 h-64" style={{fontSize : "0.8rem"}}>
                <div class="bg-white p-3 shadow-sm rounded-sm">
                  <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                    <span clas="text-green-500">
                    <FaEdit style={{float : "right"}} onClick={i => setInfoOpen(true)}/>
                    </span>
                    <span class="tracking-wide">{t("About")}</span>
                    
                  </div>
                  <div>
                    <div class="grid md:grid-cols-2">
                      <div class="grid grid-cols-2">
                        <div class="px-4 py-2 font-semibold">
                          {t("DisplayName")}
                        </div>
                        <div class="px-4 py-2">
                          {user_profile?.displayName}
                        </div>
                      </div>

                      <div class="grid grid-cols-2">
                        <div class="px-4 py-2 font-semibold">{t("ContactUs")}</div>
                        <div class="px-4 py-2">{user_profile?.cell}</div>
                      </div>

                      <div class="grid grid-cols-2">
                        <div class="px-4 py-2 font-semibold">{t("Country")}</div>
                        <div class="px-4 py-2">{user_profile?.country}</div>
                      </div>

                      <div class="grid grid-cols-2">
                        <div class="px-4 py-2 font-semibold">{t("BirthDay")}</div>
                        <div class="px-4 py-2">
                        {new Date(user_profile?.dob).toLocaleDateString()}
                        </div>
                      </div>
                      <div class="grid grid-cols-2">
                        <div class="px-4 py-2 font-semibold">Email.</div>
                        <div class="px-4 py-2 ">
                          <a
                            class="text-blue-800"
                            href={`mailto:${user_profile?.personalEmail}`}
                          >
                            {user_profile?.personalEmail}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                }
                
              </div>
     
              <div className="flex" style={{marginBottom : "20px"}}>
                <div>
                <h6 >
                <button style={{float : "right" , fontStyle : "italic"}}  onClick={x => showlikes("Enq")}>&#9955; My Recent Enquiries</button>     
                </h6>
                </div>
                <div style={{float : "right", paddingLeft : "50px"}}>
                <h6 >
                <button style={{float : "right" , fontStyle : "italic"}}  onClick={x => showlikes("likes")}>&#9825; My Likes </button>            
                </h6>
                </div>
                
                
              </div>

              {ldata}

            </div>
          </div>
        </div>
      
      <div ref={appRef}>

      <div >
        {!isTabletOrMobile ? 
        <Footer selected_lang={selected_lang} /> 
        : 
        <div style={{paddingBottom : "50px" , backgroundColor : "#16254e"}}>
        <Footerm selected_lang={selected_lang} navigate={navigate} />
        </div>
        }
        </div>
      </div>
      
                
    </>
  );
}

export default Profile;
