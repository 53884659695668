import { createSlice } from "@reduxjs/toolkit";
import {
  getFilterMaster,
  handleChangeFilterValue,
  handleListingValChange,
  getListings,
  getPremiumListings,
  handleClearAllFilters,
  
  handleClearSearch,
  getBanners,
  getAdvanceListings,
  handleClearitems,
  clearSelected,
  getUserFav,
  getLastViewed,
  implementSavedTemplate,
  getdistvbcall,
  
} from "./listingActions";



const initialState = {
  loading_listings: false,
  distvbc : {},
  listings: [],
  loading_premium_listings: false,
  premium_listings: [],
  limit: 8,
  page: 0,
  listing_count: 0,
  listing_premium_count: 0,
  filters: {
    sort: "-/1",
    type: "Featured",
    category: "1",
    citycode: "-",
    areacode: "-",
    brandCode: "-",
    modelcode: "-",
    typecode: "-",
    year: "-",
    insurence: "-",
    minp: "10",
    maxp: "15000",
    wdo: "-",
    fuel: "-",
    trans: "-",
    deposit: "-",
    delAreaCode: "-",
    bodytype:"-"
  },
  search: "",
  active_filters: [],
  filter_master: null,
  filters_master:null,
  loading_listings_filters: false,
  error: null,
  activeSlide: 0,
  selectedBrandModels: [],
  selectedModel: 0,
  selectedListing: null,
  loadingBanners: false,
  banners: {
    Cars: [],
  },
  user_fav:[],
  user_last_viewed:[]
};

const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {},
  extraReducers: {
    [getFilterMaster.pending]: (state) => {
      state.loading_listings_filters = true;
    },
    [getFilterMaster.fulfilled]: (state, action) => {
      state.loading_listings_filters = false;
      state.filter_master = action.payload;
      state.error = null;
    },
    [getFilterMaster.rejected]: (state, action) => {
      state.loading_listings_filters = false;
      state.error = action.payload.error;
    },

    
    [handleChangeFilterValue.fulfilled]: (state, action) => {
      state.filters = {
        ...state.filters,
        [action.payload.key]: action.payload.value,
      };
    },

    [handleListingValChange.fulfilled]: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
   
    [getListings.pending]: (state) => {
      state.loading_listings = true;
      state.listings = [];
      
      
    },
    [getListings.fulfilled]: (state, action) => {
      state.loading_listings = false;
      state.listings = action.payload.data;
      state.listing_count = action.payload?.countt || action.payload.count;
      state.filters_master = action.payload?.cbvtm;
      state.error = null;
    },
    [getListings.rejected]: (state, action) => {
      state.loading_listings = false;
      state.error = action.payload.error;
    },


    [getdistvbcall.pending]: (state) => {
      state.loading_listings = true;
      state.distvbc = [];
    },
    [getdistvbcall.fulfilled]: (state, action) => {
      state.loading_listings = false;
      state.distvbc = action.payload;
      state.error = null;
    },
    [getdistvbcall.rejected]: (state, action) => {
      state.loading_listings = false;
      state.error = action.payload.error;
    },

    /** 
    [updpayloadlisting.pending]: (state) => {
      state.loading_listings = true;
      state.listings = [];
    },
    [updpayloadlisting.fulfilled]: (state, action) => {
      state.loading_listings = false;
      state.listings = action.payload;
      state.error = null;
    },
    [updpayloadlisting.rejected]: (state, action) => {
      state.loading_listings = false;
      state.error = action.payload.error;
    },
*/
    [clearSelected.pending]: (state) => {
      state.loading_listings = true;
    },
    [clearSelected.fulfilled]: (state, action) => {
      state.loading_listings = false;
      state.selectedListing = null;
    },
    [clearSelected.rejected]: (state, action) => {
      state.loading_listings = false;
   
    },
    


    [getAdvanceListings.pending]: (state) => {
      state.loading_listings = true;
      state.listings = [];
    },
    [getAdvanceListings.fulfilled]: (state, action) => {
      state.loading_listings = false;
      state.listings = action.payload.data;
      state.listing_count = action.payload?.countt || action.payload.count;
      state.error = null;
    },
    [getAdvanceListings.rejected]: (state, action) => {
      state.loading_listings = false;
      state.error = action.payload.error;
    },



    




    [getPremiumListings.pending]: (state) => {
      state.loading_premium_listings = true;
      state.premium_listings = [];
    },
    [getPremiumListings.fulfilled]: (state, action) => {
      state.loading_premium_listings = false;
      state.premium_listings = action.payload.data;
      state.listing_premium_count =
        action.payload?.countt || action.payload.count;
      state.error = null;
      //state.filters_master = action.payload?.cbvtm;
      state.loading_premium_listings = false;
    },
    [getPremiumListings.rejected]: (state, action) => {
      state.loading_premium_listings = false;
      state.error = action.payload.error;
    },

    [handleClearAllFilters.fulfilled]: (state, action) => {
      state.filters = initialState.filters;
      state.search = initialState.search;
      
    },

    [handleClearSearch.fulfilled]: (state, action) => {
      state.search = initialState.search;
      
    },

    [handleClearitems.fulfilled]: (state, action) => {
      state.selectedListing = null;
      
      
    },
    

    [getBanners.pending]: (state) => {
      state.loadingBanners = true;
    },
    [getBanners.fulfilled]: (state, action) => {
      state.loadingBanners = false;
      state.banners = {
        ...state.banners,
        [action.payload.type]: action.payload.banners,
      };
      state.error = null;
    },
    [getBanners.rejected]: (state, action) => {
      state.loadingBanners = false;
      state.error = action.payload.error;
    },



    [implementSavedTemplate.fulfilled]: (state, action) => {
      state.filters = JSON.parse(action.payload?.favq);
    },

    [getUserFav.fulfilled]: (state, action) => {
      state.user_fav = action.payload;
    },

    [getLastViewed.fulfilled]: (state, action) => {
      state.user_last_viewed = action.payload;
    },

  },
});

export default listingSlice.reducer;
