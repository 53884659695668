import React from 'react'



function Userappm(props) {
  
  
  return (
    <div className='fazeel_uapp_m'>
      <div className='fazeel_app_detail'> 
        <div className='fazeel_app_detail_image'>
            <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/phone.webp`} width = "50" height="50" alt="phone" />
        </div>
        <div className='fazeel_app_detail_heading'>
            <span>{props.t("VNMRENTALMARKETPLACE1")} <span style={{color : "gray"}}>{props.t("userfriendlyappavailable")}</span> </span>
        </div>
      </div>
      <div className='fazeel_app_code'>
        
        <div className='fazeel_app_code_images'>
          <a href={process.env.REACT_APP_ANDROIDAPP}>
              <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/${props.iswhiteg}`} width = "40" height="40" alt="phone android" /> 
          </a>
        <div className='fazeel_line'>
        
        
        </div>
        <a href={process.env.REACT_APP_APPLEAPP}>
          <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/${props.iswhitea}`} width = "45" height="45" alt="phone android" /> 
        </a>
       </div>
        


        <div className='fazeel_app_code_text' style={{color : "white"}}>
        <p>Get it on <span>Google</span> or <span>Apple Play Store</span></p>
        </div>
        
      </div>
    </div>
  )
}

export default Userappm
