import React, {useState} from 'react';

import { useTranslation } from "react-i18next";
import { FaChevronRight , FaChevronLeft} from "react-icons/fa";
import {
    Link
  } from "react-router-dom";
import Modal from "react-modal";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useMediaQuery } from "react-responsive";

function Footer(props) {
    const { t } = useTranslation();
    const [faqIsOpen, setFaqIsOpen] = useState(false);   
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
const footerSection4 = [
    {label : t("FOOTERSSECTION4LINE1") , value : "phone" , icon : "phone.webp"},
    {label : t("FOOTERSSECTION4LINE2"), value : "email" , icon : "mailicon.webp"},
    {label : t("FOOTERSSECTION4LINE3"), value : "address" , icon : "location.webp"}
  ];

  const footerSection3 = [t("FOOTERSSECTION3LINE1"), t("FOOTERSSECTION3LINE2")];

  const footerSection2 = [
    // { title: t("FOOTERSSECTION2LINE1"), action: () => {} },
    // { title: t("FOOTERSSECTION2LINE2"), action: () => {} },
    {
      title: t("FOOTERSSECTION2LINE1"),
      action: "/privacy_policy",
      isLink: true,
    },
    {
      title: t("FOOTERSSECTION2LINE3"),
      action: "/terms_of_usage",
      isLink: true,
    },
    {
      title: t("FOOTERSSECTION2LINE4"),
      action: () => setFaqIsOpen(true),
      isLink: false,
    },
    {
      title: t("FOOTERSSECTION2LINE5"),
      action: "/blogs",
      isLink: true,
    },
  ];

  
  const faqs = [
    {
      title: t("FAQ1"),
      body: t("FAA1"),
    },
    {
      title: t("FAQ2"),
      body: t("FAA2"),
    },
    {
      title: t("FAQ3"),
      body: t("FAA3"),
    },
    {
      title: t("FAQ4"),
      body: t("FAA4"),
    },
    {
      title: t("FAQ5"),
      body: t("FAA5"),
    },
    {
      title: t("FAQ6"),
      body: t("FAA6"),
    },
    {
      title: t("FAQ7"),
      body: t("FAA7"),
    },
    {
      title: t("FAQ8"),
      body: t("FAA8"),
    },
    {
      title: t("FAQ9"),
      body: t("FAA9"),
    },
    {
      title: t("FAQ10"),
      body: t("FAA10"),
    },
    {
      title: t("FAQ11"),
      body: t("FAA11"),
    },
    {
      title: t("FAQ12"),
      body: t("FAA12"),
    },

    {
      title: t("FAQ13"),
      body: t("FAA13"),
    },
    {
      title: t("FAQ14"),
      body: t("FAA14"),
    },
    {
      title: t("FAQ15"),
      body: t("FAA15"),
    },
    {
      title: t("FAQ16"),
      body: t("FAA16"),
    },
    {
      title: t("FAQ17"),
      body: t("FAA17"),
    },
    {
      title: t("FAQ18"),
      body: t("FAA18"),
    },
    {
      title: t("FAQ19"),
      body: t("FAA19"),
    },
  ];


  function afterOpenModal() {}


  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      zIndex: 999999,
    },
    overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
  };


  const customStylesm = {
    content: {
        top: "50%",
        left : "auto",
        width : "90%",
        bottom: "auto",
        marginRight: "-50%",
        
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      zIndex: 999999,
    },
    overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
  };

    return (


        <section className=" text-left montserrat-400 homefootercolors py-2" style={{height : "50vh"}}>
        
        <Modal
            isOpen={faqIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={() => {
            setFaqIsOpen(false);
            }}
        style={isTabletOrMobile ? customStylesm : customStyles}
        contentLabel="Example Modal"
      >
        <section className="z-50" style={{fontSize : isTabletOrMobile ?  "0.5rem" : "0.7rem"}}>
          <div className="text-center">
            <p className="text-xl font-bold mb-5 border-bottom">
            <span onClick={x => setFaqIsOpen(false)} style={{float : "left", cursor : "pointer"}}>X</span>
           
                {t("FAQS")}
            </p>
            
          </div>

          <div className="flex flex-col justify-center">
            <PerfectScrollbar style={{ height: 500, width: 500 }}>
              {faqs.map((faq, index) => (
                <div className="border-b-2 py-2 mx-3" key={index} >
                  <p className="text-base font-medium text-sm">{faq.title}</p>
                  <p className="text-sm">{faq.body}</p>
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        </section>
           </Modal>
        
        
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 text-white mt-3">

          <div >
            <div>
                <img
                width="200"
                height="100"
                alt="logo"
                src={process.env.REACT_APP_PUBLIC_URL + "images/vnmwhite.webp"}
                />
            </div>
                <p  className="mt-5 text-white mtext_f">
                <span >  {t("FOOTERSECTION1LINE1")} </span>
                <br /><br />
                <span > {t("FOOTERSECTION1LINE2")} </span>
                </p>
          </div>


          <div className="font-bold">
            
              {t("FOOTERSECTION2TITLE")}
            
            <div className="flex flex-col mt-5">
              {footerSection2.map((section2, index) => {
                return section2.isLink ? (
                  <Link key={index}
                    to={section2.action}
                    className="text-white no-underline px-5 text-center inline-flex items-center md:my-2 cursor-pointer"
                  >
                    {props.selected_lang === "en" ? <FaChevronRight className="text-primary text-sm" /> : <FaChevronLeft className="text-primary text-sm" /> }
                    <p className="montserrat-400" style={{fontSize : "0.7rem"}}>{section2?.title}</p>
                  </Link>
                ) : (
                  <u
                    key={section2?.title}
                    onClick={section2.action}
                    className="text-white no-underline px-5 text-center inline-flex items-center md:my-2 cursor-pointer"
                  >
                    {props.selected_lang === "en" ? <FaChevronRight className="text-primary text-sm" /> : <FaChevronLeft className="text-primary text-sm" /> }
                    <p className="montserrat-400" style={{fontSize : "0.7rem"}}>{section2?.title}</p>
                  </u>
                );
              })}
            </div>
          </div>
          
          <div className="font-bold">
              {t("FOOTERSECTION3TITLE")}
            
            {footerSection3.map((section3, index) => {
              return (
                <div key={index} className="flex flex-row mt-7 items-center">
                  <div className="basis-1/4 border mr-5 ">
                    <img
                      
                      className="rounded-t-lg" height="40" width="40"
                      src={process.env.REACT_APP_PUBLIC_URL + "images/car.webp"}
                      alt="fcar"
                    />
                
                  </div>
                  <div className="3/4">
                    <p style={{fontSize : "0.7rem"}} className="montserrat-400 font-medium text-white tracking-[0] leading-[normal]">
                      {section3}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="font-bold">
            
              {t("FOOTERSECTION4TITLE")}
            
            <div className="flex flex-col  mt-5">
              {footerSection4.map((section4, index) => {
                return (
                  <div
                    key={index}
                    className="text-white py-2.5 text-center inline-flex no-underline"
                  >
                    <img width ="20" height= "10" src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/${section4.icon}`} alt={section4.value} />
                     <p  style={{marginLeft : "1vw", marginTop : "-5px", fontSize : "0.7rem" , directon : section4.value === "phone" && "ltr" }}>{section4.label}</p>
                  </div>
                );
              })}
            </div>
            
          </div>
        
        </div>
      </section>
    );
}

export default Footer;