import { useEffect, useRef, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../../assets/vnmstyles.scss'
import {
  getFilterMaster,
  getListings,
  getdistvbcall,
  getPremiumListings,
  handleChangeFilterValue,
  handleListingValChange,
  handleClearAllFilters,
  saveCount,
  getAdvanceListings,
  handleClearSearch,
} from "../../store/listings/listingActions";
import { resetup } from "../../store/auth/authhelpers.js";
import { errorToast, successToast } from "../../utils";
import { handleValLangChange } from "../../store/lang/langActions";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { handleValCurrencyChange } from "../../store/currency/currencyActions";

import { useMediaQuery } from "react-responsive";
import {
    useLocation,
    useNavigate,
    useParams,
    NavLink,
  } from "react-router-dom";
  import axiosConfig from "../../utils/axiosConfig.jsx";  
import Swal from "sweetalert2";

import { GoogleLogin } from "@react-oauth/google";

import {
  
  loginGoogleUser,
  loginUser,
  logoutUser,
  registerUser,
} from "../../store/auth/authActions";
import { Toaster } from "react-hot-toast";

//import { Carousel } from "react-responsive-carousel";
const Homecarpremiummobile = lazy(() => import('./homes/Homecarpremiummobile'));     
const Vnmbrands = lazy(() => import('./homes/Vnmbrands'));     
const Homemarket =lazy(() => import("./homes/Homemarket")) ;
const Userappm = lazy(() => import( "./homes/Userappm"))
const Vnmservicesm = lazy(() => import("./homes/Vnmservicesm")) 
const Homecarcitymobile =lazy(() => import("./homes/Homecarcitymobile")) 
const Homecartypemobile =lazy(() => import("./homes/Homecartypemobile")) 
const Vnmwhatclient = lazy(() => import("./homes/Vnmwhatclient")) 
const Footerm = lazy(()=> import( "./new_main/Footerm"))


const Homemobile = () => {
  
    const navigate = useNavigate();
    const navlink1 = useRef(null);
    const navlink2 = useRef(null);
    const navlink3 = useRef(null);
    const location = useLocation();
    
  
    let email = useRef("");
    let fullName = useRef("");
    let password = useRef("");
    let cell = useRef("");

    const expmorepref = useRef(null);
    
    const [showcall, setShowcall] = useState({eventid : "", id: "", boolv: false, boolw : false });

    const [id, setId] = useState("car");
    const { t } = useTranslation();
    var dispatch = useDispatch();
    
    const appRef = useRef();
    const listRef0 = useRef();
    
    const { type } = useParams();
    
    
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
   
    const [loginModel, setLoginModel] = useState(false);
    
    const [signupModel, setSignupModel] = useState(false);
   
    

    const { langs, selected_lang } = useSelector((state) => state.lang);
    const { distvbc } = useSelector((state) => state.listing);
    
    const [numbp, setNumbp] = useState(0)
    
    const { selected_curr, currencies, conversion_rate } =
    useSelector((state) => state.curr);

    const [emailx, setEmailx] = useState("")
    const [otpmodal, setOtpmodal] = useState(false);
    const [advanceFilterIsOpen, setAdvanceFilterIsOpen] = useState(false);
    
    
    const [validationError, setValidationError] = useState("");

    const [advanceFilter, setAdvanceFilter] = useState([]);
    
    const [distvals, setDistvals] = useState([])
    const [seldistvals, setSeldistvals] = useState("") 

    const [cwList, setCwList] = useState([])
    const [vtList, setVtList] = useState([])

    const [mdataone, setMdataone] = useState([])

    const setScroll = () => {
      try{
        if(premium_listings[0]?.categoryCode !== cwList?.data[0]?.categoryCode){
          window.location.reload(false)
          errorToast("Diff lists")
        }
      }catch(e){

      }
      
    };


    const getsplist = (ftype, tval, ord , sort ) => {
      let idx = _getCatId()
      if(ftype){
        axiosConfig(
          {
            method: "get",
            url : `/gettlists/${idx}/${ftype}/${tval}/${ord}/${sort}`,
           
            }
          ).then(t => {
            
            if(ftype === "city"){
              setCwList(t.data)
              
            }else {
              setVtList(t.data)
            
            }
   
          })
          .catch(e => {
            errorToast("some listings are not available, please try to select category.");
          }) 
      }
      
    }

// This is seperate call for machines only .... we are not using it for city or type filter for now
    const getmachines = (ftype, tval, ord , sort ) => {
      axiosConfig(
        {
          method: "get",
          url : `/getmachines/${ftype}/${tval}/${ord}/${sort}`,
         
          }
        ).then(t => {
          
          if(ftype === "city"){
           // setCwList(t.data)
            
          }else if(ftype ==="type") {
         //   setVtList(t.data)
          
          }else{
            
            setMdataone(t.data.data)
          }
 
        })
        .catch(e => {
          errorToast("some listings are not available, please try to select category.");
        }) 
    }

    
      const _handleAdvanceFilterHandler = () => {
        fsearches();
      };


     const _handleWaCount = (item, type) => {
      if (!user) {
        dispatch(saveCount({ item, type, user: { ubd: { guid: "" } } })).unwrap().then(res => {
          
          
          setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : false, boolw : true})
        });
      } else {
        dispatch(saveCount({ item, type, user })).unwrap().then(res => {
          
          setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : false, boolw : true})
        });
      }
      window.open(
        `https://wa.me/${item?.tenant?.waNumber}?text=Hi,%20messaging%20you%20regarding%20your%20listing%20${item?.bname[selected_lang]}%20,%20${item?.mname[selected_lang]}%20,%20${item?.modelyear.label}%20which%20have%20id%20as%20${item?.tluid}%20on%20https://VNMRENTAL.com%20is%20it%20available?`,
        "_blank",
        "noreferrer"
      );
  
    };

    const _handleSaveCount = async (item, type) => {
      
      if (type === 3 && !user) {
        
        
      } else if (type === 3 && user) {
        
      } else {
        dispatch(saveCount({ item, type, user }))
          .unwrap()
          .then((res) => {
            if (type === 3) {
              Swal.fire({
                title: t("REQUEST_SENT"),
                text: t("CALLBACK_MESSAGE"),
                icon: "info",
                confirmButtonText: t("COOL"),
                confirmButtonColor: "#F39C12",
              });
            }else{
              
              setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : type=== 2 ? true : false, boolw : false})
            }
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
      }
    };

    const {
        filter_master,
        filters_master,
        limit,
        page,
        filters,
        search,
        listings,
        
        premium_listings,
        
      } = useSelector((state) => state.listing);

    const { user, loading } = useSelector(
        (state) => state.auth
      );

      const _handleSearchm = () => {
        let cat_id = _getCatId();
    
        dispatch(handleListingValChange({ key: "page", value: 0 }));
        
       
        dispatch(getListings({ limit, page: 0, filters, search, cat_id }))
          .unwrap()
          .then(() => {
            
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
        
      };

      const _handleSearchText = (e) => {
        dispatch(handleListingValChange({ key: "search", value: e.target.value }));
      };

      const _getCatId = () => {
        
        switch (type) {
          case "car":
            return "1";
          case "machines":
            return "2";
          case "2":
              return "2";


          case "yatch":
            return "3";
          case "3":
            return "3";
    
          default:
            return "1";
        }
      };

      function scrollToMorelisting() {
        expmorepref.current.scrollIntoView({ behavior: "smooth", block: "start" })
      }

      useEffect(() => {
      
          let cat_id = _getCatId();
          dispatch(handleClearAllFilters({}))
          cat_id && dispatch(getdistvbcall({cat_id}))

          dispatch(
            getPremiumListings({
              limit: 100,
              page: 0,
              model: 0,
              cat_id: cat_id,
              city_id: "-",
            })
          );

          dispatch(getListings({ limit, page: 0, filters, search, cat_id }))
          .unwrap()
          .then(() => {
            
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });


        dispatch(handleListingValChange({ key: "page", value: 0 }));
        dispatch(handleListingValChange({ key: "activeSlide", value: 0 }));
        dispatch(handleListingValChange({ key: "selectedBrandModels", value: [] }));
        dispatch(handleChangeFilterValue({ key: "sort", value: "-/1" }));

        dispatch(getFilterMaster({}))
          .unwrap()
          .then(() => {})
          .catch((errorData) => {
            errorToast(errorData.error);
          });
          

          let master_filters_values = filter_master?.features?.find(
            (f) => f.categoryCode === cat_id
          )?.features;

          setAdvanceFilter(master_filters_values)


          if(!distvals){
            
            axiosConfig(`/getdistvb/${cat_id}`).then(t => {
              
            setDistvals(t.data)
            
              
          })
          }
          
       window.addEventListener("scroll", setScroll);
       return () => {
         window.removeEventListener("scroll", setScroll);
       };

// eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      useEffect(() => {
        let cat_id = _getCatId();
        dispatch(
          getPremiumListings({
            limit: 100,
            page: 0,
            model: 0,
            cat_id: cat_id,
            city_id: "-",
          })
        );
        let xd = filters
        
        axiosConfig.post(
         `/xgetdistvb`,
           {...xd , limit , page}
          
         )
         .catch(e => {
           errorToast("some listings are not available, please try to select category.");
         }) 
         
         
 // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [filters])

      useEffect(() => {
        
        switch (type) {
          case "car":
            setId("1");
            axiosConfig(`/getdistvb/1`).then(t => {
              setDistvals(t.data)
            })
            break;
          case "machines":
            setId("2");
            axiosConfig(`/getdistvb/2`).then(t => {
              setDistvals(t.data)
            })
            break;
    
          case "yatch":
            
            setId("3");
            axiosConfig(`/getdistvb/3`).then(t => {
              setDistvals(t.data)
            })
            break;
    
          default:
            setId("1");
            axiosConfig(`/getdistvb/1`).then(t => {
              
              setDistvals(t.data)
            })
            break;
        }
        if(!type){
          navigate("/car")
          setId("1");
        }
        dispatch(
          handleChangeFilterValue({
            key: "category",
            value: _getCatId(),
          })
        );
        
// eslint-disable-next-line react-hooks/exhaustive-deps
      }, [type, location]);


      const _handleClearAllFilters = () => {
        let cat_id = _getCatId();

        let master_filters_values = filter_master?.features?.find(
          (f) => f.categoryCode === cat_id
        )?.features;
        
        dispatch(handleClearAllFilters({}))
          .unwrap()
          .then(() => {
                        
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
          
          setAdvanceFilter(master_filters_values)
      };

      const _handleChangeTranslate = () => {
        let selectedLanguage = selected_lang === langs[0] ? langs[1] : langs[0];
        dispatch(
          handleValLangChange({ key: "rtl", value: selectedLanguage === langs[1] })
        );
        dispatch(
          handleValLangChange({ key: "selected_lang", value: selectedLanguage })
        );
        successToast(t(`Language Changed to ${selectedLanguage}`));
      };

      const _handleListingView = (item) => {
        _handleSaveCount(item, 0);
        dispatch(handleListingValChange({ key: "selectedListing", value: item }));
        navigate(`/item/${item?.tluid}-${item?._id}`);
      };

      const _handleChangeCurrency = () => {
         
        let selectedCurrency = 
          selected_curr === currencies[0] ? currencies[1] : currencies[0];

          

          let valuec = 1
          if(selected_curr === "AED"){
            valuec = filter_master.currencyinfo.find(t => t.name === "Dollar").value
          }else{
            valuec = filter_master.currencyinfo.find(t => t.name === "Dirham").value
          }
          
          dispatch(
          handleValCurrencyChange({
            key: "conversion_rate",
            value: String(valuec),
          })
        );
        dispatch(
          handleValCurrencyChange({ key: "selected_curr", value: selectedCurrency })
        );
        successToast(t(`Currency Changed to ${selectedCurrency}`));
      };

      const oAuth = (gauth, resp) => {
        if (gauth === "GOOGLE")
          dispatch(
            loginGoogleUser({
              g_response: resp,
            })
          )
            .unwrap()
            .then(() => {
              successToast("User Login Successfully");
              setLoginModel(false);
            })
            .catch((errorData) => {
              errorToast(errorData.error);
            });
      };
    
      const signinUser = async (e) => {
        e.preventDefault();
    
        dispatch(
          loginUser({
            email: email.value,
            password: password.value,
          })
        )
          .unwrap()
          .then(() => {
            e.target.reset();
            successToast("User Login Successfully");
            setLoginModel(false);
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
      };

      function afterOpenModal() {}

      const activateUser = (e, r) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (
          !r?.value?.trim() 
        ) {
          setValidationError("OTP is required");
        } else {
          axiosConfig.post(`${process.env.REACT_APP_API_URL}/aclink`, {id : emailx, codex : r?.value }, {headers : {"userAccessToken" : id}})
          .then(r => {
            if(r.data.message === "User is activated."){
              successToast("User Activated.");
              setOtpmodal(false)
            }
          })
            .catch((errorData) => {
              errorToast("Expired or Incorrect OTP.");
            });
        }
      };

      const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          fontSize : "0.8rem",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          zIndex: 999999,
        },
        overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
      };
    
      const signUpUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (
          !fullName.value.trim() ||
          !email.value.trim() ||
          !password.value.trim()
        ) {
          setValidationError("All fields are required");
        } else {
          dispatch(
            registerUser({
              fullName: fullName.value,
              email: email.value,
              password: password.value,
              phone: cell.value,
            })
          )
            .unwrap()
            .then(() => {
              e.target.reset();
              successToast("Registered Successfully, Check activation mail.");
              setOtpmodal(true)
            })
            .catch((errorData) => {
              if(errorData.error === "User already registered, OTP reset"){
                errorToast("User already registered, OTP reset");
                setOtpmodal(true)
              }else{
                errorToast(errorData.error);
              }
              
            });
        }
      };

      const expmore = (typeis) => {
        
        return (
          
            <button className='shadow montserrat-400 findbutton'  style={{position : "relative",  top : "3vh", paddingBottom : "2.5vh", fontWeight : "bold", borderRadius : "5px" , color : "white", height : "40px"}} 
            onClick={c => {c.preventDefault();  navigate(`/listings/${id}/${typeis}`)}}>{t("Explore More")}</button>
          
        )
      }

      const fsearches = () => {
        let nadv = advanceFilter.filter(
          (g) =>
            g.rangevalueL !== "" &&
            g.rangevalueL !== false &&
            g.rangevalueL !== undefined
        );
        let new_filters = nadv.map((filter) => {
          if (filter.rangevalueL === true) {
            return {
              ...filter,
              rangevalueL: "1",
            };
          } else {
            return filter;
          }
        });
        let filters = JSON.stringify(new_filters);
        let cat_id = id || "1";
        dispatch(handleListingValChange({ key: "page", value: 0 }));
        dispatch(getAdvanceListings({ limit, page: 0, filters, cat_id }));
        
      };

    
      const ocrv = (g, val) => {
        let newstate = advanceFilter.map((item) => ({ ...item })); // Clone objects within advanceFilter
    
        const foundIndex = newstate.findIndex(
          (h) => h.featureCode === g.featureCode
        );
    
        if (foundIndex !== -1) {
          if (g.fib) {
            newstate[foundIndex].rangevalueL = val === "true" ? false : true;
          } else {
            newstate[foundIndex].rangevalueL = val;
          }
    
          // Assuming setAdvanceFilter is a function to update the state
          setAdvanceFilter(newstate);
        } else {
         // console.error("Object with the provided featureCode not found.");
        }
      };

    return (
      <div  >
      <Toaster />

      <Modal
      ariaHideApp={false}
        isOpen={loginModel}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setLoginModel(false)} 
        style={{ ...customStyles }}
        contentLabel="Login Modal"
      >
        
            <div style={{display : "flex"}}>
                          <div>
                                    <button onClick={g => {g.preventDefault();setLoginModel(false)}} 
                                              style={{ color: "gray", fontSize : "1rem", marginTop : "10px"}}>
                                                &#x2716;
                                    </button>
                          </div>
                            &nbsp; &nbsp; &nbsp;
                          <div><h1>{t("Sign In")}</h1></div>
                      </div>
                    
        <form onSubmit={signinUser}>
          <div>
           
            <p className="mt-4 mb-8">
              {t("If you don't have an account You can")}
              <br />
              <button
                onClick={() => {
                  setLoginModel(false);
                  setSignupModel(true);
                }}
                className="link"
              >
                {t("Register here")} !
              </button>
            </p>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Email")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="email"
                placeholder={t("Enter your Email")}
                id="email"
                ref={(e) => {
                  email = e;
                }}
                type="email"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Password")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="password"
                placeholder={t("Enter your Passowrd")}
                id="password"
                ref={(e) => {
                  password = e;
                }}
                type="password"
                required
              />
            </div>
          </div>
          <center>
            <button
              type="submit"
              className="primaryButton mt-4"
              style={{ fontSize: "0.8rem" }}
            >
              {loading ? `${t("Loading")}...` : t("Sign In")}
            </button>
            
            <p
              style={{
                marginTop: "25px",
                fontSize: "0.7rem",
                cursor: "pointer",
              }}
              onClick={(t) => {
                resetup(email.value);
                setLoginModel(false);
              }}
            >
              Forgot your password ?
            </p>
          </center>
        </form>

        <br />
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            
            oAuth("GOOGLE", credentialResponse);
            
          }}
          onError={() => {
         
          }}
        />
      </Modal>

      <Modal
      ariaHideApp={false}
        isOpen={signupModel}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setSignupModel(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
            <div style={{display : "flex"}}>
                          <div>
                                    <button onClick={g => {g.preventDefault();setSignupModel(false);}} 
                                              style={{ color: "gray", fontSize : "1rem", marginTop : "10px"}}>
                                                &#x2716;
                                    </button>
                          </div>
                            &nbsp; &nbsp; &nbsp;
                          <div><h1>{t("Sign Up")}</h1></div>
                      </div>
                    
            {otpmodal ?
            <form onSubmit={e => activateUser(e, fullName)}>
             <div>
              <h5 className="block text-primary-grey text-[13px] font-medium pb-1">
                OTP Code from your email.
              </h5>
              <div className="relative">
                <input
                  className="inputField mb-8 w-full"
                  name="fullName"
                  placeholder="Enter OTP "
                  id="fullName"
                  onChange={() => setValidationError("")}
                  ref={(e) => {
                    fullName = e;
                  }}
                  type="text"
                  required
                />
              </div>
            </div>   
            <button type="submit" className="button-55">
              {loading ? `${t("Loading")}...` : "Activate"}
            </button>
            </form>
            :
            <form onSubmit={signUpUser}>
            <div>
              <p className="mt-4 mb-8">
                {t("If you already have an account registered You can")}
                <br />
                <button
                  onClick={() => {
                    setSignupModel(false);
                    setLoginModel(true);
                  }}
                  className="link"
                >
                  {t("Login here")} !
                </button>
              </p>
            </div>
            <div>
              <label className="block text-primary-grey text-[13px] font-medium pb-1">
                {t("Full Name")}
              </label>
              <div className="relative">
                <input
                  className="inputField mb-8 w-full"
                  name="fullName"
                  placeholder={t("Enter your full name")}
                  id="fullName"
                  onChange={() => setValidationError("")}
                  ref={(e) => {
                    fullName = e;
                  }}
                  type="text"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-primary-grey text-[13px] font-medium pb-1">
                {t("Email")}
              </label>
              <div className="relative">
                <input
                  className="inputField mb-8 w-full"
                  name="email"
                  placeholder={t("Enter your Email")}
                  id="email"
                  onChange={(e) => {setValidationError("");setEmailx(e.target.value)}}
                  ref={(e) => {
                    email = e;
                  }}
                  type="email"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-primary-grey text-[13px] font-medium pb-1">
                {t("Phone")}
              </label>
              <div className="relative">
                <input
                  className="inputField w-full mb-8"
                  name="cell"
                  placeholder={t("Enter your Phone Number")}
                  id="cell"
                  ref={(e) => {
                    cell = e;
                  }}
                  type="tel"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-primary-grey text-[13px] font-medium pb-1">
                {t("Password")}
              </label>
              <div className="relative">
                <input
                  className="inputField mb-8 w-full"
                  name="password"
                  placeholder={t("Enter your Passowrd")}
                  id="password"
                  onChange={() => setValidationError("")}
                  ref={(e) => {
                    password = e;
                  }}
                  type="password"
                  required
                />
              </div>
            </div>
            {validationError && (
              <p className="text-left text-red-500">{validationError}</p>
            )}
            <button type="submit" className="button-55">
              {loading ? `${t("Loading")}...` : t("Sign Up")}
            </button>
            </form>
            } 
            
      </Modal>

      <Modal
        isOpen={advanceFilterIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setAdvanceFilterIsOpen(false);
        }}
        style={customStyles}
        contentLabel="Advance Filter Modal"
        ariaHideApp={false}
      >
        <section className="z-50">
          <div className="text-center">
            <p className="text-xl font-bold mb-5 border-bottom">
              {t("ADVANCE_SEARCH")}
            </p>
          </div>

          <div className="flex flex-col justify-center">
            <PerfectScrollbar style={{ height: 500 }}>
              {(advanceFilter || [])?.map((g, key) => {
                return (
                  <div
                    id={key}
                    key={key}
                    className="flex flex-row gap-1 justify-between border-b my-2 pr-5 border-black-900"
                    style={{ paddingBottom: 10, marginLeft: 5 }}
                  >
                    <div className="flex flex-row gap-1 items-center">
                      <img
                        alt = {g?.ficon}
                        src={`${process.env.REACT_APP_API_URL}/fet/${g?.ficon}`}
                        style={{
                          width: 20,
                          objectFit: "contain",
                        }}
                      />{" "}
                      <p>{g?.[selected_lang]}</p>
                    </div>
                    <div style={{ padding: 2, marginRight: 10 }}>
                      {g.fib ? (
                        <input
                          type="checkbox"
                          value={g.rangevalueL}
                          checked={g.rangevalueL === true}
                          defaultValue={false}
                          onChange={(x) => {
                            ocrv(g, x.target.value);
                          }}
                        />
                      ) : (
                        <select
                        aria-label="State"
                          id="feature_value"
                          onChange={(v) => ocrv(g, v?.target.value)}
                          value={g.rangevalueL}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          <option value={"-"}>Choose an Option</option>
                          {g?.valueRange?.map((value) => (
                            <option value={value?.len}>
                              {value?.len || ""}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>
                );
              })}
            </PerfectScrollbar>
          </div>

          <button
            type="button"
            onClick={() => {
              
              setAdvanceFilterIsOpen(false);
              _handleAdvanceFilterHandler();
            }}
            className="text-white bg-primary font-medium  mt-4 px-6 py-2.5 text-center inline-flex items-center float-end rounded border-white border-1 border-solid border-white roundborderlightgray"
          >
            {t("lETS_GO_BUTTON")}
          </button>
        </section>
      </Modal>

    
        <>
            <div>
                {/** <div style={{backgroundColor : "black", height : "40px"}}></div> */}
              <div className="vttop_bar_r_m" style={{width : "100%", minWidth : "100%", backgroundColor : "#f1f1f1", borderBottom : "3px solid orange"}}> 
              <div className="qs"><img width="20" height="20" src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/globe.png`} alt="Globe"  /><span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "margin25"}>
                    {t("topbar_uae")}
                    <span className="popover">
                    {
                           distvbc?.cityCode?.length >= 0 ? 
                           <ul>
                            { /* eslint-disable-next-line */}
                           {distvbc?.cityCode?.map((city, index) => {
                              if(city?.city[selected_lang]){
                                return (
                                  <li style={{marginBottom : "10px" , marginTop : "10px"}}>
                                  <button key={"city" + index} id={city.cityCode} 
                                  onClick={x => {
                                    dispatch(
                                      handleChangeFilterValue({
                                        key: "citycode",
                                        value: city.city.cityCode,
                                      })
                                    );
                                  }}
                                  >
                                    {city?.city[selected_lang]}
                                  </button><br />
                                  </li>
                                )
                              }
                           
                           })}
                           </ul>
                        :
                        <p>{distvbc?.cityCode?.length}</p>
                      }   
                    </span>
                </span>
              </div>
              <div onClick={() => _handleChangeCurrency()} >
                <img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/Currency.png`} alt="Currency icon" />
                <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>
              {selected_curr === "AED" ? 
                      t("AED")
                    : 
                    t("USD")
                    }
                </span></div>
              <div >
                <img width="20" height="20" src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span>
              </div>

              <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img width="20" height="20" src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/App.png`} alt="App Icon" />
              <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>MOBILE APP</span></div>
              </div>
            </div>

            <div >
                                    {/** Mobile Logo */}                 
                                                <div style={{display : "flex", width : "100%"}}>
                                                  <div style={{width  :"50%", minWidth : "50%"}} >
                                                    <img
                                                            className="ml-2 mt-2"
                                                            width = "150"
                                                            height = "70"
                                                            src={`${process.env.REACT_APP_PUBLIC_URL}images/vnmlogo_new.webp`}
                                                            alt="VNM logo"
                                                            />
                                                  </div>          
                                    {/** Mobile Menu */}            
                                                  <div style={{display : "flex" , justifyContent : "right", width : "50%", minWidth :"50%"}} >
                                                        <div style={{marginTop :  "15px"}} className="mr-2" >
                                                        {!user ? (
                                                            <button
                                                                type="button "
                                                                className="mt-1"
                                                                onClick={() => {
                                                                setLoginModel(true);
                                                                
                                                                }}
                                                                style={{  fontSize : "0.7rem" , height : "4vh", width : "20vw" , backgroundColor : "orange" , fontWeight : "bold", borderRadius : "10px" }}
                                                            >
                                                                <center >
                                                                    {t("LOGIN")}{" "}
                                                                </center>
                                                            </button>
                                                            ) : (
                                                            <>
                                                                <button
                                                                type="button"
                                                                onClick={() => {
                                                                    
                                                                    if(window.location.href.includes("profile")){
                                                                    dispatch(logoutUser());
                                                                    }else{
                                                                    navigate("/profile");
                                                                    
                                                                    }
                                                                    
                                                                }}
                                                                style={{ marginTop: -5, fontSize : "0.7rem" , height: "40px", borderRadius : "25px"}}
                                                                className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10 "
                                                                >
                                                                { window.location.href.includes("profile") ? "Logout" :user?.fn}
                                                                </button>
                                                            </>
                                                          )}
                                                        </div>             
                                                  
                                                  </div>
                                                </div>
                                    {/** Mobile blue area with text search */}            
                                                <div className="homepremiumcolors_m montseraat-200">
                                                <h2 className="montserrat-400" >{t("ELOWV")}</h2>
                                                <p style={{fontSize : "0.9rem", marginTop : "-25px" , padding : "10px"}}>{t("ELOWV2")}</p>
                                                <center>
  {/** Find my perfect ride */}                                                
                                                          <p >
                                                              <div style={{display : "flex", width : "90%"}} >
                                                              <input type="text"  
                                                              placeholder={t("Find My Perfect Ride")} 
                                                              style={{fontSize : "0.8rem", width : "100%", padding: "5px 5px", lineHeight: "28px", backgroundColor : "#16254e",  borderRadius: "5px" , outline: "2px solid white" }}
                                                              onChange={(e) => _handleSearchText(e)}
                                                              onKeyDown={(x) => {
                                                                if(x.key === "Enter"){
                                                                  _handleSearchm()
                                                                  navigate(`/listings/${id}/listings` )
                                                                }
                                                              }}
                                                              onBlur={() => {
                                                                if(search === "") {
                                                                  _handleClearAllFilters()
                                                                } 
                                                              }}
                                                              />
                                                              <span style={{width : "20%" , marginLeft : "-28%" , marginTop  : "12px", fontSize : "0.5rem"}} >{selected_lang === "en" && t("QuickSearch")}</span>
                                                              <span style={{width : "2%", marginTop  : "10px"}}>
                                                                <img style={{width : "80%" , minWidth : "20px", backgroundColor : "orange", borderRadius : "5px", padding : "2px"}} src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/search_.png`} alt="facebook_icon" width="40" height="40"
                                                              onClick={() => {
                                                                _handleSearchm()
                                                                navigate(`/listings/${id}/listings`)}
                                                              }
                                                              /></span>
                                                              </div>
                                                          </p>
                                                          <div style={{display : "flex", width : "90%", marginTop : "12px" , marginLeft : "-7px"}}  >
                                                          <div className={type === "car" ? "buttonunderbarisactive_m" : "buttonunderbar_m"} 
                                                          style={{marginRight : "5px", paddingTop : "4px"}}
                                                           >
                                                          <NavLink
                                                          ref={navlink1}
                                                          className={({ isActive }) =>
                                                            isActive
                                                              ? "bactive no-underline capitalize montserrat-200"
                                                              : "no-underline capitalize montserrat-200"
                                                          }
                                                          to="/car"
                                                          style={{marginRight : "5px" , fontWeight : "bold"}}
                                                          onClick={(x) => {
                                                            dispatch(handleClearSearch({}));
                                                          
                                                          }}
                                                        >
                                                          {t("RENTACAR")}
                                                          </NavLink>
                                                          </div>
                                                            <div className={type === "yatch" ? "buttonunderbarisactive_m" : "buttonunderbar_m"} style={{marginRight : "5px", paddingTop : "4px"}}
                                                             >
                                                            <NavLink
                                                                ref={navlink3}
                                                                className={({ isActive }) =>
                                                                  isActive
                                                                    ? "bactive no-underline capitalize montserrat-200"
                                                                    : "no-underline capitalize montserrat-200"
                                                                }
                                                                to="/yatch"
                                                                onClick={(x) => {
                                                                  dispatch(handleClearSearch({}));
                                                                  
                                                                }} style={{marginRight : "5px", fontWeight : "bold"}}
                                                              >
                                                                {t("YATCHRENTAL")}
                                                              </NavLink>
                                                            </div>
                                                            <div className={type === "machines" ? "buttonunderbarisactive_m" : "buttonunderbar_m"} style={{ marginRight : "5px" , paddingTop : "4px"}} >
                                                            <NavLink
                                                              ref={navlink2}
                                                              to="/machines"
                                                              className={({ isActive }) =>
                                                                isActive
                                                                  ? "bactive no-underline capitalize montserrat-200"
                                                                  : "no-underline capitalize montserrat-200"
                                                              }   
                                                              
                                                              style={{marginRight : "5px", fontSize  :"0.8rem", fontWeight : "bold"}}
                                                            >
                                                              {t("MACHINES")}
                                                            </NavLink>
                                                            </div>
                                                          </div>

                                                </center>
                                                    
                                                </div>
                                    {/** Mobile view .. looking to rent a cat ?  */}          
                                                <div>
                                                <p  className="montseraat-400"
                                                    style={{
                                                      fontSize: "0.9rem",
                                                      fontWeight :  "bold",
                                                      marginTop : "1vh",
                                                      paddingLeft : "20px",
                                                      paddingRight : "20px"
                                                    }}
                                                  >
                                                    {t("LOOKING_FOR_A")}{" "}
                                                    <span style={{color : "#6A4D06"}}>{t("VEHICLE")}</span> {" "}?{" "} 
                                                      <span style={{fontWeight : "normal"}}>
                                                        {t("YOU_ARE_AT_RIGHT_PLACE")} 
                                                      </span>
                                                    
                                                </p>         
                                                </div> 
                                    {/** Mobile view .. Showing body and v type */}     
                                                <div >    
                                                  {type === "car" &&  
                                                  <div className="montserrat-200 tiles_m">
                                                      
                                                      <div id="xid1" className="atypex_m"
                                                        onClick = {t => {
                                                          dispatch(
                                                            handleChangeFilterValue({
                                                              key: "typecode",
                                                              value: "43",
                                                            })
                                                          ).then(() => {
                                                            setSeldistvals(t.target.id)
                                                            navigate(`/listings/${id}/listings` )
                                                          })
                                                        }}>
                                              {/** Luxury is vtype */}
                                                          <img id="xid1i" width="150" height="100" src={`${process.env.REACT_APP_CDN}Br7fsv0.png`} alt="Luxury_img" />
                                                          <p id="xid1p"> {t("Luxury")} </p>
                                                      </div>     
                                                      <div id="xid2" className="atypex_m"
                                                        onClick = {t => {
                                                          
                                                          dispatch(
                                                            handleChangeFilterValue({
                                                              key: "typecode",
                                                              value: "44",
                                                            })
                                                          ).then(() => {
                                                            setSeldistvals(t.target.id)
                                                            navigate(`/listings/${id}/listings` )
                                                          })
                                                        }}
                                                        >
                                              {/** Sports is vtype */}              
                                                          <img id="xid2i" width="150" height="100" src={`${process.env.REACT_APP_CDN}59Sspk9.png`} alt="Sports_img" />
                                                          <p id="xidp" > {t("Sports")} </p>
                                                      </div>     
                                                      <div id="xid3" className="atypex_m"
                                                      onClick = {t => {
                                                      
                                                        dispatch(
                                                          handleChangeFilterValue({
                                                            key: "typecode",
                                                            value: "46",
                                                          })
                                                        ).then(() => {
                                                          setSeldistvals(t.target.id)
                                                          navigate(`/listings/${id}/listings` )
                                                        })
                                                      }}
                                                      >
                                            {/** Standard is vtype 
                                             * 
                                             * {distvbc?.vtypeCode?.find(b => b.vtypeCode === "46")?.vtname[selected_lang] }
                                            */}                         
                                                        <img id="xid3i" width="150" height="100"  src={`${process.env.REACT_APP_CDN}BCtOGu7.png`} alt="Standard Vehicle" />
                                                        <p id="xid3p" > {t("Standard")} </p>
                                                      </div>     
                                                      <div id="xid4" className="atypex_m" 
                                                      onClick = {t => {
                                                        
                                                        dispatch(
                                                          handleChangeFilterValue({
                                                            key: "typecode",
                                                            value: "45",
                                                          })
                                                        ).then(() => {
                                                          setSeldistvals(t.target.id)
                                                          navigate(`/listings/${id}/listings` )
                                                        })
                                                      }}
                                                      >
                                            {/** Economy is vtype */}                         
                                                          <img id="xid4i" width="150" height="100" src={`${process.env.REACT_APP_CDN}r65Qq50.png`} alt="ECONOMY_img" />
                                                          <p id="xid4p" > {t("Economy")} </p>
                                                      </div>     
                                            
                                                      <div id="xid5"  className="atypex_m" 
                                                      onClick = {t => {
                                                        
                                                        dispatch(
                                                          handleChangeFilterValue({
                                                              key: "wdo",
                                                              value: filters.wdo === "-" ? "false" : "-",
                                                          })
                                                          ).then(() => {
                                                            setSeldistvals(t.target.id)
                                                            navigate(`/listings/${id}/listings` )
                                                          })
                                                      }}>
                                            {/** WD is vtype */}                         
                                                          <img id="xid5" width="150" height="100" style={{marginTop : "-10px"}} src={`${process.env.REACT_APP_CDN}kPlyLMd.png`} alt="WithDriver_img"  />
                                                          <p id="xid5p"  > {t("WITHDRIVER")} </p>
                                                      </div>     
                                            
                                                      <div id="xid6"  className="atypex_m" 
                                                      onClick = {t => {
                                                        
                                                        dispatch(
                                                          handleChangeFilterValue({
                                                            key: "bodytype",
                                                            value: "8",
                                                          })
                                                        ).then(() => {
                                                          setSeldistvals(t.target.id)
                                                          navigate(`/listings/${id}/listings` )
                                                        })
                                                      }}>
                                            {/** Convertable is vtype */}                         
                                                          <img id="xid6i" width="150" height="100" src={`${process.env.REACT_APP_CDN}JfczgOH.png`} alt="Convertable_img" />
                                                          <p id="xid6"> {t("Convertable")}</p>
                                                      </div>
                                                      <div id="xid7"  className="atypex_m" 
                                                      onClick = {t => {
                                                        
                                                        dispatch(
                                                          handleChangeFilterValue({
                                                            key: "bodytype",
                                                            value: "7",
                                                          })
                                                        ).then(() => {
                                                          setSeldistvals(t.target.id)
                                                          navigate(`/listings/${id}/listings` )
                                                        })
                                                      }}
                                                      >
                                          {/** SUV is vtype */}                         
                                                          <img id="xid7i" width="150" height="100" src={`${process.env.REACT_APP_CDN}HIO5UPa.png`} alt="SUV_img" />             
                                                          <p id="xid7p" > {t("SUV")} </p>
                                                      </div>     
                                                      <div id="xid8"  className="atypex_m"
                                                        onClick = {t => {
                                                          dispatch(
                                                            handleChangeFilterValue({
                                                              key: "bodytype",
                                                              value: "10",
                                                            })
                                                          ).then(() => {
                                                            setSeldistvals(t.target.id)
                                                            navigate(`/listings/${id}/listings` )
                                                          })
                                                        }}
                                                      >
                                          {/** Coupe is vtype */}                         
                                                              <img id="xid8i" width="150" height="100"  src={`${process.env.REACT_APP_CDN}KhTkLnN.png`} alt="Coupe_img" />             
                                                              <p id="xid8p" >{t("Coupe")}</p>
                                                      </div>     
                                                      <div id="xid9" className="atypex_m"
                                                      onClick = {t => {
                                                        
                                                        dispatch(
                                                          handleChangeFilterValue({
                                                            key: "bodytype",
                                                            value: "5",
                                                          })
                                                        ).then(() => {
                                                          setSeldistvals(t.target.id)
                                                          navigate(`/listings/${id}/listings` )
                                                        })
                                                      }}>
                                          {/** Hatchback is vtype */}                         
                                                            <img  id="xid9i" width="150" height="100" src={`${process.env.REACT_APP_CDN}6b7vqvZ.png`} alt="hatchback_img" />             
                                                            <p id="xid9p" > {t("Hatchback")} </p>
                                                      </div>     
                                                      <div id="xida10"  className="atypex_m"
                                                      onClick = {t => {
                                                        
                                                        dispatch(
                                                          handleChangeFilterValue({
                                                            key: "bodytype",
                                                            value: "11",
                                                          })
                                                        ).then(() => {
                                                          setSeldistvals(t.target.id)
                                                          navigate(`/listings/${id}/listings` )
                                                        })
                                                      }}
                                                      >
                                          {/** Van is vtype */}                         
                                                          <img id="xida10i" width="150" height="100" src={`${process.env.REACT_APP_CDN}2wKDjmi.png`} alt="VAN_img" />    
                                                          <p id="xida10p" > {t("Van")} </p>         
                                                      </div>     
                                                      <div id="xid11" className="atypex_m"
                                                        onClick = {t => {
                                                          
                                                          dispatch(
                                                            handleChangeFilterValue({
                                                              key: "bodytype",
                                                              value: "6",
                                                            })
                                                          ).then(() => {
                                                            setSeldistvals(t.target.id)
                                                            navigate(`/listings/${id}/listings` )
                                                          })
                                                        }}>
                                          {/** Sedan is vtype */}                         
                                                            <img id="xida11i" width="150" height="100" src={`${process.env.REACT_APP_CDN}12jBsEX.png`} alt="Sedan_img" />        
                                                            <p id="xida11p">  {t("Sedan")} </p>              
                                                      </div>     
                                                      <div id="xida12" className="atypex_m"
                                                        onClick = {t => {
                                                          
                                                          dispatch(
                                                            handleChangeFilterValue({
                                                              key: "bodytype",
                                                              value: "9",
                                                            })
                                                          ).then(() => {
                                                            setSeldistvals(t.target.id)
                                                            navigate(`/listings/${id}/listings` )
                                                          })
                                                        }}
                                                      >
                                          {/** Crossover is vtype */}                         
                                                            <img id="xida12i" width="150" height="100"  src={`${process.env.REACT_APP_CDN}YVq3k25.png`} alt="Crossover_img" />          
                                                            <p id="xida12p">{t("Crossover")} </p>                 
                                                      </div>     
                                                  </div>  
                                                }    
                                                </div>  
                                             
                                                  <center>
                                                  <div style={{backgroundColor : "#f1f1f1" , width : "90%", marginTop : "20px"}}>
                                                      <p className="montserrat-400" style={{textAlign : "center", fontSize : "0.8rem"}}> 
                                                      {t("ptext1m")}
                                                      </p>
                                                  </div>   
                                                  </center>       
                                    {/** Mobile view .. Premium cars component*/}         
                                           <Suspense>
                                                  <div style={{paddingBottom : "5vh" , marginTop : "3vh", backgroundColor : "#f1f1f1"}}>
                                                      <Homecarpremiummobile numb={numbp} setNumb={setNumbp} selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                                                        _handleListingView={_handleListingView} typeis = {"Premium"}  listRef0={listRef0} _handleSaveCount={_handleSaveCount} 
                                                        showcall={showcall} _handleWaCount={_handleWaCount} filters_master={filters_master} 
                                                        mdataone={mdataone} getmachines={getmachines} setShowcall={setShowcall} distvals={distvals}/>
                                                      <center>  
                                                      <button  className='montserrat-400 findbutton'  style={{color : "white", position : "relative",  top : "3vh", fontWeight : "bold", borderRadius : "5px", fontSize : "0.8rem" , height : "5vh"}}
                                                        onClick={c =>  scrollToMorelisting() }>{t("View More")}</button>
                                                      </center>
                                                  </div>

                                    {/** Mobile view .. Brands*/}                       
                                    
                                                  <div style={{marginTop : "5vh"}}>
                                                  <Vnmbrands selected_lang={selected_lang} brands={filters_master?.brands} distvals={distvals} seldistvals={seldistvals} setSeldistvals={setSeldistvals} _handleClearAllFilters={_handleClearAllFilters} dispatch={dispatch} handleChangeFilterValue={handleChangeFilterValue}  isTabletOrMobile={isTabletOrMobile} id={id}  navigate={navigate} />    
                                                  </div>

                                    {/** Mobile view .. featured cars component*/}         
                                                  <div ref={expmorepref} style={{marginBottom : "5vh" , marginTop : "5vh", paddingBottom : "5vh",  backgroundColor : "#f1f1f1"}}>
                                                    <Homecarcitymobile numb={numbp} setNumb={setNumbp}
                                                    selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                                                        _handleListingView={_handleListingView} typeis = {"Listings1"}  listRef0={listRef0} _handleSaveCount={_handleSaveCount} 
                                                        showcall={showcall} _handleWaCount={_handleWaCount} filters_master={filters_master} setShowcall={setShowcall} 
                                                        getsplist={getsplist} getmachines={getmachines} mdataone={mdataone} cwList={cwList} listings={listings} handleChangeFilterValue={handleChangeFilterValue} 
                                                        distvals={distvals}
                                                        /> 
                                                      <center>  {expmore("listings")} </center>
                                                  </div>
                                    {/** Mobile view .. Marketting*/}                                     
                                                  <div style={{marginTop : "5vh"}}>
                                                      <Homemarket t={t} isTabletOrMobile={isTabletOrMobile} />
                                                  </div>
                                    {/** Mobile view .. 2 featured cars component*/}                       
                                              {id !== "2" &&    <div style={{marginBottom : "5vh" , marginTop : "5vh", paddingBottom : "5vh" , backgroundColor : "#f1f1f1"}}>
                                                  {id === "1" &&  <Homecartypemobile numb={numbp} setNumb={setNumbp} selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                                                        _handleListingView={_handleListingView} typeis = {"Listings2"}  listRef0={listRef0} _handleSaveCount={_handleSaveCount} 
                                                        showcall={showcall} _handleWaCount={_handleWaCount} filters_master={filters_master} setShowcall={setShowcall} handleChangeFilterValue={handleChangeFilterValue}
                                                        getsplist={getsplist} vtList={vtList} listings={listings} 
                                                        distvals={distvals}
                                                        /> }  
                                                  <center>  {expmore("listings")} </center>
                                              </div> }
                                    {/** Mobile view .. User App*/}    
                                                <div  ref={appRef} >
                                                  <Userappm t={t}  iswhitea = {"Applestore_b.webp"} iswhiteg = {"googleplay_b.webp"} />       
                                                </div>  
                                      {/** Mobile view .. Our Services*/}    
                                                <div style={{marginTop : "50px"}}>
                                                  <Vnmservicesm t={t} selected_lang = {selected_lang}/>
                                                </div>
                                    {/** Mobile view .. Our Clients*/}               
                                                <div style={{marginTop : "50px", marginBottom : "50px"}} >
                                                <Vnmwhatclient t ={t} selected_lang={selected_lang} isTabletOrMobile={isTabletOrMobile}/>
                                                </div>
                                         </Suspense>
                                                <div style={{marginTop : "20px"}}>
                                             </div>  
            </div>

            <div style={{marginTop : "150px", paddingBottom : "50px" , backgroundColor : "#16254e"}}>
              <Suspense>
                  <Footerm selected_lang={selected_lang} navigate={navigate} />
              </Suspense>                                                   
            </div>
        </>

      </div>
    )
}
export default Homemobile  ;

