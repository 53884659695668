import React, { useEffect, useState } from 'react';
import axiosConfig from "../../utils/axiosConfig";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";

import {
    handleListingValChange,
    saveCount,
  } from "../../store/listings/listingActions";
  import { errorToast } from "../../utils";
  import { useTranslation } from "react-i18next";
  import {
    useNavigate,
  } from "react-router-dom";
  import Swal from "sweetalert2";
  import Lottie from "react-lottie";
  import LoadingAnimation from "../../assets/lottie/loading_animation.json";
  import { useMediaQuery } from "react-responsive";
   
  import verified from "../../assets/images/smicons/verified.webp";
  import Road from "../../assets/images/smicons/24.jpg";
  

function Otlc(props) {
    const  filters  = useSelector((state) => state.listing.filters);
    
    const dispatch = useDispatch();
    
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    const navigate = useNavigate();
    const [lst, setLst] = useState([])
    const { selected_lang } = useSelector((state) => state.lang);
    
    
    
    const { user } = useSelector((state) => state.auth);

    const [loading_listings, setLoading_listings] = useState(false)


    useEffect(() => {
      getlisting()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.tuid?.tuid])

    const { t} = useTranslation();
    
    const getlisting = () => {
        
       setLoading_listings(true)
        axiosConfig.get(`/ftto/${filters?.category}/${props.tuid.tuid}`).then(res => {
            
            setLst(res.data.data)    
            setLoading_listings(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {

      return (() => {
        setLst([])
        dispatch(handleListingValChange({ key: "selectedListing", value: null }));
      })
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const _handleListingView = (item) => {
        
       try{

       
        _handleSaveCount(item, 0);
        dispatch(handleListingValChange({ key: "selectedListing", value: item }));
        navigate(`/item/${item?.tluid}-${item?._id}`);
      }catch(e){
        
      } 
      };

      const _handleSaveCount = (item, type) => {
        if (type === 3 && !user) {
          Swal.fire({
            title: t("LOGIN_TO_ENQUIRE_TITLE"),
            text: t("LOGIN_TO_ENQUIRE_DESCRIPTION"),
            icon: "info",
            confirmButtonText: t("LOGIN"),
            confirmButtonColor: "#F39C12",
          })
        } else {
          dispatch(saveCount({ item, type, user }))
            .unwrap()
            .then(() => {
              if (type === 3) {
                Swal.fire({
                  title: t("REQUEST_SENT"),
                  text: t("CALLBACK_MESSAGE"),
                  icon: "info",
                  confirmButtonText: t("COOL"),
                  confirmButtonColor: "#F39C12",
                });
              }
            })
            .catch((errorData) => {
              errorToast(errorData.error);
            });
        }
      };

      const showcars = (tx, h) => {
        
        return (
          

                      <div className="fazeel_vnmcard" style={{border : "1px solid black" }} >
                                <div className="fazeel_vnmcard_image">
                                <div className="containerpremium">
                                    <div className="overlaypremiumverified">
                                    
                                    </div>
                                
                                      <div className="overlaypremiumbrand">
                                        {tx?.tenant?.cellIsVerified &&
                                          tx?.tenant?.addressIsVerified && (
                                            <img
                                              src={verified}
                                              alt="verified"
                                              style={{ width: "25px" }}
                                            />
                                          )}
                                      </div>
                                      <div className="overlaypremiumprice">
                                     {/**    <img
                                          src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${tx?.blogo}`}
                                          alt="Brands"
                                          width="50px"
                                        /> */}
                                      </div>
                                    <img
                                        className="rounded-t-lg" 
                                        style={{
                                          width: "100%"
                                        }}
                                        src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/listing/${tx?.imgmc}`}
                                        alt="imgmc"
                                        onClick={p => {
                                          props._handleListingView(tx)
                                        }} 
                                  
                                  />
                                 </div>
                                </div>
                                <div className="fazeel_vnmcard_detail" style={{width : "100%" , height : "200px" , maxHeight : "200px"}}>
{/**TOP DIV */}                                  
                                              <div className="fazeel_vnmcard_detail_box1" style={{width : "100%" , height : "25%" , maxHeight : "25%" }}>
                                                <div className="fazeel_acura">
                                                    <p>
                                                    {tx?.bname?.[props?.selected_lang]} {' '}  
                                                    {tx?.mname?.[props?.selected_lang]}
                                                    <br />
                                                    <span>{tx?.modelyear?.label}</span>
                                                    </p>
                                                </div>
                                                
                                                <div className="fazeel_aed" style={{width : "50%" , display : "flex", textAlign : "left"}}>
                                                    <img src={Road} alt="vnmRoad" style={{width : "30px" , height : "25px"}} />
                                                    <p style={{fontSize :"0.6rem"}}>
                                                    {props.t(`${props.selected_curr}`)}{parseFloat(Number(tx?.daily?.amt) / parseFloat(props.conversion_rate)).toFixed(0)}{" "}
                                                        <br />
                                                        <span style={{fontSize :"0.5rem"}} >{tx?.daily?.mileq}
                                                        {props.t("mileqq")}</span>
                                                    </p>
                                                </div>
                                                
                                            </div>
{/**Features  DIV */}                                  
                                  <div className="fazeel_vnmcard_detail_box2" style={{width : "100%" , height : "20%" , maxHeight : "20%" , marginBottom : "15px"}}>
                                  
                                  {tx?.features?.find((x) => x.featureCode === "40")
                                                ?.ficon && (
                                                    <>
                                                <div style={{marginRight : "5px", display : "flex"}}>
                                                <img
                                                    style={{ height: "20px", width: "20px" }}
                                                    src={`${process.env.REACT_APP_API_FS}${
                                                    process.env.REACT_APP_API_URL
                                                    }/fet/${
                                                    tx?.features.find((x) => x.featureCode === "40")
                                                        ?.ficon
                                                    }`}
                                                    alt="feature"
                                                /><span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features.find((x) => x.featureCode === "40").rangevalueL}</span>
                                                </div>
                                                <div style={{marginRight : "5px" , display : "flex", border : "1px solid gray" , borderRadius : "10px"}}>
                                                <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.7rem"}}>
                                                    {tx?.btname?.[props?.selected_lang]}</span>
                                                </div>
                                            
                                                </>
                                                )}
                                  </div>
{/**ModelYear Deposit  DIV */}                                      
                                  <div className="fazeel_vnmcard_detail_box3" style={{width : "100%" , height : "30%" , maxHeight : "30%"}}>
                                      <div style={{width : "50%", justifyContent : "left"}}>
                                      <div style={{width : "100%" }}>
                                     <img
                                          style={{marginLeft  : "-70px", width : "45px"}}
                                          src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/${tx?.tenant.cLogo}`}
                                          alt="Business Logo"
                                          width="30" height="30"
                                        />
                                       
                                      </div>   
                                       <div
                                          style={{ display: "flex", marginRight: "5px" }}
                                          onClick={() => props._handleListingView(tx)}
                                        >
                                          {Array.from({
                                            length: parseInt(tx?.tenant.rating) || 0,
                                          }).map((j, index1) => {
                                            return (
                                              <svg
                                                key={`filled-${h}=${index1}`}
                                                className="w-6 h-6 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#F39C12"
                                                viewBox="0 0 60 55"
                                                width="50"
                                                height="25"
                                                style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                              >
                                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                            );
                                          })}
                                          {parseFloat(tx?.tenant.rating) > parseInt(tx?.tenant.rating) && (
                                            <div style={{ position: "relative" }}>
                                              <svg
                                                key={`unfilled-1212`}
                                                className="w-6 h-6 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                style={{
                                                  position: "absolute",
                                                  paddingRight: "-15px",
                                                  marginRight: "-15px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="grey"
                                                viewBox="0 0 60 55"
                                                width="50"
                                                height="25"
                                              >
                                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                              <svg
                                                style={{
                                                  position: "absolute",
                                                  paddingRight: "-15px",
                                                  marginRight: "-15px",
                                                }}
                                                key={`unfilled-12`}
                                                className="w-6 h-6 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#F39C12"
                                                viewBox="0 0 60 55"
                                                width="50"
                                                height="25"
                                              >
                                                <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                              </svg>
                                            </div>
                                          )}
                                          {Array.from({
                                            length: 5 - parseInt(tx?.tenant.rating),
                                          }).map((k, index2) => {
                                            return (
                                              <>
                                                <svg
                                                  key={`unfilled-${h}-${index2}`}
                                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                                  aria-hidden="true"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="grey"
                                                  viewBox="0 0 60 55"
                                                  width="50"
                                                  height="25"
                                                  style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                                >
                                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                </svg>
                                              </>
                                            );
                                          })}
                                        </div>
                                      
                                      </div>
                                      <div style={{float : "right", width : "50%", marginTop  : "-20px"}}>
                                       <p>{props.t("WITHINSURANCE")}<br/> {props.t("SecurityDeposit")}: {props.t(`${props.selected_curr}`)} {parseFloat(Number(tx?.securitydeposit) / (props.conversion_rate)).toFixed(0)}</p>
                                      </div>
                                  </div>
{/**Buttons  DIV */}                                      
                                  <div className="fazeel_vnmcard_detail_box4 " style={{width : "100%" , height : "15%" , maxHeight : "15%"}} >
                                      {isTabletOrMobile ? (
                                          <form action={`tel:${tx?.tenant?.cellNumberc}`}>
                                          <button
                                            
                                            onClick={() => {
                                            props._handleSaveCount(tx, 2);
                                            }}
                                          >
                                            <img
                                              src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                              alt="call button abc"
                                            />
                                          </button>
                                          </form>
                                        ) : (
                                          <button
                                            style={{ fontSize: "0.5rem", color: "black" }}
                                            onClick={() => {
                                              props._handleSaveCount(tx, 2);
                                          //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                            }}
                                          >
                                          
                                            {props.showcall?.id === tx.tluid && props.showcall.boolv === true ? (
                                              <center>{tx?.tenant?.cellNumberc}</center>
                                            ) : (
                                              <img
                                                src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                alt="abc"
                                              />
                                            )}
                                          </button>
                                        )}    
                                    <div className="fazeel_line"></div>
                                        <button
                                        onClick={() => {props._handleWaCount(tx, "1"); props.setShowcall({ ...props.showcall, id: tx.tluid, boolv: false, boolw : true })}}
                                        > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                                    <div className="fazeel_line"></div>
                                       <button onClick={() => props._handleListingView(tx)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                                  </div>
                                </div>
                      </div>

          
        )
      }

      const showyatch = (tx, h) => {
        return (
          <div >
                        <div className="fazeel_vnmcard" style={{border :  "1px solid black" }}>
                          <div className="fazeel_vnmcard_image">
                          <div className="containerpremium">
                              <div className="overlaypremiumverified">
                              <div>
                                 
                                </div>
                              </div>
                          
                                <div className="overlaypremiumbrand">
                                  {tx?.tenant?.cellIsVerified &&
                                    tx?.tenant?.addressIsVerified && (
                                      <img
                                        src={verified}
                                        alt="verified"
                                        style={{ width: "25px" }}
                                      />
                                    )}
                                </div>
                                <div className="overlaypremiumprice">
                               {/**    <img
                                    src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${tx?.blogo}`}
                                    alt="Brands"
                                    width="50px"
                                  /> */}
                                </div>
                              <img
                                  className="rounded-t-lg" 
                                  style={{
                                    width: "100%"
                                  }}
                                  src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/listing/${tx?.imgmc}`}
                                  alt="imgmc"
                                  onClick={p => {
                                    props._handleListingView(tx)
                                  }} 
                            
                            />
                          </div>
                          </div>
                          <div className="fazeel_vnmcard_detail" style={{width : "100%" , height : "200px" , maxHeight : "200px"}}>
              {/**TOP DIV */}                                  
                            <div className="fazeel_vnmcard_detail_box1" style={{width : "100%" , height : "30%" , maxHeight : "30%" , marginTop : "-10px"}}>
                                <div className="fazeel_acura">
                                    <p>
                                    {tx?.mname?.[props?.selected_lang]}
                                    <br />
                                    <span>{tx?.modelyear?.label}</span>
                                  </p>
                                </div>
                                
                                <div className="fazeel_aed" style={{display : "flex"}}>
                                    
                                    <p>
                                    {props.t(`${props.selected_curr}`)}&nbsp; {parseFloat(Number(tx?.hourly?.amt) / parseFloat(props.conversion_rate)).toFixed(0)}{" "}
                                      <br />
                                      <span>
                                        {props.t("PerHour")}</span>
                                    </p>
                                </div>
                              
                            </div>
              {/**Features  DIV */}                                  
                            <div className="fazeel_vnmcard_detail_box2" style={{width : "100%" , height : "20%" , maxHeight : "20%" , marginBottom : "15px"}}>
                            
                            {tx?.features?.find((x) => x.featureCode === "108")
                                ?.ficon && (
                                  <>
                                <div style={{marginRight : "5px", display : "flex"}}>
                                <img
                                  style={{ height: "20px", width: "20px" }}
                                  src={`${process.env.REACT_APP_API_FS}${
                                    process.env.REACT_APP_API_URL
                                  }/fet/${
                                    tx?.features.find((x) => x.featureCode === "108")
                                      ?.ficon
                                  }`}
                                  alt="feature"
                                />
                                <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features.find((x) => x.featureCode === "108")[props?.selected_lang]}</span>
                                <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features.find((x) => x.featureCode === "108").rangevalueL}</span>
                                </div>
                              
                                </>
                              )}
                            </div>
              {/**ModelYear Deposit  DIV */}                                      
                            <div className="fazeel_vnmcard_detail_box3" style={{width : "100%" , height : "30%" }}>
                            <div style={{width : "50%"}}>
                                <img
                                  className="rounded-t-lg"
                                  style={{marginLeft : "-70px",  width : "45px"}}
                                  src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/${tx?.tenant.cLogo}`}
                                  alt="Business Logo"
                                  width="30" height="30"
                                />
                                  <div
                                    style={{ display: "flex", marginRight: "5px" }}
                                    onClick={() => props._handleListingView(tx)}
                                  >
                                    {Array.from({
                                      length: parseInt(tx?.tenant.rating) || 0,
                                    }).map((j, index1) => {
                                      return (
                                        <svg
                                          key={`filled-${h}=${index1}`}
                                          className="w-6 h-6 text-gray-800 dark:text-white"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="#F39C12"
                                          viewBox="0 0 60 55"
                                          width="50"
                                          height="25"
                                          style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                        >
                                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                        </svg>
                                      );
                                    })}
                                    {parseFloat(tx?.tenant.rating) > parseInt(tx?.tenant.rating) && (
                                      <div style={{ position: "relative" }}>
                                        <svg
                                          key={`unfilled-1212`}
                                          className="w-6 h-6 text-gray-800 dark:text-white"
                                          aria-hidden="true"
                                          style={{
                                            position: "absolute",
                                            paddingRight: "-15px",
                                            marginRight: "-15px",
                                          }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="grey"
                                          viewBox="0 0 60 55"
                                          width="50"
                                          height="25"
                                        >
                                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                        </svg>
                                        <svg
                                          style={{
                                            position: "absolute",
                                            paddingRight: "-15px",
                                            marginRight: "-15px",
                                          }}
                                          key={`unfilled-12`}
                                          className="w-6 h-6 text-gray-800 dark:text-white"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="#F39C12"
                                          viewBox="0 0 60 55"
                                          width="50"
                                          height="25"
                                        >
                                          <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                        </svg>
                                      </div>
                                    )}
                                    {Array.from({
                                      length: 5 - parseInt(tx?.tenant.rating),
                                    }).map((k, index2) => {
                                      return (
                                        <>
                                          <svg
                                            key={`unfilled-${h}-${index2}`}
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="grey"
                                            viewBox="0 0 60 55"
                                            width="50"
                                            height="25"
                                            style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                          >
                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                        </>
                                      );
                                    })}
                                  </div>
                                
                                </div>
                            </div>
              {/**Buttons  DIV */}                                      
                            <div className="fazeel_vnmcard_detail_box4 " style={{width : "100%" , height : "15%" , maxHeight : "15%"}} >
                                {isTabletOrMobile ? (
                                    <form action={`tel:${tx?.tenant?.cellNumberc}`}>
                                    <button
                                      
                                      onClick={() => {
                                      props._handleSaveCount(tx, 2);
                                      }}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                        alt="call button abc"
                                      />
                                    </button>
                                    </form>
                                  ) : (
                                    <button
                                      style={{ fontSize: "0.5rem", color: "black" }}
                                      onClick={() => {
                                        props._handleSaveCount(tx, 2);
                                    //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                      }}
                                    >
                                    
                                      {props.showcall?.id === tx.tluid && props.showcall.boolv === true ? (
                                        <center>{tx?.tenant?.cellNumberc}</center>
                                      ) : (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                          alt="abc"
                                        />
                                      )}
                                    </button>
                                  )}    
                              <div className="fazeel_line"></div>
                                  <button
                                  onClick={() => {props._handleWaCount(tx, "1"); props.setShowcall({ ...props.showcall, id: tx.tluid, boolv: false, boolw : true })}}
                                  > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                              <div className="fazeel_line"></div>
                                <button onClick={() => props._handleListingView(tx)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                            </div>
                          </div>
                          </div>

          </div>
        )
      }

      const showmachine = (tx, h) => {
          return (
            <div className="fazeel_vnmcard" >
            <div className="fazeel_vnmcard_image">
            <div className="containerpremium">
               
            
                  <div className="overlaypremiumbrand">
                    {tx?.tenant?.cellIsVerified &&
                      tx?.tenant?.addressIsVerified && (
                        <img
                          src={verified}
                          alt="verified"
                          style={{ width: "25px" }}
                        />
                      )}
                  </div>

                  <div className="overlaypremiumprice">
                 {/**    <img
                      src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${tx?.blogo}`}
                      alt="Brands"
                      width="50px"
                    /> */}
                  </div>
                <img
                    className="rounded-t-lg" 
                    style={{
                      width: "100%"
                    }}
                    src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/listing/${tx?.imgmc}`}
                    alt="imgmc"
                    onClick={p => {
                      props._handleListingView(tx)
                    }} 
              
              />
            </div>

            </div>
            <div className="fazeel_vnmcard_detail" style={{width : "100%" , height : "200px" , maxHeight : "200px"}}>
{/**TOP DIV */}                                  
              <div className="fazeel_vnmcard_detail_box1" style={{width : "100%" , height : "30%" , maxHeight : "30%" , marginTop : "-10px"}}>
                  <div className="fazeel_acura">
                      <p>
                      {tx?.mname?.[props?.selected_lang]}
                      <br />
                      <span>{tx?.modelyear?.label}</span>
                    </p>
                  </div>
                  
                  <div className="fazeel_aed" style={{display : "flex"}}>
                      
                      <p>
                      {props.t(`${props.selected_curr}`)}&nbsp; {parseFloat(Number(tx?.daily?.amt) / parseFloat(props.conversion_rate)).toFixed(0)}{" "}
                        <br />
                        <span>
                          {props.t("Daily")}</span>
                      </p>
                  </div>
                
              </div>
{/**Features  DIV */}                                  
              <div className="fazeel_vnmcard_detail_box2" style={{width : "100%" , height : "20%" , maxHeight : "20%" , marginBottom : "15px"}}>
              <div style={{marginRight : "5px" , display : "flex", border : "1px solid gray" , borderRadius : "10px"}}>
                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.7rem" , color : "black"}}>
                           {tx?.vtname[props.selected_lang]}</span>
                  </div>
              {tx?.features[0]
                  ?.ficon && (
                    <>
                  <div style={{marginRight : "5px", display : "flex"}}>
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={`${process.env.REACT_APP_API_FS}${
                      process.env.REACT_APP_API_URL
                    }/fet/${
                      tx?.features[0]
                        ?.ficon
                    }`}
                    alt="feature"
                  />
                  <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features[0][props.selected_lang]}</span>
                  <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features[0].rangevalueL}</span>
                  </div>
                  
                  </>
                )}
              </div>
{/**ModelYear Deposit  DIV */}                                      
              <div className="fazeel_vnmcard_detail_box3" style={{width : "100%" , height : "30%" , maxHeight : "30%"}}>
              <div style={{width : "50%", justifyContent : "left"}}>
              <div style={{width : "100%" }}>
                                 <img
                                      style={{marginLeft  : "-0.5vw", width : "3vw"}}
                                      src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/${tx?.tenant.cLogo}`}
                                      alt="Business Logo"
                                      width="30" height="30"
                                    />
                                   
                                  </div>   
                  <div
                      style={{ display: "flex", marginRight: "5px" }}
                      onClick={() => props._handleListingView(tx)}
                    >
                      {Array.from({
                        length: parseInt(tx?.tenant.rating) || 0,
                      }).map((j, index1) => {
                        return (
                          <svg
                            key={`filled-${h}=${index1}`}
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#F39C12"
                            viewBox="0 0 60 55"
                            width="50"
                            height="25"
                            style={{ paddingRight: "-15px", marginRight: "-15px" }}
                          >
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                          </svg>
                        );
                      })}
                      {parseFloat(tx?.tenant.rating) > parseInt(tx?.tenant.rating) && (
                        <div style={{ position: "relative" }}>
                          <svg
                            key={`unfilled-1212`}
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            style={{
                              position: "absolute",
                              paddingRight: "-15px",
                              marginRight: "-15px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="grey"
                            viewBox="0 0 60 55"
                            width="50"
                            height="25"
                          >
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                          </svg>
                          <svg
                            style={{
                              position: "absolute",
                              paddingRight: "-15px",
                              marginRight: "-15px",
                            }}
                            key={`unfilled-12`}
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#F39C12"
                            viewBox="0 0 60 55"
                            width="50"
                            height="25"
                          >
                            <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                          </svg>
                        </div>
                      )}
                      {Array.from({
                        length: 5 - parseInt(tx?.tenant.rating),
                      }).map((k, index2) => {
                        return (
                          <>
                            <svg
                              key={`unfilled-${h}-${index2}`}
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="grey"
                              viewBox="0 0 60 55"
                              width="50"
                              height="25"
                              style={{ paddingRight: "-15px", marginRight: "-15px" }}
                            >
                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                          </>
                        );
                      })}
                    </div>
                  
                  </div>
                  <div style={{float : "right", width : "50%", marginTop  : "-20px"}}>
                 {/**  <p>{props.t("MODEL")}: {tx?.modelyear?.label}<br/>{ props.t("WITHINSURANCE")}<br/> {props.t("SecurityDeposit")}: {props.t(`${props.selected_curr}`)} {parseFloat(Number(tx?.securitydeposit) / (props.conversion_rate)).toFixed(0)}</p>*/}
                  </div>
              </div>
{/**Buttons  DIV */}                                      
              <div className="fazeel_vnmcard_detail_box4 " style={{width : "100%" , height : "15%" , maxHeight : "15%"}} >
                  {isTabletOrMobile ? (
                      <form action={`tel:${tx?.tenant?.cellNumberc}`}>
                      <button
                        
                        onClick={() => {
                        props._handleSaveCount(tx, 2);
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                          alt="call button abc"
                        />
                      </button>
                      </form>
                    ) : (
                      <button
                        style={{ fontSize: "0.5rem", color: "black" }}
                        onClick={() => {
                          props._handleSaveCount(tx, 2);
                      //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                        }}
                      >
                      
                        {props.showcall?.id === tx.tluid && props.showcall.boolv === true ? (
                          <center>{tx?.tenant?.cellNumberc}</center>
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                            alt="abc"
                          />
                        )}
                      </button>
                    )}    
                <div className="fazeel_line"></div>
                    <button
                    onClick={() => {props._handleWaCount(tx, "1"); props.setShowcall({ ...props.showcall, id: tx.tluid, boolv: false, boolw : true })}}
                    > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                <div className="fazeel_line"></div>
                  <button onClick={() => props._handleListingView(tx)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
              </div>
            </div>
            </div>
          )
      }


      const retst = () => {
        return (
          <Carousel
          centerSlidePercentage={isTabletOrMobile ? 90 : 30}
          centerMode
          autoPlay
          showStatus={false}
          infiniteLoop
          stopOnHover={true}
          renderIndicator={true}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className={`${
                  hasPrev ? "absolute" : "hidden"
                } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-10`}
                onClick={clickHandler}
              >
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "images/icons/left.png"
                  }
                  alt="left Arrow"
                  style={{width : "30px", height: "30px"}}
                />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                className={`${
                  hasNext ? "absolute" : "hidden"
                } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-10 `}
                onClick={clickHandler}
              >
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "images/icons/right.png"
                  }
                  style={{width : "30px", height: "30px"}}
                  alt="right arrow"
                />
              </div>
            );
          }}
          className="direction_initial"
          showIndicators={false}
          showThumbs={false}
          showArrows={true}
          i18nIsDynamicList={true}
        >
          {lst.length <= 1 ? <span></span> : 
            lst?.map((item, index) => 
            {
            return(  
            <div
              style={{paddingRight : "30px", paddingLeft : "30px" }}
              key={`slider-${index}`}
              className="m-2 containerpremium"
              onClick={() => {_handleListingView(item); props.setSelectedListing({})}}
            >
 {/** This is the core */}             
              {filters?.category === "1" ? showcars(item, index) : filters?.category === "3" ? showyatch(item, index) : showmachine(item, index) }
            
             </div>
           )})}
        </Carousel>
        )
      }

    return (
        <div  className='montseraat-400 mt-5' >
          
           <p style={{fontSize : "1rem"}}>{props.tuid?.tenant.tname[selected_lang]}</p>
            {!loading_listings ? (
                retst()
              ) : (
                <div key={"loading"} className="text-center">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: LoadingAnimation,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={300}
                    width={300}
                  />
                </div>
              )}
        </div>
    );
}

export default Otlc;

