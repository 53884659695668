import React, { useEffect, useState } from "react";
import {
  FaCalendar,
  FaCheck,
  FaGasPump,
  FaWhatsapp,
  FaHeart,
  FaRegHeart,
} from "react-icons/fa";
import { MdGasMeter } from "react-icons/md";
import { FaAnglesRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { favProduct, handleListingValChange, saveCount } from "../../store/listings/listingActions";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { errorToast } from "../../utils";
import { useNavigate } from "react-router";

const HorizentalCard = ({ item }) => {

  const { selected_curr } =
  useSelector((state) => state.curr);


  const dispatch = useDispatch();
  const [showcall, setShowcall] = useState(false);
  const { user, user_profile } = useSelector((state) => state.auth);
  const { selected_lang } = useSelector((state) => state.lang);
  const { conversion_rate } = useSelector((state) => state.curr);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const _handleCallCount = (item, type) => {
    if (type === 2 && !user) {
      dispatch(saveCount({ item, type, user: { ubd: { guid: "callxyz" } } }));
    } else {
      dispatch(saveCount({ item, type, user }));
    }
  };

  const sendwa = (item) => {
    window.open(
      `https://wa.me/${item?.tenant?.waNumber}?text=Hi,%20messaging%20you%20regarding%20your%20listing%20${item?.bname[selected_lang]}%20,%20${item?.mname[selected_lang]}%20,%20${item?.modelyear.label}%20which%20have%20id%20as%20${item?.tluid}%20on%20https://VNMRENTAL.com`,`https://wa.me/${item?.tenant?.waNumber}?text=Hi,%20messaging%20you%20regarding%20your%20listing%20${item?.bname[selected_lang]}%20,%20${item?.mname[selected_lang]}%20,%20${item?.modelyear.label}%20which%20have%20id%20as%20${item?.tluid}%20on%20https://VNMRENTAL.com%20is%20it%20available?`,
      "_blank",
      "noreferrer"
    );
  };

  const _handleSaveCount = (item, type) => {
    dispatch(saveCount({ item, type, user }))
      .unwrap()
      .then(() => {
        if (type === 3) {
          Swal.fire({
            title: t("REQUEST_SENT"),
            text: t("CALLBACK_MESSAGE"),
            icon: "info",
            confirmButtonText: t("COOL"),
            confirmButtonColor: "#F39C12",
          });
        }
      })
      .catch((errorData) => {
        errorToast(errorData.error);
      });
  };

  const _handleListingView = (item) => {
    _handleSaveCount(item, 0);
    dispatch(handleListingValChange({ key: "selectedListing", value: item }));
    navigate(`/item/${item?.tluid}-${item?._id}`);
  };

  return (
    <div style={{marginTop : "10px", marginBottom : "10px", border : "1px solid black", borderRadius : "10px"}}>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 border border-gray-200 rounded items-center">
        {/* Image Tag */}
        <div className="containerpremium">
          <img
            className="h-auto max-w-full rounded-l-lg"
            src={`${process.env.REACT_APP_API_URL}/limg/${item?.tuid}/listing/${item.imgmc}`}
            style={{
              objectFit: "contain",
            }}
            alt=""
          />
          <div className="overlaypremiumbrand">
            <img
              className="rounded-t-lg"
              style={{  marginLeft: 0 , width : "30px", maxWidth : "30px" }}
              src={`${process.env.REACT_APP_API_URL}/limg/${item?.tuid}/${item.tenant.cLogo}`}
              alt="xyz"
            />
          </div>
          <div className="overlaypremiumprice mt-2">
            {user && user_profile?.usertags?.includes(item.tluid) ? (
              <button
                onClick={() => {
                  dispatch(
                    favProduct({
                      tluid: item.tluid,
                      typed: "pull",
                    })
                  );
                }}
              >
                <FaHeart color="red" size="28" />
              </button>
            ) : (
              <button
                onClick={() => {
                  dispatch(
                    favProduct({
                      tluid: item.tluid,
                      typed: "push",
                    })
                  );
                }}
              >
                <FaRegHeart color="red" size="28" />
              </button>
            )}
          </div>
        </div>
        {/* Image Tag */}
        <div className="col-span-2">
          <h6>
            {" "}
            {`${item?.bname[selected_lang]} ${item?.mname[selected_lang]} ${item?.modelyear?.label}`}
          </h6>

          {/* Chips */}
          <div>
            <span className="bg-gray-200 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 border-gray-500 ">
              <FaGasPump className="w-2.5 h-2.5 me-1.5" />
              {item?.fuelname[selected_lang]}
            </span>

            <span className="bg-gray-200 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 border-gray-500 ">
              <FaCalendar className="w-2.5 h-2.5 me-1.5" />
              {item?.modelyear?.label}
            </span>

            <span className="bg-gray-200 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 border-gray-500 ">
              <MdGasMeter className="w-2.5 h-2.5 me-1.5" />
              {item?.approxmiles}
            </span>

            {/* <span className="bg-gray-200 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 border-gray-500 ">
              <MdTransform className="w-2.5 h-2.5 me-1.5" />
              {item?.trmname[selected_lang]}
            </span> */}
          </div>
          {/* Chips */}

          {/* Features List 
          <div className="mt-2">
            <ul className="max-w-md space-y-1 text-gray-500 list-inside text-xs ">
              {item?.features?.slice(0, 3)?.map((feature, index) => (
                <li key={`feature-${index}`} className="flex items-center">
                  <FaCheck className="w-3.5 h-3.5 me-2 text-yellow-500 " />
                  {feature[selected_lang]}
                </li>
              ))}
            </ul>
          </div>
           Features List */}

          <div className="mt-2">
            <ul className="max-w-md space-y-1 text-gray-500 list-inside text-xs ">
                  {item?.categoryCode === "1" && 
                  <>
                  <li key={`feature-01`} className="flex items-center">
                      <FaCheck className="w-3.5 h-3.5 me-2 text-yellow-500 " />
                      Insurence : {item?.insurence ? "Full Insurence" : "Thirdparty Insurence"}
                    </li>
                    <li key={`feature-01`} className="flex items-center">
                      <FaCheck className="w-3.5 h-3.5 me-2 text-yellow-500 " />
                      Tranmission :{item?.trmname[selected_lang] } , Colors (Int/Ext) :   {item?.intcolor } / {item?.extcolor } 
                    </li>
                    </>
                    }
                 {
                  item?.categoryCode !== "1" &&
                  <>
                  <li key={`feature-01`} className="flex items-center">
                      <FaCheck className="w-3.5 h-3.5 me-2 text-yellow-500 " />
                      {item?.features[0]?.[selected_lang] } {item?.features[0]?.unit } , {item?.features[0]?.rangevalueL }  
                    </li>
                    <li key={`feature-01`} className="flex items-center">
                    <FaCheck className="w-3.5 h-3.5 me-2 text-yellow-500 " />
                    {item?.features[1]?.[selected_lang] } {item?.features[1]?.unit } , {item?.features[1]?.rangevalueL }  
                  </li>
                  
                  </>
                 }   
              
                <li key={`feature-01`} className="flex items-center">
                  <FaCheck className="w-3.5 h-3.5 me-2 text-yellow-500 " />
                  {t("SecurityDeposit")} : 
                  {(
                  parseFloat(item?.securitydeposit) /
                  conversion_rate
                )?.toFixed(2)}

                   {selected_curr}
                </li>
            </ul>
          </div>  


          {/* Button Group */}
          <div className="inline-flex rounded-md shadow-sm mt-3">
            <button
              type="button"
              onClick={(x) => {
                _handleCallCount(item, 3);
                Swal.fire({
                  title: t("REQUEST_SENT"),
                  text: t("CALLBACK_MESSAGE"),
                  icon: "info",
                  confirmButtonText: t("COOL"),
                  confirmButtonColor: "#F39C12",
                });
              }}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-yellow-500 border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              {/** <FaPhoneAlt className="w-4.5 h-4.5 me-1.5 " />*/}
            Call Back
            </button>
            <button
              type="button"
              onClick={(x) => {
                _handleCallCount(item, 1);
                sendwa(item);
              }}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-green-500 border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              <FaWhatsapp className="w-6.5 h-6.5 me-1.5" />
            </button>
            <button
              type="button"
              onClick={() => {_handleSaveCount(item, 2);   setShowcall(true);}}
              className="inline-flex items-center px-4 py-2 text-xs font-thin text-white bg-gray-600 border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            > Call
                {showcall && (
                <div>
                  {" "}
                  {item?.tenant?.landLine} {" , "} {item?.tenant?.waNumber}
                </div>
              )}
            </button>
          </div>
          {/* Button Group */}
        </div>
        <div className="bg-gray-100 px-5 rounded-r-lg">
          {/* View Details Div */}
          <span 
          
          onClick={() => _handleListingView(item)}
          className="text-yellow-500 text-xs font-bold inline-flex items-center py-1.5 rounded me-2" style={{cursor : "pointer"}}>
            View Details
            <FaAnglesRight className="w-2.5 h-2.5 me-1.5" />
          </span>
          {/* View Details Div */}
          {/* Prices Box */}
          {item?.categoryCode === "1" ? 
          <div>
          <dl className="max-w-md text-gray-900 divide-y divide-gray-400">
            <div className="flex flex-col pb-1">
              <dt className="mb-1 text-gray-600 md:text-sm font-semibold">
                {selected_curr}
                {(
                  parseFloat(user ? item?.daily?.amt : item?.daily?.amt) /
                  conversion_rate
                )?.toFixed(2)}
              </dt>
              <dd className="text-xs font-thin">
                Daily Service includes{" "}
                {(
                  parseFloat(
                    user ? item?.daily?.mileq : item?.daily?.mileq
                  ) * conversion_rate
                )?.toFixed(2)}{" "}
                Kilometers
              </dd>
            </div>
            <div className="flex flex-col pb-1">
              <dt className="mb-1 text-gray-600 md:text-sm font-semibold">
              {selected_curr}
                {(
                  parseFloat(user ? item?.weekly?.amt : item?.weekly?.amt) /
                  conversion_rate
                )?.toFixed(2)}
              </dt>
              <dd className="text-xs font-thin">
                {" "}
                Weekly Service includes{" "}
                {(
                  parseFloat(
                    user ? item?.weekly?.mileq : item?.weekly?.mileq
                  ) * conversion_rate
                )?.toFixed(2)}{" "}
                Kilometers
              </dd>
            </div>
            <div className="flex flex-col pb-1">
              <dt className="mb-1 text-gray-600 md:text-sm font-semibold">
              {selected_curr}
                {(
                  parseFloat(
                    user ? item?.monthly?.amt : item?.monthly?.amt
                  ) / conversion_rate
                )?.toFixed(2)}
              </dt>
              <dd className="text-xs font-thin">
                {" "}
                Monthly Service includes{" "}
                {(
                  parseFloat(
                    user ? item?.monthly?.mileq : item?.monthly?.mileq
                  ) * conversion_rate
                )?.toFixed(2)}{" "}
                Kilometers
              </dd>
            </div>
          </dl>
        </div>
          :
          <div>
            <dl className="max-w-md text-gray-900 divide-y divide-gray-400">
              <div className="flex flex-col pb-1">
                <dt className="mb-1 text-gray-600 md:text-sm font-semibold">
                {selected_curr}
                  {(
                    parseFloat(item?.hourly?.amt) /
                    conversion_rate
                  )?.toFixed(2)}
    
                </dt>
                <dd className="text-xs font-thin">
                 Only hourly prices are mentioned for this category, Please send enquiry for futher details.
                </dd>
              </div>
              <div className="flex flex-col pb-1">
                <dt className="mb-1 text-gray-600 md:text-sm font-semibold">
               
                </dt>
                <dd className="text-xs font-thin">
                
                </dd>
              </div>
              <div className="flex flex-col pb-1">
                <dt className="mb-1 text-gray-600 md:text-sm font-semibold">
                
                </dt>
                <dd className="text-xs font-thin">
               
                </dd>
              </div>
            </dl>
          </div>
          }
          
          {/* Prices Box */}
        </div>
      </div>
    </div>
  );
};

export default HorizentalCard;
