import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import Footer from "./new_main/Footer";

import {
    loginGoogleUser,
    loginUser,
    registerUser,
  } from "../../store/auth/authActions.js";
  import axiosConfig from "../../utils/axiosConfig.jsx";
  import { errorToast, successToast } from "../../utils/index.jsx";
 
  import parse from 'html-react-parser';
  
import { Toaster } from "react-hot-toast";
import { GoogleLogin } from "@react-oauth/google";
import { resetup } from "../../store/auth/authhelpers.js";

function Blogs(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {title} = useParams();
  
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    const {rtl } = useSelector((state) => state.lang);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [loginModel, setLoginModel] = useState(false);
    const navlink1 = useRef(null);
    const navlink2 = useRef(null);
    const navlink3 = useRef(null);
    
    const {
        banners,
      } = useSelector((state) => state.listing);
    const { user, loading } = useSelector((state) => state.auth);
    const [signupModel, setSignupModel] = useState(false);
    let fullName = useRef("");
    let password = useRef("");
    let cell = useRef("");
    let email = useRef("");
    const [lst, setLst] = useState([])
    const [tagslst, setTagslst] = useState([])
    
    const [validationError, setValidationError] = useState("");
    const [loading_listings, setLoading_listings] = useState(false)
    const [tag, setTag] = useState("ALL")  
    const [showdetail, setShowDetail] = useState(false)  
    const [showcblog, setShowcblog] = useState(false)  
    const [showupdate, setShowupdate] = useState(false)  

    const [myfile, setMyfile] = useState()

    const [blog, setBlog] = useState({
      bloguid : "",
      tag : "",
      title  : "",
      subject : "",
      intro_para :"",
      para_one : "",
      para_two : "",
      para_three :"",
      cDate : new Date(),
      authName : "",
      authId : "",
      aboutAuth : "",
      imgName : "",
      blograting  : "",
      myfilealt : "",
      feedback : []
    })


   const resetBlog = () => {
    setBlog({
      bloguid : "",
      tag : "",
      title  : "",
      subject : "",
      intro_para :"",
      para_one : "",
      para_two : "",
      para_three :"",
      cDate : new Date(),
      authName : "",
      authId : "",
      aboutAuth : "",
      imgName : "",
      blograting  : "",
      myfilealt : "",
      feedback : []
    })
   }

  useEffect(() => {
       
        if(title !== undefined){
          getblogtitle(title)
        }else{

          getblogs()
          dtags()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [useParams("title")])

   
    const signUpUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        // validation for all fields
        if (
          !fullName.value.trim() ||
          !email.value.trim() ||
          !password.value.trim()
        ) {
          setValidationError("All fields are required");
        } else {
          dispatch(
            registerUser({
              fullName: fullName.value,
              email: email.value,
              password: password.value,
              phone: cell.value,
            })
          )
            .unwrap()
            .then(() => {
              e.target.reset();
              successToast("User Registered Successfully");
              setSignupModel(false);
            })
            .catch((errorData) => {
              errorToast(errorData.error);
            });
        }
      };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const customStylesx = {
    content: {
      top: "50%",
      left: "50%",
      width :"50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const getblogs = () => {
    
    setLoading_listings(true) 
      try{
        axiosConfig.get(`/blg/blogs/${tag}`).then(res => {
            setShowDetail(false)
            setLst(res.data)    
            setLoading_listings(false)
        })
      
    }catch(e){
        setLoading_listings(false)
    }
     
 }

 const getblogss = (x) => {
  
  setLoading_listings(true) 
  try{
      axiosConfig.get(`/blg/blogs/${x}`).then(res => {
          setShowDetail(false)
          setLst(res.data)    
          setLoading_listings(false)
      })
    
  }catch(e){
      
      setLoading_listings(false)
  }
   
}

const getblogtitle = (x) => {
  try{
      axiosConfig.get(`/blg/bytitle/${x}`).then(res => {
          setBlog(res.data)  
          setShowDetail(true) 
    
      }).catch(e => {
        setBlog([])  
        setShowDetail(false) 
      })
    
  }catch(e){
  }
   
}

 const getblogsall = () => {
  
  setLoading_listings(true) 
  navigate("/blogs") 
  window.location.reload(false)
  
}
 
const dtags = () => {
  setLoading_listings(true)

  try{
      axiosConfig.get(`/blg/distcat`).then(res => {
          
          
          setTagslst(res.data)
          
          setLoading_listings(false)
      })
    
  }catch(e){
      
      setLoading_listings(false)
  }
   
}



 useEffect(() => {
  getblogs()
  
 },[tag])

 const handleClickM = event => {
  setMyfile(event.target.files[0])
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

const signinUser = async (e) => {
  e.preventDefault();

  dispatch(
    loginUser({
      email: email.value,
      password: password.value,
    })
  )
    .unwrap()
    .then(() => {
      e.target.reset();
      successToast("User Login Successfully");
      setLoginModel(false);
    })
    .catch((errorData) => {
      errorToast(errorData.error);
    });
};

 const submitblog = (e) => {
  e.preventDefault()
  if(!myfile){
    errorToast("Can not update without image");
    return;
  }
  const formData = new FormData();
  formData.append('blogimg', myfile);
  formData.append('tag' , blog.tag)
  formData.append('title' , blog.title)
  formData.append('subject' , blog.subject)
  formData.append('intro_para' , blog.intro_para)
  formData.append('para_one' , blog.para_one)
  formData.append('para_two' , blog.para_two)
  formData.append('para_three' , blog.para_three)
  formData.append('authName' , blog.authName)
  formData.append('authId' , blog.authId)
  formData.append('aboutAuth' , blog.aboutAuth)
  formData.append('imgName' , blog.imgName)
  formData.append('myfilealt' , blog.myfilealt)
  formData.append('bloguid' , blog.bloguid)
  
  if(blog.bloguid === "" || blog.bloguid === undefined){
    axiosConfig(
      {
        method: "post",
        url : `/blg/createblog`,
        data: formData,
        headers : { "Content-Type": "multipart/form-data"}
        }
      ).then(t => {
        successToast("Blog Saved");
        
      })
      .catch(e => {
        
        errorToast(e.error);
      })
    
  }else{
  
    axiosConfig(
      {
        method: "post",
        url : `/blg/updateblog`,
        data: formData,
        headers : { "Content-Type": "multipart/form-data"}
        }
      ).then(t => {
        successToast("Blog Saved");
        
      })
      .catch(e => {
        
        errorToast(e.error);
      })
  }
 
  

 }

 const delblog = (e) => {
  e.preventDefault()
 
  if(blog.bloguid === "" || blog.bloguid === undefined){
    errorToast("Can not delete")
  }else{
    axiosConfig.get(`/blg/bdel/${blog.bloguid}`).then(res => {
      successToast("Blog Deleted")
  })
  }
 
  

 }

 const oAuth = (gauth, resp) => {
  if (gauth === "GOOGLE")
    dispatch(
      loginGoogleUser({
        g_response: resp,
      })
    )
      .unwrap()
      .then(() => {
        successToast("User Login Successfully");
        setLoginModel(false);
      })
      .catch((errorData) => {
        errorToast(errorData.error);
      });
};

const showdetailx = () => {
  if(showdetail){
    return (
   
      <div>
        <div style={{display  :"flex"}}>
            <div style={{paddingLeft : "100px", paddingRight : "100px"}}>
              <div>
              <button className="blogback" onClick={() => getblogsall()}>Back</button>
              </div>
              
             
              <hr />
              <hr />
              <hr />
              <br />
              
              { ((user?.Email === "moazhammushtaq75@gmail.com") || (user?.Email === "aman@arixtech.ae")) &&
                  <>
                  <div>
                   <button className="blogback" onClick={() => {setShowcblog(true); setShowupdate(true)}}>Edit </button>
                   </div>
                   <div>
                   <button className="blogback" onClick={e => delblog(e) }>Delete </button>
                   </div>
                 
                  </>
              }  
              
            {/**   <button className="blogtotag" onClick={() => { getblogs() }} style={{float : "right"}}>Back to <span style={{color :"orange"}}>{blog?.tag}</span> </button>*/} 
            </div>


            <div>
            <center> 
              
              <img
                                 src={`${process.env.REACT_APP_API_URL}/blogs/${blog?.imgName}`}
                                className="img rounded border-dotted	border-primary border-3 imagepremium pre-imgclass"
                                alt="blog_image"
                                style={{width : "800px", height : "500px" , minHeight : "500px"}}
                                />
              
            </center>
            <div style={{display : "flex"}}>
              <div><h1>{blog?.title?.split("-")?.join(" ")}</h1></div>
              <div style={{marginLeft  : "auto"}}><span style={{float : "right"}}>Date : {new Date(blog?.cDate).toLocaleDateString()}</span></div>
            </div>
            
            </div>
          </div>
        
            <div style={{margin :  "0"}}>
           <div style={{display: "flex"}}>
                  <div style={{width : "5%"}}></div>
                  <div style={{width : "90%"}}><p style={{width : "40%"}}> , By: {blog?.authName}, <p>{blog?.aboutAuth}</p></p></div>
                  <div style={{width : "5%"}}></div>
            </div>
              
              
              <div style={{display : "flex", marginTop : "10px"}}>
                    <div style={{width : "80%", paddingLeft :"5%"}} className = "blogli">
                        <section >
                        {parse(blog?.para_one)}
                        </section>
                        <br />
                        <section>
                        {parse(blog?.para_two)}
                        </section>
                        <br />
                        <section>
                        {parse(blog?.para_three)}
                        </section>

                    </div>
                    
                    
                    {!isTabletOrMobile && <div style={{width : "20%", borderLeft : "1px solid lightgray", paddingLeft :"10px", fontSize : "0.8rem" }} >
                      <p style={{paddingLeft : "10px"}}>Read Other tags</p>
                      <ol>
                      {tagslst?.map((y, l) => {
                          return <li onClick={() => getblogss(y)} style={{cursor : "pointer"}}><span style={{fontSize : "0.8rem"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span></span> {y}</li>
                      })}
                      </ol>
                      </div>}
              </div>
            </div>


            <div style={{paddingLeft : isTabletOrMobile ? "10px": "100px", paddingRight : isTabletOrMobile ? "10px" : "100px", paddingTop : "3%"}}>
            <button className="blogback" onClick={() => getblogsall()}>Back to main </button>
           {/**    <button className="blogtotag" onClick={() => { getblogs() }} style={{float : "right"}}>Back to <span style={{color :"orange"}}>{blog?.tag}</span> </button> */}
            </div>
      </div> 
  )
  }
  
} 

const showdetaily = () => {
    if(showdetail){
      return (
     
        <div>
          <div>
              <div style={{paddingLeft : "100px", paddingRight : "100px"}}>
                <div>
                <button className="blogback" onClick={() => getblogsall()}>Back</button>
                { ( user?.Email === "moazhammushtaq75@gmail.com" || user?.Email === "aman@arixtech.ae" ) &&
                    <>
                     <button className="blogback" onClick={() => {setShowcblog(true); setShowupdate(true)}}>Edit </button>
                    <button className="blogback" onClick={e => delblog(e) }>Delete </button>
                    </>
                }
                </div>
              {/**   <button className="blogtotag" onClick={() => { getblogs() }} style={{float : "right"}}>Back to <span style={{color :"orange"}}>{blog?.tag}</span> </button>*/} 
              </div>
  
  
              <div>
              <center> 
                
                <img
                                   src={`${process.env.REACT_APP_API_URL}/blogs/${blog?.imgName}`}
                                  className="img rounded border-dotted	border-primary border-3 imagepremium pre-imgclass"
                                  alt="blog_image"
                                  style={{ maxWidth : "400px", padding : "10px"}}
                                  />
                
              </center>
              <div style={{display : "flex" , fontSize  : "0.7rem", }}>
                <div style={{marginLeft : "15px"}}><h1>{blog?.title?.split("-")?.join(" ")}</h1></div>
                <div style={{marginLeft  : "auto"}}><span style={{float : "right", margin : "15px"}}>Date : {new Date(blog?.cDate).toLocaleDateString()}</span></div>
              </div>
              </div>
            </div>
          
              <div style={{margin :  "0"}}>
             <div style={{display: "flex"}}>
                    <div style={{width : "5%"}}></div>
                    <div style={{width : "90%"}}><p style={{width : "40%"}}> , By: {blog?.authName}, <p>{blog?.aboutAuth}</p></p></div>
                    <div style={{width : "5%"}}></div>
              </div>
                
                
                <div style={{display : "flex", marginTop : "10px"}}>
                      <div style={{width : "80%", paddingLeft :"5%", fontSize : "0.7rem"}}>
                          <section>
                          {parse(blog?.para_one)}
                          </section>
                          <br />
                          <section>
                          {parse(blog?.para_two)}
                          </section>
                          <br />
                          <section>
                          {parse(blog?.para_three)}
                          </section>
  
                      </div>
                      
                      
                      {!isTabletOrMobile && <div style={{width : "20%", borderLeft : "1px solid lightgray", paddingLeft :"10px", fontSize : "0.8rem" }} >
                        <p style={{paddingLeft : "10px"}}>Read Other tags</p>
                        <ol>
                        {tagslst?.map((y, l) => {
                            return <li onClick={() => getblogss(y)} style={{cursor : "pointer"}}><span style={{fontSize : "0.8rem"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span></span> {y}</li>
                        })}
                        </ol>
                        </div>}
                </div>
              </div>
  
  
              <div style={{paddingLeft : isTabletOrMobile ? "10px": "100px", paddingRight : isTabletOrMobile ? "10px" : "100px", paddingTop : "3%"}}>
              <button className="blogback" onClick={() => getblogsall()}>Back to main </button>
             {/**    <button className="blogtotag" onClick={() => { getblogs() }} style={{float : "right"}}>Back to <span style={{color :"orange"}}>{blog?.tag}</span> </button> */}
              </div>
        </div> 
    )
    }
    
  } 

    return (
        <div style={{ minHeight: "1084px"}}>
        <Toaster />    


      <Modal
        isOpen={signupModel}
        onAfterOpen={(() => {})}
        onRequestClose={() => setSignupModel(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form onSubmit={signUpUser}>
          <div>
            <h1>{t("Sign Up")}</h1>
            <p className="mt-4 mb-8">
              {t("If you already have an account registered You can")}
              <br />
              <u
                onClick={() => {
                  setSignupModel(false);
                  setLoginModel(true);
                }}
                className="link"
                
              >
                {t("Login here")} !
              </u>
            </p>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Full Name")}
            </label>
            <div className="relative">
              <input
                className="inputField mb-8 w-full"
                name="fullName"
                placeholder={t("Enter your full name")}
                id="fullName"
                onChange={() => setValidationError("")}
                ref={(e) => {
                  fullName = e;
                }}
                type="text"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Email")}
            </label>
            <div className="relative">
              <input
                className="inputField mb-8 w-full"
                name="email"
                placeholder={t("Enter your Email")}
                id="email"
                onChange={() => setValidationError("")}
                ref={(e) => {
                  email = e;
                }}
                type="email"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Phone")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="cell"
                placeholder={t("Enter your Phone Number")}
                id="cell"
                ref={(e) => {
                  cell = e;
                }}
                type="tel"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Password")}
            </label>
            <div className="relative">
              <input
                className="inputField mb-8 w-full"
                name="password"
                placeholder={t("Enter your Passowrd")}
                id="password"
                onChange={() => setValidationError("")}
                ref={(e) => {
                  password = e;
                }}
                type="password"
                required
              />
            </div>
          </div>
          {validationError && (
            <p className="text-left text-red-500">{validationError}</p>
          )}
          <button type="submit" className="primaryButton">
            {loading ? `${t("Loading")}...` : t("Sign Up")}
          </button>
        </form>
      </Modal>
      
      <Modal
        isOpen={showcblog}
        onAfterOpen={(() => {})}
        onRequestClose={() => {}}
        style={customStylesx}
        ariaHideApp={false}
      >
        <form  style={{fontSize : "0.8rem", padding : "5px", overflow : "auto", height :"600px"}}>
          <div className="blog" style={{}} >
            <div style={{display : "flex"}}>
                    <div style={{minWidth : "430px"}}>
                        <div>
                            <label>Tag</label>&nbsp; <input type="text" value = {blog.tag} maxLength={60} onChange={x => setBlog({...blog, tag : x.target.value})}  />
                        </div >
                        <div>
                          <label>Title</label><input type="text" value = {blog?.title?.split("-")?.join(" ")} maxLength={80} onChange={x => setBlog({...blog, title : x.target.value})}  />
                        </div>
                    </div>  
                  
                  <div>
                          
                          <input type="file" id="fileUpload" onChange={e => {handleClickM(e)}}/>
                          <label>Title</label>
                          <input type="text" value = {blog.myfilealt} maxLength={80} onChange={x => setBlog({...blog, myfilealt : x.target.value})} placeholder="Alt Text" />
                  </div>  
            </div>
            
            <div>
            <label>Subject</label><textarea  value = {blog.subject} maxLength={300} onChange={x => setBlog({...blog, subject : x.target.value})}  />
            </div>
            <div>
            <label>Intro Para</label><textarea value = {blog.intro_para} maxLength={1000} onChange={x => setBlog({...blog, intro_para : x.target.value})}  />
            </div>
            <div>
            <label>First Para</label><textarea value = {blog.para_one} maxLength={3000} onChange={x => setBlog({...blog, para_one : x.target.value})}  />
            </div>
            <div>
            <label>Second Para</label><textarea value = {blog.para_two} maxLength={3000} onChange={x => setBlog({...blog, para_two : x.target.value})}  />
            </div>
            <div>
            <label>Third Para</label><textarea value = {blog.para_three} maxLength={3000} onChange={x => setBlog({...blog, para_three : x.target.value})}  />
            </div>
            <div>
            <label>Author</label><input type="text" value = {blog.authName} maxLength={3000} onChange={x => setBlog({...blog, authName : x.target.value})}  />
            </div>
            <div>
            <label>Author_eMail</label><input type="text" value = {blog.authId} maxLength={3000} onChange={x => setBlog({...blog, authId : x.target.value})}  />
            </div>
            <div>
            <label>Titles</label><input type="text" value = {blog.aboutAuth} maxLength={3000} onChange={x => setBlog({...blog, aboutAuth : x.target.value})}  />
            </div>
          </div>
          <hr />
          <br />
          <br />
          <button className="primaryButton" onClick={e =>{setShowcblog(false);submitblog(e)}}>
            {loading ? `${t("Loading")}...` : showupdate ? "Update" : "Submit Blog"}
          </button >
          <button onClick={e =>{setShowcblog(false);submitblog(e)}} style={{float : "right"}}>
            Close
          </button>
        </form>
      </Modal>

      <Modal
        isOpen={loginModel}
        onAfterOpen={() => {}}
        onRequestClose={() => setLoginModel(false)}
        style={{ ...customStyles }}
        contentLabel="Example Modal"
      >
        <form onSubmit={signinUser}>
          <div>
            <h1>{t("Sign In")}</h1>
            <p className="mt-4 mb-8">
              {t("If you don't have an account You can")}
              <br />
              <u
                onClick={() => {
                  setLoginModel(false);
                  setSignupModel(true);
                }}
                className="link"
              >
                {t("Register here")} !
              </u>
            </p>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Email")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="email"
                placeholder={t("Enter your Email")}
                id="email"
                ref={(e) => {
                  email = e;
                }}
                type="email"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Password")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="password"
                placeholder={t("Enter your Passowrd")}
                id="password"
                ref={(e) => {
                  password = e;
                }}
                type="password"
                required
              />
            </div>
          </div>
          <center>
            <button
              type="submit"
              className="primaryButton mt-4"
              style={{ fontSize: "0.8rem" }}
            >
              {loading ? `${t("Loading")}...` : t("Sign In")}
            </button>
            <br></br>
            <p
              style={{
                marginTop: "25px",
                fontSize: "0.7rem",
                cursor: "pointer",
              }}
              onClick={(t) => {
                resetup(email.value);
                setLoginModel(false);
              }}
            >
              Forgot your password ?
            </p>
          </center>
        </form>

        <br />
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            oAuth("GOOGLE", credentialResponse);
          }}
          onError={() => {
            
          }}
        />
      </Modal>

       <header>
        <div
          className="w-screen bg-cover bg-center relative "
        >
          <header className="sm:px-8 px-4 py-2 z-10 w-full">
            <nav className="flex md:justify-between items-center ">
              <div
                className="hidden max-lg:block cursor-pointer"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                <RxHamburgerMenu className="text-4xl" />
              </div>
              <Link to="/car" className="text-3xl font-bold">
                <img
                  style={{ width: 200 }}
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.png"
                  }
                  alt="logo"
                />
              </Link>
              <ul className="flex-1 flex justify-center items-center gap-16 max-lg:hidden">
              
                <NavLink
                  ref={navlink1}
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                      : "text-black text-sm not-italic font-normal no-underline capitalize"
                  }
                  to="/car"
                >
                  {t("RENTACAR")}
                </NavLink>
                <NavLink
                ref={navlink2}
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                      : "text-black text-sm not-italic font-normal no-underline capitalize"
                  }
                  to="/machines"
                >
                  {t("MACHINES")}
                </NavLink>
                <NavLink
                  ref={navlink3}
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                      : "text-black text-sm not-italic font-normal no-underline capitalize"
                  }
                  to="/yatch"
                >
                  {t("YATCHRENTAL")}
                </NavLink>
              </ul>
              <div className="flex gap-2 text-lg leading-normal font-medium font-montserrat max-lg:hidden wide:mr-24">
              {!user ? (
                  <button
                    type="button"
                    onClick={() => {
                      setLoginModel(true);
                      setIsMenuOpen(false);
                    }}
                    className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10 roundborderlightgray capitalize"
                    style={{ marginTop: -15 }}
                  >
                    {t("LOGIN")} / {t("SIGNUP")}
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        navigate("/profile");
                        // dispatch(logoutUser());
                        setIsMenuOpen(false);
                      }}
                      className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10"
                    >
                      {user?.fn}
                    </button>
                  </>
                )}
              </div>
            </nav>
          </header>
          {isMenuOpen && (
            <div className="">
              <nav className="fixed top-0 right-0 left-0 bottom-0 lg:bottom-auto bg-slate-100 z-50 ">
                <div
                  className={`hidden max-lg:block fixed ${
                    rtl ? "left-0" : "right-0"
                  }  px-8 py-4 cursor-pointer`}
                  onClick={() => {
                    setIsMenuOpen(!isMenuOpen);
                  }}
                >
                  <AiOutlineClose className="text-4xl" />
                </div>
                <div className="items-center justify-center h-full">
                  <Link to="/" className="text-3xl font-bold">
                    <img
                      style={{ width: 300 }}
                      src={
                        process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo.png"
                      }
                      alt="logo"
                    />
                  </Link>
                  <ul className=" lg:hidden flex flex-col  max-w-md divide-y text-center mt-10">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary text-lg not-italic font-normal no-underline py-3"
                          : "text-black text-lg not-italic font-normal no-underline py-3"
                      }
                      to="/car"
                    >
                      {t("RENTACAR")}
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary text-lg not-italic font-normal no-underline py-3"
                          : "text-black text-lg not-italic font-normal no-underline py-3"
                      }
                      to="/machine"
                    >
                      {t("MACHINES")}
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary text-lg not-italic font-normal no-underline py-3"
                          : "text-black text-lg not-italic font-normal no-underline py-3"
                      }
                      to="/yatch"
                    >
                      {t("YATCHRENTAL")}
                    </NavLink>
                  </ul>
                  <div className="text-center mt-10">
                  {!user ? (
                  <button
                    type="button"
                    onClick={() => {
                      setLoginModel(true);
                      setIsMenuOpen(false);
                    }}
                    className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10 roundborderlightgray capitalize"
                    style={{ marginTop: -15 }}
                  >
                    {t("LOGIN")} / {t("SIGNUP")}
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        navigate("/profile");
                        // dispatch(logoutUser());
                        setIsMenuOpen(false);
                      }}
                      className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10"
                      style={{ marginTop: -5, height: "60px" , fontSize : "0.7rem" , borderRadius : "25px"}}
                    >
                      {user?.fn}
                    </button>
                  </>
                )}
                  </div>
                </div>
              </nav>
            </div>
          )}

          <div className="container mx-auto mt-5">
            <div className="banner grid grid-cols-1 gap-0 justify-center border">
              <Carousel
                className="direction_initial"
                showIndicators={false}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                onClickItem={(e) => {
                  let bannerUrl = banners.Cars?.bannersb1[e]?.url;
                  window.open(bannerUrl, "_blank");
                }}
                autoPlay={true}
                transitionTime={0.1}
                showArrows={false}
                i18nIsDynamicList={true}
              >
                {banners.Cars?.bannersb1
                  ?.filter((banner) => banner.btype === "B-1")
                  .map((img, index) => (
                    <a target="blank" href={img.url}>
                      <img alt="banner B1"
                        width = "300" height = "200"
                        src={`${process.env.REACT_APP_API_URL}/banners/B-1/${img?.image}`}
                        style={{ height: isTabletOrMobile ? 50: 120 , objectFit: "contain" }}
                        className="w-full"
                      />
                    </a>
                  ))}
              </Carousel>
            </div>
            
          </div>
        </div>
       
      </header>

      { (user?.Email === "moazhammushtaq75@gmail.com" || user?.Email === "aman@arixtech.ae") &&
                                <>
                                <div>
                                  <center>
                                <button className="blogback" onClick={o => {setShowcblog(true);resetBlog();setShowupdate(false)}}>Create Blogs</button>
                                </center>
                                </div>
                                </>
                            }
           
           

            {
             !showdetail && isTabletOrMobile && <div style={{display :  "flex" , flexWrap: "wrap", paddingLeft : "0" }}>
              <div style={{ width :  "20%" , maxWidth : "20%" }}>
                     <div style={{padding : 15 , display : "flex" , flexWrap: "wrap", textAlign : "center"}}> Tags
                          <div onClick={() => setTag("ALL")} style={{fontSize :  "0.7rem", cursor : "pointer", width : "60px" , minWidth : "60px", paddingLeft : "5px", marginBottom : "15px", marginTop : "5px"}}><span style={{color : "orange"}}>⚬&nbsp;</span> All</div>
                          {
                              tagslst?.map((o,t) => {
                                return (<div onClick={() => setTag(o)} style={{fontSize :  "0.7rem", cursor : "pointer", width : "60px" , minWidth : "60px", paddingLeft : "5px" , marginBottom : "15px"}}><span style={{color : "orange" }}>⚬&nbsp;</span>{o}</div>)
                              })
                            }
                      </div>       

              </div>

              <div style={{width : "80%" , maxWidth : "80%", display : "flex" , flexWrap : "wrap"}}>
              {
               lst?.map(blg => {
                      return (
                          <div className="containerpremium" style={{paddingBottom : "40px", minWidth :  "100%", maxWidth: "100%" , paddingLeft : isTabletOrMobile? 40 : 0 }}>
                              <div>    
                              <div onClick={() => setTag(blg?.tag)} style={{display : "flex", fontSize : "0.7rem", cursor : "pointer"}}>
                                <div ><img alt="tags"
                                      src={`${process.env.REACT_APP_PUBLIC_URL}images/tag.png`}
                                      width = "15" height = "15"
                                      style={{width : "15px", marginTop: "5px"}} 
                                      /> </div>
                                      <div >
                                      &nbsp; {blg?.tag}</div><div style={{width : "50%"}}><p style={{float : "right", paddingRight : "10px"}} >{new Date(blg?.cDate).toLocaleDateString()}</p></div></div>
                                  <img
                                      src={`${process.env.REACT_APP_API_URL}/blogs/${blg?.imgName}`}
                                      className="img rounded border-dotted	border-primary border-3 imagepremium pre-imgclass"
                                      alt="blog_image"
                                      style={{width : "95%"}}
                                      />
                                   <center><p>{blg?.title?.split("-")?.join(" ")}</p></center>
                                  
                                 </div>    
                                    <div style={{fontSize : "0.7rem", display : "flex"}} >
                                      <div style={{width : "30%", borderRight : "1px solid lightgray"}}>
                                          <div>By: {blg?.authName}</div>
                                          <div>{blg?.subject}</div>
                                      </div>
                                      <div style={{paddingLeft : "10px",  width : "50%"}}>
                                          <div className="wrap">{blg?.para_one?.split('').map((t, x)=> {
                                            if(x < 78){ return t}
                                            })} {'\n'} </div>
                                      </div>
                                      
                                    </div>
                                    <b style={{color : "orange", fontSize : "1.0em", float : "right" , cursor : "pointer", paddingRight : "10px"}} 
                                            onClick={() => navigate(`/blogs/${blg?.title}`)}>...Read &gt;&gt;</b>
                          <hr />
                          </div>
                              )    
                 })

              }
              </div>
              
             </div>}
             { !showdetail && !isTabletOrMobile &&
              <div style={{display : isTabletOrMobile ? "block" : "flex" , flexWrap: "wrap", paddingLeft : isTabletOrMobile ? "0px" : "100px" }}>
                <div className="blogtags" style={{borderRight : "1px solid lightgray", width : isTabletOrMobile ? "0" : "20%", maxWidth : isTabletOrMobile ? "0" : "20%", padding :"10px"}}>
                      <ol style={{padding : 15}}>Tags
                            <li onClick={() => setTag("ALL")} style={{fontSize :  "0.7rem", cursor : "pointer"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>&nbsp; All</li>
                              {
                                tagslst?.map((o,t) => {
                                  return (<li onClick={() => setTag(o)} style={{fontSize :  "0.7rem", cursor : "pointer"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span> &nbsp; {o}</li>)
                                })
                              }
                        </ol>       

                </div>

                <div style={{ width : isTabletOrMobile ? "100%" : "80%" , maxWidth : "80%", display : "flex" , flexWrap : "wrap", paddingLeft : "20%", paddingTop : "10px"}}>
                {
                lst?.map(blg => {
                        return (
                            <div className="containerpremium" style={{paddingBottom : "40px", minWidth :  "350px", maxWidth: "350px" , padding : 5, marginBottom : 10}}>
                                <div>    
                                <div onClick={() => setTag(blg?.tag)} style={{display : "flex", fontSize : "0.7rem", cursor : "pointer"}}>
                                  <div ><img alt="tag"
                                        width= "30" height="20"
                                        src={`${process.env.REACT_APP_PUBLIC_URL}images/tag.png`}
                                        style={{width : "30px"}} 
                                        
                                        /> </div>
                                        <div >
                                        &nbsp; {blg?.tag}</div><div style={{width : "50%"}}><p style={{float : "right", paddingRight : "10px"}} >{new Date(blg?.cDate).toLocaleDateString()}</p></div></div>
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}/blogs/${blg?.imgName}`}
                                        className="img rounded border-dotted	border-primary border-3 imagepremium pre-imgclass"
                                        alt="blog_image"
                                        width = "200" hight = "200"
                                        style={{width : "100%"}}
                                        />
                                         <center><h5>{blg?.title?.split("-")?.join(" ")}</h5></center>
                              
                                  </div>    
                                      <div style={{fontSize : "0.7rem", display : "flex"}} >
                                        <div style={{width : "30%", borderRight : "1px solid lightgray"}}>
                                            <div>By: {blg?.authName}</div>
                                            <div>{blg?.subject}</div>
                                        </div>
                                        <div style={{paddingLeft : "10px",  width : "50%"}}>
                                            <div className="wrap">{blg?.para_one?.split('').map((t, x)=> {
                                              if(x < 78){ return t}
                                              })} {'\n'} <b style={{color : "orange", fontSize : "1.5em", float : "right" , cursor : "pointer"}} 
                                              onClick={() => navigate(`/blogs/${blg?.title}`)}>...Read &gt;&gt;</b></div>
                                        </div>
                                      </div>
                            <hr />
                            </div>
                                )    
                  })

                }
                </div>
              
             </div>
            }
                            
           {isTabletOrMobile ?  showdetaily() : showdetailx()}
           
           <Footer t = {t}/>
        </div>
    
    );
}

export default Blogs;