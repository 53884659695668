import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { App } from "./components/app";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ScrollToTop from "./components/scrollToTop";
import "./assets/vnmstyleover.css"
import "./assets/vnmtwo.css"
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <GoogleOAuthProvider clientId="1042759538521-17grvmjrorbovpitkso0j3i9p0665att.apps.googleusercontent.com">
      <Router>
        <ScrollToTop />
        <App />
      </Router>
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
