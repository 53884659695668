
import { useTranslation } from "react-i18next";
import {
  Link,
} from "react-router-dom";

function Termscon(props) {
    const { t } = useTranslation();

    return (
        <div>
              <div className="totop" 
                onClick={() =>  window.scrollTo({ top: 0, behavior: "smooth" })}>
                  &#8673;
              </div>
            <header className="sm:px-8 px-4 py-2 w-full">
                <nav className="flex md:justify-between items-center ">
                
                <Link to="/car" className="text-3xl font-bold">
                <img
                  style={{ width: 200 }}
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.png"
                  }
                  alt="logo"
                />
              </Link>
                
                </nav>
           </header>
         
          <div>
           <center> <h3>  {t("TERMS&CONDITIONS")} </h3></center>
        </div>
        <div className='vnmgrid-container'>
            <div >
                  <h5>For End User</h5>
                  <br></br>
                  <h6>1.TERMS</h6>
                  <br></br>
                  <p>VnM Rental Portal (collectively "VnM Rental", “VnM Rental”, "we", "us", "our") is based in the UAE and owns and operates the Internet website at www.VnM Rental.com (the “Website”) and an Application. (The “App”) (The Website and the App are collectively referred to as the “Platform”).</p>
                  <p>By accessing and using the Platform, you are expressly consenting to terms and conditions outlined on this page that are effective as of the date and time you use or access the Platform (the “Terms”). Please read the Terms carefully before accessing or using the information and services available through this Platform.</p>
                  <p>These Terms will constitute a legal agreement between “you” (the User of this Platform) and VnM Rental Platform (VnM Rental.com or VnM Rental). If you do not agree to, or cannot comply with, the Terms kindly refrain from using this Platform.</p>
                  <p>These Terms may be modified from time to time without notice to you and you should therefore regularly review these Terms for any such modifications. If you do not agree to any change to the Terms then you must immediately stop using the Website.</p>
                  <p>Certain areas of the Platform may be subject to additional terms of use. By using such areas, or any part of, you agree to be bound by the additional terms of use applicable to such areas. Please refer to our Privacy Policy for information about how we use your data. “Agent / Service Provider / Third Party / Merchant /Supplier / Dealer” means a Service Provider of Products / services through which the service will be provided.</p>
                  <p>VnM Rental Platform provides chauffeur service arrangement, yacht rental services and desert safari services through third Party Suppliers. VnM Rental Platform works as an intermediate, and not in the provision of the travel, rental, desert safari service itself.</p>
                  <p>VnM Rental Platform works only as an intermediate and not as a transport, rental and desert safari service. It is the User’s responsibility to ensure that all information that they, or someone else on their behalf, have provided to VnM Rental Platform is complete and accurate.</p>
                  <p>During the booking you (the User) shall pay a booking amount as mentioned for the services. VnM Rental will provide the User with invoice for the bookings done by email as provided by the User.</p>
                  <p>VnM Rental reserves the right to at any time make changes to the Platform in a way that is appropriate to the User, so as to further develop and improve their quality. In addition, VnM Rental reserves the right to, with good cause, temporarily or permanently discontinue its services on the Platform, even without User being informed directly of this.</p>
                  <p>The User can pay for their ride by credit card. Any transaction fees when making payments by credit card (e.g. due to different currencies) will be borne by the User. The displayed price and currency selected by you, will be the same price and currency charged to the Card and sent to you in the invoice by email.</p>
                  <br></br>
                  <h5>Rent a Car Services</h5>
                  <br></br>
                  <ul style={{fontStyle : "initial"}}>
                    <li style={{fontStyle : "normal"}}>
                        <p>
                        •	The contract would be concluded between the User who has booked the Rent a Car and the third party service provider who has listed Rent a car services on this Platform.
                    </p>
                    </li>
                    <li><p>•	In no circumstances, will the contract be considered between the User and us nor will we be under any obligation and/or liability (directly or indirectly) for the service provided by such third party service provider.</p>
                    </li>
                    <li><p>•	The User themselves is then entitled to demand the transportation services from the Supplier once details are sent, and any further claims directly from the Supplier.</p>
                    </li>
                    <li>
                        <p>•	During the ride, all passengers are required to follow laws as per the local Transport Authority, especially the seat belt regulations. Passengers are not allowed to open the doors while driving, throw any objects from the vehicle, and/or stick body parts out of the vehicle. Smoking is prohibited in the vehicle. If the User, or passenger, ignores this, they are liable to pay not only the cost for the cleaning of the vehicle but also compensate the loss of business due to the downtime of the vehicle and any or all fines/penalties levied by the Authorities in UAE due to such action/inaction on behalf of the passengers in the vehicle booked by the User. The consumption of food is discouraged. Alcoholic drinks are not allowed to be consumed in the car.</p>
                    </li>
                    <li>
                        <p>•	Special situations such as air-traffic controller strikes, extreme weather conditions, etc. can be compensated only to a limited extent, meaning that longer waiting periods or last-minute cancellations must be accepted by Users</p>
                    </li>
                    <li>
                        <p>•	The maximum number of passengers and pieces of luggage mentioned for each vehicle is based on factors such as size and weight of passengers and luggage. </p>
                    </li>
                    <li>
                        <p>•	The User can choose from different vehicle classes (e.g. “Luxury, Executive, Standard, Luxury etc.") for their trip. The vehicle images shown on the Platform are only suggested examples. Actual models may vary for the booked vehicle category - All cars are as per local specifications. Cars are Subject to availability, an upgrade might be offered if the booked class of vehicle is not available.</p>
                    </li>
                    <li>
                        <p>•	The User who is renting a car from the rent a car section will be required to provide the valid documents (for UAE resident: UAE driving licence, Emirates ID, etc for Non-UAE residents International driving permit/GCC driving licence, etc) which shall be under the name of the User. The User shall make sure the documents provided are correct, valid and updated.</p>
                    </li>
                 </ul>  
                 <br></br>    
                 <h6>YACHT SERVICES</h6>       
                 <br></br>
                 <ul>
                    <li><p>•	The contract would be concluded between the User who has booked the Yacht and the owner of the yacht who has listed their yacht on this Platform.</p></li>
                    <li><p>•	In no circumstances will the contract for the yacht be considered between the User and us nor will we be under any obligations for the service provided by the yachtsman.</p></li>
                    <li><p>•	Once a User submits the booking form on the Platform and makes a payment, VnM Rental will send a booking confirmation email to the User.</p></li>
                    <li><p>•	The Yachtsman will have a Captain and Crew on board who will make sure to comply with the rules applicable to them.</p></li>
                    <li><p>•	The headcount will be considered as per lives on board and maximum capacity of the yacht cannot be exceeded at any point.</p></li>
                    <li><p>•	All guests are responsible for their personal belongings and valuables.</p></li>
                    <li><p>•	All children or infants are to be closely supervised by their parents/guardians and are the sole responsibility of their parents/guardians.</p></li>
                    <li><p>•	As per Coast Guard Regulations, all guests have to wear life vests as instructed by the captain. Children and infants should keep their life vests drawn all throughout the cruise.</p></li>
                    <li><p>•	All passengers should carry either original valid ID or passport, original on the date of the event as per Coast Guard Regulations. Captain has the authority to refuse any passengers the right to enter the yacht without the above mentioned requirement.</p></li>
                    <li><p>•	Guests under the influence of alcohol or otherwise of violating Coast Guard Regulations or UAE government policies are liable to legal proceedings directly with the authority and solely responsible for the penalties/fines in accordance.</p></li>
                    <li><p>•	Any use of professional photography for the purpose of public screening requires pre-authorization from relevant authorities.</p></li>
                    <li><p>•	The Captain’s decision is final once the sail of the yacht commences. Any non-adherence to the captain’s reminder/warning or non-adherence to Dubai Coast Guard Regulations may result in strict actions.</p></li>
                    <li><p>•	Any fines/damages that arise due to acts committed by a passenger shall be the responsibility of such passenger and the individual/User who has made the booking and the same shall be borne by them.</p></li>
                    <li><p>•	We shall not be liable or held responsible for any additional expenses that may be caused by injury, sickness and/or death nor shall be responsible for any personal harm and/or injuries.</p></li>
                    <li><p>•	Smoking of cigarette/sheesha/hookah is prohibited on a yacht unless permitted by the captain.</p></li>
                    <li><p>•	Alcohol is not allowed on yachts during the holy month of Ramadan.</p></li>
                    <li><p>•	The passenger should not throw litter in the sea and must use appropriate disposal bins for the same.</p></li>
                    <li><p>•	Users shall not engage in any fishing and submarine activities without valid licence and/or permissions.</p></li>
                    <li><p>•	No crew member shall be subjected to any type of harassment by the User and/or any passenger/s at any time during the sailing period.</p></li>
                    <li><p>•	The User and/or passenger/s shall not bring any pets or any other animals on board without the consent of the yachtsman.</p></li>
                    <li><p>•	In case of special situations like storms or any other extreme conditions, User shall be compensated only to a limited extent and last minute cancellations in such events must be accepted by the User.</p></li>
                    


                 </ul>
                 <br></br>    
                 <h5>2.USE LICENSE & RESTRICTIONS</h5>       
                 <br></br>       
                 <p>Permission is granted to temporarily download copies of the materials (information or software) on VnM Rental.com Platform for personal, non-commercial transitory viewing only. This is the grant of a licence, not a transfer of title, and under this licence you may not:</p>
                 <ul>
                 <li><p>•	Modify or copy the materials;</p></li>
                 <li><p>•	Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</p></li>
                 <li><p>•	Attempt to decompile or reverse engineer any software contained on web site;</p></li>
                 <li><p>•	Remove any copyright or other proprietary notations from the materials; or</p></li>
                 <li><p>•	Transfer the materials to another person or "mirror" the materials on any other server. This licence shall automatically terminate if you violate any of these restrictions and may be terminated by VnM Rental.com/Platform at any time. Upon terminating your viewing of these materials or upon the termination of this licence, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p></li>
                 </ul>

                 <br />       
                 <h5>3. YOUR RESPONSIBILITIES, REDEMPTION & REFUNDS</h5>       
                 <br />
                 <p>We provide an online Platform (which refers to this website, mobile app, etc.) where we promote third-party/merchant businesses on our Platform. In order to make a purchase you need to register on the Platform using a valid email address and password. We are never a buyer and usually not the Service Provider; we provide a venue/platform for Service Providers and you to negotiate and complete transactions. This means that in addition to this Agreement, you will have other contractual relationships with different legal entities when you interact with the Site. VnM Rental makes no warranty about the standard or quality of any product and/or service. We base our promotional material on information provided by the Agent / Seller / Third Party / Supplier / Merchant and affiliates. VnM Rental will collect money from customers on behalf of the Agent / Service Provider / Third Party / Merchant, and then send confirmation emails for goods and services to be provided by these Agent / Service Provider / Third Party / Merchant. The payment for it will be collected via online credit card payment. Once the payment is made, the confirmation notice will be sent to the user via email within 24 (twenty four) hours of receipt of payment. In case where you wish to cancel a booking, it is mandatory for you to notify us at least before 24 (twenty four) hours of the scheduled time. A nominal amount shall be deducted towards cancellation fees and the balance shall be refunded to the original mode of payment within 10 (ten) to 45 (forty five) days depending on the issuing bank of the credit card. If you fail to notify us within the prescribed time limit, we shall be entitled to deduct the entire amount and no refund shall be made.
For most of the listed ads, we are also not a car rental / sales agency / limousine company and we provide a service whereby agents may market and you may view car details ('Details') together with other content hosted and developed by us. The agents are responsible for preparing the details and field enquiries directly from you. Details are hosted by us in good faith but are produced directly by agents and have not been verified by us. You are responsible for making your own enquiries/purchases and we provide no guarantee and accept no responsibility for the accuracy or completeness of any information contained within the details.
</p>
                 <ul>
                    <li><p>•	You are responsible for checking, confirming and satisfying yourself as the final customer</p></li>
                    <li><p>•	You are responsible for instructing a surveyor and obtaining legal advice before you do any transaction on our site</p></li>
                    <li><p>•	You are responsible for ensuring that you act in good faith towards any other accuracy of any details committing to any businesses.</p></li>
                 </ul>       

                 <br></br>    
                 <h5>4.LIMITATIONS</h5>       
                 <br></br>
                 <p>In no event shall VnM Rental Portal or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on VnM Rental Internet Website and Mobile application, even if VnM Rental or a VnM Rental’s authorised representative has been notified orally or in writing of the possibility of such damage.</p>       

                 <br></br>    
                 <h5>5.REVISIONS AND ERRATA</h5>       
                 <br></br>
                 <p>The materials appearing on VnM Rental Platform could include technical, typographical, or photographic errors. VnM Rental Platform does not warrant that any of the materials on its Platform are accurate, complete, or current. VnM Rental Platform may make changes to the materials contained on its web site at any time without notice.</p>       

                 <br></br>    
                 <h5>6.AVAILABILITY OF PLATFORM</h5>       
                 <br></br>
                 <p>We strive to ensure that our Platform and the Services are available to you at all times but cannot guarantee that either the Platform or the Services will operate continuously, without interruptions or be fault-free. On occasion, necessary maintenance or upgrade work requires us to make the Platform and the Services unavailable without notice, but we aim to keep downtime to a minimum. We accept no liability for any interruption and/or loss of service. We reserve the absolute right to alter, suspend or discontinue any part of our Platform or the Services, including your access to it.</p>       

                 <br></br>    
                 <h5>7.LINKS & THIRD PARTIES</h5>       
                 <br></br>
                 <p>The Platform may contain links to other websites on the Internet that are owned and operated by third parties (the “External Sites”). These links are provided solely as a convenience to you and not as an endorsement by VnM Rental of the contents on such External Sites. You acknowledge that VnM Rental Platform is not responsible for the availability of, or the information and content of any External Site. You should contact the site administrator or web-master for those External Sites if you have any concerns regarding such links or the content located on such external Sites. If you decide to access linked third party websites, you do so at your own risk.</p>       
                        
                 <br></br>    
                 <h5>8.PLATFORM TERMS OF USE MODIFICATIONS</h5>       
                 <br></br>
                 <p>VnM Rental may revise these terms of use for its Platform at any time without notice. By using this Platform you are agreeing to be bound by the then current version of these Terms and Conditions.</p>       
                        
                 <br></br>    
                 <h5>9.DISCLAIMER</h5>       
                 <br></br>
                 <p>The materials on VnM Rental Platform are provided "as is". We make no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, we do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on our Platform or otherwise relating to such materials or on any sites linked to this Platform. We control this Platform from the United Arab Emirates. We make no representation that this Platform is appropriate for use in other locations. If you use this Platform from other locations you are responsible for ensuring compliance with local laws. You may not use, export or re-export any materials from this Platform in violation of any applicable laws or regulations, including, but not limited to any United Arab Emirates export laws and regulations.</p>       
                
                 <br></br>    
                 <h5>10.GOVERNING LAW</h5>       
                 <br></br>
                 <p>Any claim relating to VnM Rental Platform shall be governed by the laws of the UAE without regard to its conflict of law provisions.</p>       
                       
                 <br></br>    
                 <h4>For Tenants</h4>       
                 <br></br>
                 <p>"We", "Our", "Us" refers to VnM Rental Portal trading as "VnM Rental.com" and its subsidiaries, employees, officers, agents, affiliates or assigned parties. "Our web site" and “Application” collectively refers to Platform. "You", "Your" or "the Client" refers to you as the user of the Car / Automobiles / Yacht /Desert Safari Internet Service (the "Service"). "Service" may include any of the following or a combination thereof depending on the membership chosen:</p>       
                 <br></br>
                 <ul>
                    <li><p>•	process that will facilitate your uploading and managing your own car /yacht /Desrt Safari Packages listings;</p></li>
                    <li><p>•	having your cars/yacht/ heavy machinery/ commercial vehicles featured on our Platform;</p></li>    
                    <li><p>•	Website and banners design services;</p></li>
                    <li><p>•	email marketing campaigns</p></li>
                 </ul>
                 <p>
                 The following are the standard terms and conditions ("Terms and Conditions'') that apply to your use of the Service. Receipt of an Advertising Agreement signed by you will constitute your acceptance of the Terms and Conditions. Please read the Terms and Conditions in conjunction with the Advertising Agreement, and any other document provided to you at the time of signing the Advertising Agreement. All “month” references found in the Advertising Agreement, VnM Rental Platform, media kits, VnM Rental Portal branded marketing material or any other VnM Rental written or verbal communications refer to a thirty (30) day period.
                 </p>
                 <br></br>
                 <h6>1.Your obligations</h6>       
                 <p>You must: • only advertise goods/services that are currently available for purchase, rent or lease</p>
                 <ul>
                    <li><p>•	be the holder of a valid trade license in the United Arab Emirates. Ensure that you do not make, arrange or authorize the insertion of any reference Emirates or countries permitted by your subscription; Emirates or country to which your use of the Service relates; that car from the Platform for an improper purpose, including information that is defamatory, misleading or deceptive, in breach of copyright or would otherwise expose us to any liability, legal proceedings or other sanction; to us or our Platform in any document (including promotional or merchandising material) or on any web site other than our Platform without our prior written consent;</p></li>
                    <li><p>•	abide by any applicable rules and regulations related to electronic truncations; and comply with any guidelines and codes issued by the RTA or any other local regulatory authorities;</p></li>
                    <li><p>•	You should provide the goods/services mentioned in the VnM Rental.com/Platform confirmation email presented by the customer, failure to do so will result in loss of payment and the amount will be refunded back to the customer;</p></li>
                    <li><p>•	Ensure in your use of the Service, you comply with all applicable laws;</p></li>
                    <li><p>•	You must ensure that: commerce; departments concerned with the automobile industry;</p></li>
                    <li><p>•	material submitted through the Service by you refers only to listing details of a ensure that no other person, authorised or otherwise, makes use of your</p></li>
                    <li><p>•	not make any promotional or merchandising reference to us or our Platform. You acknowledge that: particular car; subscription to the Service in breach of these terms and conditions; and without our express written permission.</p></li>
                    <li><p>•	you have read and agree to be bound by the terms of all legal notices posted on</p></li>
                    <li><p>•	you represent and warrant that you are authorised to make available the material the Platform; generated or submitted to make use of the Service and that you are acting under authority of the proprietors of all cars concerned.</p></li>
                    <li><p>•	any such conduct is in breach of these terms and conditions.</p></li>
                    <li><p>•	By using the Service, you grant us an irrevocable, world-wide, royalty free licence</p></li>
                    <li><p>•	We are not under any obligation to monitor or censor the material generated by </p></li>
                    <li><p>•	We are not responsible for the Service's content or for any errors or omissions in • systems or technological failure may impede or prevent access to all or any part of • you are responsible for and must pay the cost of all telecommunications and to commercialise, copy, licence to other persons, use and adapt for any purpose any material you generate or submit to make use of the Service. users of the Service that appears on our Platform, however we reserve the right to do so; any goods/services data provided by or on behalf of you; the data; Internet access charges incurred when using our Platform, whether or not such access has been arranged by us; • you are responsible for the security and integrity of your data; • transmission of data over the Internet can be subject to errors and delays; • We will contact you from time to time to make you aware of opportunities, products and services offered by us and our business partners. You authorise us to contact you via email, SMS, MMS and other electronic media for that purpose unless you explicitly request us not to contact you via these media. Agreement Term, Suspension or Termination of Service. You may not terminate this Agreement before the end of the contracted term under any circumstances </p></li>

                </ul>
                <br></br>       
                <h6>2. Packages, Fees and Payment</h6>        
                <br></br>
                <p>Full payment in advance or payment via Post Dated Checks (PDCs) issued by the relevant company account prior to the start date outlined in the Advertising Agreement for the rent-a-car advertising section/ Yacht section/ Desert safari packages, are the only acceptable forms of payment for this Agreement. The current fees for the Service are specified in your Advertising Agreement. We offer a number of different packages that vary in terms of price and services included. You may subscribe to any of these packages but switching, or addition of special conditions to, packages is at our sole discretion. We reserve the right to amend the terms and conditions of this Agreement, including package components and fees, at any time. You will be given notice of any changes to our fees or package components. The percentage of success fee will be mentioned on the advertising agreement/contract. VAT on the same will be charged as per UAE law if applicable. After deducting the success fee the Agent / Seller / Third Party / Merchant will receive the balance amount only for vouchers where the Agent / Service Provider / Third Party / Merchant has informed VnM Rental. Payment will be transferred to the Agent / Service Provider / Third Party / Merchant on a weekly basis. VnM Rental success fee will become due when a booking is generated/sold and VnM Rental is under no obligation to transfer any amount to the Agent / Service Provider / Third Party / Merchant when service is not provided by the Merchant. VnM Rental has the right to refund any customer who has a valid complaint about Agent / Service Provider / Third Party / Merchant offering, including but not limited to its supply or delivery.</p>

                <h6>3. Refund Policy</h6>        
                <br></br>
                <p>Content posted on Platform, whether by Credit Card through the Platform, or by cheque or bank transfer via a commercial contract is paid content ("Paid Content") and must be paid for by the originator of the content.</p>
                <br></br>        

                <h6>4. Limitation of Liability and Indemnity</h6>        
                <br></br>
                <ul>
                    <li><p>•	exclude all conditions and warranties implied into the Agreement;</p></li>
                    <li><p>•	exclude consequential, special or indirect loss or damage (included but not limited )</p></li>
                    <li><p>•	limit our liability for breach of any condition or warranty that we cannot exclude to</p></li>
                    <li><p>•	resupplying the Service; or</p></li>
                    <li><p>•	paying the cost of having the Service resupplied; and</p></li>
                    <li><p>•	limit our liability in respect of any other claim in connection with the Agreement to loss of opportunity, loss of revenue and loss of profits); the greater of (at our option): whether the claim is based in contract, tort (including negligence) or statute to the amount paid to us by you under this Agreement.</p></li>
                    <li><p>•	We will not be liable under this Agreement to the extent that liability is caused by:</p></li>
                    <li><p>•	any breach of your obligations under this agreement; or</p></li>
                    <li><p>•	any delay in performance or breach of the Agreement which arises as a result of any matter beyond our control including but not limited to viruses, other defects or failure of the server hosting our Platform.</p></li>
                    <li><p>•	You indemnify us and our officers, employees and agents ("those indemnified") against any direct or consequential liabilities, losses, damages, expenses and costs (including legal expenses on a solicitor and own client basis) incurred or suffered by any of those indemnified as a result of any claim or proceedings brought by a third party against those indemnified in connection with any material generated by you in connection with the Service.</p></li>

                </ul>
                
                <br></br>        
                <h6>5. General</h6>        
                <br></br>
                <p>We may change these Terms and Conditions at any time with or without 30 days prior written notice. You acknowledge that our publishing the amended version of the Terms and Conditions at this link on our Platform constitutes written notice to you of such changes. • No delay or failure by us to enforce any provision of this Agreement will be • A notice must be sent by e-mail, prepaid post or facsimile to your or our last • You must not assign the Agreement without our written consent. • The Agreement (together with any documents referred to in the Agreement deemed a waiver or create a precedent or will prejudice our rights. known address. or provided by us at the same time as the Agreement) comprises the entire agreement. It supersedes all prior understandings, agreements or representations. • The laws of the United Arab Emirates apply . • If any term of the Agreement is or may become for any reason invalid or unenforceable at law, the validity and enforceability of the remainder will not be affected.</p>
                <br></br>        

                <br></br>        
                <h5>5. General</h5>        
                <br></br>
                <p>"We", "Our", "Us" refers to VnM Rental Portal trading as "VnM Rental.com" (Platform) and its subsidiaries, employees, officers, agents, affiliates or assigned parties. "Our web site and Application" collectively referred to as Platform. "You", "Your" or "the Client" refers to you as the user of our Internet service (the "Service")."Service" may include the following: • online banner advertising run on our Platform • email marketing campaigns • micro-website & advertising banner creative development creative services The following are the standard terms and conditions ("Terms and Conditions'') that apply to your use of the Service. Receipt of an Advertising Agreement signed by you will constitute your acceptance of the Terms and Conditions. Please read the Terms and Conditions in conjunction with the Advertising Agreement, and any other document provided to you at the time of signing the Advertising Agreement. All “month” references found in the Advertising Agreement, VnM Rental and, media kits, VnM Rental branded marketing material or any other VnM Rental written or verbal communications refer to a thirty (30) days period.</p>
                <br></br>        
        
                <h6>1. Your obligation</h6>        
                <br></br>
                <p>You must</p>
                <ul>
                    <li><p>•	Ensure that material you generate through use of the Service is not unlawful or </p></li>
                    <li><p>•	Ensure that you do not make, arrange or authorise the insertion of any reference for an improper purpose, including information that is defamatory, misleading or deceptive, in breach of copyright or would otherwise expose us to any liability, legal proceedings or other sanction; to us or our Platform in any document (including promotional or merchandising material) or on any web site/app other than our Platform without our prior written consent; commerce; </p></li>
                    <li><p>•	Abide by any applicable rules and regulations related to electronic truncations and  in your use of the Service, comply with all applicable laws; </p></li>
            
                </ul>
                <br></br>

                <h6>2. Limitation of Liability</h6>
                <br></br>        
                <p>• The Platform and the Platform Content (including information on Sales Promotions) are provided on an "AS-IS", “without faults” and “as available” basis and, to the extent lawful, with no warranties whatsoever. VnM Rental expressly disclaims (to the fullest extent permitted by law) any and all express, implied, and statutory warranties, including, without limitation, the warranties of merchantability, fitness for a particular purpose, freedom from computer virus, title, and non-infringement of proprietary rights. </p>
                <p>• VnM Rental and its personnel are not liable for any direct, indirect, punitive, incidental, special, or consequential damages or loss (including, without limitation, incidental and consequential damages, lost profits, or damages resulting from lost data or business interruption) arising out of, or in way connected with, the use or inability to use the Platform and the Platform Content, or inability to enter into an agreement for the sale or rent of any car listed on the Platform, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not VnM Rental is advised of the possibility of such damages. </p>        
                <p>• Without limiting the above paragraph, VnM Rental does not accept any liability in respect of your involvement in any mediation, arbitration, tribunal hearing, court proceeding or other proceedings (of whatever nature) with any third party advertiser in any way. </p>
                <p>• VnM Rental does not represent or endorse the accuracy or reliability of any Platform Content (including any information on Sales Promotions) and you acknowledge that any reliance upon such Platform Content (including any information on Sales Promotions) shall be at your sole risk. • VnM Rental is not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of any email due to technical problems or traffic congestion on the Internet or on this Platform or combination thereof, including any injury or damage to any users or to any person's computer related to or resulting from participation or downloading materials in connection with the Platform.</p>
                <p>
                • Reference to any specific commercial product, process, or service by trade name, trademark, manufacturer, or otherwise does not necessarily constitute or imply its endorsement, recommendation, or favouring by VnM Rental. The views and opinions of document authors do not necessarily state or reflect those of VnM Rental or any agency thereof. The User releases VnM Rental of all claims and expenses, including appropriate attorneys fees, levied against VnM Rental by a third party for the use of VnM Rental Platform by the User which violates the contract or is a breach of these Terms.
                </p>

                <br></br>
            <h6>3. General</h6>
            <p>We may change these Terms and Conditions at any time with or without 30 days prior written notice. You acknowledge that our publishing the amended version of the Terms and Conditions at this link on our Platform constitutes written notice to you of such changes.</p>
            <p> • No delay or failure by us to enforce any provision of this Agreement will be • A notice must be sent by e-mail, prepaid post or facsimile to your or our last</p>
            <p> • You must not assign the Agreement without our written consent.</p>
            <p> • The Agreement (together with any documents referred to in the Agreement deemed a waiver or create a precedent or will prejudice our rights. known address. or provided by us at the same time as the Agreement) comprises the entire agreement. It supersedes all prior understandings, agreements or representations</p>
            <p> • The laws of the United Arab Emirates, the Emirate of Dubai apply</p>
            <p> • You submit to the exclusive jurisdiction of the Courts of the Emirate of Dubai. </p>            
            <p>• If any term of the Agreement is or may become for any reason invalid or unenforceable at law, the validity and enforceability of the remainder will not be affected. If any provisions of these Terms prove invalid, unenforceable, or contain loopholes, the remaining provisions remain in effect. The parties are obliged to replace the invalid, illegal, or unenforceable provisions with ones that come closest to the meaning and economic purpose and the intent of the parties.</p>
        
            </div>

            {/** arabic */}
           
        </div>
        </div>
    );
}

export default Termscon;