import { createAsyncThunk } from "@reduxjs/toolkit";



export const handleValCurrencyChange = createAsyncThunk(
  "layout/currecny",
  async ({ key, value }, { rejectWithValue }) => {
    return { key , value };
  }
);
