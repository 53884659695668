import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  getListings,
  getPremiumListings,
  handleListingValChange,
  saveCount,
  handleClearitems
} from "../../../store/listings/listingActions";
import { resetup } from "../../../store/auth/authhelpers.js"
import { errorToast, successToast } from "../../../utils";
import { handleValLangChange } from "../../../store/lang/langActions";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate , useParams} from "react-router-dom";
import Swal from "sweetalert2";
import WTx from "../../../assets/images/smicons/WT-1_30x30.webp";
import Enq from "../../../assets/images/smicons/Enq.webp";
import {
  loginGoogleUser,
  loginUser,
  logoutUser,
  registerUser,
} from "../../../store/auth/authActions";
import axiosConfig from "../../../utils/axiosConfig";
import { Toaster } from "react-hot-toast";
import { handleValCurrencyChange } from "../../../store/currency/currencyActions";
import { useMediaQuery } from "react-responsive";
import { RxHamburgerMenu } from "react-icons/rx";
import Otlc from '../Otlc.js'
import { GoogleLogin } from "@react-oauth/google";
import verified from "../../../assets/images/smicons/verified.webp";
import Road from "../../../assets/images/smicons/RoadBlack.webp";
import Call from "../../../assets/images/smicons/call.webp";
import CopyToClipboard from 'react-copy-to-clipboard';
import Footer from "../new_main/Footer";
import Footerm from "../new_main/Footerm";
import Menu from "../homecomps/Menu_vtwo";
import Loader from "../../../assets/images/loader.gif";
import Userapp from "../homes/Userapp"
import Userappm from "../homes/Userappm"

const Item = () => {
  const dispatch = useDispatch();
  const {id} = useParams()
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { user, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [requestIsOpen, setRequestIsOpen] = useState(false);
  
  let email = useRef("");
  let fullName = useRef("");
  let password = useRef("");
  let cell = useRef("");
  
  
  const [validationError, setValidationError] = useState("");
  const navlink1 = useRef(null);
  const navlink2 = useRef(null);
  const navlink3 = useRef(null);

  const navlink4 = useRef(null);
  const navlink5 = useRef(null);
  const navlink6 = useRef(null);

  const navlink7 = useRef(null);
  const navlink8 = useRef(null);
  const navlink18 = useRef(null);
  
  const navlink10 = useRef(null);
  
  const [loginModelEnq, setLoginModelEnq] = useState(false);
  const [xitem, setXitem] = useState({})
  

  const appRef = useRef();

  const [options, setOptions] = useState({
    loop: true,
    direction: "rtl",
    align: "center",
  });
  const { t } = useTranslation();
  const {
   
    selectedBrandModels,
    selectedModel,
    limit,
    page,
    filters,
    search,
    //selectedListing,
  } = useSelector((state) => state.listing);
  const { langs, selected_lang } = useSelector((state) => state.lang);
  const { selected_curr, currencies, conversion_rate, conversion_rates } =
    useSelector((state) => state.curr);
  
  const [loginModel, setLoginModel] = useState(false);
  const [signupModel, setSignupModel] = useState(false);
  const [showcall, setShowcall] = useState(false)
  const [seliname, setSeliname] = useState("imgmc")
  const [dactive, setDactive] = useState(true)
  const [factive, setFactive] = useState(false)
  const [cactive, setCactive] = useState(false)
  const [dlvactive, setDlvactive] = useState(false)
  const [rqdactive, setRqdactive] = useState(false)
  const [priceactive, setPriceactive] = useState(false)
  const [detailfactive, setDetailfactive] = useState(false)

  const [selectedListing, setSelectedListing] = useState({})


  const brandsr = useRef(null);
    const aboutusr = useRef(null)

    const changetype = (cat) => {
      dispatch(
          getListings({
            limit,
            page: 0,
            filters: { ...filters, category: cat },
            search,
            cat,
          })
        )
          .unwrap()
          .then(() => {})
          .catch((errorData) => {
            errorToast(errorData.error);
          });
  
        dispatch(
          getPremiumListings({
            limit: 100,
            page: 0,
            model: 0,
            cat_id: cat,
            city_id: filters.citycode,
          })
        );
    }


  useEffect(() => {
    
    return (() => {
      dispatch(handleClearitems({}))
    })
  },[])

  const loadlistingdb = () =>{
   
    try{
        axiosConfig.get(`/gl/${id}`).then(res => {
            setSelectedListing(res.data.data[0])
            setSeliname(res.data.data[0]?.imgmc)
        })
    }catch(e){
      navigate('/car')
    }
  }

  useEffect(() => {
      loadlistingdb()
    
  },[])

  useEffect(() => {
    loadlistingdb()
  
},[id])
  
  const oAuth = (gauth, resp) => {
    if (gauth === "GOOGLE")
    dispatch(
      loginGoogleUser({
        g_response: resp,
      })
    )
      .unwrap()
      .then(() => {
        successToast("User Login Successfully");
        setLoginModel(false);
      })
      .catch((errorData) => {
        errorToast(errorData.error);
      });
  };
  
  const signUpUser = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // validation for all fields
    if (
      !fullName.value.trim() ||
      !email.value.trim() ||
      !password.value.trim()
    ) {
      setValidationError("All fields are required");
    } else {
      dispatch(
        registerUser({
          fullName: fullName.value,
          email: email.value,
          password: password.value,
          phone: cell.value,
        })
      )
        .unwrap()
        .then(() => {
          e.target.reset();
          successToast("User Registered Successfully");
          setSignupModel(false);
        })
        .catch((errorData) => {
          errorToast(errorData.error);
        });
    }
  };

  const signinUser = async (e) => {
    e.preventDefault();

    dispatch(
      loginUser({
        email: email.value,
        password: password.value,
      })
    )
      .unwrap()
      .then(() => {
        e.target.reset();
        successToast("User Login Successfully");
        setLoginModel(false);
        setLoginModelEnq(false)
      })
      .catch((errorData) => {
        errorToast(errorData.error);
      });
  };

  const signinUserenq = async (e, itemt , typex) => {
    e.preventDefault();

    dispatch(
      loginUser({
        email: email.value,
        password: password.value,
      })
    )
      .unwrap()
      .then(() => {
        //e.target.reset();
        successToast("Logged in successfully, You may send send Enquiries now.");
        setLoginModel(false);
        setLoginModelEnq(false)
        
        
     
      }).then(() =>{
        _handleSaveCountx(itemt, 3)
      })
       
  };

   useEffect(() => {
    setOptions({
      ...options,
      direction: selected_lang === "ar" ? "rtl" : "ltr",
    });
    
  }, [selected_lang]);

  useEffect(() => {
    let cat_id = filters?.category
    dispatch(getListings({ limit, page, filters, search , cat_id}))
      .unwrap()
      .then(() => {})
      .catch((errorData) => {
        errorToast(errorData.error);
      });
  }, [page]);

  useEffect(() => {
    let modelId = selectedBrandModels[selectedModel]?.vid;
    if (modelId) {
      dispatch(getPremiumListings({ limit: 100, page: 0, model: modelId }));
    } else {
      dispatch(handleListingValChange({ key: "premium_listings", value: [] }));
    }
  }, [selectedModel, selectedBrandModels]);

  useEffect(() => {
      if(isTabletOrMobile){
        setDactive(false); setFactive(false); setDlvactive(false); setRqdactive(false); setCactive(false);setPriceactive(true); setDetailfactive(false)
      }else{
        setDactive(true); setFactive(false); setDlvactive(false); setRqdactive(false); setCactive(false);setPriceactive(false); setDetailfactive(false)
      }
      
  },[isTabletOrMobile])


  const _handleChangeTranslate = () => {
    let selectedLanguage = selected_lang === langs[0] ? langs[1] : langs[0];
    dispatch(
      handleValLangChange({ key: "rtl", value: selectedLanguage === langs[1] })
    );
    dispatch(
      handleValLangChange({ key: "selected_lang", value: selectedLanguage })
    );
    successToast(t(`Language Changed to ${selectedLanguage}`));
  };

  const _handleChangeCurrency = () => {
    let selectedCurrency =
      selected_curr === currencies[0] ? currencies[1] : currencies[0];
    dispatch(
      handleValCurrencyChange({
        key: "conversion_rate",
        value: conversion_rates[selectedCurrency],
      })
    );
    dispatch(
      handleValCurrencyChange({ key: "selected_curr", value: selectedCurrency })
    );
    successToast(t(`Currency Changed to ${selectedCurrency}`));
  };

  const _handleSaveCountx = async  (item, type) => {
    setXitem(item)
     if(type===3 && user) {
      
      dispatch(saveCount({ item, type, user }))
        .unwrap()
        .then(() => {
          if (type === 3) {
            Swal.fire({
              title: t("REQUEST_SENT"),
              text: t("CALLBACK_MESSAGE"),
              icon: "info",
              confirmButtonText: t("COOL"),
              confirmButtonColor: "#F39C12",
            });
            setLoginModelEnq(false)
          }
        })
        .catch((errorData) => {
          errorToast(errorData.error);
        });
      
    }
  };
  
  const _handleSaveCount = async  (item, type) => {
    setXitem(item)
    if (type === 3 && !user) {
      setLoginModelEnq(true)
    } else if(type===3 && user) {
      setLoginModelEnq(true)
      
      dispatch(saveCount({ item, type, user }))
        .unwrap()
        .then(() => {
          if (type === 3) {
            Swal.fire({
              title: t("REQUEST_SENT"),
              text: t("CALLBACK_MESSAGE"),
              icon: "info",
              confirmButtonText: t("COOL"),
              confirmButtonColor: "#F39C12",
            });
            setLoginModelEnq(false)
          }
        })
        .catch((errorData) => {
          errorToast(errorData.error);
        });
    }else{
      
       dispatch(saveCount({ item, type, user }))
        .unwrap()
        .then((res) => {
          if (type === 3) {
            Swal.fire({
              title: t("REQUEST_SENT"),
              text: t("CALLBACK_MESSAGE"),
              icon: "info",
              confirmButtonText: t("COOL"),
              confirmButtonColor: "#F39C12",
            });
          }else{
            
            setShowcall({ eventid : res.eventid[0]?._id, id : item?.tluid, boolv : type=== 2 ? true : false, boolw : false})
          }
        })
        .catch((errorData) => {
          errorToast(errorData.error);
        });
      
    }
  };


  function afterOpenModal() {}


  const _handleWaCount = (item, type) => {
    if (!user) {
      dispatch(saveCount({ item, type, user: { ubd: { guid: "" } } })).unwrap().then(res => {
        
        setShowcall({ eventid : res.eventid[0]?._id, id : item?.tluid, boolv : false, boolw : true})
      });
      
      
    } else {
      dispatch(saveCount({ item, type, user })).unwrap().then(res => {
        
        setShowcall({ eventid : res.eventid[0]?._id, id : item?.tluid, boolv : false, boolw : true})
      });
    }
    window.open(
      `https://wa.me/${item?.tenant?.waNumber}?text=Hi,%20messaging%20you%20regarding%20your%20listing%20${item?.bname[selected_lang]}%20,%20${item?.mname[selected_lang]}%20,%20${item?.modelyear.label}%20which%20have%20id%20as%20${item?.tluid}%20on%20https://VNMRENTAL.com%20is%20it%20available?`,
      "_blank",
      "noreferrer"
    );
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

   const catcodechange = () => {
    
    if(selectedListing?.categoryCode){
      return (
        <Otlc tuid = {selectedListing} setSelectedListing={setSelectedListing} t={t} 
        selected_lang={selected_lang} selected_curr={selected_curr} conversion_rate={conversion_rate} 
        _handleSaveCount={_handleSaveCount} _handleWaCount={_handleWaCount}
        setShowcall={setShowcall} 
        />
      )
    }
  }

  const listingpanelenq = (item, index) => {
    return (
        <div style={{padding : "20px"}}     >
                    <div
                      className="mt-5 bg-white  border-gray-200 rounded-lg shadow  dark:border-gray-700"
                      style={{ background: "#F2F2F2" }}
                    >
                      <div className="containerpremium" style={{zIndex : -0}} >
                        
                        <div >
                        <img
                          onClick={() => setSelectedListing(item)}
                          width={"350px"}
                          src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${item?.tuid}/listing/${item.imgmc}`}
                          alt="Featured"
                        />
                        </div>
                        <div className="overlaypremiumbrand">
                        {item.tenant?.cellIsVerified &&
                            item.tenant?.addressIsVerified && (
                                  <img src={verified} alt="verified" width="20" height ="10" />
                            )}
                        </div>
                        <div className="overlaypremiumprice"  >
                        <img
                             src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${item?.blogo}`}
                          alt="Brands name"
                          width="30"
                          height ="30"
                        />        
                        </div>
                      </div>
                        {newdetailPanelenq(item, index)}
                    </div>
         </div>
    )
  }

  const detailPanelenq = (item, index) => {
    return (
      <div >
            <div className="detailPanelmodelbox">
                <h6>{item?.mname[selected_lang]}</h6>
                <span>{item?.modelyear?.label}</span>
            </div>
            <div className="logopanelbox">
                  <div className="diva">
                     <div style={{display : "flex"}}><span>{item?.vtname[selected_lang]} / <br /> {item?.btname[selected_lang]}</span>
                       &nbsp;
                       {
                                      filters?.category === "3" ? <div style={{display : "flex", paddingLeft : "5px"}}>
                                      <div style={{paddingLeft : "10px"}}>
                                        <img src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/fet/${item?.features?.find(x => x.featureCode === "108")?.ficon}`} alt="feature" /> 
                                      </div>
                                       <div style={{paddingLeft : "5px"}}>{item?.features?.find(x => x.featureCode === "108")?.rangevalueL} </div>
                                       <div style={{paddingLeft : "20px"}}>{t("WITHINSURANCE")}</div>
                                      </div> : 
                                      <div style={{display : "flex" , paddingLeft : "5px"}}>
                                      <div style={{paddingLeft : "10px"}}><img src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/fet/${item?.features?.find(x => x.featureCode === "40")?.ficon}`} alt="feature" /></div> 
                                      <div style={{paddingLeft : "5px"}}> {item?.features?.find(x => x.featureCode === "40")?.rangevalueL} </div>
                                      <div style={{paddingLeft : "20px"}}>{t("WITHINSURANCE")}</div>
                                      </div>
                                    }
                                  
                     </div> 
                  </div>
                  <div className="divb">
                                    
                                  <img
                                    className="rounded-t-lg"
                                    style={{ marginTop: 0, marginLeft: 0 , float : "right"}}
                                    src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${item?.tuid}/${item.tenant.cLogo}`}
                                    alt="Business Logo"
                                  />
                  </div>
                  
            </div>
            <div style={{display : "flex" , float : "right"}}>
                                  {Array.from({
                                        length: parseInt(item.tenant.rating) || 0,
                                      }).map((j, index1) => {
                                        return (
                                          <svg
                                            key={`filled-${index}=${index1}`}
                                            class="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#F39C12"
                                            viewBox="0 0 60 55" width="50" height="25"
                                            style={{paddingRight : "-15px" , marginRight : "-15px"}}
                                          >
                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                        );
                                      })}
                                      {parseFloat(item.tenant.rating) >
                                        parseInt(item.tenant.rating) && (
                                        <div style={{ position: "relative" }}>
                                          <svg
                                            key={`unfilled-1212`}
                                            class="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            style={{ position: "absolute" , paddingRight : "-15px" , marginRight : "-15px"}}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="grey"
                                            viewBox="0 0 60 55" width="50" height="25"
                                            
                                          >
                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                          <svg
                                            style={{ position: "absolute" ,paddingRight : "-15px" , marginRight : "-15px"}}
                                            key={`unfilled-12`}
                                            class="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#F39C12"
                                            viewBox="0 0 60 55" width="50" height="25"
                                            
                                          >
                                            <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                          </svg>
                                        </div>
                                      )}
                                      {Array.from({
                                        length: 5 - parseInt(item.tenant.rating),
                                      }).map((k, index2) => {
                                        return (
                                          <>
                                            <svg
                                              key={`unfilled-${index}-${index2}`}
                                              class="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="grey"
                                              viewBox="0 0 60 55" width="50" height="25"
                                              style={{paddingRight : "-15px" , marginRight : "-15px"}}
                                            >
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                            </svg>
                                          </>
                                        );
                                      })
                                      }
            </div>
           
             <div className="pricedetailnew" >
                   <div  style={{paddingLeft : "10px"}}>
                                  <div>
                                      <p style={{fontSize : "0.5rem" , fontWeight : "bold"}}>{item?.daily?.mileq}{t("mileqq")}</p>
                                  </div>
                                  <div >
                                      <img src={Road} alt="vnmRoad" width={"30px"}/>
                                  </div>
                                  <div style={{width : "100%"}}>
                                  <p style={{fontSize : "0.8rem"}}> {t(`${selected_curr}`)}&nbsp; {item?.daily?.amt} </p>
                                  </div>
                                  <div style={{width : "100%", textAlign : "center"}}>
                                  <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{t("PerDay")}</p>
                                  </div>    
                   </div> 

                   <div  style={{paddingLeft : "10px"}}>
                                  <div>
                                      <p style={{fontSize : "0.5rem" , fontWeight : "bold"}}>{item?.weekly?.mileq}{t("mileqq")}</p>
                                  </div>
                                  <div >
                                      <img src={Road} alt="vnmRoad" width={"30px"}/>
                                  </div>
                                  <div style={{width : "100%"}}>
                                  <p style={{fontSize : "0.8rem"}}> {t(`${selected_curr}`)}&nbsp; {item?.weekly?.amt} </p>
                                  </div>
                                  <div style={{width : "100%", textAlign : "center"}}>
                                  <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{t("PerWeek")}</p>
                                  </div>    
                   </div>
                   <div  style={{paddingLeft : "10px"}}>
                                  <div>
                                      <p style={{fontSize : "0.5rem" , fontWeight : "bold"}}>{item?.monthly?.mileq}{t("mileqq")}</p>
                                  </div>
                                  <div >
                                      <img src={Road} alt="vnmRoad" width={"30px"}/>
                                  </div>
                                  <div style={{width : "100%"}}>
                                  <p style={{fontSize : "0.8rem"}}> {t(`${selected_curr}`)}&nbsp; {item?.monthly?.amt} </p>
                                  </div>
                                  <div style={{width : "100%", textAlign : "center"}}>
                                  <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{t("PerMonth")}</p>
                                  </div>    
                   </div>

             </div>
            <div className="sectiondepositnew" >
                  <div style={{fontSize : "0.7rem"}}>
                       {t("SecurityDeposit")} : <b>{t(`${selected_curr}`)}{" "}{parseFloat(Number(item.securitydeposit)/ parseFloat(conversion_rate)).toFixed(2)}</b>
                  </div>                      
                  <div style={{fontSize : "0.7rem"}}>
                  {t("ReturnDats")} <b>{item.refunddays} </b>
                  </div>
            </div>
            

      </div>
    )
  }

  const newdetailPanelenq = (item, index) => {
    if(item?.categoryCode === "1"){
      return (
          detailPanelenq(item, index)
        )
    }else if(item?.categoryCode === "2"){
      return (
          detailPanelenq(item, index)
        )
    }else {
      return (
          detailPanelenq(item, index)
        )
    }
    
  }

  const nontab = (selectedListing) => {
    
      return (
        <div style={{display : "flex"}} >
                <div style={{ width : "20%", minWidth : "20%"}} >
                   <h5><b>{t("Pricing")}</b></h5>
                   <p style={{fontSize : "0.6rem", float : "right", paddingRight : "10px"}}>{t("Allowed Range")}</p>
                      <div style={{ marginTop  : "15px", paddingLeft : "10px", paddingRight : "10px", fontSize : "0.7rem", backgroundColor : "#efefef" }}>
                        {selectedListing?.categoryCode === "1" && 
                        <table style={{   width : "100%"}}>
                        <tbody style={{}}>
                             <tr style={{lineHeight : "2px"}}>
                                <td >&nbsp;</td>
                                <td >&nbsp;</td>
                                <td >&nbsp;</td>
                            </tr>
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                              <td style={{paddingLeft : "10px" }}>{t("Daily")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selected_curr} {parseFloat(Number(selectedListing?.daily?.amt) / parseFloat(conversion_rate)).toFixed(2)} </td>
                              <td style={{textAlign : "center" , fontWeight : "lighter"}}>{selectedListing?.daily?.mileq} {t("mileqq")}</td>
                            </tr>
                            { Number(selectedListing?.daily?.wdamt) >= 1 &&
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                              <td style={{paddingLeft : "10px" }}>{t("Daily")} {t("WITHDRIVER")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selected_curr} {parseFloat(Number(selectedListing?.daily?.wdamt) / parseFloat(conversion_rate)).toFixed(2)} </td>
                              <td style={{textAlign : "center" , fontWeight : "lighter"}}>{selectedListing?.daily?.mileq} {t("mileqq")}</td>
                            </tr>
                            }
                            <tr style={{lineHeight : "10px"}}>
                                <td >&nbsp;</td>
                                <td >&nbsp;</td>
                                <td >&nbsp;</td>
                            </tr>
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                              <td style={{paddingLeft : "10px" }}>{t("Weekly")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}> {selectedListing?.weekly?.amt === "0" ? "NA" : <>{selected_curr} {parseFloat(Number(selectedListing?.weekly?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>} </td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}> {selectedListing?.weekly?.mileq === "0" ? "NA" : <>{selectedListing?.weekly?.mileq} {t("mileqq")}</>} </td>
                            </tr>
                            { Number(selectedListing?.weekly?.wdamt) >= 1 &&
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                              <td style={{paddingLeft : "10px" }}>{t("Weekly")} {t("WITHDRIVER")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selected_curr} {parseFloat(Number(selectedListing?.weekly?.wdamt) / parseFloat(conversion_rate)).toFixed(2)} </td>
                              <td style={{textAlign : "center" , fontWeight : "lighter"}}>{selectedListing?.weekly?.mileq} {t("mileqq")}</td>
                            </tr>
                            }
                            <tr style={{lineHeight : "10px"}}>
                              <td >&nbsp;</td>
                              <td >&nbsp;</td>
                              <td >&nbsp;</td>
                            </tr>
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                              <td style={{paddingLeft : "10px"}}>{t("Monthly")}</td>
                              <td style={{textAlign : "center" , fontWeight : "lighter"}}>{selectedListing?.monthly?.amt === "0" ? "NA" : <>{selectedListing?.weekly?.amt === "0" ? "NA" : <>{selected_curr} {parseFloat(Number(selectedListing?.monthly?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>}</>} </td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.monthly?.mileq === "0" ? "NA" : <>{selectedListing?.monthly?.mileq} {t("mileqq")}</>} </td>
                            </tr>
                            
                        </tbody>
                       </table>
                        }
                        {selectedListing?.categoryCode === "2" && 
                        <table style={{   width : "100%"}}>
                        <tbody style={{}}>
                             <tr style={{lineHeight : "2px"}}>
                                <td >&nbsp;</td>
                                <td >&nbsp;</td>
                              
                            </tr>
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                              <td style={{paddingLeft : "10px" }}>{t("Daily")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.daily?.amt === "0" ? "NA" : <>{selected_curr} {parseFloat(Number(selectedListing?.daily?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>}</td>
                              
                            </tr>
                            { Number(selectedListing?.daily?.wdamt) >= 1 &&
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                              <td style={{paddingLeft : "10px" }}>{t("Daily")} {t("WITHOPERATOR")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selected_curr} {parseFloat(Number(selectedListing?.daily?.wdamt) / parseFloat(conversion_rate)).toFixed(2)} </td>
                              
                            </tr>
                            }
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                              <td style={{paddingLeft : "10px" }}>{t("PerHour")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.hourly?.amt === "0" ? "NA" : <>{selected_curr} {parseFloat(Number(selectedListing?.hourly?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>}</td>
                              
                            </tr>
                            { Number(selectedListing?.hourly?.wdamt) >= 1 &&
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                              <td style={{paddingLeft : "10px" }}>{t("PerHour")} {t("WITHOPERATOR")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selected_curr} {parseFloat(Number(selectedListing?.hourly?.wdamt) / parseFloat(conversion_rate)).toFixed(2)} </td>
                              
                            </tr>
                            }
                            
                            
                        </tbody>
                       </table>
                        }
                        {selectedListing?.categoryCode === "3" && 
                        <table style={{   width : "100%"}}>
                        <tbody style={{}}>
                             <tr style={{lineHeight : "2px"}}>
                                <td >&nbsp;</td>
                                <td >&nbsp;</td>
                                <td >&nbsp;</td>
                            </tr>
                            <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                              <td style={{paddingLeft : "10px" }}>{t("PerHour")}</td>
                              <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.hourly?.amt === "0" ? "NA" : <>{selected_curr} {parseFloat(Number(selectedListing?.hourly?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>}</td>
                              <td style={{textAlign : "center" , fontWeight : "lighter"}}>NA</td>
                            </tr>
                            
                        </tbody>
                       </table>
                        }
                        <br />
                      </div>
                      { selectedListing?.categoryCode === "1" && 
                        <>
                            <p style={{fontSize : "0.6rem", marginTop : "10px" }}>{t("AMC")} : {selected_curr}  {parseFloat(Number(selectedListing?.daily?.xmileq) / parseFloat(conversion_rate)).toFixed(2)}</p>
                            <p style={{fontSize : "0.6rem"}}>{t("DAMC")} : {selectedListing?.daily?.mileq}</p>
                        </>
                      }
                     
                    <div key={`slider-${2}`} className="slidex" style={{fontSize : "0.5rem", marginTop : "15px"}} >
                          <center>
                                <img
                                    className="rounded-t-lg my-2"
                                    style={{ alignContent : "center", alignItems : "center", textAlign : "center", width : "150px"}}
                                    src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${selectedListing?.tuid}/${selectedListing?.tenant?.cLogo}`}
                                    alt="Business Logo"
                                />
                              <div style={{paddingLeft : "15%", paddingTop : "5px"}}>
                                  <div style={{display : "flex" }}>
                                                                {Array.from({
                                                                      length: parseInt(selectedListing?.tenant?.rating) || 0,
                                                                    }).map((j, index1) => {
                                                                      return (
                                                                        <svg
                                                                          key={`filled-${index1}=${index1}`}
                                                                          class="w-6 h-6 text-gray-800 dark:text-white"
                                                                          aria-hidden="true"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          fill="#F39C12"
                                                                          viewBox="0 0 40 40" width="50" height="25"
                                                                          
                                                                        >
                                                                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                                        </svg>
                                                                      );
                                                                    })}
                                                                    {parseFloat(selectedListing?.tenant?.rating) >
                                                                      parseInt(selectedListing?.tenant?.rating) && (
                                                                      <div  style={{ position: "relative" }}>
                                                                        <svg
                                                                          key={`unfilled-1212`}
                                                                          class="w-6 h-6 text-gray-800 dark:text-white"
                                                                          aria-hidden="true"
                                                                          style={{ position: "absolute" , paddingRight : "-15px" , marginRight : "-15px"}}
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          fill="grey"
                                                                          viewBox="0 0 40 40" width="50" height="25"
                                                                          
                                                                        >
                                                                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                                        </svg>
                                                                        <svg
                                                                          style={{ position: "absolute" ,paddingRight : "-15px" , marginRight : "-15px"}}
                                                                          key={`unfilled-12`}
                                                                          class="w-6 h-6 text-gray-800 dark:text-white"
                                                                          aria-hidden="true"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          fill="#F39C12"
                                                                          viewBox="0 0 40 40" width="50" height="25"
                                                                          
                                                                        >
                                                                          <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                                                        </svg>
                                                                      </div>
                                                                    )}
                                                                    {Array.from({
                                                                      length: 5 - parseInt(selectedListing?.tenant?.rating),
                                                                    }).map((k, index2) => {
                                                                      return (
                                                                        <>
                                                                          <svg
                                                                            key={`unfilled-${index2}-${index2}`}
                                                                            class="w-6 h-6 text-gray-800 dark:text-white"
                                                                            aria-hidden="true"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="grey"
                                                                            viewBox="0 0 40 40" width="50" height="25"
                                                                            
                                                                          >
                                                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                                          </svg>
                                                                        </>
                                                                      );
                                                                    })
                                                                    }
                                  </div>
                              </div>
                                                                    
                          </center>
                    </div>
                    <div>
                    <h5><b>{t("Operating Hours")}</b></h5>
                    {
                        selectedListing?.tenant?.operatingHours?.map((y,t) => {
                          return (
                            <div style={{fontSize : "0.7rem" }}>
                              <table style={{   width : "90%" , padding : "5px" , backgroundColor : "#efefef"}}>
                                <tbody >
                                  <tr style={{borderBottom : "1px solid lightgray"}}>
                                    <td style={{width : "60%"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span> {y?.day[selected_lang]}</td>
                                    <td style={{width : "20%"}}>{y.otime}</td>
                                    <td style={{width : "20%"}}>{y.ctime}</td>
                                  </tr>
                                </tbody>
                              </table>
                          </div>
                          )
                        })
                      }
                      </div>
                    <div>
    {/** STARS WILL COME HERE */}
                          
                    </div>
                </div>
                <div style={{ minWidth : "60%" , width : "60%", paddingLeft:  "30px", paddingRight : "30px"}}>
                  <div style={{height : "40px" , maxHeight : "40px", backgroundColor : "orange", marginBottom : "5px", fontStretch : "ultra-condensed"}}>
                      <p style={{color : "white", fontStretch : "ultra-condensed", paddingTop  : "5px"}}>
                      <span style={{float : "left", fontWeight : "bold"}}>&nbsp; {selectedListing?.bname?.[selected_lang]} - {selectedListing?.mname?.[selected_lang]}</span> 
                        {selectedListing?.categoryCode === "1" && <div><span style={{float : "right", paddingRight : "2%", color : "white"}}>{selected_curr} {parseFloat(Number(selectedListing?.daily?.amt) / parseFloat(conversion_rate)).toFixed(2)} {t("Daily")}</span> </div>}   
                         {selectedListing?.categoryCode === "3" && <div><span style={{float : "right", paddingRight : "2%" , color : "white"}}>{selected_curr} {parseFloat(Number(selectedListing?.hourly?.amt) / parseFloat(conversion_rate)).toFixed(2)} {t("Hourly")}</span></div> }   
              
                       </p>
                      
                  </div>
                  <div>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/limg/${selectedListing?.tuid}/listing/${seliname}`}
                          alt="car_image"
                          style={{
                            maxHeight: "450px",
                            objectFit: "contain",
                          }}
                        />
                       <div style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
                              <div style={{padding : "5px", cursor : "pointer"}}>
                                      <img
                                      src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${selectedListing?.tuid}/listing/${selectedListing?.imgmc}`}
                                      alt="car_image"
                                      style={{
                                        maxHeight: "90px",
                                        objectFit: "contain",
                                      }}
                                      onClick={y => {y.preventDefault();setSeliname(selectedListing?.imgmc) }}
                                    />
                              </div>
                              {selectedListing?.imgpc?.map(t => {
                                
                                return(
                                  <div style={{padding : "5px", cursor : "pointer"}}>
                                  <img
                                  src={t !== "" || t !== undefined ? `${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${selectedListing?.tuid}/listing/${t}` : Loader
                                }
                                  onClick={y => {y.preventDefault();setSeliname(t)}}
                                  alt="car_image"
                                  style={{
                                    maxHeight: "90px",
                                    objectFit: "contain",
                                  }}
                                />
                                </div>
                                )    
                              })} 
                          
                        </div>
                  </div>

                  <div style={{ backgroundColor : "#efefef", minWidth : "100%"}} className="shadow">
                      <ul className="flex-1 flex items-center gap-16 max-lg:hidden" style={{paddingLeft : "10px"}}>
                          <NavLink
                            ref={navlink4}
                            style={{fontSize : "0.6rem"}}
                            onClick={t => {setDactive(true); setFactive(false); setDlvactive(false); setRqdactive(false); setCactive(false);setPriceactive(false); setDetailfactive(false)}} 
                            className = {dactive
                            ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                            : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                          >
                              {t("Description")}
                          </NavLink>
                          <NavLink
                            ref={navlink5}
                            className={`${factive ? "text-primary text-sm not-italic font-normal no-underline capitalize" : "text-black text-sm not-italic font-normal no-underline capitalize" }` }
                            onClick={t => {setDactive(false); setFactive(true); setDlvactive(false); setRqdactive(false); setCactive(false);setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("Features")}
                          </NavLink>
                         
                          <NavLink
                          ref={navlink6}
                            className={dlvactive
                                ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                                : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(true); setRqdactive(false); setCactive(false);setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("Delivery")}
                          </NavLink>
    {/** DOCS Shall be hidden  */}                      
                         {selectedListing?.categoryCode !== "2" &&  
                          <NavLink
                          ref={navlink7}
                            className={rqdactive
                                ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                                : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(false); setRqdactive(true); setCactive(false);setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("Docs")}
                          </NavLink>
                         }
                         {selectedListing?.categoryCode === "2" &&  
                          <NavLink
                          ref={navlink18}
                            className={rqdactive
                                ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                                : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(false); setRqdactive(true); setCactive(false);setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("Certificationsand")}
                          </NavLink>
                         }
                          <NavLink
                          ref={navlink8}
                            className={cactive
                                ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                                : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(false); setRqdactive(false); setCactive(true);}} 
                            style={{fontSize : "0.6rem"}}
                          >
                             {t("Contact")}
                          </NavLink>
                        </ul>
                  </div>
                  
                  <div>
                       {dactive && <p style={{fontSize : "0.6rem", padding : "7px"}}>
                            {selectedListing?.additionalinfo?.[selected_lang]}
                        </p>}
                        {factive && <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                            <div>
                            {selectedListing?.features?.slice(0,10)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} &nbsp;{ft.rangevalueL === "1" ? "" : ":" + ft.rangevalueL} &nbsp;</span></p>
                              )
                            })}
                            </div>
                            <div>
                            {selectedListing?.features?.slice(11,20)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} &nbsp;{ft.rangevalueL === "1" ? "" : ":" + ft.rangevalueL} &nbsp;</span></p>
                              )
                            })}
                            </div><div>
                            {selectedListing?.features?.slice(21,30)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} &nbsp;{ft.rangevalueL === "1" ? "" : ":" + ft.rangevalueL} &nbsp;</span></p>
                              )
                            })}
                            </div><div>
                            {selectedListing?.features?.slice(31,40)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} &nbsp;{ft.rangevalueL === "1" ? "" : ":" + ft.rangevalueL} &nbsp;</span></p>
                              )
                            })}</div>
                        </div>}
                        {dlvactive && <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                            <div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(0,10)?.map(ft => {
                              if(ft.areaCode === "" || ft.areaCode === undefined){
                                return 
                              }
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} </span></p>
                              )
                            })}
                            </div>
                            <div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(11,20)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]}</span></p>
                              )
                            })}
                            </div><div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(21,30)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]}</span></p>
                              )
                            })}
                            </div><div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(31,40)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} </span></p>
                              )
                            })}</div><div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(41,50)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]}</span></p>
                              )
                            })}</div>
                            <div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(51,60)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]}</span></p>
                              )
                            })}</div>
                            
                        </div>}    
                        {rqdactive && selectedListing?.categoryCode !=="2" && <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                             <div style={{padding : "5px" , borderRight : "1px solid lightgray" , width : "50%"}}>
                               <label>{t("RESIDENT")}</label>
                                {selectedListing?.documentsrequiredresident?.[selected_lang]?.split(",")?.map(m => {
                                  return (
                                    <p><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{m}</p>
                                  )
                                })}
                             </div>
                             <div style={{padding : "5px" , borderRight : "1px solid lightgray", width : "50%"}}>
                                <label>{t("TOURIST")}</label>
                                {selectedListing?.documentsrequiredtourist?.[selected_lang]?.split(",")?.map(m => {
                                  return (
                                    <p><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{m}</p>
                                  )
                                })}
                              </div>     
                        </div>}
                        {rqdactive && selectedListing?.categoryCode ==="2" && 
                          <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                             <div style={{padding : "5px" , borderRight : "1px solid lightgray" , width : "50%"}}>
                              - <span style={{fontWeight : "bold"}}>{t("REQ_DOC")}</span> : {selectedListing?.documentsrequiredresident[selected_lang]}<br />
                              - <span style={{fontWeight : "bold"}}>{t("Accommodation")}</span> : {selectedListing?.maccommodationcharges}
                             </div>
                             <div style={{padding : "5px" , borderRight : "1px solid lightgray", width : "50%"}}>
                             - <span style={{fontWeight : "bold"}}>{t("Food")}</span> : {selectedListing?.mfoodcharges} <br/>
                             - <span style={{fontWeight : "bold"}}>{t("Certifications")}</span> : {selectedListing?.mcertifications}
                              </div>     
                        </div>}
                        {cactive && 
                           <div style={{fontSize :"0.6rem" , padding : "10px"}}>
                                    <p>{selectedListing?.tenant?.tname[selected_lang]}</p>
                                    <p>{selectedListing?.tenant?.stAddr && selectedListing?.tenant?.stAddr + "-"} 
                                    {selectedListing?.tenant?.areas[selected_lang]}</p>
                                    <p>{selectedListing?.tenant?.city[selected_lang]}</p> 
                         </div>
                        }
                  </div>            
                </div>
                <div style={{width : "20%", minWidth : "20%"}}>
                <div className="buttonsdetailnewp" style={{ height : "25px", maxHeight : "25px", marginTop : "10px"}}>
                  <div className="tooltip" style={{backgroundColor : "#dc2f28", color : "white", cursor : "pointer" , border :"1px solid orange", fontSize : "0.8rem" }} >
                          <center>
                                        <button style={{fontSize : "0.5rem" , color : "white"}} onClick={() => {_handleSaveCount(selectedListing, 2); setShowcall({id : selectedListing?.tluid, boolv : true})}}>
                                        {showcall.id === selectedListing?.tluid && showcall.boolv === true ? selectedListing?.tenant?.cellNumberc : 
                                            <img
                                            src={Call}
                                            alt="abc"
                                            style={{width : "15px" , height : "15px", marginTop : "5px"}}
                                          />
                                        }
                                        </button>
                                 
                           </center>
                          </div>
                          <div style={{backgroundColor : "#57b95b", color : "white", border :"1px solid orange" }}>
                            <center>
                          <button onClick={() => _handleWaCount(selectedListing, "1")}>
                         
                                    <img
                                      src={WTx}
                                      alt="abc"
                                      style={{ width : "20px" , height : "20px"}}
                                    />
                           
                          </button>
                          </center>
                             </div>
                           <div style={{backgroundColor : "#4e4e4e", color : "white", cursor : "pointer" , border :"1px solid orange"}} >
                                      <form action =   {`/item/${selectedListing?.tluid}-${selectedListing?._id}`}>
                                            <center>
                                            <button 
                                              onClick={(n) => {n.preventDefault(); _handleSaveCount(selectedListing, 3)}}
                                              className="cursor-pointer no-underline inline-flex items-center text-white"
                                            >
                                              
                                              <img
                                                src={Enq}
                                                
                                                alt="abc"
                                                style={{width : "25px" , height : "20px", marginTop : "5px"}}
                                              />
                                              
                                            </button>
                                            </center>
                                            </form>
                           </div>
                  </div>
                  <div style={{marginTop : "5px", marginBottom : "5px" , backgroundColor : "#efefef"}}>
                    <p style={{fontSize : "0.6rem",  paddingLeft : "10px", color : "orange", fontWeight : "bold"}}>{t("Specification")}</p>
                      {selectedListing?.categoryCode === "1" && 
                      <div style={{padding : "10px" , fontSize : "0.6rem"}} >
                          <table style={{   width : "100%"}}>
                            <tbody style={{}}>
                                 
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                                  <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span> {t("Name")}</td>
                                  <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.bname[selected_lang]}</td>
                                </tr>
                                
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Model Year")}</td>
                                  <td style={{textAlign : "center", fontWeight : "lighter"}}> {selectedListing?.modelyear?.label } </td>
                                </tr>
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Fuel Type")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.fuelname[selected_lang] } </td>
                                </tr>
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Transmission")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.trmname[selected_lang] } </td>
                                </tr>                                    
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Int_Ext_Col")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.extcolor} / {selectedListing?.intcolor } </td>
                                </tr>                                    
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("INSURANCE")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {t("WITHINSURANCE")} </td>
                                </tr>                          
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Seats")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}>  {selectedListing?.features?.find(x => x.featureCode === "40")?.rangevalueL !== "0" ? selectedListing?.features.find(x => x.featureCode === "40")?.rangevalueL : "0"} </td>
                                </tr>
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("SecurityDeposit")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selected_curr} {parseFloat(Number(selectedListing?.securitydeposit) / parseFloat(conversion_rate)).toFixed(2)} </td>
                                </tr>
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("ReturnDays")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.refunddays} </td>
                                </tr>
                                      
                            </tbody>
                        </table>

                      </div>}
                      {selectedListing?.categoryCode === "2" && 
                      <>
                          <div style={{padding : "10px" , fontSize : "0.6rem"}} >
                          <table style={{   width : "100%"}}>
                            <tbody style={{}}>
                                 
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                                  <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span> {t("Name")}</td>
                                  <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.bname[selected_lang]}</td>
                                </tr>
                                
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Model Year")}</td>
                                  <td style={{textAlign : "center", fontWeight : "lighter"}}> {selectedListing?.modelyear?.label } </td>
                                </tr>
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Fuel Type")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.fuelname[selected_lang] } </td>
                                </tr>
                                                                   
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Int_Ext_Col")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.extcolor} / {selectedListing?.intcolor } </td>
                                </tr>                                    
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("INSURANCE")}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}> {t("WITHINSURANCE")} </td>
                                </tr>                          
                                      
                            </tbody>
                        </table>

                      </div>
                      </>}
                      {selectedListing?.categoryCode === "3" && 
                      <>
                          <div style={{padding : "10px" , fontSize : "0.6rem"}} >
                          <table style={{   width : "100%"}}>
                            <tbody style={{}}>
                                 
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                                  <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span> {t("Name")}</td>
                                  <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.bname[selected_lang]}</td>
                                </tr>
                                
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Model Year")}</td>
                                  <td style={{textAlign : "center", fontWeight : "lighter"}}> {selectedListing?.modelyear?.label } </td>
                                </tr>
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{selectedListing?.features?.find(x => x.featureCode === "108")[selected_lang]}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}>  {selectedListing?.features?.find(x => x.featureCode === "108")?.rangevalueL !== "0" ? selectedListing?.features?.find(x => x.featureCode === "108")?.rangevalueL : "0"} </td>
                                </tr>
                            </tbody>
                        </table>

                      </div>
                      </>}
                      <p style={{fontSize : "0.6rem",  paddingLeft : "10px", color : "orange", fontWeight : "bold"}}>{t('Payment Mode')}</p>
                      <div style={{padding : "10px" , fontSize : "0.65rem"}} >
                          <table style={{   width : "100%"}}>
                            <tbody >
                                {
                                  selectedListing?.tenant?.paymentmodes.map((y, t) => {
                                    return (
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                                          <td style={{paddingLeft : "10px" }}>
                                          <span style={{color : "orange", paddingRight : "5px" }}>⚬</span>    {(y?.modename[selected_lang] === "Crypto" && selectedListing?.categoryCode ==="2") ? "Bank Transfer" :   y?.modename[selected_lang]} 
                                          </td>
                                          <td style={{textAlign : "center", fontWeight : "lighter"}}>{y?.modename[selected_lang] ? "YES" : "NA" }</td>
                                        </tr>
                                    )
                                  })
                                } 
                            </tbody>
                        </table>

                      </div>                                              
                  </div>
                  <div>
         {/** CLOSING QA FORMS 
                  {showcall.id === selectedListing?.tluid && showcall.boolv === true && showcall.boolw === false && qaforcall() }              
                  {showcall.id === selectedListing?.tluid && showcall.boolv === false && showcall.boolw === true && qaforwa()}
         */}      
                  </div>
                  {selectedListing?.categoryCode !== "2" && 
                  <div className="flex flex-col lg:flex-row justify-between py-3 my-0"  >
                          <div class="hidden lg:block" style={{width : "100%" }} >
                            <center>
                              <a href={selectedListing?.tenant?.fbProfile} target="_blank" rel="noreferrer"> 
                              <img
                                src={
                                  process.env.REACT_APP_PUBLIC_URL +
                                  "images/icons/facebook_.webp"
                                }
                                alt="fb"
                                style={{ height: 30 }}
                                
                              />
                              </a>
                              </center>
                            </div>
                          <div class="hidden lg:block"  style={{width : "100%"}}>
                            <center>
                              <a href={selectedListing?.tenant?.liProfile} target="_blank" rel="noreferrer"> 
                              <img
                                src={
                                  process.env.REACT_APP_PUBLIC_URL +
                                  "images/icons/youtube.png"
                                  
                                }
                                alt="twi"
                                style={{ height: 30 }}
                              />
                              </a>
                              </center>
                            </div>
                          <div class="hidden lg:block"  style={{width : "100%"}}>
                          <center>
                             <a href={selectedListing?.tenant?.insProfile} target="_blank" rel="noreferrer"> 
                              
                              <img
                                src={
                                  process.env.REACT_APP_PUBLIC_URL +
                                  "images/icons/instagram_.webp"
                                }
                                style={{ height: 30 }}
                                alt="insta"
                              />
                              </a>
                              </center>
                            </div>

                          <div class="hidden lg:block"  style={{width : "100%" , marginTop : "10px", cursor : "pointer"}}>
                          <center>
                          <span style={{fontSize : "0.5rem"}}></span>
                          <CopyToClipboard text={`https://vnmrental.com/item/${id}`}
                            >
                             <img
                              src={
                                process.env.REACT_APP_PUBLIC_URL +
                                "images/icons/share_.webp"
                              }
                              style={{ height: 20 , marginTop : "-5px" }}
                              alt="share"
                              className="h-10 my-2 mx-auto"
                            />
                          </CopyToClipboard>
                              
                           
                         
                              </center>
                            </div>
                        <div class="hidden lg:block"></div>        
                        <div class="hidden lg:block"></div>        
                        <div class="hidden lg:block"></div>
                        <div class="hidden lg:block"></div>        
                        <div class="hidden lg:block"></div>        
                        <div class="hidden lg:block"></div>        

                  </div>
                  }
                </div>
                
        </div>
      )
  }

  const tabview = (selectedListing) => {
      return (
       <>
       <div class="grid grid-cols-1 gap-0" style={{ marginTop: 0 , padding: "5px"}}  >
               <div style={{ minWidth : "90%" , width : "100%"}}>
                  <div style={{height : "50px" , maxHeight : "35px", marginBottom : "5px", backgroundColor : "orange", fontStretch : "ultra-condensed"}}>
                      {selectedListing?.categoryCode === "1" && <div><span style={{ paddingRight : "2%", color : "white"}}>{ selectedListing?.bname?.[selected_lang]}</span><span style={{float : "right", paddingRight : "2%", color : "white"}}>{selected_curr} {parseFloat(Number(selectedListing?.daily?.amt) / parseFloat(conversion_rate)).toFixed(2)} {t("Daily")}</span> </div>}   
                  
                       {selectedListing?.categoryCode === "3" && <div><span style={{paddingRight : "2%", color : "white"}}>{ selectedListing?.bname?.[selected_lang]}</span><span style={{float : "right", paddingRight : "2%" , color : "white"}}>{selected_curr} {parseFloat(Number(selectedListing?.hourly?.amt) / parseFloat(conversion_rate)).toFixed(2)} {t("Hourly")}</span></div> }   
                     
                      
                  </div>
                  <div>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/limg/${selectedListing?.tuid}/listing/${seliname}`}
                          alt="car_image"
                          style={{
                            maxHeight: "450px",
                            objectFit: "contain",
                          }}
                        />
                       <div style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
                              <div style={{padding : "5px", cursor : "pointer"}}><img
                                      src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${selectedListing?.tuid}/listing/${selectedListing?.imgmc}`}
                                      alt="car_image"
                                      style={{
                                        maxHeight: "90px",
                                        objectFit: "contain",
                                      }}
                                      onClick={y => setSeliname(selectedListing?.imgmc)}
                                    />
                              </div>
                              {selectedListing?.imgpc?.map(t => {
                                return(
                                  <div style={{padding : "5px", cursor : "pointer"}}><img
                                  src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${selectedListing?.tuid}/listing/${t}`}
                                  onClick={y => setSeliname(t)}
                                  alt="car_image"
                                  style={{
                                    maxHeight: "90px",
                                    objectFit: "contain",
                                  }}
                                />
                                </div>
                                )    
                              })} 
                          
                        </div>
                  </div>

                  <div className="logopanelbox" >
                  <div className="diva">
                     <div style={{display : "flex"}}><span>{selectedListing?.vtname?.[selected_lang]} / <br /> {selectedListing?.btname?.[selected_lang]}</span>
                       &nbsp;
                       {
                                      filters?.category === "3" ? <div style={{display : "flex", paddingLeft : "5px"}}>
                                      <div style={{paddingLeft : "10px"}}>
                                        <img src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/fet/${selectedListing?.features?.find(x => x.featureCode === "108")?.ficon}`} alt="feature" /> 
                                      </div>
                                       <div style={{paddingLeft : "5px"}}>{selectedListing?.features?.find(x => x.featureCode === "108")?.rangevalueL} </div>
                                       <div style={{paddingLeft : "20px"}}> {selectedListing?.category !== "2" && t("WITHINSURANCE")}</div>
                                      </div> : 
                                      <div style={{display : "flex" , paddingLeft : "5px"}}>
                                      {selectedListing?.features?.find(x => x.featureCode === "40")?.ficon && <img style={{height :"20px", width : "20px"}} src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/fet/${selectedListing?.features.find(x => x.featureCode === "40")?.ficon}`} alt="feature" />}  
                                      <div style={{paddingLeft : "5px"}}> {selectedListing?.features?.find(x => x?.featureCode === "40")?.rangevalueL} </div>
                                      <div style={{paddingLeft : "20px"}}>{t("WITHINSURANCE")}</div>
                                      </div>
                                    }
                                  
                     </div> 
                  </div>
                  <div className="divb">
                                    
                                  <img
                                    className="rounded-t-lg"
                                    style={{ marginTop: 0, marginLeft: 0 , float : "right"}}
                                    src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${selectedListing?.tuid}/${selectedListing?.tenant?.cLogo}`}
                                    alt="Business Logo"
                                  />
                  </div>
                  
                 </div>
                 <div style={{display : "flex" , float : "right", paddingRight : "4px"}}>
                                  {Array.from({
                                        length: parseInt(selectedListing?.tenant?.rating) || 0,
                                      }).map((j, index1) => {
                                        return (
                                          <svg
                                            key={`filled=${index1}`}
                                            class="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#F39C12"
                                            viewBox="0 0 60 55" width="50" height="25"
                                            style={{paddingRight : "-15px" , marginRight : "-15px"}}
                                          >
                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                        );
                                      })}
                                      {parseFloat(selectedListing?.tenant?.rating) >
                                        parseInt(selectedListing?.tenant?.rating) && (
                                        <div style={{ position: "relative" }}>
                                          <svg
                                            key={`unfilled-1212`}
                                            class="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            style={{ position: "absolute" , paddingRight : "-15px" , marginRight : "-15px"}}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="grey"
                                            viewBox="0 0 60 55" width="50" height="25"
                                            
                                          >
                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                          <svg
                                            style={{ position: "absolute" ,paddingRight : "-15px" , marginRight : "-15px"}}
                                            key={`unfilled-12`}
                                            class="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#F39C12"
                                            viewBox="0 0 60 55" width="50" height="25"
                                            
                                          >
                                            <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                          </svg>
                                        </div>
                                      )}
                                      {Array.from({
                                        length: 5 - parseInt(selectedListing?.tenant?.rating),
                                      }).map((k, index2) => {
                                        return (
                                          <>
                                            <svg
                                              key={`unfilled-${index2}`}
                                              class="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="grey"
                                              viewBox="0 0 60 55" width="50" height="25"
                                              style={{paddingRight : "-15px" , marginRight : "-15px"}}
                                            >
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                            </svg>
                                          </>
                                        );
                                      })
                                      }
                </div>
                  <hr />                    
              
                  <div style={{ backgroundColor : "#efefef", minWidth : "100%"}} className="shadow mt-3">
                      <ul className="flex-1 flex items-center gap-3" style={{paddingLeft : "10px", fontSize : "0.5rem"}}>
                          <NavLink
                            ref={navlink4}
                            style={{fontSize : "0.6rem"}}
                            onClick={t => {setDactive(true); setFactive(false); setDlvactive(false); setRqdactive(false); setCactive(false);;setPriceactive(false); setDetailfactive(false)}} 
                            className = {dactive
                            ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                            : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                          >
                            {t("Description")}
                          </NavLink>
                          <NavLink
                            ref={navlink5}
                            className={`${factive ? "text-primary text-sm not-italic font-normal no-underline capitalize" : "text-black text-sm not-italic font-normal no-underline capitalize" }` }
                            onClick={t => {setDactive(false); setFactive(true); setDlvactive(false); setRqdactive(false); setCactive(false);;setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("Features")}
                          </NavLink>
                         
                          <NavLink
                          ref={navlink6}
                            className={dlvactive
                                ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                                : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(true); setRqdactive(false); setCactive(false);setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("Delivery")}
                          </NavLink>
                          {selectedListing.categoryCode !== "2" &&
                          <NavLink
                          ref={navlink7}
                            className={rqdactive
                                ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                                : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(false); setRqdactive(true); setCactive(false);setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("Docs")}
                          </NavLink>
                          }
                          {selectedListing?.categoryCode === "2" &&  
                          <NavLink
                          ref={navlink18}
                            className={rqdactive
                                ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                                : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(false); setRqdactive(true); setCactive(false);setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("DETAILS")}
                          </NavLink>
                         }
                          <NavLink
                          ref={navlink8}
                            className={cactive
                                ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                                : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(false); setRqdactive(false); setCactive(true);setPriceactive(false); setDetailfactive(false)}} 
                            style={{fontSize : "0.6rem"}}
                          >
                            {t("Contact")}
                          </NavLink>
                          <NavLink
                            ref={navlink10}
                            style={{fontSize : "0.6rem"}}
                            onClick={t => {setDactive(false); setFactive(false); setDlvactive(false); setRqdactive(false); setCactive(false);setPriceactive(true); setDetailfactive(false)}} 
                            className = {priceactive
                            ? "text-primary text-sm not-italic font-normal no-underline capitalize"
                            : "text-black text-sm not-italic font-normal no-underline capitalize"
                            }
                          >
                            {t("Pricing")}
                          </NavLink>
                      
                        </ul>
                  </div>
           
                  <div style={{height : "500px" , maxHeight : "500px"}}>
                       {dactive && <p style={{fontSize : "0.6rem", padding : "7px"}}>
                            {selectedListing?.additionalinfo?.[selected_lang]}
                        </p>}
                        {factive && <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                            <div>
                            {selectedListing?.features?.slice(0,10)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} &nbsp;{ft.rangevalueL === "1" ? "" : ":" + ft.rangevalueL} &nbsp;</span></p>
                              )
                            })}
                            </div>
                            <div>
                            {selectedListing?.features?.slice(11,20)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} &nbsp;{ft.rangevalueL === "1" ? "" : ":" + ft.rangevalueL} &nbsp;</span></p>
                              )
                            })}
                            </div><div>
                            {selectedListing?.features?.slice(21,30)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} &nbsp;{ft.rangevalueL === "1" ? "" : ":" + ft.rangevalueL} &nbsp;</span></p>
                              )
                            })}
                            </div><div>
                            {selectedListing?.features?.slice(31,40)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} &nbsp;{ft.rangevalueL === "1" ? "" : ":" + ft.rangevalueL} &nbsp;</span></p>
                              )
                            })}</div>
                        </div>}
                        {dlvactive && <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                            <div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(0,18)?.map(ft => {
                              if(ft.areaCode === "" || ft.areaCode === undefined){
                                return 
                              }
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]} </span></p>
                              )
                            })}</div>
                            <div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(19,36)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]}</span></p>
                              )
                            })}</div>
                            <div style={{padding : "5px"}}>
                            {selectedListing?.tenant?.deliveryarea?.slice(37,50)?.map(ft => {
                              return(
                               <p> <span style={{color : "orange", paddingRight : "5px" }}>⚬</span><span>{ft[selected_lang]}</span></p>
                              )
                            })}</div>
                            
                        </div>}    
                        {rqdactive &&  selectedListing?.categoryCode !== "2" && <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                             <div style={{padding : "5px" , borderRight : "1px solid lightgray" , width : "50%"}}>
                                <label>{t("RESIDENT")}</label>
                                {selectedListing?.documentsrequiredresident?.[selected_lang]?.split(",")?.map(m => {
                                  return (
                                    <p><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{m}</p>
                                  )
                                })}
                             </div>
                             <div style={{padding : "5px" , borderRight : "1px solid lightgray", width : "50%"}}>
                             <label>{t("TOURIST")}</label>
                                {selectedListing?.documentsrequiredtourist?.[selected_lang]?.split(",")?.map(m => {
                                  return (
                                    <p><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{m}</p>
                                  )
                                })}
                              </div>     
                        </div>}
                        {rqdactive &&  selectedListing?.categoryCode === "2" && <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                        <div style={{fontSize : "0.6rem", padding : "7px", display :"flex"}}>
                             <div style={{padding : "5px" , borderRight : "1px solid lightgray" , width : "50%"}}>
                              - <span style={{fontWeight : "bold"}}>{t("REQ_DOC")}</span> : {selectedListing?.documentsrequiredresident[selected_lang]}<br />
                              - <span style={{fontWeight : "bold"}}>{t("Accommodation")}</span> : {selectedListing?.maccommodationcharges}
                             </div>
                             <div style={{padding : "5px" , borderRight : "1px solid lightgray", width : "50%"}}>
                             - <span style={{fontWeight : "bold"}}>{t("Food")}</span> : {selectedListing?.mfoodcharges} <br/>
                             - <span style={{fontWeight : "bold"}}>{t("Certifications")}</span> : {selectedListing?.mcertifications}
                              </div>     
                        </div>
                        </div>}        

                        {cactive && 
                           <div style={{fontSize :"0.6rem" , padding : "10px"}}>
                                    <p>{selectedListing?.tenant?.tname[selected_lang]}</p>
                                    <p>{selectedListing?.tenant?.stAddr && selectedListing?.tenant?.stAddr + "-"} 
                                    {selectedListing?.tenant?.areas[selected_lang]}</p>
                                    <p>{selectedListing?.tenant?.city[selected_lang]}</p> 
                                </div>
                        }
                        {priceactive && selectedListing.categoryCode === "1" && <>
                          <div className="pricedetailnew" style={{marginTop : "15px"}} >
                              
                              <div  style={{paddingLeft : "10px"}}>
                                                  <center>
                                                  <div>
                                                      <p style={{fontSize : "0.5rem" , fontWeight : "bold"}}>{selectedListing?.daily?.mileq}{t("mileqq")}</p>
                                                  </div>
                                                  <div >
                                                      <img src={Road} alt="vnmRoad" width={"30px"}/>
                                                  </div>
                                                  <div style={{width : "100%"}}>
                                                  <p style={{fontSize : "0.8rem"}}> {t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.daily?.amt) / parseFloat(conversion_rate)).toFixed(2)} </p>
                                                  </div>
                                                  <div style={{width : "100%", textAlign : "center"}}>
                                                  <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{t("PerDay")}</p>
                                                  </div>  
                                                  </center>  
                               </div> 
                          
                              <div  style={{paddingLeft : "10px"}}>
                                            <center>
                                              <div>
                                                  <p style={{fontSize : "0.5rem" , fontWeight : "bold"}}> {selectedListing?.weekly?.mileq === "0" ? "Weekly" : <>{selectedListing?.weekly?.mileq}{t("mileqq")}</>} </p>
                                              </div>
                                              <div >
                                                  <img src={Road} alt="vnmRoad" width={"30px"}/>
                                              </div>
                                              <div style={{width : "100%"}}>
                                              <p style={{fontSize : "0.8rem"}}> {selectedListing?.weekly?.amt === "0" ? "NA" : <>{t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.weekly?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>}  </p>
                                              </div>
                                              <div style={{width : "100%", textAlign : "center"}}>
                                              <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}> {selectedListing?.weekly?.amt === "0" ? "" : <>{t("PerWeek")}</>} </p>
                                              </div>
                                              </center>    
                              </div>
                             
                              <div  style={{paddingLeft : "10px"}}>
                                              <center>    
                                              <div>
                                                  <p style={{fontSize : "0.5rem" , fontWeight : "bold"}}> {selectedListing?.monthly?.mileq === "0" ? "Monthly" : <>{selectedListing?.monthly?.mileq}{t("mileqq")}</>} </p>
                                              </div>
                                              <div >
                                                  <img src={Road} alt="vnmRoad" width={"30px"}/>
                                              </div>
                                              <div style={{width : "100%"}}>
                                              <p style={{fontSize : "0.8rem"}}>{selectedListing?.monthly?.amt === "0" ? "NA" : <> {t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.monthly?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>} </p>
                                              </div>
                                              <div style={{width : "100%", textAlign : "center"}}>
                                              <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{selectedListing?.monthly?.amt === "0" ? "" : <>{t("PerMonth")}</> }  </p>
                                              </div>  
                                              </center>  
                              </div>
                              

                           </div>
                           <div className="pricedetailnew" style={{marginTop : "15px"}} >
                                  {Number(selectedListing?.daily?.wdamt) >= 1 && 
                                  <div  style={{paddingLeft : "10px"}}>
                                              <center>
                                              <div>
                                                  <p style={{fontSize : "0.5rem" , fontWeight : "bold"}}>{selectedListing?.daily?.mileq}{t("mileqq")}</p>
                                              </div>
                                              <div >
                                                  <img src={Road} alt="vnmRoad" width={"30px"}/>
                                              </div>
                                              <div style={{width : "100%"}}>
                                              <p style={{fontSize : "0.8rem"}}> {t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.daily?.wdamt) / parseFloat(conversion_rate)).toFixed(0)} </p>
                                              </div>
                                              <div style={{width : "100%", textAlign : "center"}}>
                                              <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{t("WITHDRIVER")} { t("PerDay")}</p>
                                              </div>  
                                              </center>  
                              </div> 
                                  }
                              {(Number(selectedListing?.weekly?.wdamt)) >= 1 && 
                               <div  style={{paddingLeft : "10px"}}>
                                          <center>
                                          <div>
                                              <p style={{fontSize : "0.5rem" , fontWeight : "bold"}}>{selectedListing?.weekly?.mileq}{t("mileqq")}</p>
                                          </div>
                                          <div >
                                              <img src={Road} alt="vnmRoad" width={"30px"}/>
                                          </div>
                                          <div style={{width : "100%"}}>
                                          <p style={{fontSize : "0.8rem"}}> {t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.weekly?.wdamt) / parseFloat(conversion_rate)).toFixed(0)} </p>
                                          </div>
                                          <div style={{width : "100%", textAlign : "center"}}>
                                          <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{t("Weekly")} {t("WITHDRIVER")}</p>
                                          </div>  
                                          </center>  
                                </div> 
                                    } 
                             
                           </div>
                        </>
                        }


                        {priceactive && selectedListing.categoryCode === "3" && <>
                          <div style={{marginTop : "15px"}} >
                                              <center>    
                                              <div style={{width : "100%"}}>
                                              <p style={{fontSize : "0.8rem"}}>{selectedListing?.hourly?.amt === "0" ? "NA" : <> {t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.hourly?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>} </p>
                                              </div>
                                              <div style={{width : "100%", textAlign : "center"}}>
                                              <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{selectedListing?.hourly?.amt === "0" ? "" : <>{t("PerHour")}</> }  </p>
                                              </div>  
                                              </center>  
                           </div>
                        </>
                        }
                        {priceactive && selectedListing.categoryCode === "2" && <>
                          <div style={{display : "flex", justifyContent : "center"}}>
                            <div style={{marginTop : "15px"}} >
                                    <center>    
                                        <div style={{width : "100%"}}>
                                            <p style={{fontSize : "0.8rem"}}>{selectedListing?.hourly?.amt === "0" ? "NA" : <> {t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.hourly?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>} </p>
                                        </div>
                                        
                                        <div style={{width : "100%", textAlign : "center"}}>
                                            <p style={{fontSize : "0.5rem" , right : "0px"  }}>{selectedListing?.hourly?.wdamt === "0" ? "" : <>{t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.hourly?.wdamt) / parseFloat(conversion_rate)).toFixed(2)} {t("WITHOPERATOR")}</> }  </p>
                                        </div>
                                        <div style={{width : "100%", textAlign : "center"}}>
                                            <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{selectedListing?.hourly?.amt === "0" ? "" : <>{t("PerHour")}</> }  </p>
                                        </div>  
                                      </center>  
                            </div>
                            <div style={{marginTop : "15px", paddingLeft : "40px"}} >
                                    <center>    
                                        <div style={{width : "100%"}}>
                                            <p style={{fontSize : "0.8rem"}}>{selectedListing?.daily?.amt === "0" ? "NA" : <> {t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.daily?.amt) / parseFloat(conversion_rate)).toFixed(2)}</>} </p>
                                        </div>
                                        
                                        <div style={{width : "100%", textAlign : "center"}}>
                                            <p style={{fontSize : "0.5rem" , right : "0px"  }}>{selectedListing?.hourly?.wdamt === "0" ? "" : <>{t(`${selected_curr}`)}&nbsp; {parseFloat(Number(selectedListing?.daily?.wdamt) / parseFloat(conversion_rate)).toFixed(2)} {t("WITHOPERATOR")}</> }  </p>
                                        </div>
                                        <div style={{width : "100%", textAlign : "center"}}>
                                            <p style={{fontSize : "0.5rem" , right : "0px", fontWeight : "bold"}}>{selectedListing?.hourly?.amt === "0" ? "" : <>{t("PerDay")}</> }  </p>
                                        </div>  
                                      </center>  
                            </div>
                           </div>
                        </>
                        }

                        {detailfactive && <>
                          {selectedListing?.categoryCode === "1" && 
                              <div style={{padding : "10px" , fontSize : "0.5rem"}} >
                                  <table style={{   width : "100%"}}>
                                    <tbody style={{}}>
                                        
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                                          <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span> {t("Name")}</td>
                                          <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.bname[selected_lang]}</td>
                                        </tr>
                                        
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                          <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Model Year")}</td>
                                          <td style={{textAlign : "center", fontWeight : "lighter"}}> {selectedListing?.modelyear?.label } </td>
                                        </tr>
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                          <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Fuel Type")}</td>
                                          <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.fuelname[selected_lang] } </td>
                                        </tr>
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                          <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Transmission")}</td>
                                          <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.trmname[selected_lang] } </td>
                                        </tr>                                    
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                          <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Int_Ext_Col")}</td>
                                          <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.extcolor} / {selectedListing?.intcolor } </td>
                                        </tr>                                    
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                          <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("INSURANCE")}</td>
                                          <td style={{textAlign : "center" , fontWeight : "lighter"}}> {t("WITHINSURANCE")} </td>
                                        </tr>                          
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                          <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Seats")}</td>
                                          <td style={{textAlign : "center" , fontWeight : "lighter"}}>  {selectedListing?.features.find(x => x.featureCode === "40")?.rangevalueL !== "0" ? selectedListing?.features.find(x => x.featureCode === "40")?.rangevalueL : "0"} </td>
                                        </tr>
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                          <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("SecurityDeposit")}</td>
                                          <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selected_curr} {parseFloat(Number(selectedListing?.securitydeposit) / parseFloat(conversion_rate)).toFixed(2)} </td>
                                        </tr>
                                        <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                          <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("ReturnDays")}</td>
                                          <td style={{textAlign : "center" , fontWeight : "lighter"}}> {selectedListing?.refunddays} </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>

                              </div>}
                              {selectedListing?.categoryCode === "2" && 
                              <>
                                  This is Machine
                              </>}
                              {selectedListing?.categoryCode === "3" && 
                      <>
                          <div style={{padding : "10px" , fontSize : "0.5rem"}} >
                          <table style={{   width : "100%"}}>
                            <tbody style={{}}>
                                 
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black" }} >
                                  <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span> {t("Name")}</td>
                                  <td style={{textAlign : "center", fontWeight : "lighter"}}>{selectedListing?.bname[selected_lang]}</td>
                                </tr>
                                
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px" }}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{t("Model Year")}</td>
                                  <td style={{textAlign : "center", fontWeight : "lighter"}}> {selectedListing?.modelyear?.label } </td>
                                </tr>
                                <tr style={{lineHeight : "20px", borderBottom : "1px solid black"}}>
                                  <td style={{paddingLeft : "10px"}}><span style={{color : "orange", paddingRight : "5px" }}>⚬</span>{selectedListing?.features.find(x => x.featureCode === "108")[selected_lang]}</td>
                                  <td style={{textAlign : "center" , fontWeight : "lighter"}}>  {selectedListing?.features.find(x => x.featureCode === "108")?.rangevalueL !== "0" ? selectedListing?.features.find(x => x.featureCode === "108")?.rangevalueL : "0"} </td>
                                </tr>
                            </tbody>
                        </table>

                      </div>
                      </>}    
                        </>
                        }
                       
                  </div>   
                        
                  </div>
                        

                
                <div className="irespbuttons z-50" >
                 
                   <div className="buttonsdetailnewp" style={{ height : "30px", maxHeight : "30px"}}>
                        <div style={{backgroundColor : "#dc2f28", color : "white", cursor : "pointer" , border :"1px solid white", fontSize : "0.8rem" }} >
                        <center>
                        <a href={`tel:${selectedListing?.tenant?.cellNumberc}`}  onClick={() => {_handleSaveCount(selectedListing, 2); setShowcall({id : selectedListing?.tluid, boolv : true})}}>
                                    <img
                                      src={Call}
                                      alt="abc"
                                      style={{width : "25px" , height : "25px", marginTop : "5px"}}
                                    
                                    />
                                  
                                    {selectedListing?.tenant?.cellNumber}
                                    </a>
                        </center>
                          
                              
                        </div>

                          <div style={{backgroundColor : "#57b95b", color : "white", cursor : "pointer", border :"1px solid white" }} onClick={() => {_handleWaCount(selectedListing, "1");setShowcall({id : selectedListing?.tluid, boolw : true})}}>
                                  <center>
                                  <img
                                    src={WTx}
                                    
                                    alt="abc"
                                    style={{width : "25px" , height : "25px", marginTop : "5px"}}
                                  />
                                  </center>
                          </div>
                          <div style={{backgroundColor : "#4e4e4e", color : "white", cursor : "pointer" , border :"1px solid orange"}} onClick={() => _handleSaveCount(selectedListing, 3)}>
                                            <center>
                                            <a  
                                              href={`/item/${selectedListing?.tluid}-${selectedListing?._id}`}
                                              className="cursor-pointer no-underline inline-flex items-center text-white"
                                            >
                                              
                                              <img
                                                src={Enq}
                                                alt="abc_enq"
                                                width = "25" height = "25"
                                                style={{width : "25px" , height : "25px", marginTop : "5px"}}
                                              />
                                              
                                            </a>
                                            </center>
                        </div>
                   </div>
                   
              </div>
              
        </div>
        
        </>
      )
  }
  

 return (
    <div className="mb-5">
       
      <Toaster />

      <Modal
        isOpen={requestIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setRequestIsOpen(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <section className="z-50" style={{width: 500}}>
          <div className="text-center">
          <p className="text-2xl mb-2 font-medium">Request a Call</p>
          </div>
          <div>
          <form class="max-w-sm mx-auto">
            <div class="mb-5">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="name@flowbite.com"
                required
              />
            </div>
            <div class="mb-5">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Email
              </label>
              <input
                type="email"
                id="password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                required
              />
            </div>
            <div class="mb-5">
              <label
                for="message"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Your message
              </label>
              <textarea
                id="message"
                rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                placeholder="..."
              ></textarea>
            </div>

            <button
              type="submit"
              class="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Send Now
            </button>
          </form>
          </div>
        </section>
      </Modal>


      <Modal
        isOpen={loginModel}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setLoginModel(false)}
        style={{ ...customStyles }}
        contentLabel="Login Modal"
      >
        <button style={{float : isTabletOrMobile ? "center" : "right", fontSize : "0.7rem", color : "gray", padding: "10px", marginTop : "-10px" }}
            onClick={g => setLoginModel(false)}>
            x
            </button>
        <form onSubmit={signinUser}>
          <div>
            <h1>{t("Sign In")}</h1>
            <p className="mt-4 mb-8">
              {t("If you don't have an account You can")}
              <br />
              <button
                onClick={() => {
                  setLoginModel(false);
                  setSignupModel(true);
                }}
                className="link"
              >
                {t("Register here")} !
              </button>
            </p>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Email")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="email"
                placeholder={t("Enter your Email")}
                id="email"
                ref={(e) => {
                  email = e;
                }}
                type="email"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Password")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="password"
                placeholder={t("Enter your Passowrd")}
                id="password"
                ref={(e) => {
                  password = e;
                }}
                type="password"
                required
              />
            </div>
          </div>
          <center>
            <button
              type="submit"
              className="primaryButton mt-4"
              style={{ fontSize: "0.6rem" }}
            >
              {loading ? `${t("Loading")}...` : t("Sign In")}
            </button>
            
            <p
              style={{
                marginTop: "25px",
                fontSize: "0.7rem",
                cursor: "pointer",
              }}
              onClick={(t) => {
                resetup(email.value);
                setLoginModel(false);
              }}
            >
              Forgot your password ?
            </p>
          </center>
        </form>

        <br />
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            
            oAuth("GOOGLE", credentialResponse);
            
          }}
          onError={() => {
            
          }}
        />
      </Modal>

      <Modal
        isOpen={signupModel}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setSignupModel(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
        <h1>{t("Sign Up")} 
            <button style={{float : isTabletOrMobile ? "center" : "right", fontSize : "0.7rem", color : "gray", padding: "10px", marginTop : "-10px" }}
            onClick={g => setSignupModel(false)}>
            x
            </button></h1>
            
         
        <form onSubmit={signUpUser}>
          <div>
            <p className="mt-4 mb-8">
              {t("If you already have an account registered You can")}
              <br />
              <button
                onClick={() => {
                  setSignupModel(false);
                  setLoginModel(true);
                }}
                className="link"
              >
                {t("Login here")} !
              </button>
            </p>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Full Name")}
            </label>
            <div className="relative">
              <input
                className="inputField mb-8 w-full"
                name="fullName"
                placeholder={t("Enter your full name")}
                id="fullName"
                onChange={() => setValidationError("")}
                ref={(e) => {
                  fullName = e;
                }}
                type="text"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Email")}
            </label>
            <div className="relative">
              <input
                className="inputField mb-8 w-full"
                name="email"
                placeholder={t("Enter your Email")}
                id="email"
                onChange={() => setValidationError("")}
                ref={(e) => {
                  email = e;
                }}
                type="email"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Phone")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="cell"
                placeholder={t("Enter your Phone Number")}
                id="cell"
                ref={(e) => {
                  cell = e;
                }}
                type="tel"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Password")}
            </label>
            <div className="relative">
              <input
                className="inputField mb-8 w-full"
                name="password"
                placeholder={t("Enter your Passowrd")}
                id="password"
                onChange={() => setValidationError("")}
                ref={(e) => {
                  password = e;
                }}
                type="password"
                required
              />
            </div>
          </div>
          {validationError && (
            <p className="text-left text-red-500">{validationError}</p>
          )}
          <button type="submit" className="button-55">
            {loading ? `${t("Loading")}...` : t("Sign Up")}
          </button>
        </form>
      </Modal>

      
      <Modal
        isOpen={loginModelEnq}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setLoginModelEnq(false)}
        style={{ ...customStyles }}
      >
        {!xitem?.tuid ? <div></div> 
        :
         <div style={{display : "flex"}}>
              
              {!isTabletOrMobile && 
                xitem && listingpanelenq(xitem, [])
                }
                <div>  
                {!user && 
                <>
                  <form onSubmit={p => signinUserenq(p, xitem, 3)} style={{fontSize : "0.6rem"}}>
                    <div>
                      <h1>{t("Sign In")}</h1>
                      <p className="mt-4 mb-4">
                        {t("If you don't have an account You can")}
                        <br />
                        <u
                          onClick={() => {
                            setLoginModelEnq(false);
                            setLoginModel(false);
                            setSignupModel(true);
                          }}
                          className="link"
                        >
                          {t("Register here")} !
                        </u>
                      </p>
                    </div>
                    <div>
                      <center>
                      <label className="block text-primary-grey text-[13px] font-medium pb-1">
                        {t("Sign In")}
                      </label>
                      </center>
                      <div className="relative">
                        <input
                          className="inputField w-full mb-2"
                          name="email"
                          placeholder={t("Enter your Email")}
                          id="email"
                          ref={(e) => {
                            email = e;
                          }}
                          type="email"
                          required
                        />
                      </div>
                    </div>
                    <div >
                      <div className="relative">
                        <input
                          className="inputField w-full mb-4"
                          name="password"
                          placeholder={t("Enter your Passowrd")}
                          id="password"
                          ref={(e) => {
                            password = e;
                          }}
                          type="password"
                          required
                        />
                      </div>
                    </div>
                    <center>
                      <button
                        type="submit"
                        className="primaryButton mt-4 rounded"
                        style={{ fontSize: "0.6rem" }}
                      >
                        {loading ? `${t("Loading")}...` : t("Sign In")}
                      </button> &nbsp;
                      <button
                        type="reset"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        style={{ fontSize: "0.6rem" }}
                        onClick={x => setLoginModelEnq(false)}
                      >
                        Close
                      </button>
                      <br></br>
                      <p
                        style={{
                          marginTop: "25px",
                          fontSize: "0.7rem",
                          cursor: "pointer",
                        }}
                        onClick={(t) => {
                          resetup(email.value);
                          setLoginModel(false);
                        }}
                      >
                        Forgot your password ?
                      </p>
                    </center>
                  </form>

                  <br />
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      oAuth("GOOGLE", credentialResponse);
                    }}
                    onError={() => {
                      
                    }}
                  />
                  <br />
                  </>  
                }
                </div>
          </div>
        }
      </Modal>    

      {!isTabletOrMobile ? 
           <div style={{backgroundColor : "#f1f1f1"}}>
                 <div className={selected_lang === "en" ? "vttop_bar marginenn" : "vttop_bar marginarr" }> 
                    <a
                        target="_blank"
                        href={process.env.REACT_APP_FACEBOOK_URL}
                        rel="noreferrer"
                      >
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/facebook_.webp`} width="25" height ="25" alt="facebook_icon" />
                    </a>
                    <a
                    target="_blank"
                    href={process.env.REACT_APP_INSTAGRAM_URL}
                    rel="noreferrer"
                  >
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/instagram_.webp`} width="25" height ="25" alt="instagram_icon" />
                    </a>
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_TIKTOK_URL}
                      rel="noreferrer"
                    >
                        <img width="25" height ="25" style={{width : "1.75vw", height :"3.5vh", marginTop : "2px"}} src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/tiktok.webp`} alt="tiktok_icon"  />
                    </a>
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_SNAPCHAT_URL}
                      rel="noreferrer"
                    >
                    <img  src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/snapchat_.webp`} width="25" height ="25" alt="snapchat_icon" />
                    </a>
                </div>
            
                <div className={selected_lang === "en" ? "vttop_bar_r marginen" : "vttop_bar_r marginar" } style={{float : selected_lang === "en" ? "right" : "left"}}> 
                    <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/globe.png`} alt="Globe"  /><span className={selected_lang === "en" ? "marginen25" : "margin25"}>{t("topbar_uae")}</span>
                    </div>
                    <div onClick={() => _handleChangeCurrency()}><img  width="25" height ="25" style={{width : "25px", height:  "25px", marginTop : "2px" }} src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/Currency.png`} alt="Currency icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25" }>
                    {selected_curr === "AED" ? 
                    t("AED")
                   : 
                   t("USD")
                  }
                      </span></div>
                    <div ><img width="25" height ="25" style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span></div>
                    <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img width="25" height ="25" style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/App.png`} alt="App Icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>DOWNLOAD OUR MOBILE APP</span>
                    
                    </div>
                    
                </div>
            </div>
            :
            <div>
             
              <div className="vttop_bar_r_m" style={{width : "100%", minWidth : "100%", backgroundColor : "#f1f1f1", borderBottom : "3px solid orange"}}> 
              <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/globe.png`} alt="Globe"  /><span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "margin25"}>
                    {t("topbar_uae")}
                    
                </span>
              </div>
              <div onClick={() => _handleChangeCurrency()} >
                <img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Currency.png`} alt="Currency icon" />
                <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>
              {selected_curr === "AED" ? 
                      t("AED")
                    : 
                    t("USD")
                    }
                </span></div>
              <div >
                <img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span>
              </div>

              <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img style={{width : "25px", height:  "25px"}} width="25" height ="25" src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/App.png`} alt="App Icon" />
              <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>MOBILE APP</span></div>
              </div>
            </div>  
          }

       {!isTabletOrMobile ?   
       <Menu 
          user={user} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} isTabletOrMobile={isTabletOrMobile} search={search}
          _handleSearch={() => {}} setLoginModel={setLoginModel} brandsr={brandsr} aboutusr={aboutusr} navlink1={navlink1} navlink2={navlink2} navlink3={navlink3} t={t}
          setCommingSoon={() => {}} showsearch={"1"} changetype={changetype} selected_lang={selected_lang}
        />
        :
        <div style={{display : "flex", width : "100%"}}>
        <div style={{width  :"50%", minWidth : "50%"}} >
        <a href={`${process.env.REACT_APP_PUBLIC_URL}`} >  <img
                  className="ml-2 mt-1"
                    width = "130"
                    height = "80"
                  style={{ float :"left", padding : "0.5vw" }}
                  src={
                      process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.webp"
                  }
                  alt="logo"
                  /></a>
        </div>          
{/** Mobile Menu */}            
        <div style={{display : "flex" , justifyContent : "right", width : "50%", minWidth :"50%"}} >
              <div>
              <a href={`${process.env.REACT_APP_PUBLIC_URL}`} ><RxHamburgerMenu  className="text-2xl mr-1 mt-4"  /> </a>      
              </div>
              <div style={{marginTop :  "15px"}} className="mr-2" >
              {!user ? (
                  <button
                      type="button "
                      onClick={() => {
                      setLoginModel(true);
                      setIsMenuOpen(false);
                      }}
                      style={{  fontSize : "0.7rem" , height : "4vh", width : "20vw" , backgroundColor : "orange" , fontWeight : "bold", borderRadius : "10px" }}
                  >
                      <center >
                          {t("LOGIN")}{" "}
                      </center>
                  </button>
                  ) : (
                  <>
                      <button
                      type="button"
                      onClick={() => {
                          
                          if(window.location.href.includes("profile")){
                          dispatch(logoutUser());
                          }else{
                          navigate("/profile");
                          setIsMenuOpen(false);
                          }
                          
                      }}
                      style={{ marginTop: -5 , fontSize : "0.7rem" , height: "40px", borderRadius : "25px"}}
                      className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10"
                      >
                      { window.location.href.includes("profile") ? "Logout" :user?.fn}
                      </button>
                  </>
                )}
              </div>             
        
        </div>
      </div>
       }


      <header>
        <div
          className="w-screen bg-cover bg-center relative md:px-24"
          style={{
            height: isTabletOrMobile ? "36rem" : "45rem",  // this was 45
            backgroundImage: `url(
              "${process.env.REACT_APP_PUBLIC_URL}/images/bag.webp"
            )`,
          }}
        >
        
        


        {/** Menu Component ......  Start */}          
        

{/** Menu Component ......  End */}          


          <div className="container mx-auto" >
            {
              isTabletOrMobile ? 
              <div className="banner grid grid-cols-1 gap-0" style={{ paddingLeft : "9px"}}>
                     <h4>{selectedListing?.tenant?.tname[selected_lang]} </h4>
            </div> :
            <div className="banner grid grid-cols-1 gap-0 justify-center" style={{ textAlign : "center"}}>
                      <h4>{selectedListing?.tenant?.tname[selected_lang]} </h4>
            </div>
            }
            {isTabletOrMobile ? (
                tabview(selectedListing)
            ) : (
                nontab(selectedListing)
            )}
           
          </div>
          {/** adding details */}
           
  
        </div>
      </header>

      {!isTabletOrMobile && 
      <>
      <section className = "container center" style={{width : "80%"}} >
         {catcodechange()}


      </section> 
     
          <div  ref={appRef} >
                  <Userapp t={t}  iswhitea = {"Applestore_b.webp"} iswhiteg = {"googleplay_b.webp"} />       
            </div>  
      <Footer t = {t}/>
      </>
      }
             
      {isTabletOrMobile &&
        <>  
       <div style={{marginTop  :"100px"}}> 
        {(showcall.boolw || showcall.boolv) && catcodechange()} 
        {(!showcall.boolw && !showcall.boolv) && catcodechange()} 
        </div>
       <section
          className="py-15 bg-blend-overlay mt-0"
          style={{
            marginTop : "50px",
            background: !isTabletOrMobile
              ? `rgb(61 58 58) url("${process.env.REACT_APP_PUBLIC_URL}images/city-building.webp")`
              : "rgb(61 58 58)",
            backgroundRepeat: "no-repeat",
          }}
        >
             <div  ref={appRef} style={{height : "16vh" , paddingTop : "2vh"}} >
                  <Userappm t={t}  iswhitea = {"Applestore.webp"} iswhiteg = {"googleplay.webp"} />       
            </div>  
        </section>
        
        <Footerm t = {t}/>
        
        
        </>
      }
      
      <div className={isTabletOrMobile ? "totopm" : "totop"}
                onClick={() =>  window.scrollTo({ top: 0, behavior: "smooth" })}>
                  &#8673;
      </div>
      
    </div>
  );
};
export { Item };
