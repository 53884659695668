import React, {useState} from 'react';

import { useTranslation } from "react-i18next";
import { FaChevronRight , FaChevronLeft} from "react-icons/fa";
import {
    Link
  } from "react-router-dom";
import Modal from "react-modal";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useMediaQuery } from "react-responsive";

function Footerm(props) {
    const { t } = useTranslation();
    const [faqIsOpen, setFaqIsOpen] = useState(false);   
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });


  const footerSection2 = [
    // { title: t("FOOTERSSECTION2LINE1"), action: () => {} },
    // { title: t("FOOTERSSECTION2LINE2"), action: () => {} },
    {
      title: t("FOOTERSSECTION2LINE1"),
      action: "/privacy_policy",
      isLink: true,
    },
    {
      title: t("FOOTERSSECTION2LINE3"),
      action: "/terms_of_usage",
      isLink: true,
    },
    {
      title: t("FOOTERSSECTION2LINE4"),
      action: () => setFaqIsOpen(true),
      isLink: false,
    },
    {
      title: t("FOOTERSSECTION2LINE5"),
      action: "/blogs",
      isLink: true,
    },
  ];

  
  const faqs = [
    {
      title: t("FAQ1"),
      body: t("FAA1"),
    },
    {
      title: t("FAQ2"),
      body: t("FAA2"),
    },
    {
      title: t("FAQ3"),
      body: t("FAA3"),
    },
    {
      title: t("FAQ4"),
      body: t("FAA4"),
    },
    {
      title: t("FAQ5"),
      body: t("FAA5"),
    },
    {
      title: t("FAQ6"),
      body: t("FAA6"),
    },
    {
      title: t("FAQ7"),
      body: t("FAA7"),
    },
    {
      title: t("FAQ8"),
      body: t("FAA8"),
    },
    {
      title: t("FAQ9"),
      body: t("FAA9"),
    },
    {
      title: t("FAQ10"),
      body: t("FAA10"),
    },
    {
      title: t("FAQ11"),
      body: t("FAA11"),
    },
    {
      title: t("FAQ12"),
      body: t("FAA12"),
    },

    {
      title: t("FAQ13"),
      body: t("FAA13"),
    },
    {
      title: t("FAQ14"),
      body: t("FAA14"),
    },
    {
      title: t("FAQ15"),
      body: t("FAA15"),
    },
    {
      title: t("FAQ16"),
      body: t("FAA16"),
    },
    {
      title: t("FAQ17"),
      body: t("FAA17"),
    },
    {
      title: t("FAQ18"),
      body: t("FAA18"),
    },
    {
      title: t("FAQ19"),
      body: t("FAA19"),
    },
  ];

  function afterOpenModal() {}


  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      zIndex: 999999,
    },
    overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
  };


  const customStylesm = {
    content: {
        top: "50%",
        left : "auto",
        width : "90%",
        bottom: "auto",
        marginRight: "-50%",
        
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      zIndex: 999999,
    },
    overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
  };

    return (


        <>
        
        <Modal
            isOpen={faqIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={() => {
            setFaqIsOpen(false);
            }}
           style={isTabletOrMobile ? customStylesm : customStyles}
           contentLabel="Example Modal"
       >
        <section className="z-50" style={{fontSize : isTabletOrMobile ?  "0.5rem" : "0.7rem" , width : "50%"}}>
          <div className="text-center">
            <p className="text-xl font-bold mb-5 border-bottom">
            <span onClick={x => setFaqIsOpen(false)} style={{float : "left", cursor : "pointer"}}>X</span>
                {t("FAQS")}
            </p>
            
          </div>

          <div className="flex flex-col justify-center">
            <PerfectScrollbar style={{ height: 500, width: 300 }}>
              {faqs.map((faq, index) => (
                <div className="border-b-2 py-2 mx-1" key={index} >
                  <p className="text-base font-medium text-sm">{faq.title}</p>
                  <p className="text-sm">{faq.body}</p>
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        </section>
        </Modal>
        
        <div className='flex mtext_fb'>       
              <div className='divx' >
                    <h2 onClick={p => props.navigate('/about-us')}>{t("ABOUTUS")}</h2>
                    <hr />
                    <p >
                    {t("FOOTERSECTION1LINE1")}</p>
                    <p >
                     {t("FOOTERSECTION1LINE2")}
                    </p>

              </div>
              <div className='divx' >
                        <div className="flex flex-col mt-8" >
                        {footerSection2.map((section2, index) => {
                            return section2.isLink ? (
                            <u>
                            <Link key={index}
                                to={section2.action}
                                className="text-white no-underline px-5 text-center inline-flex items-center md:my-2 cursor-pointer"
                            >
                                {props.selected_lang === "en" ? <FaChevronRight className="text-primary text-sm" /> : <FaChevronLeft className="text-primary text-sm" /> }
                                <p className="montserrat-400" style={{fontSize : "0.7rem"}}>{section2?.title}</p>
                            </Link>
                            </u>
                            ) : (
                            <u
                                key={section2?.title}
                                onClick={section2.action}
                                className="text-white no-underline px-5 text-center inline-flex items-center md:my-2 cursor-pointer"
                            >
                                {props.selected_lang === "en" ? <FaChevronRight className="text-primary text-sm" /> : <FaChevronLeft className="text-primary text-sm" /> }
                                <p className="montserrat-400" style={{fontSize : "0.7rem"}}>{section2?.title}</p>
                            </u>
                            );
                        })}
                        </div>
              </div>
        </div>
        <div style={{backgroundColor : "#16254e", color : "white" }}>
             <center>
                  <p style={{direction : "ltr"}}>
                        <span>{t("FOOTERSSECTION4LINE1")}</span>
                 </p>      
                        <span>{t("FOOTERSSECTION4LINE2")}</span>
                 
                 <p>       <span>{t("FOOTERSSECTION4LINE3")}</span>
                 </p>
                        
                  
             </center>           
        </div>                
      </>
    );
}

export default Footerm;