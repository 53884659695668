
import { useTranslation } from "react-i18next";
import {
  Link,
} from "react-router-dom";

function Privacypolicy(props) {
    const { t } = useTranslation();

    return (
        <div>
            <header className="sm:px-8 px-4 py-2 w-full">
                <nav className="flex md:justify-between items-center ">
                
                <Link to="/car" className="text-3xl font-bold">
                <img
                  style={{ width: 200 }}
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.png"
                  }
                  alt="logo"
                />
              </Link>
                
                </nav>
           </header>
         
          <div>
           <center> <h3>  {t("FOOTERSSECTION2LINE1")} </h3></center>
        </div>
        <div className='vnmgrid-container'>
            <div >
                  <h5>Preamble</h5>
                  <br></br>
                  <p>This privacy policy governs the way we collect and use information on our website and mobile Application (herein collectively referred to as the “Platform”), and by using the services on our Platform (“Services”) you agree to be bound by this policy. All references within this policy to 'we/us/our' refer to VnM rental. This Privacy Policy is in addition to any other terms and conditions applicable to the Platform. Specific privacy terms contained in any documents made available by us should be read together with this Privacy Policy.</p>
                  <br></br>
                  
                  <h5>1. Information We Collect</h5>

                  <h5>1.1 Personal Information</h5>
                  <br></br>
                    <p>When you register through our Platform, we may collect personal information such as your name, email address, phone number and other contact details, payment information (“Personal Information”). This information is crucial for creating and managing your account, processing transactions, and ensuring a seamless experience.</p>
                    <p>We allow users to access Facebook Connect to interact with friends and to share on Facebook through Wall and friends' News Feeds. If you are logged into your VnM rental account and Facebook, when you click on "Facebook Login" your profiles will merge if the email addresses match. If you are not currently registered as a VnM rental user and you use the Platform’s social network connection functions, you will first be asked to enter your social network credentials and then be given the option to register and join VnM rental. By using the Platform’s social network connection function, you will grant us permission to access all of the elements of your social network profile information that you have made available to be shared (as per the settings chosen by you in your social network profile) and to use it in accordance with the social network’s terms of use and this Privacy Policy. If we do use your personal information in these ways, we will always give you the opportunity to request that your information to not be used for further direct marketing in the future.</p>
                    
                 <br></br>    
                 
                 <h5>1.2 Usage Information</h5>
                  <br></br>
                    <p>We collect information about how you use the Platform, including the goods you search for, view, and book, as well as your interactions with the service providers. This data helps us understand user preferences, enhance our recommendation algorithms, and tailor the Platform's content to your interests.</p>
                 <br></br>

                 <h5>1.3 Location Information</h5>
                  <br></br>
                    <p>With your consent, we may collect precise location data from your device to provide location-based services, such as finding nearby rental options. This enhances your user experience by presenting relevant choices based on your current location.</p>
                 <br></br>

                 <h5>2 How We Use Your Information</h5>
                  <br></br>
                  <h5>2.1 Providing Services</h5>
                  <br></br>
                    <p>Your information is used to provide and personalize the Services offered by the Platform, including facilitating rental services between customers and Merchants. This involves processing bookings, managing reservations, and ensuring a smooth communication channel between users and service providers.</p>
                 <br></br>

                 <h5>2.2 Communication</h5>
                  <br></br>
                    <p>We may use your contact information to send you important updates, notifications, and promotional offers related to the Platform. This communication helps keep you informed about your bookings, new features, and exclusive deals that enhance your overall experience.</p>
                 <br></br>

                 <h5>2.3 Improving User Experience</h5>
                  <br></br>
                    <p>We analyze user behavior and feedback to improve the functionality, usability, and overall user experience of the Platform. This data-driven approach allows us to identify areas for enhancement, fix issues promptly, and continuously optimize the Platform to meet user expectations.</p>
                 <br></br>

                 <h5>2 How We Use Your Information</h5>
                  <br></br>
                  <h5>2.1 Providing Services</h5>
                  <br></br>
                    <p>Your information is used to provide and personalize the Services offered by the Platform, including facilitating rental services between customers and Merchants. This involves processing bookings, managing reservations, and ensuring a smooth communication channel between users and service providers.</p>
                 <br></br>

                 <h5>2.2 Communication</h5>
                  <br></br>
                    <p>We may use your contact information to send you important updates, notifications, and promotional offers related to the Platform. This communication helps keep you informed about your bookings, new features, and exclusive deals that enhance your overall experience.</p>
                 <br></br>

                 <h5>3. Information Sharing</h5>
                  <br></br>
                  <h5>3.1 With Merchants</h5>
                  <br></br>
                    <p>We share the necessary information with Merchants to facilitate the booking process and provide you with the requested services. This includes sharing details like your name, contact information, and booking preferences to ensure a seamless and efficient rental experience.</p>
                 <br></br>

                 <h5>3.2 Third Parties</h5>
                  <br></br>
                    <p>We may share your information with third parties service providers who assist us in providing and improving the Platform (e.g., hosting services, analytics). These partners adhere to strict data security standards and are essential for maintaining the Platform's functionality.</p>
                    <p>We may disclose your personal information to any of our group of companies. We may also disclose your personal information to third parties in the event that the Platform sells or buys any business or assets; if the Platform or substantially all of its assets are acquired by a third party, in which case personal data which we hold about our customers may be one of the transferred assets; or if we are under a duty to disclose or share your personal data in order to comply with any legal obligation.</p>

                 <br></br>

                 <h5>3.3 Third Parties</h5>
                  <br></br>
                    <p>We may disclose your information if required to do so by law or in response to a valid legal request. This includes cooperating with law enforcement agencies to ensure the security and integrity of the Platform and exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p>
                 <br></br>

                 <h5>3.4 Protection of your Personal Information </h5>
                  <br></br>
                    <p>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent, or otherwise share for marketing purposes the Personal Information that we collect with third parties, unless you ask or authorize us to do so.</p>
                    <p>All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.</p>
                    <p>We will not pass any debit/credit card details to third parties.</p>
                    <p>We intend to take appropriate steps to ensure data privacy and security including through various hardware and software methodologies. However, we cannot guarantee the security of any information that is disclosed online.</p>
                    <p>If you contact us, we may keep a record of that correspondence.</p>
                    <p>We may also ask you to complete surveys that we use for research purposes, although you are not obligated to respond to them.</p>
                    <p>Please note that we may use your Internet Protocol (or IP) address (which is a unique number assigned to your computer server or your Internet service provider (or ISP). We use your Internet Protocol address to help diagnose problems with our computer server, and to administer our web site(s). Your IP address is used to help identify you but contains no personal information about you.</p>

                 <br></br>

                 <h5>4 Retention of Personal Data </h5>
                  <br></br>
                    <p>We will retain your personal data for as long as your account is active or as needed to provide you services and to maintain a record of your transactions for financial reporting purposes. We will also retain your personal data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                 <br></br>

                 <h5>5. Reviewing, changing or deleting information</h5>
                  <br></br>
                    <p>You can update your personal information at any time by logging-in to your membership account and accessing "My Account", where you can update your personal information mentioned in "Account Info" and "Saved Address".</p>
                    <p>If you delete your information from our Platform, copies may remain viewable in cached and archived pages, or might have been copied or stored by other users of our Platform. Proper access and use of information provided on our Platforms is governed by our Terms of Use.</p>
                 <br></br>

                 <h5>6. Third Parties</h5>
                  <br></br>
                    <p>This Privacy Policy does not address, and we are not responsible for, the privacy, information or other practices of any third parties, including without limitation any of our affiliates and any third party operating any site to which this Platform contains a link. The inclusion of a link on the Platform does not imply endorsement of the linked site by us or by our affiliates.</p>
                    
                 <br></br>

                 <h5>7. Security</h5>
                  <br></br>
                    <p>Keeping your Personal Information secure and preventing unauthorized access is of utmost priority to us, and we take all steps reasonably necessary to protect your Personal Information against any unauthorized access, use, alteration, disclosure or destruction. We use a variety of industry-standard security technologies and procedures to help protect your Personal Information from unauthorized access, use, or disclosure. Whilst we have put in place physical, electronic and managerial procedures to secure and safeguard your Personal Information, we will not be held responsible for any unauthorized access by third parties and we cannot guarantee that the Personal Information provided by you or that is transmitted via this Platform or by e-mail is totally secure and safe. You shall not hold us responsible or liable in case of data breaches caused by action/omissions beyond our reasonable control. We may use personal information provided by you in order to conduct appropriate anti-fraud checks. Personal Information that you provide may be disclosed to a credit reference or fraud prevention agency, which may keep a record of that information. This is done only to confirm your identity.</p>
                    
                 <br></br>


                 <h5>8. Amendment </h5>
                  <br></br>
                   <p>We reserve the right, at our discretion, to modify or remove portions of this Privacy Policy at any time. You should review this Privacy Policy periodically so that you are updated on any changes.</p>
                    
                 <br></br>

                 <h5>9. Cookies</h5>
                  <br></br>
                   <p>When you visit our site, our server sends your computer a "cookie." This cookie is a small packet of information that identifies you as a user of our system. Cookies save you time by retaining your contact information. Cookies do not deliver viruses and can only be read by the server that sent you the cookie. You have the ability to accept or decline cookies by modifying the settings in your browser. Please bear in mind however, that you may not be able to use all the features of our site if cookies are disabled.</p>
                    
                 <br></br>

                 <h5>10. Applicable Law and Jurisdiction</h5>
                  <br></br>
                   <p>This Policy, as well as your access to the Platform, is subject to and governed by the laws of the United Arab Emirates as applied in the Emirate of Abu Dhabi without giving effect to conflicts of law principles thereof. Any dispute regarding this Policy or our handling of your Personal Information and General Information shall be subject to the exclusive jurisdiction of the Courts in Abu Dhabi.</p>
                    
                 <br></br>


                  <h5>11. Contact Us</h5>
                  <br></br>
                   <p>If you have any queries, complaints or recommendations about this Policy, or if you believe that any Personal Information that we may hold about you is incorrect or incomplete, please contact us at the following address and we will endeavor to respond to you promptly: support@vnmrental.com.</p>
                    
                 <br></br>
   
            </div>
        </div>
        <div className="totop" 
                onClick={() =>  window.scrollTo({ top: 0, behavior: "smooth" })}>
                  &#8673;
              </div>
        </div>
    );
}

export default Privacypolicy;