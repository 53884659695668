import { createSlice } from "@reduxjs/toolkit";
import { handleValCurrencyChange } from "./currencyActions";

const initialState = {
  selected_curr: "AED",
  currencies: ["AED", "USD"],
  conversion_rate: 1,
  conversion_rates:{ USD : 3.67, AED: 1} 
};

const currSlice = createSlice({
  name: "curr",
  initialState,
  reducers: {},
  extraReducers: {
    [handleValCurrencyChange.pending]: (state) => {},
    [handleValCurrencyChange.fulfilled]: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    [handleValCurrencyChange.rejected]: (state, action) => {},
  },
});

export default currSlice.reducer;
