import React, { useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeFilterValue } from "../../store/listings/listingActions";
import { useTranslation } from "react-i18next";

export default function PriceRange() {
  const { selected_curr } = useSelector((state) => state.curr);

  const [minValue, setMinValue] = useState(10);
  const [maxValue, setMaxValue] = useState(15000);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleInput = (e) => {
    dispatch(
      handleChangeFilterValue({
        key: "minp",
        value: e.minValue
      })
    );
    dispatch(
      handleChangeFilterValue({
        key: "maxp",
        value: e.maxValue
      })
    );
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  return (
    <div className="App">
      <MultiRangeSlider
        min={10}
        max={15000}
        style={{ border: "none", boxShadow: "none" }}
        step={50}
        barInnerColor="#F39C12"
        ruler={false}
        label={t("PRICE")}
        stepOnly
        minValue={minValue}
        maxValue={maxValue}
        onChange={(e)=>{
          
          handleInput(e);        
        }}
      />
      <div className="flex flex-row justify-between" style={{cursor : "none"}}>
        <p>{t(`${selected_curr}`)} {minValue}</p> <p>{t(`${selected_curr}`)}{maxValue}+</p>
      </div>
    </div>
  );
}
