import React, {useState} from 'react'

import {
  Link,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";
import Modal from "react-modal";
import PerfectScrollbar from "react-perfect-scrollbar";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999999,
  },
  overlay: { zIndex: 1000 },
};


export default function Expire() {
  const {id} = useParams()
  const { t } = useTranslation();
  
  const [faqIsOpen, setFaqIsOpen] = useState(false);
  const [requestIsOpen, setRequestIsOpen] = useState(false);
  
  const footerSection4 = [
    t("FOOTERSSECTION4LINE1"),
    t("FOOTERSSECTION4LINE2"),
    t("FOOTERSSECTION4LINE3"),
  ];

  const footerSection3 = [t("FOOTERSSECTION3LINE1"), t("FOOTERSSECTION3LINE2")];
  const footerSection2 = [
    // { title: t("FOOTERSSECTION2LINE1"), action: () => {} },
    // { title: t("FOOTERSSECTION2LINE2"), action: () => {} },
    {
      title: t("FOOTERSSECTION2LINE1"),
      action: "/privacy_policy",
      isLink: true,
    },
    {
      title: t("FOOTERSSECTION2LINE3"),
      action: "/terms_of_usage",
      isLink: true,
    },
    {
      title: t("FOOTERSSECTION2LINE4"),
      action: () => setFaqIsOpen(true),
      isLink: false,
    },
  ];

  const faqs = [
    {
      title: t("FAQ1"),
      body: t("FAA1"),
    },
    {
      title: t("FAQ2"),
      body: t("FAA2"),
    },
    {
      title: t("FAQ3"),
      body: t("FAA3"),
    },
    {
      title: t("FAQ4"),
      body: t("FAA4"),
    },
    {
      title: t("FAQ5"),
      body: t("FAA5"),
    },
    {
      title: t("FAQ6"),
      body: t("FAA6"),
    },
    {
      title: t("FAQ7"),
      body: t("FAA7"),
    },
    {
      title: t("FAQ8"),
      body: t("FAA8"),
    },
    {
      title: t("FAQ9"),
      body: t("FAA9"),
    },
    {
      title: t("FAQ10"),
      body: t("FAA10"),
    },
    {
      title: t("FAQ11"),
      body: t("FAA11"),
    },
    {
      title: t("FAQ12"),
      body: t("FAA12"),
    },
    
    {
      title: t("FAQ13"),
      body: t("FAA13"),
    },
    {
      title: t("FAQ14"),
      body: t("FAA14"),
    },
    {
      title: t("FAQ15"),
      body: t("FAA15"),
    },
    {
      title: t("FAQ16"),
      body: t("FAA16"),
    },
    {
      title: t("FAQ17"),
      body: t("FAA17"),
    },
    {
      title: t("FAQ18"),
      body: t("FAA18"),
    },
    {
      title: t("FAQ19"),
      body: t("FAA19"),
    },
  ];

  function afterOpenModal() {}


  const configDel = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/acdelink`, {id : id }, {headers : {"userAccessToken" : id}}).catch(e => {
    })
  }

  return (
    <div >
      
      <Modal
        isOpen={requestIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setRequestIsOpen(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <section className="z-50" style={{ width: 500 }}>
          <div className="text-center">
            <p className="text-2xl mb-2 font-medium">Request a Call</p>
          </div>
          <div>
            <form class="max-w-sm mx-auto">
              <div class="mb-5">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="name@flowbite.com"
                  required
                />
              </div>
              <div class="mb-5">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                </label>
                <input
                  type="email"
                  id="password"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  required
                />
              </div>
              <div class="mb-5">
                <label
                  for="message"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                  placeholder="..."
                ></textarea>
              </div>

              <button
                type="submit"
                class="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                Send Now
              </button>
            </form>
          </div>
        </section>
      </Modal>
      <Modal
        isOpen={faqIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setFaqIsOpen(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <section className="z-50">
          <div className="text-center">
            <p className="text-xl font-bold mb-5 border-bottom">{t("FAQS")}</p>
          </div>

          <div className="flex flex-col justify-center">
            <PerfectScrollbar style={{ height: 500, width: 500 }}>
              {faqs.map((faq, index) => (
                <div className="border-b-2 py-2 mx-3">
                  <p className="text-base font-medium">{faq.title}</p>
                  <p className="text-sm">{faq.body}</p>
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        </section>
      </Modal>

      <div className='centerActivation' style={{height : "200%", fontWeight : "bolder"}}>
      <h1>Sorry to watch you leave for now.</h1>
      <br />
      <h2>Hello! {jwtDecode(id).dn}</h2>
      <h2>{jwtDecode(id).Email}</h2>
      <h2>are you sure you want to delete user?</h2>
      <p>We will save your preferences for a while in case you change your mind.</p>
      <br />
      <center>
      <button
                className={`z-10 flex items-center justify-center px-4 h-10 leading-tight  border border-primary-300  hover:bg-primary hover:text-white  cursor-pointer`}
                onClick={(e) => {
                 
                  configDel()
                }}
              >
              Proceed to delete
              </button>
      
      </center>
      </div>          
      
      <section className="bg-primary-grey p-10 text-left">
        <div>
          <div>
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "images/logo.png"}
              width="300px"
              alt="logo"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 text-white">
          <div className="mt-5">
            <p className="w-[295px]  [font-family:'Montserrat-Medium',Helvetica] font-light text-white text-[18px] tracking-[0] leading-[normal] text-justify">
              {t("FOOTERSECTION1LINE1")}
            </p>
            <p className="mt-5 w-[295px]  [font-family:'Montserrat-Medium',Helvetica] font-light text-white text-[18px] tracking-[0] leading-[normal] text-justify">
              {t("FOOTERSECTION1LINE2")}
            </p>
          </div>
          <div className=" text-left md:text-left ">
            <h5 className="text-[26px] font-bold">
              {t("FOOTERSECTION2TITLE")}
            </h5>
            <div className="flex flex-col mt-5 ">
              {footerSection2.map((section2, index) => {
                return section2.isLink ? (
                  <Link
                    to={section2.action}
                    class="text-white font-light  no-underline text-sm px-5 py-2.5 text-center inline-flex items-center md:my-2 cursor-pointer"
                  >
                    <FaChevronRight className="text-primary text-xl" />
                    <p className="text-[18px] font-medium">{section2?.title}</p>
                  </Link>
                ) : (
                  <a
                    href="/#"
                    key={section2?.title}
                    onClick={section2.action}
                    class="text-white font-light  no-underline text-sm px-5 py-2.5 text-center inline-flex items-center md:my-2 cursor-pointer"
                  >
                    <FaChevronRight className="text-primary text-xl" />
                    <p className="text-[18px] font-medium">{section2?.title}</p>
                  </a>
                );
              })}
            </div>
          </div>
          <div>
            <h5 className="text-[26px] font-bold">
              {t("FOOTERSECTION3TITLE")}
            </h5>
            {footerSection3.map((section3, index) => {
              return (
                <div key={section3} class="flex flex-row mt-5 items-center">
                  <div class="basis-1/4 border mr-5 ">
                    <img
                      class="rounded-t-lg"
                      src={process.env.REACT_APP_PUBLIC_URL + "images/car.png"}
                      alt=""
                    />
                  </div>
                  <div class="3/4">
                    <p className=" [font-family:'Montserrat-Medium',Helvetica] font-medium text-white text-[18px] tracking-[0] leading-[normal]">
                      {section3}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <h5 className="text-[26px] font-bold">
              {t("FOOTERSECTION4TITLE")}
            </h5>
            <div className="flex flex-col  mt-5">
              {footerSection4.map((section4, index) => {
                return (
                  <a
                    href="/#"
                    key={section4}
                    class="text-white font-light  text-sm py-2.5 text-center inline-flex items-center  md:my-2 no-underline"
                  >
                    <FaChevronRight className="text-primary text-xl" />
                    <p className="text-[18px] ">{section4}</p>
                  </a>
                );
              })}
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                id="first_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-100 p-2.5"
                placeholder={t("SUBCRIBE_PLACEHOLDER")}
                required
              />
              <button
                type="button"
                className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center mt-2 md:w-1/2"
              >
                {t("SUBSCRIBE")}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
