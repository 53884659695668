import React from 'react'



function Userapp(props) {


  return (
    <div className='fazeel_uapp'>
      <div className='fazeel_app_detail'> 
        <div className='fazeel_app_detail_image'>
            <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/phone.webp`} width = "60" height="60" alt="phone" />
        </div>
        <div className='fazeel_app_detail_heading'>
            <h2>{props.t("VNMRENTALMARKETPLACE1")} <span style={{color : window.location.href.includes("about-us") ? "black" : window.location.href.includes("listing")  ? "black" : "white"}}>{props.t("userfriendlyappavailable")}</span> </h2>
        </div>
      </div>
      <div className='fazeel_app_code'>
        <div className='fazeel_app_code_images'>
          
          <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/${!window.location.href.includes("about-us") ? props?.iswhiteg : "googleplay_b.webp"}`} width = "45" height="45" alt="phone android" /> 
          
          
          
          <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/android qr.png`} width = "55" height="55" alt="phone android" />
          
        <div className='fazeel_line'>

        </div>
          <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/apple qr.png`} width = "55v" height="55" alt="phone apple" />
          <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/${!window.location.href.includes("about-us") ? props?.iswhitea : "Applestore_b.webp" }`} width = "45" height="45" alt="phone android" /> 
          
          
          
       </div>

        <div className='fazeel_app_code_text' style={{color : window.location.href.includes("about-us") ? "black" : window.location.href.includes("listing")  ? "black" : "white"}}>
        <p>Get it on <span>Google</span> or <span>Apple Play Store</span></p> 
        </div>
        
      </div>
    </div>
  )
}

export default Userapp
