import React from "react";

function Vnmervices(props) {
  return (
    <div className="fazeel_serv">
      <div className="fazeel_serv_heading" style={{marginTop : "20px", marginBottom : "20px" ,paddingLeft : "100px",paddingRight : "100px" }}>
        <h3 className="montserrat-600" style={{ color : "#16254e"}}>{props.t("OurServices")}</h3>
        <p className='montserrat-200' style={{color : "black", fontSize :  "0.8rem"}}>{props.t("WHYCHOOSETEXTL2")} </p>
      </div>

      <div className="fazeel_serv_detail">
        <div className="fazeel_serv_b1">
          <div className="fazeel_b1_text" >
            
                <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/icons/key.webp`} width = "60" height="60" style={{marginLeft : props.selected_lang === "en" ? "80%" : "0px" , marginRight : props.selected_lang === "ar" ? "80%" : "0px" }}  alt="car rental 1" />
            
          <h1>{props.t("pickupanddelivery")}</h1>
          <p>
          {props.t("FAA3_pickupanddelivery_service_1_p")}
          </p>
          </div>
          <div className="fazeel_b1_text">
          <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/icons/buddy.webp`} width = "35" height="35" style={{marginLeft : props.selected_lang === "en" ? "80%" : "0px", marginRight : props.selected_lang === "ar" ? "80%" : "0px"}} alt="car rental" />
          <h1>{props.t("personaldriver")}</h1>
          <p>
          {props.t("FAA4_personaldriver_service_2_p")}
          </p>
          </div>
        </div>
        
        <div className="fazeel_serv_b2" style={{width : "35%"}}>
          <div className="fazeel_serv_b2_img1">
          <img style={{}} src ={`${process.env.REACT_APP_CDN}yeNh1C1.png`} alt = "Marketing" width = "325" height = "200" />
          </div>
     
      
        </div>


        <div className="fazeel_serv_b3">

        <div className="fazeel_b3_text">
        <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/icons/fuel.webp`} width = "30" height="30" alt="" />
          <h1>{props.t("fuelplans")}</h1>
          <p>
            {props.t("FAA6_fuelplans_service_3_p")}
          </p>
        </div>
       
       
        <div className="fazeel_b3_text" >
        <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/icons/locationx.webp`} width = "30" height="30" alt="" />
          <h1>{props.t("carnevigation")}</h1>
          <p>
          {props.t("FAA2_carnevigation_service_4_heading_p")}
          </p>
        </div>
        
          
        </div>
      </div>
    </div>
  );
}

export default Vnmervices;
