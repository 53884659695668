import { useDispatch, useSelector } from "react-redux";
import PriceRange from "../../../components/priceRangeSlider/range";

import { Suspense, useEffect, useRef, useState } from "react";
import '../../../assets/vnmstyles.scss'
import {
  getListings,
  getdistvbcall,
  handleChangeFilterValue,
  handleListingValChange,
  handleClearAllFilters,
  saveCount,
  favProduct,
} from "../../../store/listings/listingActions";
import Footerm from "../new_main/Footerm";
import { RxHamburgerMenu } from "react-icons/rx";
import { resetup } from "../../../store/auth/authhelpers.js";
import { errorToast, successToast } from "../../../utils";
import { handleValLangChange } from "../../../store/lang/langActions";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { handleValCurrencyChange } from "../../../store/currency/currencyActions";
import Menu_vtwo from "../homecomps/Menu_vtwo"
import { useMediaQuery } from "react-responsive";
import {
    useNavigate,
    useParams,
  
  } from "react-router-dom";
  import axiosConfig from "../../../utils/axiosConfig.jsx";  
import Footer from "../new_main/Footer";
import Lottie from "react-lottie";
import LoadingAnimation from "../../../assets/lottie/loading_animation.json";
import Homepremiumlisting from "../homes/Homepremiumlisting"
import Homepremiumlistingm from "../homes/Homepremiumlistingm"
import Userapp from "../homes/Userapp"
import Userappm from "../homes/Userappm"
import Swal from "sweetalert2";
import verified from "../../../assets/images/smicons/verified.webp";
import sideone from "../../../assets/images/sidetwo.gif";
import { GoogleLogin } from "@react-oauth/google";

import {
  
  loginGoogleUser,
  loginUser,
  logoutUser,
  registerUser,
} from "../../../store/auth/authActions";
import { Toaster } from "react-hot-toast";




function Vnmlisting(props) {
    const navigate = useNavigate();
    
    const navlink1 = useRef(null);
    const navlink2 = useRef(null);
    const navlink3 = useRef(null);
    

    let email = useRef("");
    let fullName = useRef("");
    let password = useRef("");
    let cell = useRef("");

    const brandsr = useRef(null);
    const aboutusr = useRef(null);
    
    const [showcall, setShowcall] = useState({eventid : "", id: "", boolv: false, boolw : false });

    const [id] = useState("car");
    const { t } = useTranslation();
    var dispatch = useDispatch();
    const listRef = useRef();
    const appRef = useRef();
    const listRef0 = useRef();
    const { type, xtr } = useParams();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    const [loginModel, setLoginModel] = useState(false);
    const [signupModel, setSignupModel] = useState(false);
    const { langs, selected_lang } = useSelector((state) => state.lang);
    const { distvbc } = useSelector((state) => state.listing);
    const { selected_curr, currencies, conversion_rate } =
    useSelector((state) => state.curr);
    const [emailx, setEmailx] = useState("")
    const [otpmodal, setOtpmodal] = useState(false);
    const [advanceFilterIsOpen, setAdvanceFilterIsOpen] = useState(false);
    const [mobileFilterIsOpen, setMobileFilterIsOpen] = useState(false);
    const [validationError, setValidationError] = useState("");

    const [advanceFilter, setAdvanceFilter] = useState([]);
    const [listingsx , setListingsx] = useState([])
    const [listingcountx , setListingcountx] = useState([])
    const [uniqlist , setUniqlist] = useState([])
    const [showProcessing, setShowProcessing] = useState(false)
    const [mobilesorting, setMobilesorting] = useState(false);


      const _handleAdvanceFilterHandler = () => {
        fsearches();
      };

   
     const _handleWaCount = (item, type) => {
      if (!user) {
        dispatch(saveCount({ item, type, user: { ubd: { guid: "" } } })).unwrap().then(res => {
          
          
          setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : false, boolw : true})
        });
      } else {
        dispatch(saveCount({ item, type, user })).unwrap().then(res => {
          
          setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : false, boolw : true})
        });
      }
      window.open(
        `https://wa.me/${item?.tenant?.waNumber}?text=Hi,%20messaging%20you%20regarding%20your%20listing%20${item?.bname[selected_lang]}%20,%20${item?.mname[selected_lang]}%20,%20${item?.modelyear.label}%20which%20have%20id%20as%20${item?.tluid}%20on%20https://VNMRENTAL.com%20is%20it%20available?`,
        "_blank",
        "noreferrer"
      );
     // OCD
     // https://web.whatsapp.com/send?phone=971565388444&text=Hi,%20I%20am%20a%20verified%20user%20at%20OneClickDrive.%20I%27d%20like%20to%20rent%20the%20listed%20Rolls%20Royce%20Cullinan%20Mansory%202022%20https://www.oneclickdrive.com/details/index/search-car-rentals-dubai/Rolls-Royce/Cullinan-Mansory/?id=26737%20for%20AED%207500/day.%20Is%20it%20available?  
  
    };

    const _handleSaveCount = async (item, type) => {
      if (type === 3 && !user) {
    
        
      } else if (type === 3 && user) {
        
      } else {
        dispatch(saveCount({ item, type, user }))
          .unwrap()
          .then((res) => {
            if (type === 3) {
              Swal.fire({
                title: t("REQUEST_SENT"),
                text: t("CALLBACK_MESSAGE"),
                icon: "info",
                confirmButtonText: t("COOL"),
                confirmButtonColor: "#F39C12",
              });
            }else{
              
              setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : type=== 2 ? true : false, boolw : false})
            }
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
      }
    };

    const {
        filter_master,
        limit,
        page,
        filters,
        search,
        loading_listings,
        premium_listings,
        
      } = useSelector((state) => state.listing);

    const { user, loading, user_profile } = useSelector(
        (state) => state.auth
      );

      const _handleSearch = () => {
        let cat_id = _getCatId();
    
        dispatch(handleListingValChange({ key: "page", value: 0 }));
        
       
        dispatch(getListings({ limit, page: 0, filters, search, cat_id }))
          .unwrap()
          .then(() => {
            
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
        
        scrollToListing();
        
      };

      const _handleSearchText = (e) => {
        
      //  dispatch(handleListingValChange({ key: "search", value: e.target.value }));
        xbvmsearch(e.target.value)
      };

      const _getCatId = () => {
        
        switch (type) {
          case "car":
            return "1";

          case "1":
              return "1";
    
          case "about-us":
              return "about-us";

          case "machines":
            return "2";
          
           case "2":
              return "2";

          case "yatch":
            return "3";

            case "3":
              return "3";  
    
          default:
            return "1";
        }
      };

      function scrollToListing() {
        listRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
   
       useEffect( () => {
  
        let cat_id = _getCatId();
        
        cat_id && dispatch(getdistvbcall({cat_id}))
           axiosConfig(`/getdistvb/${cat_id}`).then(t => {
              setAdvanceFilter(t.data.featuresx)
          })
            
            xbvm()
      }, [filters , page])

      const _handleClearAllFilters = () => {
        let cat_id = _getCatId();
        let master_filters_values = filter_master?.features?.find(
          (f) => f.categoryCode === cat_id
        )?.features;
        
        

        dispatch(handleClearAllFilters({}))
          .unwrap()
          .then(() => {
            
            dispatch(getListings({ limit, page, filters, search, cat_id }))
              .unwrap()
              .then(() => {})
              .catch((errorData) => {
                
                errorToast(errorData.error);
              });
            
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
          
          setAdvanceFilter(master_filters_values)
      };

     
      const _handleChangeTranslate = () => {
        let selectedLanguage = selected_lang === langs[0] ? langs[1] : langs[0];
        dispatch(
          handleValLangChange({ key: "rtl", value: selectedLanguage === langs[1] })
        );
        dispatch(
          handleValLangChange({ key: "selected_lang", value: selectedLanguage })
        );
        successToast(t(`Language Changed to ${selectedLanguage}`));
      };

      
      const _handleListingView = (item) => {
        _handleSaveCount(item, 0);
        dispatch(handleListingValChange({ key: "selectedListing", value: item }));
        navigate(`/item/${item?.tluid}-${item?._id}`);
      };

     

      const _handleChangeCurrency = () => {
         
        let selectedCurrency = 
          selected_curr === currencies[0] ? currencies[1] : currencies[0];

          

          let valuec = 1
          if(selected_curr === "AED"){
            valuec = filter_master.currencyinfo.find(t => t.name === "Dollar").value
          }else{
            valuec = filter_master.currencyinfo.find(t => t.name === "Dirham").value
          }
          
          dispatch(
          handleValCurrencyChange({
            key: "conversion_rate",
            value: String(valuec),
          })
        );
        dispatch(
          handleValCurrencyChange({ key: "selected_curr", value: selectedCurrency })
        );
        successToast(t(`Currency Changed to ${selectedCurrency}`));
      };

      const oAuth = (gauth, resp) => {
        if (gauth === "GOOGLE")
          dispatch(
            loginGoogleUser({
              g_response: resp,
            })
          )
            .unwrap()
            .then(() => {
              successToast("User Login Successfully");
              setLoginModel(false);
            })
            .catch((errorData) => {
              errorToast(errorData.error);
            });
      };
    
      const signinUser = async (e) => {
        e.preventDefault();
    
        dispatch(
          loginUser({
            email: email.value,
            password: password.value,
          })
        )
          .unwrap()
          .then(() => {
            e.target.reset();
            successToast("User Login Successfully");
            setLoginModel(false);
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
      };

      function afterOpenModal() {}


      const activateUser = (e, r) => {
        e.preventDefault();
        e.stopPropagation();
        if (
          !r?.value?.trim() 
        ) {
          setValidationError("OTP is required");
        } else {
          axiosConfig.post(`${process.env.REACT_APP_API_URL}/aclink`, {id : emailx, codex : r?.value }, {headers : {"userAccessToken" : id}})
          .then(r => {
            if(r.data.message === "User is activated."){
              successToast("User Activated.");
              setOtpmodal(false)
            }
          })
            .catch((errorData) => {
              errorToast("Expired or Incorrect OTP.");
            });
        }
      };
    

      const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          fontSize : "0.8rem",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          zIndex: 999999,
        },
        overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
      };
    
     

      const signUpUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (
          !fullName.value.trim() ||
          !email.value.trim() ||
          !password.value.trim()
        ) {
          setValidationError("All fields are required");
        } else {
          dispatch(
            registerUser({
              fullName: fullName.value,
              email: email.value,
              password: password.value,
              phone: cell.value,
            })
          )
            .unwrap()
            .then(() => {
              e.target.reset();
              successToast("Registered Successfully, Check activation mail.");
              setOtpmodal(true)
            })
            .catch((errorData) => {
              if(errorData.error === "User already registered, OTP reset"){
                errorToast("User already registered, OTP reset");
                setOtpmodal(true)
              }else{
                errorToast(errorData.error);
              }
              
            });
        }
      };


      const fsearches = () => {

        let nadv = advanceFilter.filter(
          (g) =>
            g.rangevalueL !== "" &&
            g.rangevalueL !== false &&
            g.rangevalueL !== undefined
        );
        let new_filters = nadv.map((filter) => {
          if (filter.rangevalueL === true) {
            return {
              ...filter,
              rangevalueL: "1",
            };
          } else {
            return filter;
          }
        });
        let filters = JSON.stringify(new_filters);
        let cat_id = type || "1";
        dispatch(handleListingValChange({ key: "page", value: 0 }));
        //dispatch(getAdvanceListings({ limit, page: 0, filters, cat_id }))
        axiosConfig.get(`/gvbcrf/${limit}/${page}/${cat_id}/0/1/${filters}`)
        .then(x => {
          
          setListingsx(x.data.data)
        })
        
      };

      const _handleAdvanceFilterOpen = () => {
        setAdvanceFilterIsOpen(true);
        setMobileFilterIsOpen(false);
      };

     

      const _handleLetsGoButton = () => {
        let cat_id = _getCatId();
        dispatch(handleListingValChange({ key: "page", value: 0 }));
        dispatch(getListings({ limit, page: 0, filters, search, cat_id }))
          .unwrap()
          .then(() => {
            successToast("Listings Refresh");
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
      };

      const ocrv = (g, val) => {
        let newstate = advanceFilter.map((item) => ({ ...item })); // Clone objects within advanceFilter
    
        const foundIndex = newstate.findIndex(
          (h) => h.featureCode === g.featureCode
        );
    
        if (foundIndex !== -1) {
          if (g.fib) {
            newstate[foundIndex].rangevalueL = val === "true" ? false : true;
          } else {
            newstate[foundIndex].rangevalueL = val;
          }
    
          // Assuming setAdvanceFilter is a function to update the state
          setAdvanceFilter(newstate);
        } else {
         // console.error("Object with the provided featureCode not found.");
        }
      };


      const Pagination = ({ totalItems, pageSize, currentPage, onPageChange }) => {
        const totalPages = Math.ceil(totalItems / pageSize);
        const neighbors = 1; // Number of neighboring pages to display on each side of the current page
    
        const handlePageChange = (page) => {
          onPageChange(page);
        };
    
        let startPage = Math.max(0, currentPage - neighbors);
        let endPage = Math.min(totalPages - 1, currentPage + neighbors * 2);
    
        const pages = Array.from(
          { length: endPage - startPage + 1 },
          (_, index) => startPage + index
        );
        
        

        return (
          <div className="flex justify-around items-center mt-8 montseraat-200">
            {!isTabletOrMobile && <div>
              <p style={{fontSize : "0.8rem"}}>{totalItems} { t("Active Listings")}</p>
            </div>} 
            <div dir="ltr" className="flex justify-center items-center">
              <ul className="flex space-x-2" style={{listStyleType : "none"}}>
                <li style={{listStyleType : "none"}}>
                  <button
                    href="/"
                    className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg "
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageChange(currentPage - 1 >= 0 ? currentPage - 1 : 0);
                    }}
                    disabled={loading_listings}
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 1 1 5l4 4"
                      />
                    </svg>
                  </button>
                </li>
                {pages.map((page) => (
                  <li style={{listStyleType : "none"}}
                    key={page}
                    className={`pagination-item ${
                      page === currentPage ? "pagination-item-active" : ""
                    }`}
                  >
                    <button
                      className={`z-10 flex items-center justify-center px-4 h-10 leading-tight ${
                        page === currentPage
                          ? "bg-primary text-white"
                          : "text-white bg-white  pagbutton"
                      }  border border-primary-300  hover:bg-primary hover:text-white bg-blue cursor-pointer`}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePageChange(page);
                      }}
                      disabled={loading_listings}
                    >
                      {page + 1}{" "}
                      {/* Add 1 to display page numbers starting from 1 */}
                    </button>
                  </li>
                ))}
                <li style={{listStyleType : "none"}}>
                  <button
                    className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg "
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageChange(
                        currentPage + 1 <= totalPages - 1
                          ? currentPage + 1
                          : totalPages - 1
                      );
                    }}
                    disabled={loading_listings}
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
    
           {!isTabletOrMobile && <div>
              <p  style={{fontSize : "0.8rem"}}>
                Page {currentPage + 1} of {totalPages}
              </p>
            </div>} 
          </div>
        );
      };

      const _handlePagination = (page) => {
        dispatch(handleListingValChange({ key: "page", value: page }));
        //window.scrollTo(0, 0);
        scrollTop();
      };

      const showrem = () => <img style={{marginTop : "2px"}} 
      src ={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/bin_icon.webp`} width = {"15px"}   
      alt="alt_remove" />

     

      const carfilter = () => {
        return (
            <div>
                {/** CITY FILTER IS CHANGED HERE */}
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                        <p className="font-light " style={{fontSize : "0.7rem", fontWeight : "bold", textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("City")}</p>
                        <div style={{display : "flex"}}>
                            <div style={{width : "90%", padding : "5px"}}>
                                    <select
                                    value={filters.citycode}
                                    id=""
                                    className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                                    onChange={(e) => {
                                            
                                             dispatch(handleChangeFilterValue({
                                            key: "citycode",
                                            value: e.target.value,
                                            }))
                                    }}
                                >
                                 <option value={"-"}>{t("ALL_CITIES")}</option>
                                  {distvbc?.cityCode?.map((city, index) => {
                                   if(city?.city?.[selected_lang]){
                                    return(
                                    <option key={"city" + index} value={city.city.cityCode}>
                                      {city?.city?.[selected_lang]}
                                    </option>
                                    )
                                   }   
                                    
                                  }
                                )}
                                </select>
                            </div>
                            <div  style={{padding : "5px" , display : filters.citycode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                 dispatch(handleChangeFilterValue({
                                    key: "citycode",
                                    value: "-",
                                    })
                                )
                            }}>
                               {showrem()}
                            </div>
                        </div>
                </div>
                 <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                    <p className="font-light" style={{ fontSize : "0.7rem", fontWeight : "bold", textAlign : selected_lang === "en" ?  "left" : "right" }}>
                    {t("Area")}
                  </p>
                  <div style={{display : "flex"}}>
                    <div style={{width : "90%", padding : "5px"}}>
                        <select
                            value={filters.areacode}
                            id=""
                            className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                            onChange={(e) => {
                            dispatch(
                                handleChangeFilterValue({
                                key: "areacode",
                                value: e.target.value,
                                })
                            );
                            }}
                        >
                            <option value={"-"}>{t("Select Area")}</option>
                            {uniqlist?.areas
                            ?.map((area, index) => {
                                  if(area?.[selected_lang]){
                                    return (
                                      area?.areaCode && <option key={"area" + index} value={area.areaCode}>
                                      {area?.[selected_lang]}
                                      </option>
                                    )
                                  }
                            
                                
                            })}
                        </select>
                    </div>
                    <div  style={{padding : "5px" , display : filters.areacode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "areacode",
                                        value: "-",
                                        }))
                                }}>
                                    {showrem()}
                    </div>
                  </div>
                </div>

                <div style={{width : "80%", color : "white", marginTop :"20px"}}> <hr style={{color : "white"}} /></div> 
  
{/** brand filter */}                
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                <p className="font-light " style={{fontSize : "0.7rem", fontWeight : "bold", textAlign : selected_lang === "en" ?  "left" : "right" }}>
                    {t("Brand")}
                  </p>
                  <div style={{display : "flex"}}>  
                    <div style={{width : "90%", padding : "5px"}}>
                        <select
                            value={filters.brandCode}
                            id=""
                            className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                            onChange={(e) => {
                            dispatch(
                                handleChangeFilterValue({
                                key: "brandCode",
                                value: e.target.value,
                                })
                            );
                            }}
                        >
                            <option value={"-"}>{t("Select Brand")}</option>
                            {
                            uniqlist?.brands?.map((brand, index) => {
                              if(brand?.bname?.[selected_lang]){
                                return (
                                  <option key={"brand" + index} value={brand.bid}>
                                      {brand?.bname?.[selected_lang]}
                                  </option>
                                )
                              }
                            
                            }) }
                        </select>        
                    </div>
                    <div  style={{padding : "5px" , display : filters.brandCode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "brandCode",
                                        value: "-",
                                        }))
                                }}>
                          {showrem()}
                    </div>
                  </div>
                </div>


                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                <p className="font-light " style={{fontSize : "0.7rem",  fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>
                  {t("Model")}
                </p>
                <div style={{display : "flex"}}>
                <div style={{width : "90%", padding : "5px"}}>
                        <select
                        value={filters.modelcode}
                        id=""
                        onChange={(e) => {
                            dispatch(
                            handleChangeFilterValue({
                                key: "modelcode",
                                value: e.target.value,
                            })
                            );
                        }}
                        className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                        >
                        <option value={"-"}>{t("Select Model")}</option>
                        {uniqlist?.model?.map((model, index) => {
                            if(model?.mname?.[selected_lang]){
                              return (
                                <option key={"model" + index} value={model.vid}>
                                  {model?.mname?.[selected_lang]}
                                  </option>
                              )
                            }
                          
                            
                        })}
                        </select>
                    </div>
                    <div  style={{padding : "5px" , display : filters.modelcode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "modelcode",
                                        value: "-",
                                        }))
                                }}>
                  {showrem()}
                    </div>
                </div>
                </div>

                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                   <p className="font-light" style={{fontSize : "0.7rem",  fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>
                    {t("Vehicle Type")}
                  </p>
                  <div style={{display : "flex"}}>
                  <div style={{width : "90%", padding : "5px"}}>
                  <select
                            value={filters?.typecode}
                            id=""
                            className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                            onChange={(e) => {
                                dispatch(
                                    handleChangeFilterValue({
                                      key: "typecode",
                                      value: e.target.value,
                                    })
                                  );
                            }}
                        >
                            <option value={"-"}>{t("All Types")}</option>
                            { uniqlist?.uvtname?.map((type, index) => {
                              if(type?.vtname[selected_lang]){
                                return (
                                  <option
                                  key={"type" + index}
                                  value={type.vtypeCode}
                                  >
                                  {type?.vtname[selected_lang]}
                                  </option>
                                )
                              }
                            }
                           )}
                  </select>
                  </div>


                  <div  style={{padding : "5px" , display : filters.typecode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "typecode",
                                        value: "-",
                                        }))
                                }}>
                    {showrem()}
                    </div>
                  </div>
                </div>

                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                        <p className="font-light" style={{fontSize : "0.7rem",  fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("Body Type")}</p>
                        <div style={{display : "flex"}}>
                        <div style={{width : "90%", padding : "5px"}}>        
                        <select
                            value={filters?.bodytype}
                            id=""
                            className="border focus:ring-orange-500 focus:border-orange-500 block w-full"
                            style={{color : "black"}}
                            onChange={(e) => {
                             dispatch(
                                handleChangeFilterValue({
                                key: "bodytype",
                                value: e.target.value,
                                })
                            );
                            }}
                        >
                            <option value={"-"}>{t("All Types")}</option>
                            {uniqlist?.btname?.map((type, index) => {
                                if(type.btname?.[selected_lang]){
                                  return (
                                      <option key={"type" + index} value={type.btypeCode}>
                                          {type.btname?.[selected_lang]}
                                      </option>
                                  )
                                }
                            })}
                        </select>
                         </div>
                     
                         <div  style={{padding : "5px" , display : filters.bodytype === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "bodytype",
                                        value: "-",
                                        }))
                                }}>
                        {showrem()}
                        </div>
                        </div>
                           
                </div>

                {
                  uniqlist?.delareas?.length >= 1 && 
                  <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                        <p className="font-light" style={{fontSize : "0.7rem",  fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("SelectDeliveryArea")}</p>
                        <div style={{display : "flex"}}>
                        <div style={{width : "90%", padding : "5px"}}>        
                        <select
                            value={filters?.delAreaCode}
                            id=""
                            className="border focus:ring-orange-500 focus:border-orange-500 block w-full"
                            style={{color : "black"}}
                            onChange={(e) => {
                             dispatch(
                                handleChangeFilterValue({
                                key: "delAreaCode",
                                value: e.target.value,
                                })
                            );
                            }}
                        >
                            <option value={"-"}>{t("Any Areas")}</option>
                            {uniqlist?.delareas?.map((type, index) => {
                                if(type?.[selected_lang]){
                                  return (
                                      <option key={"area" + index} value={type.areaCode}>
                                          {type?.[selected_lang]}
                                      </option>
                                  )
                                }
                            })}
                        </select>
                         </div>
                     
                         <div  style={{padding : "5px" , display : filters.bodytype === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "delareaCode",
                                        value: "-",
                                        }))
                                }}>
                        {showrem()}
                        </div>
                        </div>
                           
                </div>
                }
                
                
                <div style={{width : "80%", color : "white", marginTop :"40px"}}> <hr style={{color : "white"}} /></div> 
              
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "2%", paddingRight : "2%", paddingTop : "7%"}}>
                    <label className="font-light mb-1" style={{fontSize : "0.7rem",  fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("PRICE")}</label>
                    <div style={{marginLeft : "15px", marginRight : "15px"}}><PriceRange /></div>
                </div>
                
                <div style={{width : "80%", color : "white", marginTop :"20px", marginBottom : "20px"}}> <hr style={{color : "white"}} /></div> 
                
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "10%"}}>
                <table style={{fontSize : "0.8rem"}}>
                    <tr>
                        <td>  <label
                                for="default-radio-1"
                                className="font-light mb-1 mr-5" style={{fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}
                            >
                                {t("WITHOUTDEPOSIT")}
                            </label>
                        </td>
                        <td>
                        <input
                            id="deposit"
                            type="checkbox"
                            checked={filters.deposit !== "-"}
                            value={filters.deposit === "-" ? true : "-"}
                            onChange={(e) => {
                              dispatch(
                                handleChangeFilterValue({
                                  key: "deposit",
                                  value: e.target.value,
                                })
                              );
                            }}
                            name="deposit"
                            style={{marginTop : "5px", marginBottom : "5px"}}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <label
                      for="default-radio-1"
                      className="font-light mb-1 mr-5" style={{fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}
                    >
                      {id === "2"
                        ? t("WITHOPERATOR")
                        : id === "3"
                        ? t("WITHCREW")
                        : t("WITHDRIVER")}
                    </label>
                        </td>
                        <td>
                        <input
                  id="wdo"
                  type="checkbox"
                  checked={filters.wdo !== "-"}
                  value={filters.wdo === "-" ? false : "-"}
                  onChange={(e) => {
                    
                    dispatch(
                      handleChangeFilterValue({
                        key: "wdo",
                        value: e.target.value,
                      })
                    );
                  }}
                  name="Driver"
                  style={{marginTop : "5px", marginBottom : "5px"}}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                        </td>
                    </tr>
                   {/**  <tr>
                        <td>
                        <label
                  for="default-radio-1"
                  className="font-light mb-1 mr-5" style={{fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}
                >
                  {t("WITHINSURANCE")}
                </label>
                        </td>
                          <td>
                      <input
                  id="insurence"
                  type="checkbox"
                  checked={filters.insurence !== "-"}
                  value={filters.insurence === "-" ? true : "-"}
                  onChange={(e) => {
                    dispatch(
                      handleChangeFilterValue({
                        key: "insurence",
                        value: e.target.value,
                      })
                    );
                  }}
                  name="Insurance"
                  style={{marginTop : "5px", marginBottom : "5px"}}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                /> 
                        </td>
                    </tr>*/}
                </table>
              
                
                </div>
            
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "10%"}}>
                    <button
                    type="button"
                    onClick={() => _handleLetsGoButton()}
                    className="bg-primary font-medium mt-4 py-1 text-center items-center "
                    style={{borderRadius : "5px", color : "black", width : "10vw"}}
                >
                    {t("lETS_GO_BUTTON")}
                </button>
                <br />
                
                <button   
                className="font-medium mt-4 px-3 py-1 text-center inline-flex items-center "
                style={{backgroundColor : "white",  borderRadius : "5px", color : "black" , width : "10vw"
                , textAlign : "center"}}
                onClick={t => {
                                    _handleClearAllFilters()
                         }}>
                     {t("CLEARALLFILTERS")} &nbsp;  
                     <img  src ={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/bin_icon.webp`} width = {"15px"}   alt="alt_remove" />   
                </button>      

                </div>



            </div>
        )
      }

      const otherfilter = () => {
        return (
            <div>
                {/** CITY FILTER IS CHANGED HERE */}
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                        <p className="font-light mb-1" style={{fontWeight : "bold", textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("City")}</p>
                        <div style={{display : "flex"}}>
                            <div style={{width : "90%", padding : "5px"}}>
                                    <select
                                    value={filters.citycode}
                                    id=""
                                    className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                                    onChange={(e) => {
                                        dispatch(handleChangeFilterValue({
                                            key: "citycode",
                                            value: e.target.value,
                                            }))
                                    }}
                                >
                                    <option value={"-"}>{t("Select City")}</option>
                                    {distvbc?.cityCode?.map((city, index) => (
                                    
                                    <option key={"city" + index} value={city.city.cityCode}>
                                      {city?.city?.[selected_lang]}
                                    </option>
                                  ))}
                                </select>
                            </div>
                            <div  style={{padding : "5px" , display : filters.citycode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                
                                 dispatch(handleChangeFilterValue({
                                    key: "citycode",
                                    value: "-",
                                    })
                                )
                            }}>
                                {showrem()}
                            </div>
                        </div>
                </div>

                
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "2%", paddingRight : "2%", paddingTop : "7%"}}>
                    <label className="font-light mb-1" style={{fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("PRICE")}</label>
                    <div style={{marginLeft : "15px", marginRight : "15px"}}><PriceRange /></div>
                </div>
                

                
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "10%"}}>
                <table style={{fontSize : "0.8rem"}}>
                   
                    <tr>
                        <td>
                        <label
                  for="default-radio-1"
                  className="font-light mb-1 mr-5" style={{fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}
                >
                  {type === "2"
                    ? t("WITHOPERATOR")
                    : type === "3"
                    ? t("WITHCREW")
                    : t("WITHDRIVER")}
                </label>
                        </td>
                        <td>
                        <input
                            id="wdo"
                            type="checkbox"
                            checked={filters.wdo !== "-"}
                            value={filters.wdo === "-" ? false : "-"}
                            onChange={(e) => {
                              dispatch(
                                handleChangeFilterValue({
                                  key: "wdo",
                                  value: e.target.value,
                                })
                              );
                            }}
                            name="Driver"
                            style={{marginTop : "5px", marginBottom : "5px"}}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                    </tr>
                    
                </table>
              
                
                </div>
            
              
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "10%"}}>
                    <button
                    type="button"
                    onClick={() => _handleLetsGoButton()}
                    className="bg-primary font-medium mt-4 px-6 py-1 text-center inline-flex items-center "
                    style={{borderRadius : "3px", color : "black", width : "10vw"}}
                >
                    {t("lETS_GO_BUTTON")}
                </button>
                <br />
                
                <button   
                className="font-medium mt-4 px-3 py-1 text-center inline-flex items-center "
                style={{backgroundColor : "white",  borderRadius : "5px", color : "black" , width : "10vw", textAlign : "center"}}
                onClick={t => {
                                    _handleClearAllFilters()
                         }}>
                     {t("CLEARALLFILTERS")} &nbsp;  <img style={{marginTop : "5px"}} src ={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/bin_icon.webp`} width = {"15px"}   alt="alt_remove" />   
                </button>      
                </div>


            </div>
        )
      }

      const machinefilter = () => {
        return (
            <div>
                {/** CITY FILTER IS CHANGED HERE */}
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                        <p className="font-light mb-1" style={{fontWeight : "bold", textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("City")}</p>
                        <div style={{display : "flex"}}>
                            <div style={{width : "90%", padding : "5px"}}>
                                    <select
                                    value={filters.citycode}
                                    id=""
                                    className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                                    onChange={(e) => {
                                        dispatch(handleChangeFilterValue({
                                            key: "citycode",
                                            value: e.target.value,
                                            }))
                                    }}
                                >
                                    <option value={"-"}>{t("Select City")}</option>
                                    {distvbc?.cityCode?.map((city, index) => (
                                    
                                    <option key={"city" + index} value={city.city.cityCode}>
                                      {city?.city?.[selected_lang]}
                                    </option>
                                  ))}
                                </select>
                            </div>
                            <div  style={{padding : "5px" , display : filters.citycode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                
                                 dispatch(handleChangeFilterValue({
                                    key: "citycode",
                                    value: "-",
                                    })
                                )
                            }}>
                                {showrem()}
                            </div>
                        </div>
                        
                </div>
{/** Brand */}
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                <p className="font-light " style={{fontSize : "0.7rem", fontWeight : "bold", textAlign : selected_lang === "en" ?  "left" : "right" }}>
                    {t("Brand")}
                  </p>
                  <div style={{display : "flex"}}>  
                    <div style={{width : "90%", padding : "5px"}}>
                        <select
                            value={filters.brandCode}
                            id=""
                            className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                            onChange={(e) => {
                            dispatch(
                                handleChangeFilterValue({
                                key: "brandCode",
                                value: e.target.value,
                                })
                            );
                            }}
                        >
                            <option value={"-"}>{t("Select Brand")}</option>
                            {
                            uniqlist?.brands?.map((brand, index) => {
                              if(brand?.bname?.[selected_lang]){
                                return (
                                  <option key={"brand" + index} value={brand.bid}>
                                      {brand?.bname?.[selected_lang]}
                                  </option>
                                )
                              }
                            
                            }) }
                        </select>        
                    </div>
                    <div  style={{padding : "5px" , display : filters.brandCode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "brandCode",
                                        value: "-",
                                        }))
                                }}>
                          {showrem()}
                    </div>
                  </div>
                </div>
            
{/** Model */}                                
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                <p className="font-light " style={{fontSize : "0.7rem",  fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>
                  {t("Model")}
                </p>
                <div style={{display : "flex"}}>
                <div style={{width : "90%", padding : "5px"}}>
                        <select
                        value={filters.modelcode}
                        id=""
                        onChange={(e) => {
                            dispatch(
                            handleChangeFilterValue({
                                key: "modelcode",
                                value: e.target.value,
                            })
                            );
                        }}
                        className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                        >
                        <option value={"-"}>{t("Select Model")}</option>
                        {uniqlist?.model?.map((model, index) => {
                            if(model?.mname?.[selected_lang]){
                              return (
                                <option key={"model" + index} value={model.vid}>
                                  {model?.mname?.[selected_lang]}
                                  </option>
                              )
                            }
                          
                            
                        })}
                        </select>
                    </div>
                    <div  style={{padding : "5px" , display : filters.modelcode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "modelcode",
                                        value: "-",
                                        }))
                                }}>
                  {showrem()}
                    </div>
                </div>
                </div>

{/** Type */}
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                   <p className="font-light" style={{fontSize : "0.7rem",  fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>
                    {t("Vehicle Type")}
                  </p>
                  <div style={{display : "flex"}}>
                  <div style={{width : "90%", padding : "5px"}}>
                  <select
                            value={filters?.typecode}
                            id=""
                            className="border border-gray-300 text-gray-900 focus:ring-orange-500 focus:border-orange-500 block w-full"
                            onChange={(e) => {
                                dispatch(
                                    handleChangeFilterValue({
                                      key: "typecode",
                                      value: e.target.value,
                                    })
                                  );
                            }}
                        >
                            <option value={"-"}>{t("All Types")}</option>
                            { uniqlist?.uvtname?.map((type, index) => {
                              if(type?.vtname[selected_lang]){
                                return (
                                  <option
                                  key={"type" + index}
                                  value={type.vtypeCode}
                                  >
                                  {type?.vtname[selected_lang]}
                                  </option>
                                )
                              }
                            }
                           )}
                  </select>
                  </div>


                  <div  style={{padding : "5px" , display : filters.typecode === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "typecode",
                                        value: "-",
                                        }))
                                }}>
                    {showrem()}
                    </div>
                  </div>
                </div>                

                {
                  uniqlist?.delareas?.length >= 1 && 
                  <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "2%"}}>
                        <p className="font-light" style={{fontSize : "0.7rem",  fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("SelectDeliveryArea")}</p>
                        <div style={{display : "flex"}}>
                        <div style={{width : "90%", padding : "5px"}}>        
                        <select
                            value={filters?.delAreaCode}
                            id=""
                            className="border focus:ring-orange-500 focus:border-orange-500 block w-full"
                            style={{color : "black"}}
                            onChange={(e) => {
                             dispatch(
                                handleChangeFilterValue({
                                key: "delAreaCode",
                                value: e.target.value,
                                })
                            );
                            }}
                        >
                            <option value={"-"}>{t("Any Areas")}</option>
                            {uniqlist?.delareas?.map((type, index) => {
                                if(type?.[selected_lang]){
                                  return (
                                      <option key={"area" + index} value={type.areaCode}>
                                          {type?.[selected_lang]}
                                      </option>
                                  )
                                }
                            })}
                        </select>
                         </div>
                     
                         <div  style={{padding : "5px" , display : filters.bodytype === "-" && 'none' , cursor : "pointer"}} onClick={t => {
                                    dispatch(handleChangeFilterValue({
                                        key: "delareaCode",
                                        value: "-",
                                        }))
                                }}>
                        {showrem()}
                        </div>
                        </div>
                           
                </div>
                }


                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "2%", paddingRight : "2%", paddingTop : "7%"}}>
                    <label className="font-light mb-1" style={{fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}>{t("PRICE")}</label>
                    <div style={{marginLeft : "15px", marginRight : "15px"}}><PriceRange /></div>
                </div>
                

                
                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "10%"}}>
                <table style={{fontSize : "0.8rem"}}>
                   
                    <tr>
                        <td>
                        <label
                  for="default-radio-1"
                  className="font-light mb-1 mr-5" style={{fontWeight : "bold" , textAlign : selected_lang === "en" ?  "left" : "right"}}
                >
                  {type === "2"
                    ? t("WITHOPERATOR")
                    : type === "3"
                    ? t("WITHCREW")
                    : t("WITHDRIVER")}
                </label>
                        </td>
                        <td>
                        <input
                            id="wdo"
                            type="checkbox"
                            checked={filters.wdo !== "-"}
                            value={filters.wdo === "-" ? false : "-"}
                            onChange={(e) => {
                              dispatch(
                                handleChangeFilterValue({
                                  key: "wdo",
                                  value: e.target.value,
                                })
                              );
                            }}
                            name="Driver"
                            style={{marginTop : "5px", marginBottom : "5px"}}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                    </tr>
                    
                </table>
              
                
                </div>
            
                

                <div className="montserrat-400" style={{fontSize :  "0.8rem", paddingLeft : "10%", paddingRight : "10%", paddingTop : "10%"}}>
                    <button
                    type="button"
                    onClick={() => _handleLetsGoButton()}
                    className="bg-primary font-medium mt-4 px-6 py-1 text-center inline-flex items-center "
                    style={{borderRadius : "3px", color : "black", width : "10vw"}}
                >
                    {t("lETS_GO_BUTTON")}
                </button>
                <br />
                
                <button   
                className="font-medium mt-4 px-3 py-1 text-center inline-flex items-center "
                style={{backgroundColor : "white",  borderRadius : "5px", color : "black" , width : "10vw", textAlign : "center"}}
                onClick={t => {
                                    _handleClearAllFilters()
                         }}>
                     {t("CLEARALLFILTERS")} &nbsp;  <img style={{marginTop : "5px"}} src ={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/bin_icon.webp`} width = {"15px"}   alt="alt_remove" />   
                </button>      
                </div>


            </div>
        )
      }

      function scrollTop() {
        window.scrollTo({ top: 200, behavior: "smooth" });
      }

      const mobilefilterx = () => {
        return (
          <div className="buttonsmfilter">
            
            <button
            onClick={y => {
              setMobileFilterIsOpen(true);
            }}
            style={{  fontSize : "0.7rem" , height: "30px" , width : "80px" , backgroundColor : "orange" , fontWeight : "bold", borderRadius : "10px" }}
            >{t("Filter_inlisting")}</button>
            
          </div>
        )
      }

      const xbvm  = () => {
        
        let xd = {...filters , limit : limit, page : page }

        setShowProcessing(true)
        
        if(search !== ""){
          
          if(search.includes(["." , "$" , "%", "&"])){
            errorToast("Invalid Search text");
          }else{
            
            axiosConfig.post(
              `/xgetdistvbtxt`,
                {txt : search , limit: limit, page : page}
               
              ).then(t => {
                
                
    
                setListingcountx(t.data.data.countt)
                setListingsx(t.data.data.data)
                setUniqlist(t.data.data.cbvtm)
                
                setShowProcessing(false)
              })
              .catch(e => {
                setShowProcessing(false)
                errorToast("some listings are not available, please try to select category.");
              }) 
          }
     
        }else{

          axiosConfig.post(
            `/xgetdistvb`,
              {...xd }
             
            ).then(t => {
              
              
  
              setListingcountx(t.data.data.countt)
              setListingsx(t.data.data.data)
              setUniqlist(t.data.data.cbvtm)
              
              setShowProcessing(false)
            })
            .catch(e => {
              setShowProcessing(false)
              errorToast("some listings are not available, please try to select category.");
            }) 
        }
       
      }

      const xbvmsearch  = (txte) => {

        setShowProcessing(true)
        
          if(search.includes(["." , "$" , "%", "&"])){
            errorToast("Invalid Search text");
          }else{
            axiosConfig.post(
              `/xgetdistvbtxt`,
                {txt : txte , limit: limit, page : page}
               
              ).then(t => {
                
                setShowProcessing(false)
                setListingcountx(t.data.data.countt)
                setListingsx(t.data.data.data)
                setUniqlist(t.data.data.cbvtm)
                
                setShowProcessing(false)
              })
              .catch(e => {
                setShowProcessing(false)
                errorToast("some listings are not available, please try to select category.");
              }) 
          }
     
       
      }

    return (
        <div>
          
            <Toaster />
                <Modal
                   ariaHideApp={false}
                    isOpen={loginModel}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={() => setLoginModel(false)}
                    style={{ ...customStyles }}
                    contentLabel="Login Modal"
                >
                    <form onSubmit={signinUser}>
                    <div style={{display : "flex"}}>
                       <div>
                                <button onClick={g => {g.preventDefault(); setLoginModel(false);}} 
                                          style={{ color: "gray", fontSize : "1rem", marginTop : "10px"}}>
                                            &#x2716;
                                </button>
                       </div>
                         &nbsp; &nbsp; &nbsp;
                       <div><h1>{t("Sign In")}</h1></div>
                 
                      </div>
                      <p className="mt-4 mb-8">
                        {t("If you don't have an account You can")}
                        <br />
                        <button
                            onClick={() => {
                            setLoginModel(false);
                            setSignupModel(true);
                            }}
                            className="link"
                        >
                            {t("Register here")} !
                        </button>
                        </p>
                    
                    <div>
                        <label className="block text-primary-grey text-[13px] font-medium pb-1">
                        {t("Email")}
                        </label>
                        <div className="relative">
                        <input
                            className="inputField w-full mb-8"
                            name="email"
                            placeholder={t("Enter your Email")}
                            id="email"
                            ref={(e) => {
                            email = e;
                            }}
                            type="email"
                            required
                        />
                        </div>
                    </div>

                    <div>
                        <label className="block text-primary-grey text-[13px] font-medium pb-1">
                        {t("Password")}
                        </label>
                        <div className="relative">
                        <input
                            className="inputField w-full mb-8"
                            name="password"
                            placeholder={t("Enter your Passowrd")}
                            id="password"
                            ref={(e) => {
                            password = e;
                            }}
                            type="password"
                            required
                        />
                        </div>
                    </div>
                    <center>
                        <button
                        type="submit"
                        className="primaryButton mt-4"
                        style={{ fontSize: "0.6rem" }}
                        >
                        {loading ? `${t("Loading")}...` : t("Sign In")}
                        </button>
                        
                        <p
                        style={{
                            marginTop: "25px",
                            fontSize: "0.7rem",
                            cursor: "pointer",
                        }}
                        onClick={(t) => {
                            resetup(email.value);
                            setLoginModel(false);
                        }}
                        >
                        Forgot your password ?
                        </p>
                    </center>
                    </form>

                    <br />
                    <GoogleLogin
                    onSuccess={(credentialResponse) => {
                        
                        oAuth("GOOGLE", credentialResponse);
                        
                    }}
                    onError={() => {
                    
                    }}
                    />
                </Modal>

                <Modal
                  ariaHideApp={false}
                    isOpen={signupModel}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={() => setSignupModel(false)}
                    style={customStyles}
                    contentLabel="Signup Modal"
                >
                      <div style={{display : "flex"}}>
                          <div>
                                    <button onClick={g => {g.preventDefault();setSignupModel(false);}} 
                                              style={{ color: "gray", fontSize : "1rem", marginTop : "10px"}}>
                                                &#x2716;
                                    </button>
                          </div>
                            &nbsp; &nbsp; &nbsp;
                          <div><h1>{t("Sign Up")}</h1></div>
                      </div>
                    
                  
                        {otpmodal ?
                        <form onSubmit={e => activateUser(e, fullName)}>
                        <div>
                        <h5 className="block text-primary-grey text-[13px] font-medium pb-1">
                            OTP Code from your email.
                        </h5>
                        <div className="relative">
                            <input
                            className="inputField mb-8 w-full"
                            name="fullName"
                            placeholder="Enter OTP "
                            id="fullName"
                            onChange={() => setValidationError("")}
                            ref={(e) => {
                                fullName = e;
                            }}
                            type="text"
                            required
                            />
                        </div>
                        </div>   
                        <button type="submit" className="button-55">
                        {loading ? `${t("Loading")}...` : "Activate"}
                        </button>
                        </form>
                        :
                        <form onSubmit={signUpUser}>
                        <div>
                        <p className="mt-4 mb-8">
                            {t("If you already have an account registered You can")}
                            <br />
                            <button
                            onClick={() => {
                                setSignupModel(false);
                                setLoginModel(true);
                            }}
                            className="link"
                            >
                            {t("Login here")} !
                            </button>
                        </p>
                        </div>
                        <div>
                        <label className="block text-primary-grey text-[13px] font-medium pb-1">
                            {t("Full Name")}
                        </label>
                        <div className="relative">
                            <input
                            className="inputField mb-8 w-full"
                            name="fullName"
                            placeholder={t("Enter your full name")}
                            id="fullName"
                            onChange={() => setValidationError("")}
                            ref={(e) => {
                                fullName = e;
                            }}
                            type="text"
                            required
                            />
                        </div>
                        </div>
                        <div>
                        <label className="block text-primary-grey text-[13px] font-medium pb-1">
                            {t("Email")}
                        </label>
                        <div className="relative">
                            <input
                            className="inputField mb-8 w-full"
                            name="email"
                            placeholder={t("Enter your Email")}
                            id="email"
                            onChange={(e) => {setValidationError("");setEmailx(e.target.value)}}
                            ref={(e) => {
                                email = e;
                            }}
                            type="email"
                            required
                            />
                        </div>
                        </div>
                        <div>
                        <label className="block text-primary-grey text-[13px] font-medium pb-1">
                            {t("Phone")}
                        </label>
                        <div className="relative">
                            <input
                            className="inputField w-full mb-8"
                            name="cell"
                            placeholder={t("Enter your Phone Number")}
                            id="cell"
                            ref={(e) => {
                                cell = e;
                            }}
                            type="tel"
                            required
                            />
                        </div>
                        </div>
                        <div>
                        <label className="block text-primary-grey text-[13px] font-medium pb-1">
                            {t("Password")}
                        </label>
                        <div className="relative">
                            <input
                            className="inputField mb-8 w-full"
                            name="password"
                            placeholder={t("Enter your Passowrd")}
                            id="password"
                            onChange={() => setValidationError("")}
                            ref={(e) => {
                                password = e;
                            }}
                            type="password"
                            required
                            />
                        </div>
                        </div>
                        {validationError && (
                        <p className="text-left text-red-500">{validationError}</p>
                        )}
                        <button type="submit" className="button-55">
                        {loading ? `${t("Loading")}...` : t("Sign Up")}
                        </button>
                        </form>
                        } 
                        
                </Modal>

                <Modal
                    isOpen={advanceFilterIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={() => {
                      setAdvanceFilterIsOpen(false);
                    }}
                    style={customStyles}
                    contentLabel="Advance Filter Modal"
                    ariaHideApp={false}
                  >
                    <section className="z-50">
                      <div className="text-center">
                        <p className="text-xl font-bold mb-5 border-bottom">
                          {t("ADVANCE_SEARCH")}
                        </p>
                      </div>

                      <div className="flex flex-col justify-center">
                        <PerfectScrollbar style={{ height: 500 }}>
                         
                          {(advanceFilter || [])?.map((g, key) => {
                            return (
                              <div
                                id={key}
                                key={key}
                                className="flex flex-row gap-1 justify-between border-b my-2 pr-5 border-black-900"
                                style={{ paddingBottom: 10, marginLeft: 5 }}
                              >
                                <div className="flex flex-row gap-1 items-center">
                                  <img
                                    alt = {g?.ficon}
                                    src={`${process.env.REACT_APP_API_URL}/fet/${g?.ficon}`}
                                    style={{
                                      width: 20,
                                      objectFit: "contain",
                                    }}
                                  />{" "}
                                  <p>{g?.[selected_lang]}</p>
                                </div>
                                <div style={{ padding: 2, marginRight: 10 }}>
                                  {g?.fib ? (
                                    <input
                                      type="checkbox"
                                      value={g.rangevalueL}
                                      checked={g.rangevalueL === true}
                                      defaultValue={false}
                                      onChange={(x) => {
                                        ocrv(g, x.target.value);
                                      }}
                                    />
                                  ) : (
                                    <select
                                      id="feature_value"
                                      onChange={(v) => ocrv(g, v?.target.value)}
                                      value={g?.rangevalueL}
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    >
                                      <option value={"-"}>Choose an Option</option>
                                      {g?.valueRange?.map((value) => (
                                        <option value={value?.len}>
                                          {value?.len || ""}
                                        </option>
                                      ))}
                                    </select>
                             
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </PerfectScrollbar>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          setMobileFilterIsOpen(false);
                          setAdvanceFilterIsOpen(false);
                          _handleAdvanceFilterHandler();
                        }}
                        className="text-white bg-primary font-medium  mt-4 px-6 py-2.5 text-center inline-flex items-center float-end rounded border-white border-1 border-solid border-white roundborderlightgray"
                      >
                        {t("lETS_GO_BUTTON")}
                      </button>
                    </section>
                </Modal>

                <Modal
                      ariaHideApp={false}
                        isOpen={mobileFilterIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={() => {
                          setMobileFilterIsOpen(false);
                        }}
                        style={customStyles}
                        contentLabel="Mobile filter Modal"
                      >
                     
                        <section className="flex flex-row justify-center">
                          {/* {isTabletOrMobile && ( */}
                          <div
                            className="pb-5"
                            style={{
                              background: "#F2F2F2",
                              zIndex: 1,
                              height: 500,
                              overflowY: "scroll",
                            }}
                          >
                            <div className="bg-primary flex py-1 px-5 justify-between rounded items-center">
                              <div>
                              <button onClick={g => {g.preventDefault(); setMobileFilterIsOpen(false); }} 
                              style={{ color: "gray", fontSize : "1rem", marginTop : "5px"}}>
                                &#x2716;
                              </button></div>
                              &nbsp;  &nbsp;
                            <div>
                              <p className="text-white  text-3xl font-medium ">
                              {t("FIND_MORE_OPTIONS")}
                              </p>
                            </div>
                            </div>

                            <div className="flex flex-col items-center px-4 gap-4 mt-4  ">
                              <div className="w-full">
                                <p className="font-light text-small ml-1 mb-1">{t("City")}</p>

                                <select
                                  value={filters.citycode}
                                  id=""
                                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                  onChange={(e) => {
                                    dispatch(
                                      handleChangeFilterValue({
                                        key: "citycode",
                                        value: e.target.value,
                                      })
                                    );
                                  }}
                                >
                                 <option value={"-"}>{t("ALL_CITIES")}</option>
                                  {distvbc?.cityCode?.map((city, index) => {
                                    if(city?.city?.[selected_lang]){
                                      return(
                                        <option key={"city" + index} value={city.city.cityCode}>
                                        {city?.city?.[selected_lang]}
                                      </option>
                                      )
                                    }
                                    })}
                                </select>
                              </div>
                        
                              {(type === "1" || type === "car") && (
                                <>
                                    <div className="w-full">
                                    <p className="font-light text-small ml-1 mb-1">
                                      {t("Area")}
                                    </p>
                                    <select
                                      value={filters.areacode}
                                      id=""
                                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "areacode",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                    >
                                      <option value={"-"}>{t("Select Area")}</option>
                                      {uniqlist?.areas
                                      ?.map((area, index) => (
                                          
                                          area?.areaCode && <option key={"area" + index} value={area.areaCode}>
                                          {area?.[selected_lang]}
                                          </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="w-full">
                                    <p className="font-light text-small ml-1 mb-1">
                                      {t("Brand")}
                                    </p>
                                    <select
                                      value={filters.brandCode}
                                      id=""
                                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "brandCode",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                    >
                                      <option value={"-"}>{t("Select Brand")}</option>
                                      {
                                      uniqlist?.brands?.map((brand, index) => {
                                        if(brand?.bname?.[selected_lang]){
                                            return (
                                            <option key={"brand" + index} value={brand.bid}>
                                              {brand?.bname?.[selected_lang]}
                                            </option>
                                            )
                                            
                                        }
                                     
                                      }) }
                                    </select>
                                  </div>
                                </>
                              )}

                              {(type === "1" || type === "car" || type === "2" || type === "machines") && (
                                <div className="w-full">
                                  <p className="font-light text-small ml-1 mb-1">
                                    {t("Model")}
                                  </p>
                                  <select
                                    value={filters.modelcode}
                                    id=""
                                    onChange={(e) => {
                                      dispatch(
                                        handleChangeFilterValue({
                                          key: "modelcode",
                                          value: e.target.value,
                                        })
                                      );
                                    }}
                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                  >
                                       <option value={"-"}>{t("Select Model")}</option>
                                            {uniqlist?.model?.map((model, index) => {
                                              if(model?.mname?.[selected_lang]){
                                                return (
                                                  <option key={"model" + index} value={model.vid}>
                                                {model?.mname?.[selected_lang]}
                                                </option>
                                                )
                                              }
                                            })}
                                  </select>
                                </div>
                              )}
                              {(type === "1" || type === "car" || type === "2" || type === "machines") && (
                                <>
                                  <div className="w-full">
                                    <p className="font-light text-small ml-1 mb-1">
                                      {t("Vehicle Type")}
                                    </p>
                                    <select
                                      value={filters?.typecode}
                                      id=""
                                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "typecode",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                    >
                                      <option value={"-"}>{t("Select Type")}</option>
                                      { uniqlist?.uvtname?.map((type, index) => {
                                        if(type?.vtname[selected_lang]){
                                            return (
                                              <option
                                                key={"type" + index}
                                                value={type.vtypeCode}
                                                >
                                                {type?.vtname[selected_lang]}
                                                </option>
                                            )  
                                        }
                                        
                                      }
                                        
                                    )}
                                    </select>
                                  </div>

                                  <div className="w-full">
                                    <p className="font-light text-small ml-1 mb-1">
                                      {t("SelectDeliveryArea")}
                                    </p>
                                    <select
                                      value={filters?.delAreaCode}
                                      id=""
                                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "delAreaCode",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                    >
                                      <option value={"-"}>{t("Select Type")}</option>
                                      { uniqlist?.delareas?.map((type, index) => {
                                        if(type?.[selected_lang]){
                                            return (
                                              <option
                                                key={"Area" + index}
                                                value={type.areaCode}
                                                >
                                                {type?.[selected_lang]}
                                                </option>
                                            )  
                                        }
                                        
                                      }
                                        
                                    )}
                                    </select>
                                  </div>

                                </>
                              )}
                              {(type === "1" || type === "car" ) && (
                                <>
                                       <div className="w-full">
                                    <p className="font-light text-small ml-1 mb-1">
                                      {t("Body Type")}
                                    </p>
                                    <select
                                      value={filters?.bodytype}
                                      id=""
                                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "bodytype",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                    >
                                      <option value={"-"}>{t("Select Body Type")}</option>
                                      {uniqlist?.btname?.map((type, index) => {
                                        if(type.btname?.[selected_lang]){
                                          return (
                                            <option key={"type" + index} value={type.btypeCode}>
                                                {type.btname?.[selected_lang]}
                                            </option>
                                          )
                                        }
                                      
                                      })}
                                    </select>
                                  </div>
                           
                                </>
                              )}
                              
                              {(type === "x1" || type === "xcar") && (
                                <div className="w-full">
                                  <p className="font-light text-small ml-1 mb-1">
                                    {t("Delivery_Area")}
                                  </p>
                                  <select
                                    value={filters.delAreaCode}
                                    id=""
                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    onChange={(e) => {
                                      dispatch(
                                        handleChangeFilterValue({
                                          key: "delAreaCode",
                                          value: e.target.value,
                                        })
                                      );
                                    }}
                                  >
                                  <option value={"-"}>{t("SelectDeliveryArea")}</option>
                                  {uniqlist?.delareas?.filter(o => o.areaCode !== "")
                                          ?.map((area, index) => (
                                            
                                            area?.areaCode &&  <option key={"area" + index} value={area.areaCode}>
                                              {area?.[selected_lang]}
                                              </option>
                                          ))}
                                  </select>
                                </div>
                              )}
                            </div>



                            <div className="flex flex-col items-center px-4 gap-4 ">
                              <div className="w-full">
                                <div className="flex items-center mt-4">
                                  <input
                                    id="wdo"
                                    type="checkbox"
                                    //   checked={filters.wdo !== "-"}
                                    //   value={filters.wdo === "-" ? true : false}
                                    checked={filters.wdo !== "-"}
                                    value={filters.wdo === "-" ? false : "-"}
                                    onChange={(e) => {
                                      dispatch(
                                        handleChangeFilterValue({
                                          key: "wdo",
                                          value: e.target.value,
                                        })
                                      );
                                    }}
                                    name="Driver"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  
                                  <label for="default-radio-1" className="mx-2 text-sm font-medium">
                                    {(type === "2" || type === "machines")
                                      ? t("WITHOPERATOR")
                                      : (type === "3" || type === "yacht")
                                      ? t("WITHCREW")
                                      : t("WITHDRIVER")}
                                  </label>
                                </div>
                              </div>
                            {(type === "1" || type === "car") && (
                              <>
                                <div className="w-full">
                                  <div className="flex items-center mt-4">
                                 {/**    <input
                                      id="insurence"
                                      type="checkbox"
                                      checked={filters.insurence !== "-"}
                                      value={filters.insurence === "-" ? true : "-"}
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "insurence",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                      name="Insurance"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    /> 
                                    <label for="default-radio-1" className="mx-2 text-sm font-medium">
                                      {t("WITHINSURANCE")}
                                    </label> */}
                                  </div>
                                </div>
                              
                                <div className="w-full">
                                  <div className="flex items-center mt-4">
                                    <input
                                      id="deposit"
                                      type="checkbox"
                                      checked={filters.deposit !== "-"}
                                      value={filters.deposit === "-" ? true : "-"}
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "deposit",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                      name="deposit"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                      for="default-radio-1"
                                      className="mx-2 text-sm font-medium"
                                    >
                                      {t("WITHOUTDEPOSIT")}
                                    </label>
                                  </div>
                                </div>
                                </>
                              )}

                              <div className="w-full">
                                <PriceRange />
                              </div>
                                

                                
                            </div>
                              
                            <div className="w-full text-center mt-2 mb-2">
                                  <button
                                        onClick={() => _handleAdvanceFilterOpen()}
                                        className="mt-2 text-sm "
                                      >
                                        {t("ADVANCEFILTERS")}
                                      </button>
                                  
                            </div>
                        
                            <div className="w-full">
                                    <>
                                    <button
                                      onClick={() => _handleClearAllFilters()}
                                      className="px-6 py-2.5 text-center inline-flex items-center  rounded border-white border-1 border-solid border-white roundborderlightgray"
                                    >
                                      {t("CLEARALLFILTERS")}
                                    </button>
                              
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setMobileFilterIsOpen(false);
                                          _handleLetsGoButton();
                                        }}
                                        className="text-white bg-primary font-medium  mt-4 px-6 py-2.5 text-center inline-flex items-center  ml-10 rounded border-white border-1 border-solid border-white roundborderlightgray"
                                      >
                                        {t("lETS_GO_BUTTON")}
                                      </button>
                                    </>
                            </div>
                                

                          </div>
                          {/* )} */}
                        </section>
                </Modal>

          {/** this is mobile sorting */}

              <Modal
                ariaHideApp={false}
                  isOpen={mobilesorting}
                  onAfterOpen={afterOpenModal}
                  style={customStyles}
                  contentLabel="Mobile filter Modal"
                  onRequestClose={() => {
                    setMobilesorting(false)
                  }}
                  
                >
                
                  <div style={{height : "8vh",  width : "100%"}}>
                                    <select
                                            value={filters?.sort}
                                            id=""
                                            
                                            style={{ float :"left",  color : "gray",  width : "200px" , backgroundColor : "white", fontSize : "1rem", marginTop : "20px"}}
                                            onChange={(e) => {
                                              dispatch(
                                                handleChangeFilterValue({
                                                  key: "sort",
                                                  value: e.target.value,
                                                })
                                              );
                                              setMobilesorting(false)
                                            }}
                                          >
                                          <option value={"1/0"}>
                                            {t("Price Low to High")}
                                          </option>
                                          <option value={"1/1"}>
                                            {t("Price High to Low")}
                                          </option>
                                          <option value={"2/1"}>
                                            {t("Model Year High to Low")}
                                          </option>
                                          <option value={"2/0"}>
                                            {t("Model Year Low to High")}
                                          </option>
                                          <option value={"-/1"}>
                                            {t("Most Recently Updated")}
                                          </option>
                                    </select> 
                                    
                                    
                  </div>
                  
              </Modal>

                {!isTabletOrMobile ? 
                 <div style={{backgroundColor : "#f1f1f1"}}>
                     <div className={selected_lang === "en" ? "vttop_bar marginenn" : "vttop_bar marginarr" }> 
                    <a
                        target="_blank"
                        href={process.env.REACT_APP_FACEBOOK_URL}
                        rel="noreferrer"
                      >
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/facebook_.webp`} alt="facebook_icon" />
                    </a>
                    <a
                    target="_blank"
                    href={process.env.REACT_APP_INSTAGRAM_URL}
                    rel="noreferrer"
                  >
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/instagram_.webp`} alt="instagram_icon" />
                    </a>
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_TIKTOK_URL}
                      rel="noreferrer"
                    >
                        <img style={{width : "1.75vw", height :"3.5vh", marginTop : "2px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/tiktok.webp`} alt="tiktok_icon"  />
                    </a>
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_SNAPCHAT_URL}
                      rel="noreferrer"
                    >
                    <img  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/snapchat_.webp`} alt="snapchat_icon" />
                    </a>
                </div>
        
            
                <div  className={selected_lang === "en" ? "vttop_bar_r marginen" : "vttop_bar_r marginar" } style={{cursor : "default", float : selected_lang === "en" ? "right" : "left"}}> 
  {/** className="qs" */} 
                    <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/globe.png`} alt="Globe"  /><span className={selected_lang === "en" ? "marginen25" : "margin25"}>{t("topbar_uae")}</span> {/**  <span className="popover">Select City from here </span> */} </div>
                    <div onClick={() => _handleChangeCurrency()}><img  style={{width : "25px", height:  "25px", marginTop : "2px" }} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Currency.png`} alt="Currency icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25" }>
                    {selected_curr === "AED" ? 
                    t("AED")
                   : 
                   t("USD")
                  }
                      </span></div>
                    <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span></div>
                    <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/App.png`} alt="App Icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>Download Our Mobile App</span>
                    
                    </div>
                    
                </div>
                 </div>
                  :
                  <div>
                
                      {/** className="qs" ,  <div style={{backgroundColor : "black", height : "40px"}}></div> */}
                    <div  className="vttop_bar_r_m" style={{width : "100%", minWidth : "100%", backgroundColor : "#f1f1f1", borderBottom : "3px solid orange"}}> 
                    <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/globe.png`} alt="Globe"  /><span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "margin25"}>
                          {t("topbar_uae")}
                        
                      </span>
                    </div>
                    <div onClick={() => _handleChangeCurrency()} >
                      <img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Currency.png`} alt="Currency icon" />
                      <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>
                    {selected_curr === "AED" ? 
                            t("AED")
                          : 
                          t("USD")
                          }
                      </span></div>
                    <div >
                      <img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span>
                    </div>

                    <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/App.png`} alt="App Icon" />
                    <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>MOBILE APP</span></div>
                    </div>
                    <div className="respbuttons z-50" style={{left : "43%"}} >
                                          
                                          {mobilefilterx()}
                                          
                             </div>
                  </div>  
                }

            {!isTabletOrMobile ? 
            <>
    {/** MENU AREA */}        
            <div>
            <Menu_vtwo 
                user={user} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} isTabletOrMobile={isTabletOrMobile} search={search}
                _handleSearch={_handleSearch} setLoginModel={setLoginModel} brandsr={brandsr} aboutusr={aboutusr} navlink1={navlink1} navlink2={navlink2} navlink3={navlink3} t={t}
                setCommingSoon={() => {}} showsearch={"1"} changetype={() => {}} selected_lang={selected_lang}
            />
            </div>
            
    {/** MENU AREA */}        

            <div className="homepremiumcolors aboutusdiv2" style={{color : "white"}}>
                <center>
                    <h4 className="montserrat-400">{t("VNMRENTALMARKETPLACE")}</h4>
                    <p style={{fontSize:  "0.8em"}} className="montserrat-400">{t("thebrand2")}</p>
                </center>
           </div>
  
            <div style={{marginTop : "4vh", justifyContent : "center", marginBottom : "4vh"}}>
                <center>
                <h6 className="montserrat-400" style={{fontWeight : "bold"}}>{t("Active Listings")}</h6>
                <p className="montserrat-400" style={{fontSize:  "0.8em"}}>{t("thebrand4")}</p>
                </center>
            </div>

            <center>
                <div style={{width : "80%"}}> 
                    <div style={{height : "8vh", backgroundColor : "orange", width : "100%"}}>
                      {xtr === "listings" && 
                        
                        <>
                          <label style={{ float : "left", padding : "2.5vh", fontSize : "0.8rem", color : "white"}}>{t("Sort By")}</label>              
                          <select
                                  value={filters?.sort}
                                  id=""
                                  
                                  style={{ float :"left",  color : "gray",  width : "200px" , backgroundColor : "white", fontSize : "0.7rem", marginTop : "20px", marginLeft : "20px"}}
                                  onChange={(e) => {
                                    dispatch(
                                      handleChangeFilterValue({
                                        key: "sort",
                                        value: e.target.value,
                                      })
                                    );
                                  }}
                                >
                                 <option value={"1/0"}>
                                  {t("Price Low to High")}
                                </option>
                                <option value={"1/1"}>
                                  {t("Price High to Low")}
                                </option>
                                <option value={"2/1"}>
                                  {t("Model Year High to Low")}
                                </option>
                                <option value={"2/0"}>
                                  {t("Model Year Low to High")}
                                </option>
                                <option value={"-/1"}>
                                  {t("Most Recently Updated")}
                                </option>
                          </select> 
                       
                       </>
                    }
                          
                          
                    </div>
                    <div style={{height : "1vh", backgroundColor : "white" , width : "100%"}}>
                        
                    </div> 

                    <div style={{display : "flex"}} className="montserrat-400">
                        
                        <div style={{width : "30%", backgroundColor : "#2b499a", color : "white" }}>
                          <div style={{marginLeft : "-0.5vw"}}>
                          <h1 className="font-light mb-1 ml-7" style={{fontWeight : "bold", textAlign : selected_lang === "en" ?  "left" : "right", fontSize : "1rem", left : selected_lang === "en" ? "1vw" : "0px" , marginRight : selected_lang === "en" ? "0px" : "2vw"}}>{t("Filter_inlisting")}</h1>
                          <p className="font-light mb-1 ml-7 " style={{ marginTop : "-15px", textAlign : selected_lang === "en" ?  "left" : "right", fontSize : "0.7rem" , left : selected_lang === "en" ? "10px" : "0px" , marginRight : selected_lang === "en" ? "0px" : "2vw"}}>{t("Find My Perfect Ride")}</p>
                          </div>

                            {type === "1" && carfilter()}  
                            {type === "2" && machinefilter()}  
                            {type === "3" && otherfilter()}  
                         
                        </div>  
                        
                        <div style={{width : "80%"}}>
                            {type === "1"  &&
                            <center >
                             <div style={{ padding : "15px"}}>   
                             {showProcessing ? 
                            <Lottie
                            options={{
                            loop: true,
                            autoplay: true,
                            animationData: LoadingAnimation,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                            }}
                            height={300}
                            width={300}
                        /> :
                            <Suspense fallback={
                                <Lottie
                                options={{
                                loop: true,
                                autoplay: true,
                                animationData: LoadingAnimation,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                                }}
                                height={300}
                                width={300}
                            /> 
                            } >

                            <Homepremiumlisting selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                            _handleListingView={_handleListingView} listRef0={listRef0} _handleSaveCount={_handleSaveCount} 
                            premium_listings={premium_listings} user={user} user_profile={user_profile} isTabletOrMobile={isTabletOrMobile}
                            showcall={showcall} _handleWaCount={_handleWaCount} favProduct={favProduct} verified={verified}
                            typeis = {xtr} listings={listingsx} id = {1} setShowcall={setShowcall} filters = {filters}
                            />
                            </Suspense>
                            }
                             
                             </div>
                             {(xtr === "listings" && !loading_listings)  && (
                                <section>
                                {Pagination({
                                    totalItems: listingcountx,
                                    pageSize: limit,
                                    currentPage: page,
                                    onPageChange: _handlePagination,
                                })}
                                </section>
                            )}
                             </center>
                            }
                             {type === "2"  &&
                            <center >
                             <div style={{ padding : "15px"}}>   
                             {showProcessing ? 
                            <Lottie
                            options={{
                            loop: true,
                            autoplay: true,
                            animationData: LoadingAnimation,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                            }}
                            height={300}
                            width={300}
                        /> :
                            <Suspense fallback={
                                <Lottie
                                options={{
                                loop: true,
                                autoplay: true,
                                animationData: LoadingAnimation,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                                }}
                                height={300}
                                width={300}
                            /> 
                            } >
                            
                            <Homepremiumlisting selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                            _handleListingView={_handleListingView} listRef0={listRef0} _handleSaveCount={_handleSaveCount} 
                            premium_listings={premium_listings} user={user} user_profile={user_profile} isTabletOrMobile={isTabletOrMobile}
                            showcall={showcall} _handleWaCount={_handleWaCount} favProduct={favProduct} verified={verified}
                            typeis = {xtr} listings={listingsx} id = {2} setShowcall={setShowcall} filters = {filters}
                            />

                            </Suspense>
                            }
                             
                             </div>
                             { (xtr === "listings" && !loading_listings)  && (
                                <section>
                                {Pagination({
                                    totalItems: listingcountx,
                                    pageSize: limit,
                                    currentPage: page,
                                    onPageChange: _handlePagination,
                                })}
                                </section>
                            )}
                             </center>
                            }
                             {type === "3"  &&
                            <center >
                             <div style={{ padding : "15px"}}>   
                             {showProcessing ? 
                            <Lottie
                            options={{
                            loop: true,
                            autoplay: true,
                            animationData: LoadingAnimation,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                            }}
                            height={300}
                            width={300}
                        /> :
                            <Suspense fallback={
                                <Lottie
                                options={{
                                loop: true,
                                autoplay: true,
                                animationData: LoadingAnimation,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                                }}
                                height={300}
                                width={300}
                            /> 
                            } >
                            <Homepremiumlisting selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                            _handleListingView={_handleListingView} listRef0={listRef0} _handleSaveCount={_handleSaveCount} 
                            premium_listings={premium_listings} user={user} user_profile={user_profile} isTabletOrMobile={isTabletOrMobile}
                            showcall={showcall} _handleWaCount={_handleWaCount} favProduct={favProduct} verified={verified}
                            typeis = {xtr} listings={listingsx} id = {3}
                            />
                            </Suspense>
                            }
                             
                             </div>
                             {(xtr === "listings" && !loading_listings) && (
                                <section>
                                {Pagination({
                                    totalItems: listingcountx,
                                    pageSize: limit,
                                    currentPage: page,
                                    onPageChange: _handlePagination,
                                })}
                                </section>
                            )}
                             </center>
                            }   
                        </div>  

                        <div style={{width : "20%"}}>
                            <img src={sideone} alt="verified" width={"100%"} />
                        </div>  
                    </div>
                </div>    
            </center>
            
            <div ref={appRef}>    
            <Userapp t ={t} selected_lang={selected_lang} iswhitea={"Applestore_b.webp"} iswhiteg={"googleplay_b.webp"}  />    
            </div>
            <div>
            
            <Footer selected_lang={selected_lang} />
            </div>
            </>
            :
            <>
              <div className="z-10">
                      
            
                <div style={{display : "flex", width : "100%"}}>
                       <div style={{width  :"50%", minWidth : "50%"}} >
                                                    <a 
                                                    href = "/car" >
                                                    <img
                                                            className="ml-2 mt-2"
                                                              width = "140"
                                                              height = "120"
                                                            style={{ float :"left", padding : "0.5vw" }}
                                                            src={
                                                                process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.webp"
                                                            }
                                                            alt="logo"
                                                            />
                                                    </a>
                      </div>          
                                    {/** Mobile Menu */}            
                      <div style={{display : "flex" , justifyContent : "right", width : "50%", minWidth :"50%"}} >
                                                        <div className="mt-1 mr-2" >
                                                        <RxHamburgerMenu className="text-2xl mt-4"  onClick={x => navigate('/car')}/>       
                                                        </div>
                                                        <div style={{marginTop :  "15px"}} className="mr-2" >
                                                        {!user ? (
                                                            <button
                                                                type="button "
                                                                onClick={() => {
                                                                setLoginModel(true);
                                                                setIsMenuOpen(false);
                                                                }}
                                                                style={{  fontSize : "0.7rem" , height : "4vh", width : "20vw" , backgroundColor : "orange" , fontWeight : "bold", borderRadius : "10px" }}
                                                                className="mt-1"
                                                            >
                                                                <center >
                                                                    {t("LOGIN")}{" "}
                                                                </center>
                                                            </button>
                                                            ) : (
                                                            <>
                                                                <button
                                                                type="button"
                                                                onClick={() => {
                                                                    
                                                                    if(window.location.href.includes("profile")){
                                                                    dispatch(logoutUser());
                                                                    }else{
                                                                    navigate("/profile");
                                                                    setIsMenuOpen(false);
                                                                    }
                                                                    
                                                                }}
                                                                style={{ marginTop: -5, fontSize : "0.7rem" , height: "40px", borderRadius : "25px"}}
                                                                className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10"
                                                                >
                                                                { window.location.href.includes("profile") ? "Logout" :user?.fn}
                                                                </button>
                                                            </>
                                                          )}
                                                        </div>             
                                                  
                      </div>
                </div>

                <div style={{backgroundImage:'linear-gradient(to right,#16254E , #3355B4)',height:'8vh',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',textAlign:'center',gap:'2px',borderBottom:'4px solid orange', marginTop  :"1vh"}}>
                <h1 style={{fontSize:'0.8rem',margin:'0',color:'white',fontFamily:'Montserrat'}}>{t("VNMRENTALMARKETPLACE")}</h1>
                <p style={{fontSize:'0.7rem',margin:'0',color:'white'}}>{t("thebrand2")}</p>
                </div>
          
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',textAlign:'center'}}>
                <h1 style={{fontSize:'1rem',fontFamily:'Montserrat',fontWeight:'600'}}>{t("thebrand3")}</h1>
                <p style={{fontSize:'0.55rem',fontFamily:'Montserrat',fontWeight:'300'}}>{t("thebrand4")}</p>
              </div>

              <p >
                                                              <div className="mt-3" style={{display : "flex", width : "90%", justifyContent : "center"}} >
                                                              <input type="text"  
                                                              className="shadow"
                                                              placeholder={t("Find My Perfect Ride")} 
                                                              style={{fontSize : "0.6rem", width : "100%", padding: "5px 5px", lineHeight: "28px",  border: "2px solid blue"}}
                                                              //onChange={(e) => _handleSearchText(e)}
                                                              onKeyDown={(x) => {
                                                                if(x.key === "Enter"){
                                                                  //_handleSearchm()
                                                                  _handleSearchText(x)
                                                                }
                                                              }}
                                                              onBlur={() => {
                                                                if(search === "") {
                                                                  _handleClearAllFilters()
                                                                } 
                                                              }}
                                                              
                                                              />
                                                              <span  style={{width : "20%" , marginLeft : "-28%" , marginTop  : "12px", fontSize : "0.5rem"}} >{t("QuickSearch")}</span>
                                                              <span  style={{width : "2%", marginTop  : "10px"}}><img style={{width : "80%" , minWidth : "20px", backgroundColor : "orange", borderRadius : "5px", padding : "2px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/search_.png`} alt="facebook_icon" 
                                                              onClick={(x) => _handleSearchText(x)}
                                                              /></span>
                                                              </div>
              </p>
            <div style ={{paddingLeft : "20px",  marginTop : "0.9vh", fontSize : "0.6rem", width : "100%"}} className="montserrat-400">                                                 
  {/** Mobile view total listings */}                  
                  <p style={{fontWeight : "bold"}} >{listingcountx} {t("Active Listings")}</p>
                  <div style={{display: "flex" , gap : "10vw", fontSize : "0.8rem" , width : "95%" , marginTop : "0.5vh" }} >
  {/** Mobile view filter Button */}                  
                    <div style={{display : "flex", gap : "10px" }} onClick={c => setMobileFilterIsOpen(true)} >
                        <img width="20vw" style={{height : "2vh"}} onClick={c => {setMobileFilterIsOpen(true);  }}   src={`${process.env.REACT_APP_PUBLIC_URL}/images/sort.webp`} alt="sort_img" />
                        <span>{t("Filter_inlisting")}</span>
                    </div>
  {/** Mobile view Sort Button */}                      
                    <div style={{display : "flex" , gap : "10px"}} onClick={h =>  setMobilesorting(true)} >
                        <img width="20vw" style={{height : "2vh"}}  src={`${process.env.REACT_APP_PUBLIC_URL}/images/filter.webp`} alt="filter_img" />
                        <span>{t("Sort By")}</span>
                    </div>
  {/** Mobile clear filter Button */}    
                    <div onClick={x => _handleClearAllFilters()} style={{ right : "10vw", display : "flex" , gap : "10px"}} >
                        <img width="20vw" style={{height : "2vh"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/bin_icon.webp`} alt="filter_img" />
                        <span>{t("CLEARALLFILTERS")}</span>
                    </div>
                  </div>                                                
              </div>
            <div >
                {/** Items */}
                                                              
                <div style={{ display: "flex", flexDirection: "row",backgroundColor:'#f1f1f1', marginTop  : "3vh" }}>
                  <div style={{borderRadius: "10px"}}>
                        
                  {showProcessing ? 
                              <Lottie
                              options={{
                              loop: true,
                              autoplay: true,
                              animationData: LoadingAnimation,
                              rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                              },
                              }}
                              height={300}
                              width={300}
                          /> :
                              <Suspense fallback={
                                  <Lottie
                                  options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: LoadingAnimation,
                                  rendererSettings: {
                                      preserveAspectRatio: "xMidYMid slice",
                                  },
                                  }}
                                  height={300}
                                  width={300}
                              /> 
                              } >
                        
                          <Homepremiumlistingm selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                              _handleListingView={_handleListingView} listRef0={listRef0} _handleSaveCount={_handleSaveCount} 
                              premium_listings={premium_listings} user={user} user_profile={user_profile} isTabletOrMobile={isTabletOrMobile}
                              showcall={showcall} _handleWaCount={_handleWaCount} favProduct={favProduct} verified={verified}
                              typeis = {xtr} listings={listingsx} id = {1} setShowcall={setShowcall} type={type}  
                              filters = {filters}
                              />
                          
                          </Suspense>}
                          {(xtr === "listings" && !loading_listings) && (
                                  <section style={{marginBottom : "2vh"}}>
                                  {Pagination({
                                      totalItems: listingcountx,
                                      pageSize: limit,
                                      currentPage: page,
                                      onPageChange: _handlePagination,
                                  })}
                                  </section>
                              )}                       
                  </div>
                                                      
                </div>                                              

              </div>                                                 
              <div style={{height : "150px", paddingTop  : "30px"}}  ref={appRef}>
              <center>
              <Userappm t={t}  iswhitea = {"Applestore_b.webp"} iswhiteg = {"googleplay_b.webp"} />       
              </center>
              
              </div>
              <div style={{paddingBottom : "50px" , backgroundColor : "#16254e"}}>
                <Footerm selected_lang={selected_lang} navigate={navigate} />
              </div>
            
              </div>
            </>

          }
          
       
                    
        </div>
    );
}

export default Vnmlisting;
