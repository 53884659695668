import { useEffect } from "react";
import {  useSelector } from "react-redux";
// import { NavLink } from "react-router-dom";

const Navbar = () => {

  const {
    accessToken,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (accessToken) {
    }

  }, []);


  return (
    <header>
    </header>
  );
};

export { Navbar };
