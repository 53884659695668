import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import {
  favProduct
} from "../../../store/listings/listingActions";
import { FaHeart, FaRegHeart } from "react-icons/fa";

import Lottie from "react-lottie";
import LoadingAnimation from "../../../assets/lottie/loading_animation.json";
import verified from "../../../assets/images/smicons/verified.png";
import Road from "../../../assets/images/smicons/24.jpg";
import '../../../assets/vnmstyles.scss'

const Homecarp = (props) => {
  const dispatch = useDispatch();
   
  const { user, user_profile } = useSelector(
    (state) => state.auth
  );

  const {
    filters,
    listings,
    loading_listings,
   } = useSelector((state) => state.listing);


  useEffect((props) => {
      let sorty = filters.sort
      let sortt = ["1", "0"] 
      sortt = sorty.split("/")
      
      if( props?.typeis === "Listings2"){
          props.getsplist("city", filters.citycode, filters.sort.split("/")[1] , filters.sort.split("/")[0])
      }else if (props?.typeis === "Listings1"){
          props.getsplist("type", filters.typecode, sortt[1] , sortt[0])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]) 

  const showcars = () => {
    
      return (
        <div style={{display : "flex", width : "100%"}}>
                        {loading_listings ? 
                        <Lottie
                            options={{
                            loop: true,
                            autoplay: true,
                            animationData: LoadingAnimation,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                            }}
                            height={300}
                            width={300}
                        />:
                        
                        <>
                         <div style={{width : "20%"}} className="xwrapper">
                            <div className="xcard">
                            <button style={{color : "white", fontWeight :"bold", fontSize : "3rem"}} onClick={i => {
                              
                              if(props.typeis === "Listings1"){
                                if( Number(props.numb) !== 0  ){
                                  props.setNumb(Number(props.numb) -1)   
                                }   
                              }else{
                                if( Number(props.numb) !== 0  ){
                                  props.setNumb(Number(props.numb) -1)   
                                }
                              }

                            }}> 
                            <div className="sidearrow"> <img alt="sidearrowl" src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/64_w.webp`}  /> </div>
                            </button>
                            </div>
                        </div>
                            <div style={{ display : "flex"}} >
                           
                            {props.cwList?.slice(Number(props?.numb) , Number(props?.numb) + 4)?.map((tx,h) => {
                             
                             return(
                                <div className="fazeel_vnmcard boxaf" >
                                <div className="fazeel_vnmcard_image">
                                <div className="containerpremium">
                                    <div className="overlaypremiumverified">
                                    <div>
                                        {user && user_profile?.usertags?.includes(tx?.tluid) ? (
                                          <button
                                            onClick={() => {
                                              user !== null &&
                                                dispatch(
                                                  favProduct({
                                                    tluid: tx?.tluid,
                                                    typed: "pull",
                                                  })
                                                );
                                            }}
                                            aria-label="heart"
                                          >
                                            <FaHeart
                                              color="red"
                                              size={"28"}
                                            />
                                          </button>
                                        ) : (
                                          <button
                                          aria-label="Heart"  
                                          onClick={() => {
                                              user !== null &&
                                                dispatch(
                                                  favProduct({
                                                    tluid: tx?.tluid,
                                                    typed: "push",
                                                  })
                                                );
                                            }}
                                          >
                                            <FaRegHeart
                                              color="red"
                                              size="28"
                                            />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                
                                      <div className="overlaypremiumbrand">
                                        {tx?.tenant?.cellIsVerified &&
                                          tx?.tenant?.addressIsVerified && (
                                            <img
                                              src={verified}
                                              alt="verified"
                                              style={{ width: "25px" }}
                                            />
                                          )}
                                      </div>
                                      <div className="overlaypremiumprice">
                                     {/**    <img
                                          src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${tx?.blogo}`}
                                          alt="Brands"
                                          width="50px"
                                        /> */}
                                      </div>
                                    <img
                                        className="rounded-t-lg" 
                                        src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/listing/${tx?.imgmc}`}
                                        alt="imgmc"
                                        height="400" width="400"
                                        onClick={p => {
                                          props._handleListingView(tx)
                                        }} 
                                  
                                  />
                                 </div>
                                </div>
                                <div className="fazeel_vnmcard_detail" style={{width : "100%" , height : "200px" , maxHeight : "200px"}}>
{/**TOP DIV */}                                  
                                              <div className="fazeel_vnmcard_detail_box1" style={{width : "100%" , height : "25%" , maxHeight : "25%" }}>
                                                <div className="fazeel_acura">
                                                    <p>
                                                    {tx?.bname?.[props?.selected_lang]} {' '}  
                                                    {tx?.mname?.[props?.selected_lang]}
                                                    <br />
                                                    <span>{tx?.modelyear?.label}</span>
                                                    </p>
                                                </div>
                                                
                                                <div className="fazeel_aed" style={{width : "50%" , display : "flex", textAlign : "left"}}>
                                                    <img src={Road} alt="vnmRoad" style={{width : "2vw" , height : "3vh"}} />
                                                    <p style={{fontSize :"0.7rem"}}>
                                                    {props.t(`${props.selected_curr}`)}{parseFloat(Number(tx?.daily?.amt) / parseFloat(props.conversion_rate)).toFixed(0)}{" "}
                                                        <br />
                                                        <span style={{fontSize :"0.5rem"}} >{tx?.daily?.mileq}
                                                        {props.t("mileqq")}</span>
                                                    </p>
                                                </div>
                                                
                                            </div>
{/**Features  DIV */}                                  
                                  <div className="fazeel_vnmcard_detail_box2" style={{width : "100%" , height : "20%" , maxHeight : "20%" , marginBottom : "15px"}}>
                                  
                                  {tx?.features?.find((x) => x.featureCode === "40")
                                                ?.ficon && (
                                                    <>
                                                <div style={{marginRight : "5px", display : "flex"}}>
                                                <img
                                                    style={{ height: "20px", width: "20px" }}
                                                    src={`${process.env.REACT_APP_API_FS}${
                                                    process.env.REACT_APP_API_URL
                                                    }/fet/${
                                                    tx?.features.find((x) => x.featureCode === "40")
                                                        ?.ficon
                                                    }`}
                                                    alt="feature"
                                                /><span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features.find((x) => x.featureCode === "40").rangevalueL}</span>
                                                </div>
                                                <div style={{marginRight : "5px" , display : "flex", border : "1px solid gray" , borderRadius : "10px"}}>
                                                <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.7rem" , color : "black"}}>
                                                    {tx?.btname[props.selected_lang]}</span>
                                                </div>
                                            
                                                </>
                                                )}
                                  </div>
{/**ModelYear Deposit  DIV */}                                      
                                  <div className="fazeel_vnmcard_detail_box3" style={{width : "100%" , height : "30%" , maxHeight : "30%"}}>
                                  <div style={{ alignItems : "flex-start"}}>
                                     <div style={{width : "100%" }}>
                                     <img
                                          style={{marginLeft  : "-1.75vw", width : "3vw"}}
                                          src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/${tx?.tenant.cLogo}`}
                                          alt="Business Logo"
                                          width="30" height="30"
                                        />
                                       
                                      </div>   
                                       <div
                                          style={{ display: "flex", marginRight: "5px" }}
                                          onClick={() => props._handleListingView(tx)}
                                        >
                                          {Array.from({
                                            length: parseInt(tx?.tenant.rating) || 0,
                                          }).map((j, index1) => {
                                            return (
                                              <svg
                                                key={`filled-${h}=${index1}`}
                                                className="w-6 h-6 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#F39C12"
                                                viewBox="0 0 60 55"
                                                width="50"
                                                height="25"
                                                style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                              >
                                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                            );
                                          })}
                                          {parseFloat(tx?.tenant.rating) > parseInt(tx?.tenant.rating) && (
                                            <div style={{ position: "relative" }}>
                                              <svg
                                                key={`unfilled-1212`}
                                                className="w-6 h-6 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                style={{
                                                  position: "absolute",
                                                  paddingRight: "-15px",
                                                  marginRight: "-15px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="grey"
                                                viewBox="0 0 60 55"
                                                width="50"
                                                height="25"
                                              >
                                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                              <svg
                                                style={{
                                                  position: "absolute",
                                                  paddingRight: "-15px",
                                                  marginRight: "-15px",
                                                }}
                                                key={`unfilled-12`}
                                                className="w-6 h-6 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#F39C12"
                                                viewBox="0 0 60 55"
                                                width="50"
                                                height="25"
                                              >
                                                <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                              </svg>
                                            </div>
                                          )}
                                          {Array.from({
                                            length: 5 - parseInt(tx?.tenant.rating),
                                          }).map((k, index2) => {
                                            return (
                                              <>
                                                <svg
                                                  key={`unfilled-${h}-${index2}`}
                                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                                  aria-hidden="true"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="grey"
                                                  viewBox="0 0 60 55"
                                                  width="50"
                                                  height="25"
                                                  style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                                >
                                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                </svg>
                                              </>
                                            );
                                          })}
                                        </div>
                                      
                                      </div>
                                      <div style={{textAlign : "left", width : "100%", marginTop  : "-20px"}}>
                                       <p>{ props.t("WITHINSURANCE") }
                                       <br/> {props.t("SecurityDeposit")}: {props.t(`${props.selected_curr}`)} {parseFloat(Number(tx?.securitydeposit) / (props.conversion_rate)).toFixed(0)}
                                       </p>
                                      </div>
                                  </div>
{/**Buttons  DIV */}                                      
                                  <div className="fazeel_vnmcard_detail_box4 " style={{width : "100%" , height : "15%" , maxHeight : "15%"}} >
                                     
                                          <button
                                            style={{ fontSize: "0.5rem", color: "black" }}
                                            onClick={() => {
                                              props._handleSaveCount(tx, 2);
                                          //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                            }}
                                          >
                                          
                                            {props.showcall?.id === tx.tluid && props.showcall.boolv === true ? (
                                              <center>{tx?.tenant?.cellNumberc}</center>
                                            ) : (
                                              <img
                                                src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                alt="abc"
                                              />
                                            )}
                                          </button>
                                        
                                    <div className="fazeel_line"></div>
                                        <button
                                        onClick={() => {props._handleWaCount(tx, "1"); props.setShowcall({ ...props.showcall, id: tx.tluid, boolv: false, boolw : true })}}
                                        > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                                    <div className="fazeel_line"></div>
                                       <button onClick={() => props._handleListingView(tx)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                                  </div>
                                </div>
                                </div>
                              )
                          }) 
                            }
                        </div>
                        <div style={{width : "15%"}} className="xwrapper ">
                        <div className="xcard ">
                            <button style={{color : "white", fontWeight :"bold", fontSize : "3rem"}}  
                            onClick={i => {
                                  
                                    
                                    if((Number(props.cwList?.length)) - (Number(props.numb)+1) >= 4){
                                      props.setNumb(Number(props.numb) +1)   
                                     }
                                 
                                    
                                    
                                
                                }}> <div className="sidearrow"> <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/65_w.webp`} /> </div>
                            </button>
                            </div>
                        </div>
                        </>}
            </div>
      )
  }


    const showmachines = () => {
      return (
        <div style={{display : "flex", width : "100%"}}>
        {loading_listings ? 
        <Lottie
            options={{
            loop: true,
            autoplay: true,
            animationData: LoadingAnimation,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
            }}
            height={300}
            width={300}
        />:
        
        <>
        <div style={{width : "20%"}} className="xwrapper">
            <div className="xcard">
            <button style={{color : "white", fontWeight :"bold", fontSize : "3rem"}} onClick={i => {
              
              if(props.typeis === "Listings1"){
                if( Number(props.numb) !== 0  ){
                  props.setNumb(Number(props.numb) -1)   
                }   
              }else{
                if( Number(props.numb) >= 8  ){
                  props.setNumb(Number(props.numb) -1)   
                }
              }
  
            }}> 
            <div className="sidearrow"> <img alt="sidearrowl" src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/64_w.webp`}  /> </div>
            </button>
            </div>
        </div>
            <div style={{ display : "flex"}} >
            { props?.cwList && props.cwList?.data?.slice(Number(props?.numb) , Number(props?.numb) + 4)?.map((tx,h) => {
              return(
                <div className="fazeel_vnmcard" >
                <div className="fazeel_vnmcard_image">
                <div className="containerpremium">
                    <div className="overlaypremiumverified">
                    <div>
                        {user && user_profile?.usertags?.includes(tx?.tluid) ? (
                          <button
                            onClick={() => {
                              user !== null &&
                                dispatch(
                                  favProduct({
                                    tluid: tx?.tluid,
                                    typed: "pull",
                                  })
                                );
                            }}
                            aria-label="heart"
                          >
                            <FaHeart
                              color="red"
                              size={"28"}
                            />
                          </button>
                        ) : (
                          <button
                          aria-label="Heart"
                            onClick={() => {
                              user !== null &&
                                dispatch(
                                  favProduct({
                                    tluid: tx?.tluid,
                                    typed: "push",
                                  })
                                );
                            }}
                          >
                            <FaRegHeart
                              color="red"
                              size="28"
                              
                            />
                          </button>
                        )}
                      </div>
                    </div>
                
                      <div className="overlaypremiumbrand">
                        {tx?.tenant?.cellIsVerified &&
                          tx?.tenant?.addressIsVerified && (
                            <img
                              src={verified}
                              alt="verified"
                              style={{ width: "25px" }}
                            />
                          )}
                      </div>
                      <div className="overlaypremiumprice">
                     {/**    <img
                          src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${tx?.blogo}`}
                          alt="Brands"
                          width="50px"
                        /> */}
                      </div>
                    <img
                        className="rounded-t-lg" 
                        src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/listing/${tx?.imgmc}`}
                        alt="imgmc"
                        height="400" width="400"
                        onClick={p => {
                          props._handleListingView(tx)
                        }} 
                  
                  />
                </div>
                </div>
                <div className="fazeel_vnmcard_detail" style={{width : "100%" , height : "200px" , maxHeight : "200px"}}>
    {/**TOP DIV */}                                  
                  <div className="fazeel_vnmcard_detail_box1" style={{width : "100%" , height : "30%" , maxHeight : "30%" , marginTop : "-10px"}}>
                      <div className="fazeel_acura">
                          <p>
                          {tx?.mname?.[props?.selected_lang]}
                          <br />
                          <span>{tx?.modelyear?.label}</span>
                        </p>
                      </div>
                      
                      <div className="fazeel_aed" style={{display : "flex"}}>
                          
                          <p>
                          {props.t(`${props.selected_curr}`)}&nbsp; {parseFloat(Number(tx?.hourly?.amt) / parseFloat(props.conversion_rate)).toFixed(0)}{" "}
                            <br />
                            <span>
                              {props.t("PerHour")}</span>
                          </p>
                      </div>
                    
                  </div>
    {/**Features  DIV */}                                  
                  <div className="fazeel_vnmcard_detail_box2" style={{width : "100%" , height : "20%" , maxHeight : "20%" , marginBottom : "15px"}}>
                  
                  {tx?.features[0]
                      ?.ficon && (
                        <>
                      <div style={{marginRight : "5px", display : "flex"}}>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={`${process.env.REACT_APP_API_FS}${
                          process.env.REACT_APP_API_URL
                        }/fet/${
                          tx?.features[0]
                            ?.ficon
                        }`}
                        alt="feature"
                      />
                      <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features[0][props.selected_lang]}</span>
                      <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features[0].rangevalueL}</span>
                      </div>
                    
                      </>
                    )}
                  </div>
    {/**ModelYear Deposit  DIV */}                                      
                  <div className="fazeel_vnmcard_detail_box3" style={{width : "100%" , height : "30%" , maxHeight : "30%"}}>
                  <div style={{width : "50%", justifyContent : "left"}}>
                  <div style={{width : "100%" }}>
                                     <img
                                          style={{marginLeft  : "-1.75vw", width : "3vw"}}
                                          src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/${tx?.tenant.cLogo}`}
                                          alt="Business Logo"
                                          width="30" height="30"
                                        />
                                       
                                      </div>   
                      <div
                          style={{ display: "flex", marginRight: "5px" }}
                          onClick={() => props._handleListingView(tx)}
                        >
                          {Array.from({
                            length: parseInt(tx?.tenant.rating) || 0,
                          }).map((j, index1) => {
                            return (
                              <svg
                                key={`filled-${h}=${index1}`}
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#F39C12"
                                viewBox="0 0 60 55"
                                width="50"
                                height="25"
                                style={{ paddingRight: "-15px", marginRight: "-15px" }}
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            );
                          })}
                          {parseFloat(tx?.tenant.rating) > parseInt(tx?.tenant.rating) && (
                            <div style={{ position: "relative" }}>
                              <svg
                                key={`unfilled-1212`}
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                style={{
                                  position: "absolute",
                                  paddingRight: "-15px",
                                  marginRight: "-15px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="grey"
                                viewBox="0 0 60 55"
                                width="50"
                                height="25"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg
                                style={{
                                  position: "absolute",
                                  paddingRight: "-15px",
                                  marginRight: "-15px",
                                }}
                                key={`unfilled-12`}
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#F39C12"
                                viewBox="0 0 60 55"
                                width="50"
                                height="25"
                              >
                                <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                              </svg>
                            </div>
                          )}
                          {Array.from({
                            length: 5 - parseInt(tx?.tenant.rating),
                          }).map((k, index2) => {
                            return (
                              <>
                                <svg
                                  key={`unfilled-${h}-${index2}`}
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="grey"
                                  viewBox="0 0 60 55"
                                  width="50"
                                  height="25"
                                  style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                >
                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                </svg>
                              </>
                            );
                          })}
                        </div>
                      
                      </div>
                      <div style={{float : "right", width : "50%", marginTop  : "-20px"}}>
                      <p>{props.t("MODEL")}: {tx?.modelyear?.label}<br/>{ props.t("WITHINSURANCE")}<br/> {props.t("SecurityDeposit")}: {props.t(`${props.selected_curr}`)} {parseFloat(Number(tx?.securitydeposit) / (props.conversion_rate)).toFixed(0)}</p>
                      </div>
                  </div>
    {/**Buttons  DIV */}                                      
                  <div className="fazeel_vnmcard_detail_box4 " style={{width : "100%" , height : "15%" , maxHeight : "15%"}} >
                          <button
                            style={{ fontSize: "0.5rem", color: "black" }}
                            onClick={() => {
                              props._handleSaveCount(tx, 2);
                          //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                            }}
                          >
                          
                            {props.showcall?.id === tx.tluid && props.showcall.boolv === true ? (
                              <center>{tx?.tenant?.cellNumberc}</center>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                alt="abc"
                              />
                            )}
                          </button>
                        
                    <div className="fazeel_line"></div>
                        <button
                        onClick={() => {props._handleWaCount(tx, "1"); props.setShowcall({ ...props.showcall, id: tx.tluid, boolv: false, boolw : true })}}
                        > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                    <div className="fazeel_line"></div>
                      <button onClick={() => props._handleListingView(tx)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                  </div>
                </div>
                </div>
              )
          }) 
            }
        </div>
        <div style={{width : "15%"}} className="xwrapper">
        <div className="xcard">
            <button style={{color : "white", fontWeight :"bold", fontSize : "3rem"}} onClick={i => {
                  
                  if(props.typeis === "Listings1"){
                    if((Number(listings.length) - 8) - (Number(props.numb)+1) >= 4){
                      props.setNumb(Number(props.numb) +1)   
                    }
                  }else{
                    if((Number(listings.length)) - (Number(props.numb)+1) >= 4){
                      props.setNumb(Number(props.numb) +1)   
                    }
                  }
  
                    
                    
                
                }}> <div className="sidearrow"> <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/65_w.webp`} /> </div>
            </button>
            </div>
        </div>
        </>}
    </div>
  )
  }


  const showyachts = () => {
    return (
                <div style={{display : "flex", width : "100%"}}>
                    {loading_listings ? 
                    <Lottie
                        options={{
                        loop: true,
                        autoplay: true,
                        animationData: LoadingAnimation,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                        },
                        }}
                        height={300}
                        width={300}
                    />:
                    
                    <>
                    <div style={{width : "20%"}} className="xwrapper">
                        <div className="xcard">
                        <button style={{color : "white", fontWeight :"bold", fontSize : "3rem"}} onClick={i => {
                          
                          if(props.typeis === "Listings1"){
                            if( Number(props.numb) !== 0  ){
                              props.setNumb(Number(props.numb) -1)   
                            }   
                          }else{
                         
                            if( Number(props.numb) >= 8  ){
                              props.setNumb(Number(props.numb) -1)   
                            }
                          }

                        }}> 
                        <div className="sidearrow"> <img alt="sidearrowl" src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/64_w.webp`}  /> </div>
                        </button>
                        </div>
                    </div>
                        <div style={{ display : "flex"}} >
                        { props?.cwList && props.cwList?.slice(Number(props?.numb) , Number(props?.numb) + 4)?.map((tx,h) => {
                          return(
                            <div className="fazeel_vnmcard boxaf" >
                            <div className="fazeel_vnmcard_image">
                            <div className="containerpremium">
                                <div className="overlaypremiumverified">
                                <div>
                                    {user && user_profile?.usertags?.includes(tx?.tluid) ? (
                                      <button
                                        onClick={() => {
                                          user !== null &&
                                            dispatch(
                                              favProduct({
                                                tluid: tx?.tluid,
                                                typed: "pull",
                                              })
                                            );
                                        }}
                                        aria-label="heart"
                                      >
                                        <FaHeart
                                          color="red"
                                          size={"28"}
                                        />
                                      </button>
                                    ) : (
                                      <button
                                      aria-label="Heart"
                                        onClick={() => {
                                          user !== null &&
                                            dispatch(
                                              favProduct({
                                                tluid: tx?.tluid,
                                                typed: "push",
                                              })
                                            );
                                        }}
                                      >
                                        <FaRegHeart
                                          color="red"
                                          size="28"
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                            
                                  <div className="overlaypremiumbrand">
                                    {tx?.tenant?.cellIsVerified &&
                                      tx?.tenant?.addressIsVerified && (
                                        <img
                                          src={verified}
                                          alt="verified"
                                          style={{ width: "25px" }}
                                        />
                                      )}
                                  </div>
                                  <div className="overlaypremiumprice">
                                  </div>
                                <img
                                    className="rounded-t-lg" 
                                    src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/listing/${tx?.imgmc}`}
                                    alt="imgmc"
                                    height="400" width="400"
                                    onClick={p => {
                                      props._handleListingView(tx)
                                    }} 
                              
                              />
                            </div>
                            </div>
                            <div className="fazeel_vnmcard_detail" style={{width : "100%" , height : "200px" , maxHeight : "200px"}}>
                {/**TOP DIV */}                                  
                              <div className="fazeel_vnmcard_detail_box1" style={{width : "100%" , height : "30%" , maxHeight : "30%" , marginTop : "-10px"}}>
                                  <div className="fazeel_acura">
                                      <p>
                                      {tx?.mname?.[props?.selected_lang]}
                                      <br />
                                      <span>{tx?.modelyear?.label}</span>
                                    </p>
                                  </div>
                                  
                                  <div className="fazeel_aed" style={{display : "flex"}}>
                                      
                                      <p>
                                      {props.t(`${props.selected_curr}`)}&nbsp; {parseFloat(Number(tx?.hourly?.amt) / parseFloat(props.conversion_rate)).toFixed(0)}{" "}
                                        <br />
                                        <span>
                                          {props.t("PerHour")}</span>
                                      </p>
                                  </div>
                                
                              </div>
                {/**Features  DIV */}                                  
                              <div className="fazeel_vnmcard_detail_box2" style={{width : "100%" , height : "20%" , maxHeight : "20%" , marginBottom : "15px"}}>
                              
                              {tx?.features?.find((x) => x.featureCode === "108")
                                  ?.ficon && (
                                    <>
                                  <div style={{marginRight : "5px", display : "flex"}}>
                                  <img
                                    style={{ height: "20px", width: "20px" }}
                                    src={`${process.env.REACT_APP_API_FS}${
                                      process.env.REACT_APP_API_URL
                                    }/fet/${
                                      tx?.features.find((x) => x.featureCode === "108")
                                        ?.ficon
                                    }`}
                                    alt="feature"
                                  />
                                  <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features.find((x) => x.featureCode === "108")[props.selected_lang]}</span>
                                  <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{tx?.features.find((x) => x.featureCode === "108").rangevalueL}</span>
                                  </div>
                                
                                  </>
                                )}
                              </div>
                {/**ModelYear Deposit  DIV */}                                      
                              <div className="fazeel_vnmcard_detail_box3" style={{width : "100%" , height : "30%" , maxHeight : "30%"}}>
                              <div style={{width : "30%", justifyContent : "left"}}>
                              <div style={{width : "50%" }}>
                                      <img
                                            style={{marginLeft  : "-1.75vw", width : "3vw"}}
                                            src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${tx?.tuid}/${tx?.tenant.cLogo}`}
                                            alt="Business Logo"
                                            width="30" height="30"
                                          />
                                        
                                        </div>   
                                  <div
                                      style={{ display: "flex", marginRight: "5px" }}
                                      onClick={() => props._handleListingView(tx)}
                                    >
                                      {Array.from({
                                        length: parseInt(tx?.tenant.rating) || 0,
                                      }).map((j, index1) => {
                                        return (
                                          <svg
                                            key={`filled-${h}=${index1}`}
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#F39C12"
                                            viewBox="0 0 60 55"
                                            width="50"
                                            height="25"
                                            style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                          >
                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                        );
                                      })}
                                      {parseFloat(tx?.tenant.rating) > parseInt(tx?.tenant.rating) && (
                                        <div style={{ position: "relative" }}>
                                          <svg
                                            key={`unfilled-1212`}
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            style={{
                                              position: "absolute",
                                              paddingRight: "-15px",
                                              marginRight: "-15px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="grey"
                                            viewBox="0 0 60 55"
                                            width="50"
                                            height="25"
                                          >
                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                          <svg
                                            style={{
                                              position: "absolute",
                                              paddingRight: "-15px",
                                              marginRight: "-15px",
                                            }}
                                            key={`unfilled-12`}
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#F39C12"
                                            viewBox="0 0 60 55"
                                            width="50"
                                            height="25"
                                          >
                                            <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                          </svg>
                                        </div>
                                      )}
                                      {Array.from({
                                        length: 5 - parseInt(tx?.tenant.rating),
                                      }).map((k, index2) => {
                                        return (
                                          <>
                                            <svg
                                              key={`unfilled-${h}-${index2}`}
                                              className="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="grey"
                                              viewBox="0 0 60 55"
                                              width="50"
                                              height="25"
                                              style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                            >
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                            </svg>
                                          </>
                                        );
                                      })}
                                    </div>
                                  
                                  </div>
                                  <div style={{float : "right", width : "50%", marginTop  : "-20px"}}>
                                  <p>{/** {props.t("MODEL")}: {tx?.modelyear?.label}<br/>{tx?.insurence ? props.t("WITHINSURANCE") : props.t("WITHOUTINSURANCE")}<br/> {props.t("SecurityDeposit")}: {props.t(`${props.selected_curr}`)} {parseFloat(Number(tx?.securitydeposit) / (props.conversion_rate)).toFixed(0)}  */}</p>
                                  </div>
                              </div>
                {/**Buttons  DIV */}                                      
                              <div className="fazeel_vnmcard_detail_box4 " style={{width : "100%" , height : "15%" , maxHeight : "15%"}} >
                                      <button
                                        style={{ fontSize: "0.5rem", color: "black" }}
                                        onClick={() => {
                                          props._handleSaveCount(tx, 2);
                                      //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                        }}
                                      >
                                      
                                        {props.showcall?.id === tx.tluid && props.showcall.boolv === true ? (
                                          <center>{tx?.tenant?.cellNumberc}</center>
                                        ) : (
                                          <img
                                            src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                            alt="abc"
                                          />
                                        )}
                                      </button>
                                <div className="fazeel_line"></div>
                                    <button
                                    onClick={() => {props._handleWaCount(tx, "1"); props.setShowcall({ ...props.showcall, id: tx.tluid, boolv: false, boolw : true })}}
                                    > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                                <div className="fazeel_line"></div>
                                  <button onClick={() => props._handleListingView(tx)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                              </div>
                            </div>
                            </div>
                          )
                      }) 
                        }
                    </div>
                    <div style={{width : "15%"}} className="xwrapper">
                    <div className="xcard">
                        <button style={{color : "white", fontWeight :"bold", fontSize : "3rem"}} onClick={i => {
                              
                              if(props.typeis === "Listings1"){
                                if((Number(listings.length) - 8) - (Number(props.numb)+1) >= 4){
                                  props.setNumb(Number(props.numb) +1)   
                                }
                              }else{
                                if((Number(listings.length)) - (Number(props.numb)+1) >= 4){
                                  props.setNumb(Number(props.numb) +1)   
                                }
                              }

                                
                                
                            
                            }}> <div className="sidearrow"> <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/65_w.webp`} /> </div>
                        </button>
                        </div>
                    </div>
                    </>}
                </div>
    )
  }

  return (
    <div>
        {filters.category === "1" && showcars()}
        {filters.category === "2" && showmachines()}
        {filters.category === "3" && showyachts()}
    </div>
  );
};
export default Homecarp ;



/**
 * 
 * 
 * 
 * 
 * 
 */