
import axiosConfig from "../../utils/axiosConfig";

export const resetup = (mailid) => {
    if(mailid === ""  || mailid === undefined || mailid === null || !mailid.includes("@")){
      
    }else{
        axiosConfig.get(`/vnmresetup/:${mailid}` ).then(t => {
            return t.data.message

        }).catch(x => {
            return x.response.data.message
        })
    }
     
}