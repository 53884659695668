import { createSlice } from "@reduxjs/toolkit";
import { handleValLangChange } from "./langActions";

const initialState = {
  selected_lang: "en",
  langs: ["en", "ar"],
  rtl: false,
};

const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {},
  extraReducers: {
    [handleValLangChange.pending]: (state) => {},
    [handleValLangChange.fulfilled]: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    [handleValLangChange.rejected]: (state, action) => {},
  },
});

export default langSlice.reducer;
