/* eslint-disable no-underscore-dangle */
import axios from "axios";


let store;

// Recommended approach to avoid circular import dependency error
export const injectStore = (_store) => {
  store = _store;
};

export const apiErrorResponse = (error) => {
  if (error?.response) {
   } else if (error?.request) {
  } else {
  }
};

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: false,
});

instance.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getState().auth;

    if (accessToken) {
      config.headers = {
        vnmuat: `${accessToken}`,
        userAccessToken: `${accessToken}`,
        Accept: "application/json",
      };
    }

    return config;
  },
  (err) => {
    Promise.reject(err)
  }
);

let calledOnce = false;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error?.config;


    if(error?.code === "ERR_NETWORK"){
      window.location.href = "/no-internet";
    }


    if (error?.response !== null) {
      if (error?.response.status === 403 && !originalRequest._retry) {
        if (!calledOnce) {
          calledOnce = true;

          try {
            const refreshData = await instance.get("/refresh_token/verify");

            if (refreshData) {
              
              axios.defaults.headers.common.Authorization = `Bearer ${refreshData.data.access_token}`;

              // store.dispatch(
              //   setCredentials({
              //     user,
              //     access_token: refreshData.data.access_token,
              //   })
              // );

              return instance(originalRequest);
            }
          } catch (error) {
            if (error?.response && error?.response.data) {
              return Promise.reject(error?.response.data);
            }

            return Promise.reject(error);
          } finally {
            originalRequest._retry = true;
            calledOnce = false;
          }
        }
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
