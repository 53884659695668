import { createAsyncThunk } from "@reduxjs/toolkit";



export const handleValLangChange = createAsyncThunk(
  "layout/lang",
  async ({ key, value }, { rejectWithValue }) => {
    return { key , value };
  }
);
