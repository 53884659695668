import React from 'react';
import { useDispatch } from "react-redux";
import { FaHeart, FaRegHeart } from "react-icons/fa";



function Homepremiumlistingm(props) {
    let dispatch = useDispatch()
    
    

    const cars_pre = () => {
           return( 
            props.premium_listings?.map((x , y) =>{
                
                return (
                  <div id={y} key={y} style={{border : "1px solid white", marginBottom : "2vh" , marginTop  : "1vh" }} className='montserrat-400'>
                  <div style={{display : "flex"}}>
                      <div style={{width : "50%"}}>
                      
                      <div className="containerpremium">
                          <div className="overlaypremiumverified">
                          <div style={{marginLeft : "-10px"}}>
                              {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                                <button
                                  onClick={() => {
                                    props?.user !== null &&
                                      dispatch(
                                        props.favProduct({
                                          tluid: x?.tluid,
                                          typed: "pull",
                                        })
                                      );
                                  }}
                                  aria-label="heart"
                                >
                                  <FaHeart
                                    color="red"
                                    size={props.isTabletOrMobile ? "15" : "28"}
                                  />
                                </button>
                              ) : (
                                <button
                                aria-label="Heart"
                                  onClick={() => {
                                    props?.user !== null &&
                                      dispatch(
                                        props.favProduct({
                                          tluid: x?.tluid,
                                          typed: "push",
                                        })
                                      );
                                  }}
                                >
                                  <FaRegHeart
                                    color="red"
                                    size="28"
                                    style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                      
                            <div className="overlaypremiumbrand">
                              {x?.tenant?.cellIsVerified &&
                                x?.tenant?.addressIsVerified && (
                                  <img
                                    src={props.verified}
                                    alt="verified"
                                    style={{ width: "25px" }}
                                  />
                                )}
                            </div>
                            <div className="overlaypremiumprice">
                           {/**    <img
                                src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                                alt="Brands"
                                width="50px"
                              /> */}
                            </div>
                          <img
                              className="rounded-t-lg" 
                              style={{
                                width: "100%"
                              }}
                              src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                              alt="imgmc"
                              width="50" height="50"
                              onClick={p => {
                                props._handleListingView(x)
                              }} 
                          />
                       </div>
                      </div>
                      
                      <div style={{width : "50%", fontSize : "0.7rem", cursor : "default" }}>
                          {/** Right Pannel */}
                         
                          <div style={{display : "flex"}}>
                              <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right" , fontSize  :"0.6rem"}} >
                                  <div className='montserrat-400' style={{paddingLeft : "10px", paddingRight : "10px"}} >
                                  <span style={{ fontWeight : "bold", color : "black"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                                  <p style={{fontWeight : "bold" , color : "black"}}>{props.t("PRICE")} <span style={{fontWeight : "bold"}}>{props.selected_curr} {parseFloat(Number( x?.daily?.amt) / (props.conversion_rate)).toFixed(0)} &nbsp; </span>
                                  <span style={{fontWeight : "normal" , color : "black" }}>{props.t("PerDay")}</span></p>
                                  <p><span >{props.t("Year")}</span> : <span className='s2'>{x?.modelyear?.label}</span></p>
                                  <p ><span >{ props.t("WITHINSURANCE") }</span></p>    
                                 
                                     </div>
                                 

                              </div>
                         
{/** Business Logo and stars */}                                
                          <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                                  <img
                                      width="30" height="30"
                                      style={{
                                      width: "10vw",
                                      marginTop: 0,
                                      
                                  }}
                                      src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                                      alt="Business Logo"
                                  />

{/** STARS */}                                         
                                  <div style={{ display: "flex", float: "right"  }}>
                                      {Array.from({
                                      length: parseInt(x?.tenant.rating) || 0,
                                      }).map((j, index1) => {
                                      return (
                                          <svg
                                          key={`filled-${y}=${index1}`}
                                          className="w-5 h-5 text-gray-800 dark:text-white"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="#F39C12"
                                          viewBox="0 0 60 55"
                                          width="20"
                                          height="13"
                                          style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                          >
                                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                      );
                                      })}
                                      {parseFloat(x?.tenant.rating) >
                                      parseInt(x?.tenant.rating) && (
                                      <div style={{ position: "relative" }}>
                                          <svg
                                          key={`unfilled-1212`}
                                          className="w-5 h-5 text-gray-800 dark:text-white"
                                          aria-hidden="true"
                                          style={{
                                              position: "absolute",
                                              paddingRight: "-15px",
                                              marginRight: "-15px",
                                          }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="grey"
                                          viewBox="0 0 60 55"
                                          width="25"
                                          height="13"
                                          >
                                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                          <svg
                                          style={{
                                              position: "absolute",
                                              paddingRight: "-15px",
                                              marginRight: "-15px",
                                          }}
                                          key={`unfilled-12`}
                                          className="w-5 h-5 text-gray-800 dark:text-white"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="#F39C12"
                                          viewBox="0 0 60 55"
                                          width="25"
                                          height="13"
                                          >
                                          <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                          </svg>
                                      </div>
                                      )}
                                      {Array.from({
                                      length: 5 - parseInt(x?.tenant.rating),
                                      }).map((k, index2) => {
                                      return (
                                          <>
                                          <svg
                                              key={`unfilled-${y}-${index2}`}
                                              className="w-5 h-5 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="grey"
                                              viewBox="0 0 60 55"
                                              width="25"
                                              height="13"
                                              style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                          >
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                          </svg>
                                          </>
                                      );
                                      })}
                                  </div>

                                      
                          </div>
                          
                          </div>
{/** Buttons */}                                                                     
                              <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" , gap : "2vw"}}>
                                      <div style={{width : "60%"}}>
                                      <div className='listingbuttonm' style={{width : "100%" , height : "35%" , maxHeight : "35%", display : "flex" , justifyContent : "flex-end"}} >
                                                  
                                                      <form action={`tel:${x?.tenant?.cellNumberc}`} style={{marginTop : "0"}}>
                                                      <button
                                                      onClick={() => {
                                                      props._handleSaveCount(x, 2);
                                                      }}
                                                      >
                                                      <img
                                                          style={{marginTop : "10px",  width  :"30px"}}
                                                          src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                          alt="call button abc"
                                                      />
                                                      </button>
                                                      </form>
                                                  
                                              
                                                  <button
                                                  onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                                  > <img 
                                                  style={{marginTop : "10px",  width  :"30px"}}  
                                                  alt="WA Icon" 
                                                     src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} />
                                                 </button>
                                              
                                                  <button onClick={() => props._handleListingView(x)}>
                                                     <img alt="Detail Menu" 
                                                     style={{marginTop : "10px"}}
                                                     src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} />
                                                   </button>
                                       </div>
                                     </div>
                             </div>
                              
                      </div>
                  </div>
               </div>
                )
            }) 
        )
    }

    const cars_lis = () => {
        return( 
         props.listings?.map((x , y) =>{
            
             return (
                 <div id={y} key={y} style={{border : "1px solid white", marginBottom : "2vh" , marginTop  : "1vh" }} className='montserrat-400'>
                         <div style={{display : "flex"}}>
                             <div style={{width : "50%"}}>
                             
                             <div className="containerpremium">
                                 <div className="overlaypremiumverified">
                                 <div style={{marginLeft : "-10px"}}>
                                     {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                                       <button
                                         onClick={() => {
                                           props?.user !== null &&
                                             dispatch(
                                               props.favProduct({
                                                 tluid: x?.tluid,
                                                 typed: "pull",
                                               })
                                             );
                                         }}
                                         aria-label="heart"
                                       >
                                         <FaHeart
                                           color="red"
                                           size={props.isTabletOrMobile ? "15" : "28"}
                                         />
                                       </button>
                                     ) : (
                                       <button
                                       aria-label="Heart"
                                         onClick={() => {
                                           props?.user !== null &&
                                             dispatch(
                                               props.favProduct({
                                                 tluid: x?.tluid,
                                                 typed: "push",
                                               })
                                             );
                                         }}
                                       >
                                         <FaRegHeart
                                           color="red"
                                           size="28"
                                           style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                                         />
                                       </button>
                                     )}
                                   </div>
                                 </div>
                             
                                   <div className="overlaypremiumbrand">
                                     {x?.tenant?.cellIsVerified &&
                                       x?.tenant?.addressIsVerified && (
                                         <img
                                           src={props.verified}
                                           alt="verified"
                                           style={{ width: "25px" }}
                                         />
                                       )}
                                   </div>
                                   <div className="overlaypremiumprice">
                                  {/**    <img
                                       src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                                       alt="Brands"
                                       width="50px"
                                     /> */}
                                   </div>
                                 <img
                                     className="rounded-t-lg" 
                                     style={{
                                       width: "100%"
                                     }}
                                     src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                                     alt="imgmc"
                                     width="50" height="50"
                                     onClick={p => {
                                       props._handleListingView(x)
                                     }} 
                                 />
                              </div>
                             </div>
                             
                             <div style={{width : "50%", fontSize : "0.7rem", cursor : "default" }}>
                                 {/** Right Pannel */}
                                
                                 <div style={{display : "flex"}}>
                                     <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right" , fontSize  :"0.6rem"}} >
                                         <div className='montserrat-400' style={{paddingLeft : "10px", paddingRight : "10px"}} >
                                         <span style={{ fontWeight : "bold", color : "black"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                                         <p style={{fontWeight : "bold" , color : "black"}}>{props.t("PRICE")} <span style={{fontWeight : "bold"}}>{props.selected_curr} {parseFloat(Number(props.filters.wdo === "false" ?x?.daily?.wdamt : x?.daily?.amt) / (props.conversion_rate)).toFixed(0)} &nbsp; </span>
                                         <span style={{fontWeight : "normal" , color : "black" }}>{props.t("PerDay")}</span></p>
                                         <p><span >{props.t("Year")}</span> : <span className='s2'>{x?.modelyear?.label}</span></p>
                                         <p ><span >{ props.t("WITHINSURANCE") }</span></p>    
                                        
                                            </div>
                                        

                                     </div>
                                
{/** Business Logo and stars */}                                
                                 <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                                         <img
                                             width="30" height="30"
                                             style={{
                                             width: "10vw",
                                             marginTop: 0,
                                             
                                         }}
                                             src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                                             alt="Business Logo"
                                         />

{/** STARS */}                                         
                                         <div style={{ display: "flex", float: "right"  }}>
                                             {Array.from({
                                             length: parseInt(x?.tenant.rating) || 0,
                                             }).map((j, index1) => {
                                             return (
                                                 <svg
                                                 key={`filled-${y}=${index1}`}
                                                 className="w-5 h-5 text-gray-800 dark:text-white"
                                                 aria-hidden="true"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 fill="#F39C12"
                                                 viewBox="0 0 60 55"
                                                 width="20"
                                                 height="13"
                                                 style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                                 >
                                                 <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                 </svg>
                                             );
                                             })}
                                             {parseFloat(x?.tenant.rating) >
                                             parseInt(x?.tenant.rating) && (
                                             <div style={{ position: "relative" }}>
                                                 <svg
                                                 key={`unfilled-1212`}
                                                 className="w-5 h-5 text-gray-800 dark:text-white"
                                                 aria-hidden="true"
                                                 style={{
                                                     position: "absolute",
                                                     paddingRight: "-15px",
                                                     marginRight: "-15px",
                                                 }}
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 fill="grey"
                                                 viewBox="0 0 60 55"
                                                 width="25"
                                                 height="13"
                                                 >
                                                 <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                 </svg>
                                                 <svg
                                                 style={{
                                                     position: "absolute",
                                                     paddingRight: "-15px",
                                                     marginRight: "-15px",
                                                 }}
                                                 key={`unfilled-12`}
                                                 className="w-5 h-5 text-gray-800 dark:text-white"
                                                 aria-hidden="true"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 fill="#F39C12"
                                                 viewBox="0 0 60 55"
                                                 width="25"
                                                 height="13"
                                                 >
                                                 <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                                 </svg>
                                             </div>
                                             )}
                                             {Array.from({
                                             length: 5 - parseInt(x?.tenant.rating),
                                             }).map((k, index2) => {
                                             return (
                                                 <>
                                                 <svg
                                                     key={`unfilled-${y}-${index2}`}
                                                     className="w-5 h-5 text-gray-800 dark:text-white"
                                                     aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     fill="grey"
                                                     viewBox="0 0 60 55"
                                                     width="25"
                                                     height="13"
                                                     style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                                 >
                                                     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                 </svg>
                                                 </>
                                             );
                                             })}
                                         </div>

                                             
                                 </div>
                                 
                                 </div>
{/** Buttons */}                                                                     
                                     <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" , gap : "2vw"}}>
                                             <div style={{width : "60%"}}>
                                             <div className='listingbuttonm' style={{width : "100%" , height : "35%" , maxHeight : "35%", display : "flex" , justifyContent : "flex-end"}} >
                                                         
                                                             <form action={`tel:${x?.tenant?.cellNumberc}`} style={{marginTop : "0"}}>
                                                             <button
                                                             onClick={() => {
                                                             props._handleSaveCount(x, 2);
                                                             }}
                                                             >
                                                             <img
                                                                 style={{marginTop : "10px",  width  :"30px"}}
                                                                 src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                                 alt="call button abc"
                                                             />
                                                             </button>
                                                             </form>
                                                         
                                                     
                                                         <button
                                                         onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                                         > <img 
                                                         style={{marginTop : "10px",  width  :"30px"}}  
                                                         alt="WA Icon" 
                                                            src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} />
                                                        </button>
                                                     
                                                         <button onClick={() => props._handleListingView(x)}>
                                                            <img alt="Detail Menu" 
                                                            style={{marginTop : "10px"}}
                                                            src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} />
                                                          </button>
                                              </div>
                                            </div>
                                    </div>
                                     
                             </div>
                         </div>
                      </div>
             )
         }) 
     )
 }

    const yacht_pre = () => {
      return(

      
      props.premium_listings?.map((x , y) =>{
        
        return (
          <div id={y} key={y} style={{border : "1px solid white", marginBottom : "2vh" , marginTop  : "1vh" }} className='montserrat-400'>
          <div style={{display : "flex"}}>
              <div style={{width : "50%"}}>
              
              <div className="containerpremium">
                  <div className="overlaypremiumverified">
                  <div style={{marginLeft : "-10px"}}>
                      {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                        <button
                          onClick={() => {
                            props?.user !== null &&
                              dispatch(
                                props.favProduct({
                                  tluid: x?.tluid,
                                  typed: "pull",
                                })
                              );
                          }}
                          aria-label="heart"
                        >
                          <FaHeart
                            color="red"
                            size={props.isTabletOrMobile ? "15" : "28"}
                          />
                        </button>
                      ) : (
                        <button
                        aria-label="Heart"
                          onClick={() => {
                            props?.user !== null &&
                              dispatch(
                                props.favProduct({
                                  tluid: x?.tluid,
                                  typed: "push",
                                })
                              );
                          }}
                        >
                          <FaRegHeart
                            color="red"
                            size="28"
                            style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                          />
                        </button>
                      )}
                    </div>
                  </div>
              
                    <div className="overlaypremiumbrand">
                      {x?.tenant?.cellIsVerified &&
                        x?.tenant?.addressIsVerified && (
                          <img
                            src={props.verified}
                            alt="verified"
                            style={{ width: "25px" }}
                          />
                        )}
                    </div>
                    <div className="overlaypremiumprice">
                   {/**    <img
                        src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                        alt="Brands"
                        width="50px"
                      /> */}
                    </div>
                  <img
                      className="rounded-t-lg" 
                      style={{
                        width: "100%"
                      }}
                      src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                      alt="imgmc"
                      width="50" height="50"
                      onClick={p => {
                        props._handleListingView(x)
                      }} 
                  />
               </div>
              </div>
              
              <div style={{width : "50%", fontSize : "0.7rem", cursor : "default" }}>
                  {/** Right Pannel */}
                 
                  <div style={{display : "flex"}}>
                      <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right" , fontSize  :"0.6rem"}} >
                          <div className='montserrat-400' style={{paddingLeft : "10px", paddingRight : "10px", color : "black"}} >
                          <span style={{ fontWeight : "bold", color : "black"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                          <p style={{fontWeight : "bold" , color : "black"}}>{props.t("PRICE")} <span style={{fontWeight : "bold"}}>{props.selected_curr} {parseFloat(Number( x?.hourly?.amt) / (props.conversion_rate)).toFixed(0)} &nbsp; </span>
                          <span style={{fontWeight : "normal" , color : "black" }}>{props.t("Hourly")}</span></p>
                          <p></p>
                          <p style={{display : x?.insurence ? "block" : "none"}}></p>    
                          </div>
                          <p style={{paddingLeft : "10px"}}>
                                      {x?.features[0]
                                          ?.ficon && (
                                          <>
                                          <div style={{marginRight : "5px", display : "flex"}}>
                                          <img
                                          style={{ height: "20px", width: "20px" }}
                                          src={`${process.env.REACT_APP_API_FS}${
                                              process.env.REACT_APP_API_URL
                                          }/fet/${
                                              x?.features[0]
                                              ?.ficon
                                          }`}
                                          alt="feature"
                                          />
                                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{x?.features[0][props.selected_lang]}</span>
                                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{x?.features[0].rangevalueL}</span>
                                          </div>
                                      
                                          </>
                                      )}</p>

                       </div>
                 
{/** Business Logo and stars */}                                
                  <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                          <img
                              width="30" height="30"
                              style={{
                              width: "10vw",
                              marginTop: 0,
                              
                          }}
                              src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                              alt="Business Logo"
                          />

{/** STARS */}                                         
                          <div style={{ display: "flex", float: "right"  }}>
                              {Array.from({
                              length: parseInt(x?.tenant.rating) || 0,
                              }).map((j, index1) => {
                              return (
                                  <svg
                                  key={`filled-${y}=${index1}`}
                                  className="w-5 h-5 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#F39C12"
                                  viewBox="0 0 60 55"
                                  width="20"
                                  height="13"
                                  style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                  >
                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                              );
                              })}
                              {parseFloat(x?.tenant.rating) >
                              parseInt(x?.tenant.rating) && (
                              <div style={{ position: "relative" }}>
                                  <svg
                                  key={`unfilled-1212`}
                                  className="w-5 h-5 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  style={{
                                      position: "absolute",
                                      paddingRight: "-15px",
                                      marginRight: "-15px",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="grey"
                                  viewBox="0 0 60 55"
                                  width="25"
                                  height="13"
                                  >
                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  <svg
                                  style={{
                                      position: "absolute",
                                      paddingRight: "-15px",
                                      marginRight: "-15px",
                                  }}
                                  key={`unfilled-12`}
                                  className="w-5 h-5 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#F39C12"
                                  viewBox="0 0 60 55"
                                  width="25"
                                  height="13"
                                  >
                                  <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                  </svg>
                              </div>
                              )}
                              {Array.from({
                              length: 5 - parseInt(x?.tenant.rating),
                              }).map((k, index2) => {
                              return (
                                  <>
                                  <svg
                                      key={`unfilled-${y}-${index2}`}
                                      className="w-5 h-5 text-gray-800 dark:text-white"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="grey"
                                      viewBox="0 0 60 55"
                                      width="25"
                                      height="13"
                                      style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                  >
                                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  </>
                              );
                              })}
                          </div>



                              
                  </div>
                  
                  </div>

{/** asd */}
                                
{/** Buttons */}                                                                     
                      <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" , gap : "2vw"}}>
                              <div style={{width : "60%"}}>
                              <div className='listingbuttonm' style={{width : "100%" , height : "35%" , maxHeight : "35%", display : "flex" , justifyContent : "flex-end"}} >
                                          
                                              <form action={`tel:${x?.tenant?.cellNumberc}`} style={{marginTop : "0"}}>
                                              <button
                                              onClick={() => {
                                              props._handleSaveCount(x, 2);
                                              }}
                                              >
                                              <img
                                                  style={{marginTop : "10px",  width  :"30px"}}
                                                  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                  alt="call button abc"
                                              />
                                              </button>
                                              </form>
                                          
                                      
                                          <button
                                          onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                          > <img 
                                          style={{marginTop : "10px",  width  :"30px"}}  
                                          alt="WA Icon" 
                                             src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} />
                                         </button>
                                      
                                          <button onClick={() => props._handleListingView(x)}>
                                             <img alt="Detail Menu" 
                                             style={{marginTop : "10px"}}
                                             src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} />
                                           </button>
                               </div>
                             </div>
                     </div>
                      
              </div>
          </div>
       </div>
            
          )
      }) 
    )
    }

    const yacht_lis = () => {
    return( 
    props.listings?.map((x , y) =>{
        
        return (
          <div id={y} key={y} style={{border : "1px solid white", marginBottom : "2vh" , marginTop  : "1vh" }} className='montserrat-400'>
          <div style={{display : "flex"}}>
              <div style={{width : "50%"}}>
              
              <div className="containerpremium">
                  <div className="overlaypremiumverified">
                  <div style={{marginLeft : "-10px"}}>
                      {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                        <button
                          onClick={() => {
                            props?.user !== null &&
                              dispatch(
                                props.favProduct({
                                  tluid: x?.tluid,
                                  typed: "pull",
                                })
                              );
                          }}
                          aria-label="heart"
                        >
                          <FaHeart
                            color="red"
                            size={props.isTabletOrMobile ? "15" : "28"}
                          />
                        </button>
                      ) : (
                        <button
                        aria-label="Heart"
                          onClick={() => {
                            props?.user !== null &&
                              dispatch(
                                props.favProduct({
                                  tluid: x?.tluid,
                                  typed: "push",
                                })
                              );
                          }}
                        >
                          <FaRegHeart
                            color="red"
                            size="28"
                            style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                          />
                        </button>
                      )}
                    </div>
                  </div>
              
                    <div className="overlaypremiumbrand">
                      {x?.tenant?.cellIsVerified &&
                        x?.tenant?.addressIsVerified && (
                          <img
                            src={props.verified}
                            alt="verified"
                            style={{ width: "25px" }}
                          />
                        )}
                    </div>
                    <div className="overlaypremiumprice">
                   {/**    <img
                        src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                        alt="Brands"
                        width="50px"
                      /> */}
                    </div>
                  <img
                      className="rounded-t-lg" 
                      style={{
                        width: "100%"
                      }}
                      src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                      alt="imgmc"
                      width="50" height="50"
                      onClick={p => {
                        props._handleListingView(x)
                      }} 
                  />
               </div>
              </div>
              
              <div style={{width : "50%", fontSize : "0.7rem", cursor : "default" }}>
                  {/** Right Pannel */}
                 
                  <div style={{display : "flex"}}>
                      <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right" , fontSize  :"0.6rem"}} >
                          <div className='montserrat-400' style={{paddingLeft : "10px", paddingRight : "10px", color : "black"}} >
                          <span style={{ fontWeight : "bold", color : "black"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                          <p style={{fontWeight : "bold" , color : "black"}}>{props.t("PRICE")} <span style={{fontWeight : "bold"}}>{props.selected_curr} {parseFloat(Number(props.filters.wdo === "false" ?x?.hourly?.wdamt : x?.hourly?.amt) / (props.conversion_rate)).toFixed(0)} &nbsp; </span>
                          <span style={{fontWeight : "normal" , color : "black" }}>{props.t("Hourly")}</span></p>
                          <p></p>
                          <p style={{display : x?.insurence ? "block" : "none"}}></p>    
                          </div>
                          <p style={{paddingLeft : "10px"}}>
                                      {x?.features[0]
                                          ?.ficon && (
                                          <>
                                          <div style={{marginRight : "5px", display : "flex"}}>
                                          <img
                                          style={{ height: "20px", width: "20px" }}
                                          src={`${process.env.REACT_APP_API_FS}${
                                              process.env.REACT_APP_API_URL
                                          }/fet/${
                                              x?.features[0]
                                              ?.ficon
                                          }`}
                                          alt="feature"
                                          />
                                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{x?.features[0][props.selected_lang]}</span>
                                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{x?.features[0].rangevalueL}</span>
                                          </div>
                                      
                                          </>
                                      )}</p>

                       </div>
                 
{/** Business Logo and stars */}                                
                  <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                          <img
                              width="30" height="30"
                              style={{
                              width: "10vw",
                              marginTop: 0,
                              
                          }}
                              src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                              alt="Business Logo"
                          />

{/** STARS */}                                         
                          <div style={{ display: "flex", float: "right"  }}>
                              {Array.from({
                              length: parseInt(x?.tenant.rating) || 0,
                              }).map((j, index1) => {
                              return (
                                  <svg
                                  key={`filled-${y}=${index1}`}
                                  className="w-5 h-5 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#F39C12"
                                  viewBox="0 0 60 55"
                                  width="20"
                                  height="13"
                                  style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                  >
                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                              );
                              })}
                              {parseFloat(x?.tenant.rating) >
                              parseInt(x?.tenant.rating) && (
                              <div style={{ position: "relative" }}>
                                  <svg
                                  key={`unfilled-1212`}
                                  className="w-5 h-5 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  style={{
                                      position: "absolute",
                                      paddingRight: "-15px",
                                      marginRight: "-15px",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="grey"
                                  viewBox="0 0 60 55"
                                  width="25"
                                  height="13"
                                  >
                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  <svg
                                  style={{
                                      position: "absolute",
                                      paddingRight: "-15px",
                                      marginRight: "-15px",
                                  }}
                                  key={`unfilled-12`}
                                  className="w-5 h-5 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#F39C12"
                                  viewBox="0 0 60 55"
                                  width="25"
                                  height="13"
                                  >
                                  <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                  </svg>
                              </div>
                              )}
                              {Array.from({
                              length: 5 - parseInt(x?.tenant.rating),
                              }).map((k, index2) => {
                              return (
                                  <>
                                  <svg
                                      key={`unfilled-${y}-${index2}`}
                                      className="w-5 h-5 text-gray-800 dark:text-white"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="grey"
                                      viewBox="0 0 60 55"
                                      width="25"
                                      height="13"
                                      style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                  >
                                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  </>
                              );
                              })}
                          </div>



                              
                  </div>
                  
                  </div>

{/** asd */}
                                
{/** Buttons */}                                                                     
                      <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" , gap : "2vw"}}>
                              <div style={{width : "60%"}}>
                              <div className='listingbuttonm' style={{width : "100%" , height : "35%" , maxHeight : "35%", display : "flex" , justifyContent : "flex-end"}} >
                                          
                                              <form action={`tel:${x?.tenant?.cellNumberc}`} style={{marginTop : "0"}}>
                                              <button
                                              onClick={() => {
                                              props._handleSaveCount(x, 2);
                                              }}
                                              >
                                              <img
                                                  style={{marginTop : "10px",  width  :"30px"}}
                                                  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                  alt="call button abc"
                                              />
                                              </button>
                                              </form>
                                          
                                      
                                          <button
                                          onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                          > <img 
                                          style={{marginTop : "10px",  width  :"30px"}}  
                                          alt="WA Icon" 
                                             src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} />
                                         </button>
                                      
                                          <button onClick={() => props._handleListingView(x)}>
                                             <img alt="Detail Menu" 
                                             style={{marginTop : "10px"}}
                                             src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} />
                                           </button>
                               </div>
                             </div>
                     </div>
                      
              </div>
          </div>
       </div>
        )
    
      }) 
    )
    }

 
    const machine_lis = () => {
      return( 
       props.listings?.map((x , y) =>{
          
           return (
               <div id={y} key={y} style={{border : "1px solid white", marginBottom : "2vh" , marginTop  : "1vh" }} className='montserrat-400'>
                       <div style={{display : "flex"}}>
                           <div style={{width : "50%"}}>
                           
                           <div className="containerpremium">
                               <div className="overlaypremiumverified">
                               <div style={{marginLeft : "-10px"}}>
                                   {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                                     <button
                                       onClick={() => {
                                         props?.user !== null &&
                                           dispatch(
                                             props.favProduct({
                                               tluid: x?.tluid,
                                               typed: "pull",
                                             })
                                           );
                                       }}
                                       aria-label="heart"
                                     >
                                       <FaHeart
                                         color="red"
                                         size={props.isTabletOrMobile ? "15" : "28"}
                                       />
                                     </button>
                                   ) : (
                                     <button
                                       onClick={() => {
                                         props?.user !== null &&
                                           dispatch(
                                             props.favProduct({
                                               tluid: x?.tluid,
                                               typed: "push",
                                             })
                                           );
                                       }}
                                     >
                                       <FaRegHeart
                                         color="red"
                                         size="28"
                                         style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                                       />
                                     </button>
                                   )}
                                 </div>
                               </div>
                           
                                 <div className="overlaypremiumbrand">
                                   {x?.tenant?.cellIsVerified &&
                                     x?.tenant?.addressIsVerified && (
                                       <img
                                         src={props.verified}
                                         alt="verified"
                                         style={{ width: "25px" }}
                                       />
                                     )}
                                 </div>
                                 <div className="overlaypremiumprice">
                                {/**    <img
                                     src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                                     alt="Brands"
                                     width="50px"
                                   /> */}
                                 </div>
                               <img
                                   className="rounded-t-lg" 
                                   style={{
                                     width: "100%"
                                   }}
                                   src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                                   alt="imgmc"
                                   width="50" height="50"
                                   onClick={p => {
                                     props._handleListingView(x)
                                   }} 
                               />
                            </div>
                           </div>
                           
                           <div style={{width : "50%", fontSize : "0.7rem", cursor : "default" }}>
                               {/** Right Pannel */}
                              
                               <div style={{display : "flex"}}>
                                   <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right" , fontSize  :"0.6rem"}} >
                                       <div className='montserrat-400' style={{paddingLeft : "10px", paddingRight : "10px"}} >
                                       <span style={{ fontWeight : "bold", color : "black"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                                       <p style={{fontWeight : "bold" , color : "black"}}>{props.t("PRICE")} <span style={{fontWeight : "bold"}}>{props.selected_curr} {parseFloat(Number(props.filters.wdo === "false" ?x?.daily?.wdamt : x?.daily?.amt) / (props.conversion_rate)).toFixed(0)} &nbsp; </span>
                                       <span style={{fontWeight : "normal" , color : "black" }}>{props.t("PerDay")}</span></p>
                                       <p><span >{props.t("Year")}</span> : <span className='s2'>{x?.modelyear?.label}</span></p>
                                       <p >{x?.hourly?.amt && (Number(x?.hourly?.amt) > 1) && <>{props?.t("Available")} {props?.t('PerHour')} , {x?.daily?.wdamt && (Number(x?.daily?.wdamt) > 1) && <>{props?.t("WITHOPERATOR")}</>}  </> }</p>    
                                      
                                          </div>
                                      

                                   </div>
                              
{/** Business Logo and stars */}                                
                               <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                                       <img
                                           width="30" height="30"
                                           style={{
                                           width: "10vw",
                                           marginTop: 0,
                                           
                                       }}
                                           src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                                           alt="Business Logo"
                                       />

{/** STARS */}                                         
                                       <div style={{ display: "flex", float: "right"  }}>
                                           {Array.from({
                                           length: parseInt(x?.tenant.rating) || 0,
                                           }).map((j, index1) => {
                                           return (
                                               <svg
                                               key={`filled-${y}=${index1}`}
                                               className="w-5 h-5 text-gray-800 dark:text-white"
                                               aria-hidden="true"
                                               xmlns="http://www.w3.org/2000/svg"
                                               fill="#F39C12"
                                               viewBox="0 0 60 55"
                                               width="20"
                                               height="13"
                                               style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                               >
                                               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                               </svg>
                                           );
                                           })}
                                           {parseFloat(x?.tenant.rating) >
                                           parseInt(x?.tenant.rating) && (
                                           <div style={{ position: "relative" }}>
                                               <svg
                                               key={`unfilled-1212`}
                                               className="w-5 h-5 text-gray-800 dark:text-white"
                                               aria-hidden="true"
                                               style={{
                                                   position: "absolute",
                                                   paddingRight: "-15px",
                                                   marginRight: "-15px",
                                               }}
                                               xmlns="http://www.w3.org/2000/svg"
                                               fill="grey"
                                               viewBox="0 0 60 55"
                                               width="25"
                                               height="13"
                                               >
                                               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                               </svg>
                                               <svg
                                               style={{
                                                   position: "absolute",
                                                   paddingRight: "-15px",
                                                   marginRight: "-15px",
                                               }}
                                               key={`unfilled-12`}
                                               className="w-5 h-5 text-gray-800 dark:text-white"
                                               aria-hidden="true"
                                               xmlns="http://www.w3.org/2000/svg"
                                               fill="#F39C12"
                                               viewBox="0 0 60 55"
                                               width="25"
                                               height="13"
                                               >
                                               <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                               </svg>
                                           </div>
                                           )}
                                           {Array.from({
                                           length: 5 - parseInt(x?.tenant.rating),
                                           }).map((k, index2) => {
                                           return (
                                               <>
                                               <svg
                                                   key={`unfilled-${y}-${index2}`}
                                                   className="w-5 h-5 text-gray-800 dark:text-white"
                                                   aria-hidden="true"
                                                   xmlns="http://www.w3.org/2000/svg"
                                                   fill="grey"
                                                   viewBox="0 0 60 55"
                                                   width="25"
                                                   height="13"
                                                   style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                               >
                                                   <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                               </svg>
                                               </>
                                           );
                                           })}
                                       </div>

                                           
                               </div>
                               
                               </div>
{/** Buttons */}                                                                     
                                   <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" , gap : "2vw"}}>
                                           <div style={{width : "60%"}}>
                                           <div className='listingbuttonm' style={{width : "100%" , height : "35%" , maxHeight : "35%", display : "flex" , justifyContent : "flex-end"}} >
                                                       
                                                           <form action={`tel:${x?.tenant?.cellNumberc}`} style={{marginTop : "0"}}>
                                                           <button
                                                           onClick={() => {
                                                           props._handleSaveCount(x, 2);
                                                           }}
                                                           >
                                                           <img
                                                               style={{marginTop : "10px",  width  :"30px"}}
                                                               src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                               alt="call button abc"
                                                           />
                                                           </button>
                                                           </form>
                                                       
                                                   
                                                       <button
                                                       onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                                       > <img 
                                                       style={{marginTop : "10px",  width  :"30px"}}  
                                                       alt="WA Icon" 
                                                          src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} />
                                                      </button>
                                                   
                                                       <button onClick={() => props._handleListingView(x)}>
                                                          <img alt="Detail Menu" 
                                                          style={{marginTop : "10px"}}
                                                          src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} />
                                                        </button>
                                            </div>
                                          </div>
                                  </div>
                                   
                           </div>
                       </div>
                    </div>
           )
       }) 
   )
}



    return (
        <>
        
          { props?.type === "1" && props.typeis === "premium" && cars_pre()}
           { props?.type === "1" && props.typeis !== "premium" && cars_lis()} 

          {props?.type === "2" && props.typeis !== "premium" && machine_lis()}   
           
           { props?.type === "3" && props.typeis === "premium" && yacht_pre()}
           { props?.type === "3" && props.typeis !== "premium" && yacht_lis()} 

      
        {/**  props?.type === "3" || props.type === "yatch" &&  props.typeis === "premium" && yacht_pre() */}
     
        </>
    );
}

export default Homepremiumlistingm;


/**  props?.type === "2" && props.typeis !== "premium" && machine_lis()} */
