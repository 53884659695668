import React, {lazy , Suspense, useEffect, useState, useRef} from "react";
import { useTranslation } from "react-i18next";
import { RxHamburgerMenu } from "react-icons/rx";
import axiosConfig from "../../../utils/axiosConfig.jsx";  
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { successToast } from "../../../utils";
import { handleValLangChange } from "../../../store/lang/langActions";
import Footerm from "../new_main/Footerm";
import Footer from "../new_main/Footer";
import Menuvtwo from "../homecomps/Menu_vtwo.js";
import {
  useNavigate,
} from "react-router-dom";

import {
  handleChangeFilterValue,
} from "../../../store/listings/listingActions";

import { handleValCurrencyChange } from "../../../store/currency/currencyActions";

const Vnmbrands = lazy(() => import('./Vnmbrands'));     
const Userapp = lazy(() => import('./Userapp'));     
const Thebrandmobile = lazy(() => import("./Thebrandmobile"));

function Aboutus(props) {
  const brandsr = useRef(null);
  const aboutusr = useRef(null)
  const navlink1 = useRef(null);
  const navlink2 = useRef(null);
  const navlink3 = useRef(null);
  const appRef = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [distvals, setDistvals] = useState([])
    const [seldistvals, setSeldistvals] = useState("") 

  const { selected_lang, langs } = useSelector((state) => state.lang);
  const { distvbc, filters_master, search } = useSelector((state) => state.listing);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { selected_curr, currencies } =
  useSelector((state) => state.curr);

  const _handleChangeTranslate = () => {
    let selectedLanguage = selected_lang === langs[0] ? langs[1] : langs[0];
    dispatch(
      handleValLangChange({ key: "rtl", value: selectedLanguage === langs[1] })
    );
    dispatch(
      handleValLangChange({ key: "selected_lang", value: selectedLanguage })
    );
    successToast(t(`Language Changed to ${selectedLanguage}`));
  };

  const {
    filter_master,
  } = useSelector((state) => state.listing);
  
  


  const _handleChangeCurrency = () => {
         
    let selectedCurrency = 
      selected_curr === currencies[0] ? currencies[1] : currencies[0];

      

      let valuec = 1
      if(selected_curr === "AED"){
        valuec = filter_master.currencyinfo.find(t => t.name === "Dollar").value
      }else{
        valuec = filter_master.currencyinfo.find(t => t.name === "Dirham").value
      }
      
      dispatch(
      handleValCurrencyChange({
        key: "conversion_rate",
        value: String(valuec),
      })
    );
    dispatch(
      handleValCurrencyChange({ key: "selected_curr", value: selectedCurrency })
    );
    successToast(t(`Currency Changed to ${selectedCurrency}`));
  };

  const getdv = () => {
    
      axiosConfig(`/getdistvb/1`).then(t => {
        console.log(t.data)
      setDistvals(t.data)
      
        
    })
    
  }

  const aboutusm = () => {
    return (
      <div style={{cursor : "default"}}>  
        {/* Top Bar with Currency and mobile app */}
             <div className="vttop_bar_r_m" style={{width : "100%", minWidth : "100%", backgroundColor : "#f1f1f1", borderBottom : "3px solid orange"}}> 
                  <div className="qs"><img width="20" height="20" src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/globe.png`} alt="Globe"  /><span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "margin25"}>
                        {t("topbar_uae")}
                        <span className="popover">
                        {
                              distvbc?.cityCode?.length >= 0 ? 
                              <ul>
                                { /* eslint-disable-next-line */}
                              {distvbc?.cityCode?.map((city, index) => {
                                  if(city?.city[selected_lang]){
                                    return (
                                      <li style={{marginBottom : "10px" , marginTop : "10px"}}>
                                      <button key={"city" + index} id={city.cityCode} 
                                      onClick={x => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "citycode",
                                            value: city.city.cityCode,
                                          })
                                        );
                                      }}
                                      >
                                        {city?.city[selected_lang]}
                                      </button><br />
                                      </li>
                                    )
                                  }
                              
                              })}
                              </ul>
                            :
                            <p>{distvbc?.cityCode?.length}</p>
                          }   
                        </span>
                    </span>
                  </div>
                  <div onClick={() => _handleChangeCurrency()} >
                    <img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/Currency.png`} alt="Currency icon" />
                    <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>
                  {selected_curr === "AED" ? 
                          t("AED")
                        : 
                        t("USD")
                        }
                    </span></div>
                  <div >
                    <img width="20" height="20" src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span>
                  </div>

                  <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })}><img width="20" height="20" src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/App.png`} alt="App Icon" />
                  <span style={{marginTop : "10px"}} className={selected_lang === "en" ? "marginen25" : "marginar25"}>MOBILE APP</span>
                  </div>
              </div>
        {/* Top Bar with MENU */}
              <div style={{display : "flex", width : "100%"}}>
                      <div style={{width  :"50%", minWidth : "50%"}} >
                                          <a 
                                          href = "/car" >
                                          <img
                                                  className="ml-2 mt-1"
                                                  width = "130"
                                                  height = "100"
                                                  style={{ float :"left", padding : "0.5vw" }}
                                                  src={
                                                      process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.webp"
                                                  }
                                                  alt="logo"
                                                  />
                                          </a>
                            </div>          
                          {/** Mobile Menu */}            
                            <div style={{display : "flex" , justifyContent : "right", width : "50%", minWidth :"50%"}} >
                                              <div>
                                              <RxHamburgerMenu className="text-2xl mt-3.5"  />       
                                              </div>
                                              <div style={{marginTop :  "15px"}} className="mr-2" >
                                              {!props.user ? (
                                                  <button
                                                      type="button"
                                                      onClick={() => {
                                                        navigate('/car')
                                                      }}
                                                      style={{  fontSize : "0.7rem" , height : "4vh", width : "20vw" , backgroundColor : "#f1f1f1" , fontWeight : "bold", borderRadius : "10px" }}
                                                  >
                                                      <center >
                                                          Home
                                                      </center>
                                                  </button>
                                                  ) : (
                                                  <>
                                                      <button
                                                      type="button"
                                                      onClick={() => {
                                                          
                                                          if(window.location.href.includes("profile")){
                                                          dispatch(props.logoutUser());
                                                          }else{
                                                          props.navigate("/profile");
                                                          props.setIsMenuOpen(false);
                                                          }
                                                          
                                                      }}
                                                      style={{ marginTop: -5, fontSize : "0.7rem" , height: "40px", borderRadius : "25px"}}
                                                      className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10"
                                                      >
                                                      { window.location.href.includes("profile") ? "Logout" :props.user?.fn}
                                                      </button>
                                                  </>
                                                )}
                                              </div>             
                                        
                            </div>
          
              </div>
      <Suspense>
        <div>
          <Thebrandmobile t={t} 
            selected_lang={props.selected_lang} brands={filters_master} distvals={distvals} seldistvals={seldistvals} setSeldistvals={props.setSeldistvals} _handleClearAllFilters={props._handleClearAllFilters} dispatch={dispatch} handleChangeFilterValue={props.handleChangeFilterValue} id={"1"}  navigate={navigate}
            appref = {appRef}                                    
          />
          </div>
      </Suspense>
      <div style={{marginTop : "150px", paddingBottom : "50px" , backgroundColor : "#16254e"}}>
          <Footerm selected_lang={selected_lang} navigate={navigate} />
        </div>
    </div>
    )  
  }

  const aboutus = () => {
    return (
      <div >
          <div>
          <div className={selected_lang === "en" ? "vttop_bar marginenn" : "vttop_bar marginarr" }> 
                    <a
                        target="_blank"
                        href={process.env.REACT_APP_FACEBOOK_URL}
                        rel="noreferrer"
                      >
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/facebook_.webp`} alt="facebook_icon" />
                    </a>
                    <a
                    target="_blank"
                    href={process.env.REACT_APP_INSTAGRAM_URL}
                    rel="noreferrer"
                  >
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/instagram_.webp`} alt="instagram_icon" />
                    </a>
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_TIKTOK_URL}
                      rel="noreferrer"
                    >
                        <img  src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/tiktok.webp`} alt="tiktok_icon"  />
                    </a>
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_SNAPCHAT_URL}
                      rel="noreferrer"
                    >
                    <img  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/snapchat_.webp`} alt="snapchat_icon" />
                    </a>
                </div>
            
                <div className={selected_lang === "en" ? "vttop_bar_r marginen" : "vttop_bar_r marginar" } style={{float : selected_lang === "en" ? "right" : "left"}}> 
                    <div className="qs"><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/globe.png`} alt="Globe"  /><span className={selected_lang === "en" ? "marginen25" : "margin25"}>{t("topbar_uae")}</span>
                     <span className="popover">
                    {
                      distvals[0]?.cityCode?.length >= 0 ? 
                                  <ul>
                                   { /* eslint-disable-next-line */}
                                  {distvals[0]?.cityCode?.map((city, index) => {
                                    if(city?.city[selected_lang] !== ""){
                                      return (
                                        <li style={{marginBottom : "10px" , marginTop : "10px"}}>
                                        <button key={"city" + index} id={city.cityCode} 
                                        onClick={x => {
                                          dispatch(
                                            handleChangeFilterValue({
                                              key: "citycode",
                                              value: city.city.cityCode,
                                            })
                                          );
                                        }}
                                        >
                                          {city?.city[selected_lang]}
                                        </button><br />
                                        </li>
                                      ) 
                                    }
                                   
                                      })}
                                  </ul>
                      :
                      <p>{distvals[0]?.cityCode?.length}</p>
                    }   
                      
                    </span> 
                    </div>
                    <div onClick={() => _handleChangeCurrency()}><img  style={{width : "25px", height:  "25px", marginTop : "2px" }} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Currency.png`} alt="Currency icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25" }>
                    {selected_curr === "AED" ? 
                    t("AED")
                   : 
                   t("USD")
                  }
                      </span></div>
                    <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span></div>
                    <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/App.png`} alt="App Icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>DOWNLOAD OUR MOBILE APP</span>
                    
                    </div>
                    
                </div>
          </div>
          <div>
          <Menuvtwo 
              user={'x'} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} isTabletOrMobile={isTabletOrMobile} search={search}
              _handleSearch={() => {}} setLoginModel={() => {}} brandsr={brandsr} aboutusr={aboutusr} navlink1={navlink1} navlink2={navlink2} navlink3={navlink3} 
              t={t}
               showsearch={"1"} changetype={() => {}} selected_lang={selected_lang}
          />
          </div>
          <div className="homepremiumcolors aboutusdiv1" style={{color : "white"}}>
          <center>
            <h3 className="montserrat-400">{t("VNMRENTALMARKETPLACE")}</h3>
            <h6 className="montserrat-400">{t("thebrand2")}</h6>
           </center>
          </div>
  
          <div style={{marginTop : "5vh"}}>
            <center>
              <h6 className="montserrat-400" style={{fontWeight : "bold"}}>{t("thebrand3")}</h6>
              <p className="montserrat-400">{t("thebrand4")}</p>
            
              <div style={{display : "flex", gap : "15vw", justifyContent : "center", marginTop : "5vh"}} >
                    <div className="fazeel_b1_text">
                        <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/fastandeasybooking1.webp`} width = {"80vw"}   alt="" />
                        <h5 className="aboutusfourtextx montserrat-400">{t("About_us__howitworks_Heading_1")}</h5>
                    </div>

                    <div className="fazeel_b1_text">
                        <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/fastandeasybooking2.webp`} width = {"80vw"}   alt="" />
                        <h5 className="aboutusfourtextx montserrat-400">{t("About_us__howitworks_Heading_2")}</h5>
                    </div>

                    <div className="fazeel_b1_text">
                        <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/fastandeasybooking3.webp`} width = {"80vw"}   alt="" />
                        <h5 className="aboutusfourtextx montserrat-400">{t("About_us__howitworks_Heading_3")}</h5>
                    </div>

                    <div className="fazeel_b1_text">
                        <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/fastandeasybooking.webp`} width = {"80vw"}   alt="" />
                        <h5 className="aboutusfourtextx montserrat-400">{t("About_us__howitworks_Heading_4")}</h5>
                    </div>

              </div>
            </center>
          
          </div>
  
          <div style={{width : "100%" ,  paddingLeft : props.selected_lang === "en" ? "50px" : "", paddingRight : props.selected_lang === "en" ? "0" : "50px", marginBottom : "10vh", marginTop : "10vh", paddingTop : "1px", backgroundColor :"#f1f1f1"}}>
                <div style={{display : "flex", justifyContent : "center", alignItems : "center"}}>
                  <div className='montserrat-400' style={{width : "50%" , maxWidth : "50%" , paddingRight : "10vw" , paddingLeft : "10vw"}}>
                      <h3>{t("aboutus_welcome")}</h3>
                      <br />
                      <p style={{fontSize : "0.8rem", color : "gray"}}>{t("aboutus_main_section_description")}</p>
                      <br />
                  </div>
                  <div style={{width : "50%" , maxWidth : "50%", float : "right"}}>
                      <img style={{float : "right"}} src ={`${process.env.REACT_APP_CDN}bx6X8rS.png`} alt = "CDN Marketing" width = {"100%"}  />
                  </div>
              </div>
                  
          </div>

          <div style={{width : "100%" ,  paddingLeft : props.selected_lang === "en" ? "50px" : "", paddingRight : props.selected_lang === "en" ? "0" : "50px", marginBottom : "10vh", marginTop : "10vh",  backgroundColor :"white"}}>
                <div style={{display : "flex", justifyContent : "center", alignItems : "center", marginBottom : "8vh"}}>
                  <div style={{width : "40%" , maxWidth : "50%"}}>
                    <center>
                      <img src ={`${process.env.REACT_APP_PUBLIC_URL}images/vnmlogo_new.png`} alt = "Marketing" width = {"50%"} />
                      </center>
                  </div>
                  <div className='montserrat-400' style={{width : "50%" , maxWidth : "50%" , paddingRight : "10vw" , paddingLeft : "10vw"}}>
                      <h3>{t("aboutus_second_section_heading")}</h3>
                      <br />
                      <p style={{fontSize : "0.8rem", color : "gray"}}>{t("aboutus_second_section_description")}</p>
                      <br />
                      <p style={{fontSize : "0.8rem", color : "gray"}}>{t("aboutus_second_section_description1")}</p>
                  </div>
                    
              </div>
              <Suspense>
              <Vnmbrands selected_lang={selected_lang} brands={filters_master} distvals={distvals} seldistvals={seldistvals} setSeldistvals={props.setSeldistvals} _handleClearAllFilters={props._handleClearAllFilters} dispatch={dispatch} handleChangeFilterValue={handleChangeFilterValue}  isTabletOrMobile={isTabletOrMobile}
              id={"1"}  navigate={navigate} counts = {8} />    
              </Suspense>
          </div>
          

          <div  className="homefootercolors montserrat-400" style={{padding : "0"}}>
              <img style={{width : "100%", minWidth : "100%"}} src ={`${process.env.REACT_APP_PUBLIC_URL}images/fastandeasybookingx2.png`} alt = "vnm_aboutusfull"/>
              <center>
                <div className="homefootercolors" style={{color : "white", height : "15vh"}}   >
                  <div style={{marginTop : "2vh"}}><h1 className=" montserrat-400">{t("EXRR")}</h1></div>
                </div>
              </center>
           
          </div>

          <div  ref={appRef} style={{  justifyContent : "center", alignContent : "center"}}>
            <Userapp t ={t} selected_lang={props.selected_lang} iswhitea = {"Applestore.webp"} iswhiteg = {"googleplay.webp"}  />
          </div>
          <Footer selected_lang={selected_lang} /> 
      </div>
    )  
  }

  useEffect(() => {
      getdv()
  },[])

  return (
      <>
       {isTabletOrMobile ? <Suspense>{aboutusm()}</Suspense> : <Suspense>{aboutus()}</Suspense>}               
       </>
  );
}

export default Aboutus;
