import React from 'react';
import {
    Link,
    NavLink,
    useNavigate,
  
  } from "react-router-dom";
  
  import {
    handleClearSearch,
    
    
} from "../../../store/listings/listingActions";
  import { useDispatch, useSelector } from "react-redux";
  import {
    logoutUser,
  } from "../../../store/auth/authActions";

  
  import { RxHamburgerMenu } from "react-icons/rx";
  import { AiOutlineClose } from "react-icons/ai";



function Menu_vtwo(props) {
  

    var dispatch =useDispatch()
    
    var navigate= useNavigate()
    const {  selected_lang, rtl } = useSelector((state) => state.lang);
    
    return (
        <>
        <header className="sm:px-8 px-4 py-2 w-full montserrat-200">
            <nav className="flex md:justify-between items-center">
              <div
                className="hidden max-lg:block cursor-pointer"
                onClick={() => {
                  props.setIsMenuOpen(!props.isMenuOpen);
                }}
              >
                <RxHamburgerMenu className="text-4xl" />
              </div>
              <Link to="/car">
                <img
                    width = "180"
                    height = "100"
                  style={{  marginLeft : "60px" }}
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.webp"
                  }
                  alt="logo"
                />
              </Link>
              <div className="flex items-center gap-10 max-lg:hidden" style={{ marginRight : selected_lang === "en" ? "-300px" : "+300px"}} >
                <NavLink
                  ref={props.aboutusr}
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary text-sm not-italic font-bold no-underline capitalize  toupper uppercase"
                      : "text-black text-sm not-italic font-normal no-underline capitalize  toupper uppercase"
                  }
                  style={{ fontSize: "0.7rem" }}
                  to="/about-us"
                  onClick={(x) => {
                    dispatch(handleClearSearch({}));
                  }}
                  
                >
                  {props.t("BRANDS")}
                  
                </NavLink>

                <NavLink
                  ref={props.navlink1}
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary text-sm not-italic font-bold no-underline capitalize  toupper uppercase"
                      : "text-black text-sm not-italic font-normal no-underline capitalize  toupper uppercase"
                  }
                  style={{ fontSize: "0.7rem" }}
                  to="/car"
                  onClick={(x) => {
                    dispatch(handleClearSearch({}));
                    
                    props.changetype("1")
                  }}
                >
                  {props.t("RENTACAR")}
                </NavLink>
                
                  <NavLink
                    ref={props.navlink2}
                    className={({ isActive }) =>
                      isActive
                        ? "text-primary text-sm not-italic font-bold no-underline capitalize  toupper uppercase"
                        : "text-black text-sm not-italic font-normal no-underline capitalize  toupper uppercase"
                    }
                    to="/machines"
                    onClick={(x) => {
                      //props.setCommingSoon(true);
                      //dispatch(handleClearSearch({}));
                     // props.changetype("2")
                      props.changetype("2")
                      //props.setCommingSoon(true);
                     // props.navlink1.current.onClick()
                    }}
                    style={{ fontSize: "0.7rem" }}
                  >
                    {props.t("MACHINES")}
                  </NavLink>
                
                <NavLink
                  ref={props.navlink3}
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary text-sm not-italic font-bold no-underline capitalize toupper uppercase"
                      : "text-black text-sm not-italic font-normal no-underline capitalize toupper uppercase"
                  }
                  to="/yatch"
                  style={{ fontSize: "0.7rem" }}
                  onClick={(x) => {
                    dispatch(handleClearSearch({}));
                    
                    props.changetype("3")
                  }}
                >
                  {props.t("YATCHRENTAL")}
                </NavLink>
              
                <NavLink
                  ref={props.brandsr}
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary text-sm not-italic font-bold no-underline capitalize toupper uppercase"
                      : "text-black text-sm not-italic font-normal no-underline capitalize toupper uppercase"
                  }
                  style={{ fontSize: "0.7rem" }}
                  to="/blogs"
                >
                  {props.t("FOOTERSSECTION2LINE5")}
                </NavLink>


              </div>
            {props.user === "x" ? 
                <button
                className='findmyperfectridebutton'
                type="button"
                onClick={() => {
                  navigate('/car')
                }}
                style={{  fontSize : "0.7rem" , height: "30px" , width : "80px" , backgroundColor : "gray" , fontWeight : "bold", borderRadius : "5px" }}
              >
                <center >
                    Main
                </center>
              </button>
          
            :
            <div style={{marginRight : "7vw"}} >
               
                {!props.user ? (
                  <button
                    className='findmyperfectridebutton'
                    type="button"
                    onClick={() => {
                      props.setLoginModel(true);
                      props.setIsMenuOpen(false);
                    }}
                    style={{  fontSize : "0.7rem" , height: "30px" , width : "80px" , backgroundColor : "orange" , fontWeight : "bold", borderRadius : "5px" }}
                  >
                    <center >
                        {props.t("LOGIN")}{" "}
                    </center>
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        
                        if(window.location.href.includes("profile")){
                          dispatch(logoutUser());
                        }else{
                          navigate("/profile");
                          props.setIsMenuOpen(false);
                        }
                        
                      }}
                      style={{ marginTop: -5, fontSize : "0.7rem" , height: "40px", borderRadius : "25px"}}
                      className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10"
                    >
                       { window.location.href.includes("profile") ? "Logout" : props.user?.fn}
                    </button>
                  </>
                )}
              </div>
            }
              
            </nav>
          </header>

          {props.isMenuOpen && (
            <nav className="fixed top-0 right-0 left-0 bottom-0 lg:bottom-auto bg-slate-100 z-50">
              <div
                className={`hidden max-lg:block fixed ${
                  rtl ? "left-0" : "right-0"
                }  px-8 py-4 cursor-pointer`}
                onClick={() => {
                  props.setIsMenuOpen(!props.isMenuOpen);
                }}
              >
                <AiOutlineClose className="text-4xl" />
              </div>
              <div className="items-center justify-center h-full ">
                <Link to="/" className="text-3xl font-bold">
                  <center>
                  <img
                     width = "250"
                     height = "180"
                    style={{ padding  :"10px" }}
                    src={
                      process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo_new.webp"
                    }
                    alt="logo"
                  />
                  </center>
                </Link>
                <div className=" lg:hidden flex flex-col   max-w-md divide-y text-center mt-10">
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "text-primary text-lg not-italic montserrat-400 toupper font-normal no-underline py-3"
                        : "text-black text-lg not-italic font-normal  no-underline py-3"
                    }
                    to="/car"
                    onClick={(x) => props.setIsMenuOpen(false)}
                  >
                   <span className='uppercase'> {props.t("RENTACAR")}</span>
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "text-primary text-lg not-italic font-normal no-underline py-3"
                        : "text-black text-lg not-italic font-normal no-underline py-3"
                    }
                    to="/machine"
                    onClick={(x) => {
                      //props.setCommingSoon(true);
                      props.setIsMenuOpen(false);
                    }}
                  >
                    {props.t("MACHINES")}
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "text-primary text-lg not-italic font-normal no-underline py-3"
                        : "text-black text-lg not-italic font-normal no-underline py-3"
                    }
                    to="/yatch"
                    onClick={(x) => props.setIsMenuOpen(false)}
                  >
                    {props.t("YATCHRENTAL")}
                  </NavLink>
                  <div className="mt-5">
                      {!props.user ? (
                        <button
                          type="button"
                          onClick={() => {
                            props.setLoginModel(true);
                            props.setIsMenuOpen(false);
                          }}
                          className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center items-center  ml-10 roundborderlightgray capitalize"
                          style={{ height: "60px" , marginLeft : "0%" }}
                        >
                            <img
                              style={{ width: "25px", marginTop: "-8px" }}
                              src={
                                process.env.REACT_APP_PUBLIC_URL + "images/signin.webp"
                              }
                              alt="singin"
                            />
                            <p style={{ fontSize: "0.7rem", fontWeight: "bold" }}>
                              {props.t("LOGIN")}{" "}
                            </p>
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={() => {
                              if(window.location.href.includes("profile")){
                                dispatch(logoutUser());
                              }else{
                                navigate("/profile");
                                props.setIsMenuOpen(false);
                              }
                            }}
                            className="text-white bg-primary font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center  ml-10"
                          >
                               { window.location.href.includes("profile") ? "Logout" : props.user?.fn}
                          </button>
                        </>
                      )}
                </div>
                </div>
           
              </div>
            </nav>
          )}
          </>
    );
}

export default Menu_vtwo;