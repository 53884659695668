import { createSlice } from "@reduxjs/toolkit";
import {
  getUserProfile,
  loginGoogleUser,
  loginUser,
  logoutUser,
  registerUser,
  resetPassword,
  // verifyUserDetails,
} from "./authActions";
import { jwtDecode } from "jwt-decode";
import { favProduct } from "../../store/listings/listingActions";
const userAccessToken = localStorage.getItem("userAccessToken")
  ? localStorage.getItem("userAccessToken")
  : null;

const initialState = {
  loading: false,
  user: userAccessToken ? jwtDecode(userAccessToken) : null,
  accessToken: userAccessToken,
  error: null,
  success: false,
  savedTemplate: "",
  loading_reset: false,
  user_profile: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.user = jwtDecode(action.payload);
      state.accessToken = action.payload;

      localStorage.setItem("userAccessToken", action.payload);
      localStorage.setItem("user", jwtDecode(action.payload));
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
    },
    [registerUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.message;
      state.error = null;
      state.success = true;
    },
    [registerUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },

    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loading = false;

      state.user = jwtDecode(action.payload);
      state.accessToken = action.payload;
      localStorage.setItem("userAccessToken", action.payload);
      state.error = null;
    },
    [loginUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },

    [loginGoogleUser.pending]: (state) => {
      state.loading = true;
    },
    [loginGoogleUser.fulfilled]: (state, action) => {
      state.loading = false;

      state.user = jwtDecode(action.payload);
      state.accessToken = action.payload;
      localStorage.setItem("userAccessToken", action.payload);
      state.error = null;
    },
    [loginGoogleUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },

    [logoutUser.pending]: (state) => {
      state.loading = true;
    },
    [logoutUser.fulfilled]: (state) => {
      state.loading = false;
      state.user = null;
      state.accessToken = null;
      localStorage.removeItem("userAccessToken");
      state.success = true;
      state.error = null;
    },
    [logoutUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },

    [getUserProfile.fulfilled]: (state, action) => {
      state.user_profile = action.payload;
    },

    [resetPassword.pending]: (state) => {
      state.loading_reset = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.loading_reset = false;
      state.success = true;
      state.error = null;
    },
    [resetPassword.rejected]: (state, action) => {
      state.loading_reset = false;
      state.error = action.payload.error;
    },

    [favProduct.fulfilled]: (state, action) => {
      const { tluid, typed } = action.payload;
      let updatedUserTags;
      
      
      if (typed === "pull") {
        updatedUserTags = state.user_profile.usertags.filter(
          (tag) => tag !== tluid
        );
      } else {
        if (!state?.user_profile?.usertags.includes(tluid)) {
          updatedUserTags = [...state.user_profile?.usertags, tluid];
        } else {
          updatedUserTags = [...state.user_profile?.usertags];
        }
      }

      return {
        ...state,
        user_profile: {
          ...state.user_profile,
          usertags: updatedUserTags,
        },
      };
    },

    // [verifyUserDetails.pending]: (state) => {
    //   state.loading = true;
    // },
    // [verifyUserDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.user = action.payload.user_details;
    //   state.error = null;
    // },
    // [verifyUserDetails.rejected]: (state) => {
    //   state.loading = false;
    // },
  },
});

export default authSlice.reducer;

export const { setCredentials } = authSlice.actions;
