import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const ar = require('./lang/ar.json');
const en = require('./lang/en.json');


i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: en
      },
      ar: {
        translation: ar
      },
    }
  });

export default i18n;