import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../utils/axiosConfig";

export const registerUser = createAsyncThunk(
  "user/register",
  async ({ fullName, email, password, phone }, { rejectWithValue }) => {
    try {
      const response = await axiosConfig.post("/lgn/rnmcua", {
        guid: email,
        psscd: password,
        displayName: fullName,
        personalEmail: email,
        cell: phone,
      });

      return await response.data.message;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axiosConfig.post("/lgn/rnmusrl", {
        guid: email,
        pass: password,
        typeIs: "User",
      });
      
      return await response.headers.arixauth;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);




export const loginGoogleUser = createAsyncThunk(
  "user/login/google",
  async ({ g_response }, { rejectWithValue }) => {
    try {
      const response = await axiosConfig.get(`/googleauth/${JSON.stringify(g_response)}`);
      
      return await response.headers.arixauth;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);



export const logoutUser = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
      return "Signout Successfully";
   }
);




export const getUserProfile = createAsyncThunk(
  "user/profile",
  async ({ email }, { rejectWithValue }) => {
    try {
      
      let listing_url = `rnmgpo/${email}`;

      const response = await axiosConfig.get(listing_url);
      return await response.data ;
    } catch (error) {
      
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);



export const resetPassword = createAsyncThunk(
  "user/reset",
  async ({ guid, opass, cpass }, { rejectWithValue }) => {
    try {
      const response = await axiosConfig.post("/lgn/rnmcpuu", {
        "guid" : guid,
        "opass" : opass,
        "cpass" : cpass
      });

      return await response;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);

export const changeinfo = createAsyncThunk(
  "user/reset",
  async ({ guid, cell, country, dob }, { rejectWithValue }) => {
    try {
      const response = await axiosConfig.post("/lgn/rnmciuu", {
        "guid" : guid,
        "cell" : cell,
        "country" : country,
        "dob" : dob
      });

      return await response;
    } catch (error) {
      return rejectWithValue({
        error: error?.response.data
          ? error?.response.data.message
          : error?.message,
      });
    }
  }
);







