import React from 'react';

import { useDispatch } from "react-redux";
import { Suspense } from 'react';
import { FaHeart, FaRegHeart } from "react-icons/fa";


function Loading() {
    
    return <h1 style={{color : "black"}}>🌀 Loading...</h1>;
  }


function Homepremiumlisting(props) {
    let dispatch = useDispatch()
    
    const cars_pre = () => {
           return( 
            props.premium_listings?.map((x , y) =>{
                
                return (
                    <div>
                    <Suspense fallback={<Loading />}>
                    <div id={y} key={y} style={{border : "1px solid #f1f1f1", marginBottom : "15px" }} className='montserrat-400'>
                            <div style={{display : "flex"}}>
                                <div style={{width : "40%"}}>
                                
                                <div className="containerpremium">
                                    <div className="overlaypremiumverified">
                                    <div style={{marginLeft : "-10px"}}>
                                        {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                                          <button
                                            onClick={() => {
                                              props?.user !== null &&
                                                dispatch(
                                                  props.favProduct({
                                                    tluid: x?.tluid,
                                                    typed: "pull",
                                                  })
                                                );
                                            }}
                                            aria-label="heart"
                                          >
                                            <FaHeart
                                              color="red"
                                              size={props.isTabletOrMobile ? "15" : "28"}
                                            />
                                          </button>
                                        ) : (
                                          <button
                                          aria-label="Heart"
                                            onClick={() => {
                                              props?.user !== null &&
                                                dispatch(
                                                  props.favProduct({
                                                    tluid: x?.tluid,
                                                    typed: "push",
                                                  })
                                                );
                                            }}
                                          >
                                            <FaRegHeart
                                              color="red"
                                              size="28"
                                              style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                                            />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                
                                      <div className="overlaypremiumbrand">
                                        {x?.tenant?.cellIsVerified &&
                                          x?.tenant?.addressIsVerified && (
                                            <img
                                              src={props.verified}
                                              alt="verified"
                                              style={{ width: "25px" }}
                                            />
                                          )}
                                      </div>
                                      <div className="overlaypremiumprice">
                                     {/**    <img
                                          src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                                          alt="Brands"
                                          width="50px"
                                        /> */}
                                      </div>
                                    <img
                                        className="rounded-t-lg" 
                                        style={{
                                          width: "100%"
                                        }}
                                        src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                                        alt="imgmc"
                                        height="100" width="100"
                                        onClick={p => {
                                          props._handleListingView(x)
                                        }} 
                                    />
                                 </div>
                                </div>
                                
                                <div style={{width : "60%", fontSize : "0.7rem"}}>
                                    {/** Right Pannel */}
                                   
                                    <div style={{display : "flex", minHeight : "10vh"}}>
                                        <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}} >
                                            <span style={{padding : "5px" , fontWeight : "bold", color : "gray"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                                            <p style={{fontSize : "0.6rem" , color : "gray", padding : "5px"}}>{x.listingType}</p>
                                        </div>
                                        <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                                            <img
                                                className="rounded-t-lg"
                                                style={{
                                                width: "2vw",
                                                marginTop: 0,
                                                float : "right"
                                            }}
                                                src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                                                alt="Business Logo"
                                                width="30" height="30"
                                            />
                                            <div style={{ display: "flex", float: "right"  }}>
                                                {Array.from({
                                                length: parseInt(x?.tenant.rating) || 0,
                                                }).map((j, index1) => {
                                                return (
                                                    <svg
                                                    key={`filled-${y}=${index1}`}
                                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#F39C12"
                                                    viewBox="0 0 60 55"
                                                    width="50"
                                                    height="25"
                                                    style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                                    >
                                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                    </svg>
                                                );
                                                })}
                                                {parseFloat(x?.tenant.rating) >
                                                parseInt(x?.tenant.rating) && (
                                                <div style={{ position: "relative" }}>
                                                    <svg
                                                    key={`unfilled-1212`}
                                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                                    aria-hidden="true"
                                                    style={{
                                                        position: "absolute",
                                                        paddingRight: "-15px",
                                                        marginRight: "-15px",
                                                    }}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="grey"
                                                    viewBox="0 0 60 55"
                                                    width="50"
                                                    height="25"
                                                    >
                                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                    </svg>
                                                    <svg
                                                    style={{
                                                        position: "absolute",
                                                        paddingRight: "-15px",
                                                        marginRight: "-15px",
                                                    }}
                                                    key={`unfilled-12`}
                                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#F39C12"
                                                    viewBox="0 0 60 55"
                                                    width="50"
                                                    height="25"
                                                    >
                                                    <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                                    </svg>
                                                </div>
                                                )}
                                                {Array.from({
                                                length: 5 - parseInt(x?.tenant.rating),
                                                }).map((k, index2) => {
                                                return (
                                                    <>
                                                    <svg
                                                        key={`unfilled-${y}-${index2}`}
                                                        className="w-6 h-6 text-gray-800 dark:text-white"
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="grey"
                                                        viewBox="0 0 60 55"
                                                        width="50"
                                                        height="25"
                                                        style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                                    >
                                                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                    </svg>
                                                    </>
                                                );
                                                })}
                                            </div>

                                    </div>
                                    </div>
                                    <div >
                                        <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                                            <p><span className='s1'>{props.t("Model Year")}</span> : <span className='s2'>{x?.modelyear?.label}</span></p>
                                            <p><span className='s1'>{props.t("Body Type")}</span> : <span className='s2'>{x?.btname[props.selected_lang]}</span></p>
                                            <p><span className='s1'>{props.t("Transmission")}</span> : <span className='s2'>{x?.trmname[props.selected_lang] }</span></p>    
                                        </div>
                                        <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                                            <p><span className='s1'>{props.t("Fuel Type")}</span> : <span className='s2'>{x?.fuelname[props.selected_lang]} </span></p>
                                            <p><span className='s1'>{props.t("Mileage")}</span> : <span className='s2'>{x?.approxmiles} {props.t("mileqq")}</span></p>
                                        </div>
                                        <div className='column30 montserrat-400' >
                                        
                                            <p style={{fontWeight : "bold" , color : "black"}}>{props.t("PRICE")}</p>
                                            <p style={{fontWeight : "bold", color : "black"}} className="sp">{props.selected_curr} {parseFloat(Number( x?.daily?.amt) / (props.conversion_rate)).toFixed(0)} &nbsp; </p>
                                            
                                        </div>
                                        <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" }}>
                                                <div style={{width : "40%"}}>
                                                  
                                                </div>
                                                <div style={{width : "60%"}}>
                                                <div className='listingbutton' style={{width : "100%" , height : "15%" , maxHeight : "15%", display : "flex" , justifyContent : "flex-end"}} >
                                                            {props.isTabletOrMobile ? (
                                                                <form action={`tel:${x?.tenant?.cellNumberc}`}>
                                                                <button
                                                                
                                                                onClick={() => {
                                                                props._handleSaveCount(x, 2);
                                                                }}
                                                                >
                                                                <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                                    alt="call button abc"
                                                                />
                                                                </button>
                                                                </form>
                                                            ) : (
                                                                <button
                                                                style={{ fontSize: "0.5rem", color: "black" }}
                                                                onClick={() => {
                                                                    props._handleSaveCount(x, 2);
                                                                //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                                                }}
                                                                >
                                                                
                                                                {props.showcall?.id === x.tluid && props.showcall.boolv === true ? (
                                                                    <center>{x?.tenant?.cellNumberc}</center>
                                                                ) : (
                                                                    <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                                    alt="abc"
                                                                    />
                                                                )}
                                                                </button>
                                                            )}    
                                                        <div className="fazeel_line"></div>
                                                            <button
                                                            onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                                            > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                                                        <div className="fazeel_line"></div>
                                                            <button onClick={() => props._handleListingView(x)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                                                 </div>
                                               </div>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                         </div>
                    </Suspense>
                    </div>
                )
            }) 
        )
    }

    const cars_lis = () => {
        return( 
         props.listings?.map((x , y) =>{
           
             return (
                 <div id={y} key={y} style={{border : "1px solid #f1f1f1", marginBottom : "15px" }} className='montserrat-400'>
                         <div style={{display : "flex"}}>
                             <div style={{width : "40%"}}>
                             
                             <div className="containerpremium">
                                 <div className="overlaypremiumverified">
                                 <div style={{marginLeft : "-10px"}}>
                                     {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                                       <button
                                         onClick={() => {
                                           props?.user !== null &&
                                             dispatch(
                                               props.favProduct({
                                                 tluid: x?.tluid,
                                                 typed: "pull",
                                               })
                                             );
                                         }}
                                         aria-label="heart"
                                       >
                                         <FaHeart
                                           color="red"
                                           
                                           size={props.isTabletOrMobile ? "15" : "28"}
                                         />
                                       </button>
                                     ) : (
                                       <button
                                       aria-label="Heart"
                                         onClick={() => {
                                           props?.user !== null &&
                                             dispatch(
                                               props.favProduct({
                                                 tluid: x?.tluid,
                                                 typed: "push",
                                               })
                                             );
                                         }}
                                       >
                                         <FaRegHeart
                                           color="red"
                                           size="28"
                                           style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                                         />
                                       </button>
                                     )}
                                   </div>
                                 </div>
                             
                                   <div className="overlaypremiumbrand">
                                     {x?.tenant?.cellIsVerified &&
                                       x?.tenant?.addressIsVerified && (
                                         <img
                                           src={props.verified}
                                           alt="verified"
                                           style={{ width: "25px" }}
                                         />
                                       )}
                                   </div>
                                   <div className="overlaypremiumprice">
                                  {/**    <img
                                       src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                                       alt="Brands"
                                       width="50px"
                                     /> */}
                                   </div>
                                 <img
                                     className="rounded-t-lg" 
                                     style={{
                                       width: "100%"
                                     }}
                                     src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                                     alt="imgmc"
                                     height="100" width="100"
                                     onClick={p => {
                                       props._handleListingView(x)
                                     }} 
                                 />
                              </div>
                             </div>
                             
                             <div style={{width : "60%", fontSize : "0.7rem"}}>
                                 {/** Right Pannel */}
                                
                                 <div style={{display : "flex", minHeight : "10vh"}}>
                                     <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}} >
                                         <span style={{padding : "5px" , fontWeight : "bold", color : "gray"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                                         <p style={{fontSize : "0.6rem" , color : "gray", padding : "5px"}}>{x.listingType}</p>
                                     </div>
                                     <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                                         <img
                                             className="rounded-t-lg"
                                             style={{
                                             width: "2vw",
                                             marginTop: 0,
                                             float : "right"
                                         }}
                                             src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                                             alt="Business Logo"
                                             width="30" height="30"
                                         />
                                         <div style={{ display: "flex", float: "right"  }}>
                                             {Array.from({
                                             length: parseInt(x?.tenant.rating) || 0,
                                             }).map((j, index1) => {
                                             return (
                                                 <svg
                                                 key={`filled-${y}=${index1}`}
                                                 className="w-6 h-6 text-gray-800 dark:text-white"
                                                 aria-hidden="true"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 fill="#F39C12"
                                                 viewBox="0 0 60 55"
                                                 width="50"
                                                 height="25"
                                                 style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                                 >
                                                 <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                 </svg>
                                             );
                                             })}
                                             {parseFloat(x?.tenant.rating) >
                                             parseInt(x?.tenant.rating) && (
                                             <div style={{ position: "relative" }}>
                                                 <svg
                                                 key={`unfilled-1212`}
                                                 className="w-6 h-6 text-gray-800 dark:text-white"
                                                 aria-hidden="true"
                                                 style={{
                                                     position: "absolute",
                                                     paddingRight: "-15px",
                                                     marginRight: "-15px",
                                                 }}
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 fill="grey"
                                                 viewBox="0 0 60 55"
                                                 width="50"
                                                 height="25"
                                                 >
                                                 <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                 </svg>
                                                 <svg
                                                 style={{
                                                     position: "absolute",
                                                     paddingRight: "-15px",
                                                     marginRight: "-15px",
                                                 }}
                                                 key={`unfilled-12`}
                                                 className="w-6 h-6 text-gray-800 dark:text-white"
                                                 aria-hidden="true"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 fill="#F39C12"
                                                 viewBox="0 0 60 55"
                                                 width="50"
                                                 height="25"
                                                 >
                                                 <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                                 </svg>
                                             </div>
                                             )}
                                             {Array.from({
                                             length: 5 - parseInt(x?.tenant.rating),
                                             }).map((k, index2) => {
                                             return (
                                                 <>
                                                 <svg
                                                     key={`unfilled-${y}-${index2}`}
                                                     className="w-6 h-6 text-gray-800 dark:text-white"
                                                     aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     fill="grey"
                                                     viewBox="0 0 60 55"
                                                     width="50"
                                                     height="25"
                                                     style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                                 >
                                                     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                 </svg>
                                                 </>
                                             );
                                             })}
                                         </div>

                                 </div>
                                 </div>
                                 <div >
                                     <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                                         <p><span className='s1'>{props.t("Model Year")}</span> : <span className='s2'>{x?.modelyear?.label}</span></p>
                                         <p><span className='s1'>{props.t("Body Type")}</span> : <span className='s2'>{x?.btname[props.selected_lang]}</span></p>
                                         <p><span className='s1'>{props.t("Transmission")}</span> : <span className='s2'>{x?.trmname[props.selected_lang] }</span></p>    
                                     </div>
                                     <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                                         <p><span className='s1'>{props.t("Fuel Type")}</span> : <span className='s2'>{x?.fuelname[props.selected_lang]} </span></p>
                                         <p><span className='s1'>{props.t("Vehicle Type")}</span> : <span className='s2'>{x?.vtname[props?.selected_lang]} </span></p>
                                     </div>
                                     <div className='column30 montserrat-400' >
                                     
                                          
                                         <p style={{fontWeight : "bold", color : "black"}} className="sp">{props.selected_curr} {parseFloat(Number(props?.filters?.wdo  === "false" ?  x?.daily?.wdamt : x?.daily?.amt) / (props.conversion_rate)).toFixed(0)} 
                                         </p><span style={{fontSize : "0.5rem", fontWeight: "normal"}}> {props.t("PerDay")}</span>
                                     </div>
                                     <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" }}>
                                             <div style={{width : "40%"}}>
                                               
                                             </div>
                                             <div style={{width : "60%"}}>
                                             <div className='listingbutton' style={{width : "100%" , height : "15%" , maxHeight : "15%", display : "flex" , justifyContent : "flex-end"}} >
                                                         {props.isTabletOrMobile ? (
                                                             <form action={`tel:${x?.tenant?.cellNumberc}`}>
                                                             <button
                                                             
                                                             onClick={() => {
                                                             props._handleSaveCount(x, 2);
                                                             }}
                                                             >
                                                             <img
                                                                 src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                                 alt="call button abc"
                                                             />
                                                             </button>
                                                             </form>
                                                         ) : (
                                                             <button
                                                             style={{ fontSize: "0.5rem", color: "black" }}
                                                             onClick={() => {
                                                                 props._handleSaveCount(x, 2);
                                                             //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                                             }}
                                                             >
                                                             
                                                             {props.showcall?.id === x.tluid && props.showcall.boolv === true ? (
                                                                 <center>{x?.tenant?.cellNumberc}</center>
                                                             ) : (
                                                                 <img
                                                                 src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                                 alt="abc"
                                                                 />
                                                             )}
                                                             </button>
                                                         )}    
                                                     <div className="fazeel_line"></div>
                                                         <button
                                                         onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                                         > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                                                     <div className="fazeel_line"></div>
                                                         <button onClick={() => props._handleListingView(x)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                                              </div>
                                            </div>
                                     </div>
                                     
                                 </div>
                             </div>
                         </div>
                      </div>
             )
         }) 
     )
 }

    const yacht_pre = () => {
      return (

      
      props.premium_listings?.map((x , y) =>{
        
        return (
              <div>
              <Suspense fallback={<Loading />}>
              <div id={y} key={y} style={{border : "1px solid #f1f1f1", marginBottom : "15px" }} className='montserrat-400'>
                      <div style={{display : "flex"}}>
                          <div style={{width : "40%"}}>
                          
                          <div className="containerpremium">
                              <div className="overlaypremiumverified">
                              <div style={{marginLeft : "-10px"}}>
                                  {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                                    <button
                                      onClick={() => {
                                        props?.user !== null &&
                                          dispatch(
                                            props.favProduct({
                                              tluid: x?.tluid,
                                              typed: "pull",
                                            })
                                          );
                                      }}
                                      aria-label="heart"
                                    >
                                      <FaHeart
                                        color="red"
                                        size={props.isTabletOrMobile ? "15" : "28"}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                    aria-label="Heart"  
                                    onClick={() => {
                                        props?.user !== null &&
                                          dispatch(
                                            props.favProduct({
                                              tluid: x?.tluid,
                                              typed: "push",
                                            })
                                          );
                                      }}
                                    >
                                      <FaRegHeart
                                        color="red"
                                        size="28"
                                        style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                          
                                <div className="overlaypremiumbrand">
                                  {x?.tenant?.cellIsVerified &&
                                    x?.tenant?.addressIsVerified && (
                                      <img
                                        src={props.verified}
                                        alt="verified"
                                        style={{ width: "25px" }}
                                      />
                                    )}
                                </div>
                                <div className="overlaypremiumprice">
                              {/**     <img
                                    src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                                    alt="Brands"
                                    width="50px"
                                  /> */}
                                </div>
                              <img
                                  className="rounded-t-lg" 
                                  style={{
                                    width: "100%"
                                  }}
                                  src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                                  alt="imgmc"
                                  height="100" width="100"
                                  onClick={p => {
                                    props._handleListingView(x)
                                  }} 
                              />
                            </div>
                          </div>
                          
                          <div style={{width : "60%", fontSize : "0.7rem"}}>
                              {/** Right Pannel */}
                              
                              <div style={{display : "flex", minHeight : "10vh"}}>
                                  <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}} >
                                      <span style={{padding : "5px" , fontWeight : "bold", color : "gray"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                                      <p style={{fontSize : "0.6rem" , color : "gray", padding : "5px"}}>{x.listingType}</p>
                                  </div>
                                  <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                                      <img
                                          className="rounded-t-lg"
                                          style={{
                                          width: "2vw",
                                          marginTop: 0,
                                          float : "right"
                                      }}
                                          src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                                          alt="Business Logo"
                                          width="30" height="30"
                                      />
                                      <div style={{ display: "flex", float: "right"  }}>
                                          {Array.from({
                                          length: parseInt(x?.tenant.rating) || 0,
                                          }).map((j, index1) => {
                                          return (
                                              <svg
                                              key={`filled-${y}=${index1}`}
                                              className="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="#F39C12"
                                              viewBox="0 0 60 55"
                                              width="50"
                                              height="25"
                                              style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                              >
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                          );
                                          })}
                                          {parseFloat(x?.tenant.rating) >
                                          parseInt(x?.tenant.rating) && (
                                          <div style={{ position: "relative" }}>
                                              <svg
                                              key={`unfilled-1212`}
                                              className="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              style={{
                                                  position: "absolute",
                                                  paddingRight: "-15px",
                                                  marginRight: "-15px",
                                              }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="grey"
                                              viewBox="0 0 60 55"
                                              width="50"
                                              height="25"
                                              >
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                              <svg
                                              style={{
                                                  position: "absolute",
                                                  paddingRight: "-15px",
                                                  marginRight: "-15px",
                                              }}
                                              key={`unfilled-12`}
                                              className="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="#F39C12"
                                              viewBox="0 0 60 55"
                                              width="50"
                                              height="25"
                                              >
                                              <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                              </svg>
                                          </div>
                                          )}
                                          {Array.from({
                                          length: 5 - parseInt(x?.tenant.rating),
                                          }).map((k, index2) => {
                                          return (
                                              <>
                                              <svg
                                                  key={`unfilled-${y}-${index2}`}
                                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                                  aria-hidden="true"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="grey"
                                                  viewBox="0 0 60 55"
                                                  width="50"
                                                  height="25"
                                                  style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                              >
                                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                              </>
                                          );
                                          })}
                                      </div>

                              </div>
                              </div>
                              <div >
                                  <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                                      <p></p>
                                      <p>
                                      {x?.features[0]
                                          ?.ficon && (
                                          <>
                                          <div style={{marginRight : "5px", display : "flex"}}>
                                          <img
                                          style={{ height: "20px", width: "20px" }}
                                          src={`${process.env.REACT_APP_API_FS}${
                                              process.env.REACT_APP_API_URL
                                          }/fet/${
                                              x?.features[0]
                                              ?.ficon
                                          }`}
                                          alt="feature"
                                          />
                                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{x?.features[0][props.selected_lang]}</span>
                                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{x?.features[0].rangevalueL}</span>
                                          </div>
                                      
                                          </>
                                      )}</p>
                                  </div>
                                  <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                                      <p>
                                         <span className='s1'> . </span> 
                                      </p>
                                  </div>
                                  <div className='column30 montserrat-400' >
                                      <p style={{fontWeight : "bold", color : "black" , textAlign :"left"}} className="sp">
                                        {props.t("PRICE")}
                                        {parseFloat(Number(x?.hourly?.amt) / (props.conversion_rate)).toFixed(0)}
                                      <span style={{fontSize : "0.6rem", fontWeight: "normal"}}>{props.t("Hourly")}</span></p>
                                  </div>
                                  <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" }}>
                                          <div style={{width : "40%"}}>
                                            
                                          </div>
                                          <div style={{width : "60%"}}>
                                          <div className='listingbutton' style={{width : "100%" , height : "15%" , maxHeight : "15%", display : "flex" , justifyContent : "flex-end"}} >
                                                      {props.isTabletOrMobile ? (
                                                          <form action={`tel:${x?.tenant?.cellNumberc}`}>
                                                          <button
                                                          
                                                          onClick={() => {
                                                          props._handleSaveCount(x, 2);
                                                          }}
                                                          >
                                                          <img
                                                              src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                              alt="call button abc"
                                                          />
                                                          </button>
                                                          </form>
                                                      ) : (
                                                          <button
                                                          style={{ fontSize: "0.5rem", color: "black" }}
                                                          onClick={() => {
                                                              props._handleSaveCount(x, 2);
                                                          //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                                          }}
                                                          >
                                                          
                                                          {props.showcall?.id === x.tluid && props.showcall.boolv === true ? (
                                                              <center>{x?.tenant?.cellNumberc}</center>
                                                          ) : (
                                                              <img
                                                              src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                              alt="abc"
                                                              />
                                                          )}
                                                          </button>
                                                      )}    
                                                  <div className="fazeel_line"></div>
                                                      <button
                                                      onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                                      > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                                                  <div className="fazeel_line"></div>
                                                      <button onClick={() => props._handleListingView(x)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                                            </div>
                                          </div>
                                      </div>
                                  
                              </div>
                          </div>
                      </div>
              </div>
              </Suspense>
              </div>
          )
      }) 
      )
    }


    const yacht_lis = () => {
    return( 
    props.listings?.map((x , y) =>{
      
        return (
          <div id={y} key={y} style={{border : "1px solid #f1f1f1", marginBottom : "15px" }} className='montserrat-400'>
          <div style={{display : "flex"}}>
              <div style={{width : "40%"}}>
              
              <div className="containerpremium">
                  <div className="overlaypremiumverified">
                  <div style={{marginLeft : "-10px"}}>
                      {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                        <button
                          onClick={() => {
                            props?.user !== null &&
                              dispatch(
                                props.favProduct({
                                  tluid: x?.tluid,
                                  typed: "pull",
                                })
                              );
                          }}
                          aria-label="heart"
                        >
                          <FaHeart
                            color="red"
                            size={props.isTabletOrMobile ? "15" : "28"}
                          />
                        </button>
                      ) : (
                        <button
                        aria-label="Heart"
                          onClick={() => {
                            props?.user !== null &&
                              dispatch(
                                props.favProduct({
                                  tluid: x?.tluid,
                                  typed: "push",
                                })
                              );
                          }}
                        >
                          <FaRegHeart
                            color="red"
                            size="28"
                            style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                          />
                        </button>
                      )}
                    </div>
                  </div>
              
                    <div className="overlaypremiumbrand">
                      {x?.tenant?.cellIsVerified &&
                        x?.tenant?.addressIsVerified && (
                          <img
                            src={props.verified}
                            alt="verified"
                            style={{ width: "25px" }}
                          />
                        )}
                    </div>
                    <div className="overlaypremiumprice">
                     {/**  <img
                        src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                        alt="Brands"
                        width="50px"
                      />*/}
                    </div>
                  <img
                      className="rounded-t-lg" 
                      style={{
                        width: "100%"
                      }}
                      src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                      alt="imgmc"
                      height="100" width="100"
                      onClick={p => {
                        props._handleListingView(x)
                      }} 
                  />
                </div>
              </div>
              
              <div style={{width : "60%", fontSize : "0.8rem"}}>
                  {/** Right Pannel */}
                  
                  <div style={{display : "flex", minHeight : "10vh"}}>
                      <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}} >
                          <span style={{padding : "5px" , fontWeight : "bold", color : "gray"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                          <p style={{fontSize : "0.6rem" , color : "gray", padding : "5px"}}>{x.listingType}</p>
                      </div>
                      <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                          <img
                              width="30" height="30"
                              className="rounded-t-lg"
                              style={{
                              width: "2vw",
                              marginTop: 0,
                              float : "right"
                          }}
                              src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                              alt="Business Logo"
                          />
                          <div style={{ display: "flex", float: "right"  }}>
                              {Array.from({
                              length: parseInt(x?.tenant.rating) || 0,
                              }).map((j, index1) => {
                              return (
                                  <svg
                                  key={`filled-${y}=${index1}`}
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#F39C12"
                                  viewBox="0 0 60 55"
                                  width="50"
                                  height="25"
                                  style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                  >
                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                              );
                              })}
                              {parseFloat(x?.tenant.rating) >
                              parseInt(x?.tenant.rating) && (
                              <div style={{ position: "relative" }}>
                                  <svg
                                  key={`unfilled-1212`}
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  style={{
                                      position: "absolute",
                                      paddingRight: "-15px",
                                      marginRight: "-15px",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="grey"
                                  viewBox="0 0 60 55"
                                  width="50"
                                  height="25"
                                  >
                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  <svg
                                  style={{
                                      position: "absolute",
                                      paddingRight: "-15px",
                                      marginRight: "-15px",
                                  }}
                                  key={`unfilled-12`}
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#F39C12"
                                  viewBox="0 0 60 55"
                                  width="50"
                                  height="25"
                                  >
                                  <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                  </svg>
                              </div>
                              )}
                              {Array.from({
                              length: 5 - parseInt(x?.tenant.rating),
                              }).map((k, index2) => {
                              return (
                                  <>
                                  <svg
                                      key={`unfilled-${y}-${index2}`}
                                      className="w-6 h-6 text-gray-800 dark:text-white"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="grey"
                                      viewBox="0 0 60 55"
                                      width="50"
                                      height="25"
                                      style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                  >
                                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  </>
                              );
                              })}
                          </div>

                  </div>
                  </div>
                  <div >
                      <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                          <p><span className='s1'> </span></p>
                          <p style={{padding : "5px"}}>
                                      {x?.features[0]
                                          ?.ficon && (
                                          <>
                                          <div style={{marginRight : "5px", display : "flex"}}>
                                          <img
                                          style={{ height: "20px", width: "20px" }}
                                          src={`${process.env.REACT_APP_API_FS}${
                                              process.env.REACT_APP_API_URL
                                          }/fet/${
                                              x?.features[0]
                                              ?.ficon
                                          }`}
                                          alt="feature"
                                          />
                                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{x?.features[0][props.selected_lang]}</span>
                                          <span style={{paddingLeft : "3px" ,paddingRight : "5px" , fontSize : "0.5rem", fontWeight : "bold", marginTop : "2px"}}>{x?.features[0].rangevalueL}</span>
                                          </div>
                                      
                                          </>
                                      )}</p>    
                      </div>
                      <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                          <p>.</p>
                      </div>
                      <div className='column30 montserrat-400' style={{textAlign : "left"}}>
                          <p style={{fontWeight : "bold", color : "black"}}>{props.t("PRICE")}</p>
                          <p style={{fontWeight : "bold", color : "black"}} className="sp">{parseFloat(Number(props?.filters?.wdo === "false" ?  x?.hourly?.wdamt : x?.hourly?.amt) / (props.conversion_rate)).toFixed(0)}
                          <span style={{fontWeight : "normal" , color : "black", fontSize : "0.6rem"}}>/{props.t("PerHour")} </span></p>
                      </div>
                      <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" }}>
                              <div style={{width : "40%"}}>
                                
                              </div>
                              <div style={{width : "60%"}}>
                              <div className='listingbutton' style={{width : "100%" , height : "15%" , maxHeight : "15%", display : "flex" , justifyContent : "flex-end"}} >
                                          {props.isTabletOrMobile ? (
                                              <form action={`tel:${x?.tenant?.cellNumberc}`}>
                                              <button
                                              
                                              onClick={() => {
                                              props._handleSaveCount(x, 2);
                                              }}
                                              >
                                              <img
                                                  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                  alt="call button abc"
                                              />
                                              </button>
                                              </form>
                                          ) : (
                                              <button
                                              style={{ fontSize: "0.5rem", color: "black" }}
                                              onClick={() => {
                                                  props._handleSaveCount(x, 2);
                                              //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                              }}
                                              >
                                              
                                              {props.showcall?.id === x.tluid && props.showcall.boolv === true ? (
                                                  <center>{x?.tenant?.cellNumberc}</center>
                                              ) : (
                                                  <img
                                                  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                  alt="abc"
                                                  />
                                              )}
                                              </button>
                                          )}    
                                      <div className="fazeel_line"></div>
                                          <button
                                          onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                          > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                                      <div className="fazeel_line"></div>
                                          <button onClick={() => props._handleListingView(x)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                                </div>
                              </div>
                          </div>
                      
                  </div>
              </div>
          </div>
  </div>
        )
    }) 
    )
    }

    const machine_pre = () => {
      return (
        props.listings?.map((x , y) =>{
         return (
              <div id={y} key={y} style={{border : "1px solid #f1f1f1", marginBottom : "15px" }} className='montserrat-400'>
                      <div style={{display : "flex"}}>
                          <div style={{width : "40%"}}>
                          
                          <div className="containerpremium">
                              <div className="overlaypremiumverified">
                              <div style={{marginLeft : "-10px"}}>
                                  {props?.user && props?.user_profile?.usertags?.includes(x?.tluid) ? (
                                    <button
                                      onClick={() => {
                                        props?.user !== null &&
                                          dispatch(
                                            props.favProduct({
                                              tluid: x?.tluid,
                                              typed: "pull",
                                            })
                                          );
                                      }}
                                      aria-label="heart"
                                    >
                                      <FaHeart
                                        color="red"
                                        
                                        size={props.isTabletOrMobile ? "15" : "28"}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                    aria-label="Heart"
                                      onClick={() => {
                                        props?.user !== null &&
                                          dispatch(
                                            props.favProduct({
                                              tluid: x?.tluid,
                                              typed: "push",
                                            })
                                          );
                                      }}
                                    >
                                      <FaRegHeart
                                        color="red"
                                        size="28"
                                        style={{ paddingRight: props.isTabletOrMobile ? "10px" : "0" }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                          
                                <div className="overlaypremiumbrand">
                                  {x?.tenant?.cellIsVerified &&
                                    x?.tenant?.addressIsVerified && (
                                      <img
                                        src={props.verified}
                                        alt="verified"
                                        style={{ width: "25px" }}
                                      />
                                    )}
                                </div>
                                <div className="overlaypremiumprice">
                               {/**    <img
                                    src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/brands/${x?.blogo}`}
                                    alt="Brands"
                                    width="50px"
                                  /> */}
                                </div>
                              <img
                                  className="rounded-t-lg" 
                                  style={{
                                    width: "100%"
                                  }}
                                  src={`${process.env.REACT_APP_API_FS}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/listing/${x?.imgmc}`}
                                  alt="imgmc"
                                  height="100" width="100"
                                  onClick={p => {
                                    props._handleListingView(x)
                                  }} 
                              />
                           </div>
                          </div>
                          
                          <div style={{width : "60%", fontSize : "0.7rem"}}>
                    {/** Right Pannel */}
                             
                              <div style={{display : "flex", minHeight : "10vh"}}>
                                  <div className='columnx' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}} >
                                      <span style={{padding : "5px" , fontWeight : "bold", color : "gray"}}>{x.bname[props.selected_lang]} {x.mname[props.selected_lang]}</span>
                                      <p style={{fontSize : "0.6rem" , color : "gray", padding : "5px"}}>{x.vtname[props.selected_lang]}</p>
                                  </div>
                                  <div className='columny' style={{ display : "grid" , justifyContent : props.selected_lang === "en" ?  "right" : "left"}} >
                                      <img
                                          width="30" height="30"
                                          className="rounded-t-lg"
                                          style={{
                                          width: "2vw",
                                          marginTop: 0,
                                          float : "right"
                                      }}
                                          src={`${process.env.REACT_APP_API_CL}${process.env.REACT_APP_API_URL}/limg/${x?.tuid}/${x?.tenant.cLogo}`}
                                          alt="Business Logo"
                                      />
                                      <div style={{ display: "flex", float: "right"  }}>
                                          {Array.from({
                                          length: parseInt(x?.tenant.rating) || 0,
                                          }).map((j, index1) => {
                                          return (
                                              <svg
                                              key={`filled-${y}=${index1}`}
                                              className="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="#F39C12"
                                              viewBox="0 0 60 55"
                                              width="50"
                                              height="25"
                                              style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                              >
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                          );
                                          })}
                                          {parseFloat(x?.tenant.rating) >
                                          parseInt(x?.tenant.rating) && (
                                          <div style={{ position: "relative" }}>
                                              <svg
                                              key={`unfilled-1212`}
                                              className="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              style={{
                                                  position: "absolute",
                                                  paddingRight: "-15px",
                                                  marginRight: "-15px",
                                              }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="grey"
                                              viewBox="0 0 60 55"
                                              width="50"
                                              height="25"
                                              >
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                              <svg
                                              style={{
                                                  position: "absolute",
                                                  paddingRight: "-15px",
                                                  marginRight: "-15px",
                                              }}
                                              key={`unfilled-12`}
                                              className="w-6 h-6 text-gray-800 dark:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="#F39C12"
                                              viewBox="0 0 60 55"
                                              width="50"
                                              height="25"
                                              >
                                              <path d="m 12 7 l 0 -6 a 1.534 1.534 0 0 0 -2.752 0 L 7.365 5.847 l -5.051 0.734 A 1.535 1.535 0 0 0 1.463 9.2 l 3.656 3.563 l -0.863 5.031 a 1.532 1.532 0 0 0 2.226 1.616 L 11 17.033 l 1 -5.033 Z" />
                                              </svg>
                                          </div>
                                          )}
                                          {Array.from({
                                          length: 5 - parseInt(x?.tenant.rating),
                                          }).map((k, index2) => {
                                          return (
                                              <>
                                              <svg
                                                  key={`unfilled-${y}-${index2}`}
                                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                                  aria-hidden="true"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="grey"
                                                  viewBox="0 0 60 55"
                                                  width="50"
                                                  height="25"
                                                  style={{ paddingRight: "-15px", marginRight: "-15px" }}
                                              >
                                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                              </svg>
                                              </>
                                          );
                                          })}
                                      </div>

                              </div>
                              </div>
                              <div >
                                  <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                                      <p><span className='s1'>{props.t("Model Year")}</span> : <span className='s2'>{x?.modelyear?.label}</span></p>
                                      <p></p>
                                      <p><span className='s1' style={{marginTop : "15px"}}>{x?.hourly?.amt && Number(x?.hourly?.amt) >= 1 && <>{props.t("hourly")} @ {x?.hourly?.amt} {props.selected_curr}</> }</span></p>    
                                  </div>
                                  <div className='column30 montserrat-400' style={{textAlign : props.selected_lang === "en" ? "left" : "right"}}>
                                      <p><span className='s1'>{props.t("Fuel Type")}</span> : <span className='s2'>{x?.fuelname[props.selected_lang]} </span></p>
                                      <p></p>
                                  </div>
                                  <div className='column30 montserrat-400' >
                                  
                                       
                                      <p style={{fontWeight : "bold", color : "black"}} className="sp">{props.selected_curr} {parseFloat(Number(props?.filters?.wdo  === "false" ?  x?.daily?.wdamt : x?.daily?.amt) / (props.conversion_rate)).toFixed(0)} 
                                      </p><span style={{fontSize : "0.5rem", fontWeight: "normal"}}> {props.t("PerDay")}</span>
                                  </div>
                                  <div className='montserrat-400' style={{display : "flex", justifyContent : "flex-end", width : "100%" ,textAlign : props.selected_lang === "en" ? "left" : "right", fontSize : "0.7rem" }}>
                                          <div style={{width : "40%"}}>
                                            
                                          </div>
                                          <div style={{width : "60%"}}>
                                          <div className='listingbutton' style={{width : "100%" , height : "15%" , maxHeight : "15%", display : "flex" , justifyContent : "flex-end"}} >
                                                      {props.isTabletOrMobile ? (
                                                          <form action={`tel:${x?.tenant?.cellNumberc}`}>
                                                          <button
                                                          
                                                          onClick={() => {
                                                          props._handleSaveCount(x, 2);
                                                          }}
                                                          >
                                                          <img
                                                              src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                              alt="call button abc"
                                                          />
                                                          </button>
                                                          </form>
                                                      ) : (
                                                          <button
                                                          style={{ fontSize: "0.5rem", color: "black" }}
                                                          onClick={() => {
                                                              props._handleSaveCount(x, 2);
                                                          //   setShowcall({ ...showcall, id: item.tluid, boolv: true, boolw : false });
                                                          }}
                                                          >
                                                          
                                                          {props.showcall?.id === x.tluid && props.showcall.boolv === true ? (
                                                              <center>{x?.tenant?.cellNumberc}</center>
                                                          ) : (
                                                              <img
                                                              src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/call.webp`}
                                                              alt="abc"
                                                              />
                                                          )}
                                                          </button>
                                                      )}    
                                                  <div className="fazeel_line"></div>
                                                      <button
                                                      onClick={() => {props._handleWaCount(x, "1"); props.setShowcall({ ...props.showcall, id: x.tluid, boolv: false, boolw : true })}}
                                                      > <img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Wa.webp`} /></button>
                                                  <div className="fazeel_line"></div>
                                                      <button onClick={() => props._handleListingView(x)}><img alt="sidearrowr"  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Det.webp`} /></button>
                                           </div>
                                         </div>
                                  </div>
                                  
                              </div>
                          </div>
                      </div>
                   </div>
          )
      }) 
      )
    } 
 

    return (
        <>
        
        { props?.id === 1 && props.typeis === "premium" && cars_pre()}
        { props?.id === 1 && props.typeis !== "premium" && cars_lis()}

        { props?.id === 2 && machine_pre()}
        

        { props?.id === 3 && props.typeis === "premium" && yacht_pre()}
        { props?.id === 3 && props.typeis !== "premium" && yacht_lis()}
        </>
    );
}

export default Homepremiumlisting;