import React from 'react'

export default function NoInternet() {
  return (
    <div className="bg-white dark:bg-gray-900 h-screen"> 
      <section >
    <div className="py-8 px-4 mx-auto max-w-screen-xl  lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
            <center>
              <a href="/"><img
                      class="rounded-t-lg"
                      src={process.env.REACT_APP_PUBLIC_URL + "images/vnmlogo.png"}
                      alt=""
                      width={"250px"}
            /></a></center>
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-7xl text-primary-600 dark:text-primary-500">Service Unreachable
</h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something is wrong.</p>
            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Please check your internet connection or reach us at support@vnmrental.com</p>
            
            <a href="/" className="inline-flex bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</a>
        </div>   
    </div>
</section>
    </div>
  )
}
