import { useEffect, useRef, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceRangex from "../../components/prslider/range";
import '../../assets/vnmstyles.scss'
import {
  getFilterMaster,
  getListings,
  getdistvbcall,
  getPremiumListings,
  handleChangeFilterValue,
  handleListingValChange,
  handleClearAllFilters,
  saveCount,
  getAdvanceListings,
  saveFilterQuery,
  handleClearSearch,
  implementSavedTemplate,
} from "../../store/listings/listingActions";
import Homemachine from "./homes/Homemachine";
import { resetup } from "../../store/auth/authhelpers.js";
import { errorToast, successToast } from "../../utils";
import { handleValLangChange } from "../../store/lang/langActions";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { handleValCurrencyChange } from "../../store/currency/currencyActions";
import Menuvtwo from "./homecomps/Menu_vtwo"

import { useMediaQuery } from "react-responsive";
import {
    useLocation,
    useNavigate,
    useParams,
    NavLink,
  } from "react-router-dom";
  import axiosConfig from "../../utils/axiosConfig.jsx";  
import Footer from "./new_main/Footer";

//import Abovef from "./Abovef";
//import HomePremium from "./homes/HomePremium"
import Lottie from "react-lottie";
import LoadingAnimation from "../../assets/lottie/loading_animation.json";
import Homefilters from "./homes/Homefilters"
import HomeMfilters from "./homes/HomeMfilters"
//import Vnmbrands from "./homes/Vnmbrands"
import Vnmservices from "./homes/Vnmservices"
import Userapp from "./homes/Userapp"
import Swal from "sweetalert2";
import Homecar from "./homes/Homecar";
import Homecarp from "./homes/Homecarp";

import { GoogleLogin } from "@react-oauth/google";

import {
  
  loginGoogleUser,
  loginUser,
  registerUser,
} from "../../store/auth/authActions";
import { Toaster } from "react-hot-toast";

//import { Carousel } from "react-responsive-carousel";

const Vnmbrands = lazy(() => import('./homes/Vnmbrands'));     
const Homemarket =lazy(() => import("./homes/Homemarket")) ;
const Vnmwhatclient = lazy(() => import("./homes/Vnmwhatclient")) 


const Home = () => {
  
    const navigate = useNavigate();
    const navlink1 = useRef(null);
    const navlink2 = useRef(null);
    const navlink3 = useRef(null);
    const location = useLocation();
    
  
    let email = useRef("");
    let fullName = useRef("");
    let password = useRef("");
    let cell = useRef("");

    const brandsr = useRef(null);
    const aboutusr = useRef(null);
    const expmorepref = useRef(null);
    
    const [showcall, setShowcall] = useState({eventid : "", id: "", boolv: false, boolw : false });

    const [id, setId] = useState("car");
    const { t } = useTranslation();
    var dispatch = useDispatch();
    
    const appRef = useRef();
    const listRef0 = useRef();
    
    const listRef2 = useRef();
    const { type } = useParams();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
   
    const [loginModel, setLoginModel] = useState(false);
    
    const [signupModel, setSignupModel] = useState(false);
   
    

    const { langs, selected_lang } = useSelector((state) => state.lang);
    const { distvbc } = useSelector((state) => state.listing);
    const [numba, setNumba] = useState(0)
    const [numbb, setNumbb] = useState(0)
    const [numbp, setNumbp] = useState(0)
    const [uniqlist , setUniqlist] = useState([])


    const { selected_curr, currencies, conversion_rate } =
    useSelector((state) => state.curr);

    const [emailx, setEmailx] = useState("")
    const [otpmodal, setOtpmodal] = useState(false);
    const [advanceFilterIsOpen, setAdvanceFilterIsOpen] = useState(false);
    
    const [showProcessing, setShowProcessing] = useState(false)    
    
    const [validationError, setValidationError] = useState("");

    const [advanceFilter, setAdvanceFilter] = useState([]);
    
    
    const [allbrandslist , setAllbrandslist] = useState([])
    
    const [distvals, setDistvals] = useState([])
    const [seldistvals, setSeldistvals] = useState("") 

    const [cwList, setCwList] = useState([])
    const [vtList, setVtList] = useState([])

    const [mdataone, setMdataone] = useState([])

    const setScroll = () => {
      try{
        if(premium_listings[0]?.categoryCode !== cwList?.data[0]?.categoryCode){
          window.location.reload(false)
          errorToast("Diff lists")
        }
      }catch(e){

      }
      
    };


    const getsplist = (ftype, tval, ord , sort ) => {
      let idx = _getCatId()
      if(ftype){
        axiosConfig(
          {
            method: "get",
            url : `/gettlists/${idx}/${ftype}/${tval}/${ord}/${sort}`,
           
            }
          ).then(t => {
            setShowProcessing(false)
            if(ftype === "city"){
              setCwList(t.data)
              
            }else {
              setVtList(t.data)
            
            }
   
          })
          .catch(e => {
            
            setShowProcessing(false)
            errorToast("some listings are not available, please try to select category.");
          }) 
      }
      
    }

// This is seperate call for machines only .... we are not using it for city or type filter for now
    const getmachines = (ftype, tval, ord , sort ) => {
      axiosConfig(
        {
          method: "get",
          url : `/getmachines/${ftype}/${tval}/${ord}/${sort}`,
         
          }
        ).then(t => {
          setShowProcessing(false)
          if(ftype === "city"){
           // setCwList(t.data)
            
          }else if(ftype ==="type") {
         //   setVtList(t.data)
          
          }else{
            
            setMdataone(t.data.data)
          }
 
        })
        .catch(e => {
          
          setShowProcessing(false)
          errorToast("some listings are not available, please try to select category.");
        }) 
    }

    
      const _handleAdvanceFilterHandler = () => {
        fsearches();
      };


     const _handleWaCount = (item, type) => {
      if (!user) {
        dispatch(saveCount({ item, type, user: { ubd: { guid: "" } } })).unwrap().then(res => {
          
          
          setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : false, boolw : true})
        });
      } else {
        dispatch(saveCount({ item, type, user })).unwrap().then(res => {
          
          setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : false, boolw : true})
        });
      }
      window.open(
        `https://wa.me/${item?.tenant?.waNumber}?text=Hi,%20messaging%20you%20regarding%20your%20listing%20${item?.bname[selected_lang]}%20,%20${item?.mname[selected_lang]}%20,%20${item?.modelyear.label}%20which%20have%20id%20as%20${item?.tluid}%20on%20https://VNMRENTAL.com%20is%20it%20available?`,
        "_blank",
        "noreferrer"
      );
  
    };

    const _handleSaveCount = async (item, type) => {
      
      if (type === 3 && !user) {
        
        
      } else if (type === 3 && user) {
        
      } else {
        dispatch(saveCount({ item, type, user }))
          .unwrap()
          .then((res) => {
            if (type === 3) {
              Swal.fire({
                title: t("REQUEST_SENT"),
                text: t("CALLBACK_MESSAGE"),
                icon: "info",
                confirmButtonText: t("COOL"),
                confirmButtonColor: "#F39C12",
              });
            }else{
              
              setShowcall({ eventid : res.eventid[0]?._id, id : item.tluid, boolv : type=== 2 ? true : false, boolw : false})
            }
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
      }
    };

    const {
        filter_master,
        filters_master,
        limit,
        page,
        filters,
        search,
        listing_count,
        premium_listings,
        loading_premium_listings,
        
      } = useSelector((state) => state.listing);

    const { user, loading } = useSelector(
        (state) => state.auth
      );

      const _handleSearch = () => {
        let cat_id = _getCatId();
    
        dispatch(handleListingValChange({ key: "page", value: 0 }));
        
      
        dispatch(getListings({ limit, page: 0, filters, search, cat_id }))
          .unwrap()
          .then(() => {
            
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
        
      };


      const _handleSearchText = (e) => {
        dispatch(handleListingValChange({ key: "search", value: e.target.value }));
      };

      const _getCatId = () => {
        
        switch (type) {
          case "car":
            return "1";
          case "machines":
            return "2";
          case "2":
              return "2";


          case "yatch":
            return "3";
          case "3":
            return "3";
    
          default:
            return "1";
        }
      };

      function scrollToMorelisting() {
        expmorepref.current.scrollIntoView({ behavior: "smooth", block: "start" })
      }

      useEffect(() => {
      
          let cat_id = _getCatId();
          dispatch(handleClearAllFilters({}))
          cat_id && dispatch(getdistvbcall({cat_id}))

          dispatch(
            getPremiumListings({
              limit: 100,
              page: 0,
              model: 0,
              cat_id: cat_id,
              city_id: "-",
            })
          );

          dispatch(getListings({ limit, page: 0, filters, search, cat_id }))
          .unwrap()
          .then(() => {
            
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });


        dispatch(handleListingValChange({ key: "page", value: 0 }));
        dispatch(handleListingValChange({ key: "activeSlide", value: 0 }));
        dispatch(handleListingValChange({ key: "selectedBrandModels", value: [] }));
        dispatch(handleChangeFilterValue({ key: "sort", value: "-/1" }));

        dispatch(getFilterMaster({}))
          .unwrap()
          .then(() => {})
          .catch((errorData) => {
            errorToast(errorData.error);
          });
          

          let master_filters_values = filter_master?.features?.find(
            (f) => f.categoryCode === cat_id
          )?.features;

          setAdvanceFilter(master_filters_values)
//setAllbrandslist(distvals[0]?.bid ? distvals[0]?.bid  : filter_master?. )
          if(filters?.brandCode === "-"){
            setAllbrandslist(distvals[0]?.bid ? distvals[0]?.bid  : filters_master?.brands)
          }

          if(!distvals){
            
            axiosConfig(`/getdistvb/${cat_id}`).then(t => {
              
            setDistvals(t.data)
            
              
          })
          }
          
       window.addEventListener("scroll", setScroll);
       return () => {
         window.removeEventListener("scroll", setScroll);
       };

// eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      useEffect(() => {
        let cat_id = _getCatId();
        dispatch(
          getPremiumListings({
            limit: 100,
            page: 0,
            model: 0,
            cat_id: cat_id,
            city_id: "-",
          })
        );
        let xd = filters
        setShowProcessing(true)
        axiosConfig.post(
         `/xgetdistvb`,
           {...xd , limit , page}
          
         ).then(t => {
          setShowProcessing(false)
           setUniqlist(t.data.data.cbvtm)
         })
         .catch(e => {
           
           setShowProcessing(false)
           errorToast("some listings are not available, please try to select category.");
         }) 
         
         
 // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [filters])

      useEffect(() => {
        
        switch (type) {
          case "car":
            setId("1");
            axiosConfig(`/getdistvb/1`).then(t => {
              setDistvals(t.data)
            })
            break;
          case "machines":
            setId("2");
            axiosConfig(`/getdistvb/2`).then(t => {
              setDistvals(t.data)
            })
            break;
    
          case "yatch":
            
            setId("3");
            axiosConfig(`/getdistvb/3`).then(t => {
              setDistvals(t.data)
            })
            break;
    
          default:
            setId("1");
            axiosConfig(`/getdistvb/1`).then(t => {
              
              setDistvals(t.data)
            })
            break;
        }
        if(!type){
          navigate("/car")
          setId("1");
        }
        dispatch(
          handleChangeFilterValue({
            key: "category",
            value: _getCatId(),
          })
        );
        
// eslint-disable-next-line react-hooks/exhaustive-deps
      }, [type, location]);


      const _handleClearAllFilters = () => {
        let cat_id = _getCatId();

        let master_filters_values = filter_master?.features?.find(
          (f) => f.categoryCode === cat_id
        )?.features;
        
        dispatch(handleClearAllFilters({}))
          .unwrap()
          .then(() => {
                        
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
          
          setAdvanceFilter(master_filters_values)
      };

      const _handleChangeTranslate = () => {
        let selectedLanguage = selected_lang === langs[0] ? langs[1] : langs[0];
        dispatch(
          handleValLangChange({ key: "rtl", value: selectedLanguage === langs[1] })
        );
        dispatch(
          handleValLangChange({ key: "selected_lang", value: selectedLanguage })
        );
        successToast(t(`Language Changed to ${selectedLanguage}`));
      };

      const _handleListingView = (item) => {
        _handleSaveCount(item, 0);
        dispatch(handleListingValChange({ key: "selectedListing", value: item }));
        navigate(`/item/${item?.tluid}-${item?._id}`);
      };

      const _handleChangeCurrency = () => {
         
        let selectedCurrency = 
          selected_curr === currencies[0] ? currencies[1] : currencies[0];

          

          let valuec = 1
          if(selected_curr === "AED"){
            valuec = filter_master.currencyinfo.find(t => t.name === "Dollar").value
          }else{
            valuec = filter_master.currencyinfo.find(t => t.name === "Dirham").value
          }
          
          dispatch(
          handleValCurrencyChange({
            key: "conversion_rate",
            value: String(valuec),
          })
        );
        dispatch(
          handleValCurrencyChange({ key: "selected_curr", value: selectedCurrency })
        );
        successToast(t(`Currency Changed to ${selectedCurrency}`));
      };

      const oAuth = (gauth, resp) => {
        if (gauth === "GOOGLE")
          dispatch(
            loginGoogleUser({
              g_response: resp,
            })
          )
            .unwrap()
            .then(() => {
              successToast("User Login Successfully");
              setLoginModel(false);
            })
            .catch((errorData) => {
              errorToast(errorData.error);
            });
      };
    
      const signinUser = async (e) => {
        e.preventDefault();
    
        dispatch(
          loginUser({
            email: email.value,
            password: password.value,
          })
        )
          .unwrap()
          .then(() => {
            e.target.reset();
            successToast("User Login Successfully");
            setLoginModel(false);
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
      };

      function afterOpenModal() {}

      const activateUser = (e, r) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (
          !r?.value?.trim() 
        ) {
          setValidationError("OTP is required");
        } else {
          axiosConfig.post(`${process.env.REACT_APP_API_URL}/aclink`, {id : emailx, codex : r?.value }, {headers : {"userAccessToken" : id}})
          .then(r => {
            if(r.data.message === "User is activated."){
              successToast("User Activated.");
              setOtpmodal(false)
            }
          })
            .catch((errorData) => {
              errorToast("Expired or Incorrect OTP.");
            });
        }
      };

      const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          fontSize : "0.8rem",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          zIndex: 999999,
        },
        overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
      };
    
      const signUpUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (
          !fullName.value.trim() ||
          !email.value.trim() ||
          !password.value.trim()
        ) {
          setValidationError("All fields are required");
        } else {
          dispatch(
            registerUser({
              fullName: fullName.value,
              email: email.value,
              password: password.value,
              phone: cell.value,
            })
          )
            .unwrap()
            .then(() => {
              e.target.reset();
              successToast("Registered Successfully, Check activation mail.");
              setOtpmodal(true)
            })
            .catch((errorData) => {
              if(errorData.error === "User already registered, OTP reset"){
                errorToast("User already registered, OTP reset");
                setOtpmodal(true)
              }else{
                errorToast(errorData.error);
              }
              
            });
        }
      };

      const expmore = (typeis) => {
        
        return (
            <button  className='shadow montserrat-400 findbutton trasor'  style={{position : "relative",  top : "3vh", fontWeight : "bold", borderRadius : "5px" , height : "5vh"}}
             onClick={c => {c.preventDefault();  navigate(`/listings/${id}/${typeis}`)}}>{t("Explore More")}</button>
          
          
        )
      }

      const fsearches = () => {
        let nadv = advanceFilter.filter(
          (g) =>
            g.rangevalueL !== "" &&
            g.rangevalueL !== false &&
            g.rangevalueL !== undefined
        );
        let new_filters = nadv.map((filter) => {
          if (filter.rangevalueL === true) {
            return {
              ...filter,
              rangevalueL: "1",
            };
          } else {
            return filter;
          }
        });
        let filters = JSON.stringify(new_filters);
        let cat_id = id || "1";
        dispatch(handleListingValChange({ key: "page", value: 0 }));
        dispatch(getAdvanceListings({ limit, page: 0, filters, cat_id }));
        
      };

      const searchyacht = () => {
        return (
          <div
            className=" -mt-14 rounded montserrat-400"
            style={{ background: "white" , width : "83%"}}
          >
            <div className="flex flex-row py-1 justify-between rounded items-center">
              <p

                style={{
                  fontSize: "0.8rem",
                }}
              >
                <span
                  style={{
                    color: "black",
                    paddingLeft: "20px",
                  }}
                >
                  *{t("Select criteria or leave them empty")}
                </span>
              </p>
              <p>
                <span
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  {/**t("FIND_MORE_OPTIONS") */}
                  {listing_count ? (
                    <>
                      {listing_count} {t("SEARCHRESULTS")}
                    </>
                  ) : (
                    ""
                  )}
                </span>{" "}
              </p>
              {id !== "3" && (
                <div className="flex flex-col">
                  <button
                    onClick={() => _handleClearAllFilters()}
                    className="mt-2"
                    style={{ cursor: "pointer" }}
                  >
                    {t("CLEARALLFILTERS")}
                  </button>
                </div>
              )}
            </div>
    
            <div className="flex flex-row items-center px-4 gap-4 mt-2">
              <div className="flex items-center mt-4 basis-1/6">
                <select
                  aria-label="State"
                  value={filters.citycode}
                  id="xgf"
                  style={{fontSize : "0.6rem"}}
                  className="border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(e) => {
                    dispatch(
                      handleChangeFilterValue({
                        key: "citycode",
                        value: e.target.value,
                      })
                    );
                  }}
                >
                  <option value={"-"}>{t("Select City")}</option>
                  {filters_master?.cities.map((city, index) => (
                    <option key={"brand" + index} value={city.cityCode}>
                      {city?.[selected_lang]}
                    </option>
                  ))}
    
                  <div className="flex flex-row items-center px-4 gap-4 ">
                    <div className="basis-1/6">
                      <div className="flex items-center mt-4">
                        <input
                          id="wdo"
                          type="checkbox"
                          checked={filters.wdo !== "-"}
                          value={filters.wdo === "-" ? false : "-"}
                          onChange={(e) => {
                            dispatch(
                              handleChangeFilterValue({
                                key: "wdo",
                                value: e.target.value,
                              })
                            );
                          }}
                          name="Driver"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="default-radio-1"
                          className="mx-2 text-sm font-medium"
                        >
                          {id === "2"
                            ? t("WITHOPERATOR")
                            : id === "3"
                            ? t("WITHCREW")
                            : t("WITHDRIVER")}
                        </label>
                      </div>
                    </div>
    
                    <div className="basis-1/6">
                      <div className="flex items-center mt-4">
                        <input
                          id="insurence"
                          type="checkbox"
                          checked={filters.insurence !== "-"}
                          value={filters.insurence === "-" ? true : "-"}
                          onChange={(e) => {
                            dispatch(
                              handleChangeFilterValue({
                                key: "insurence",
                                value: e.target.value,
                              })
                            );
                          }}
                          name="Insurance"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="default-radio-1"
                          className="mx-2 text-sm font-medium"
                        >
                          {t("WITHINSURANCE")}
                        </label>
                      </div>
                    </div>
                    {id !== "2" && id !== "3" && (
                      <div className="basis-1/6">
                        <div className="flex items-center mt-4">
                          <input
                            id="deposit"
                            type="checkbox"
                            checked={filters.deposit !== "-"}
                            value={filters.deposit === "-" ? true : "-"}
                            onChange={(e) => {
                              dispatch(
                                handleChangeFilterValue({
                                  key: "deposit",
                                  value: e.target.value,
                                })
                              );
                            }}
                            name="deposit"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="default-radio-1"
                            className="mx-2 text-sm font-medium"
                          >
                            {t("WITHOUTDEPOSIT")}
                          </label>
                        </div>
                      </div>
                    )}
    
                    <div className="basis-1/3">
                      <PriceRangex />
                    </div>
    
                    <div className="basis-1/6 text-end">
                      <button
                        onClick={() => _handleAdvanceFilterOpen()}
                        className="mt-2 text-sm "
                      >
                        {t("ADVANCEFILTERS")}
                      </button>
                    </div>
                    {user && (
                      <div className="basis-1/6 text-end">
                        <button
                          onClick={() => handleSaveQuery()}
                          className="mt-2 text-sm "
                        >
                          {t("SaveTemplate")}
                        </button>
    
                        <button
                          onClick={() => handleSavedTemplate()}
                          className="mt-2 text-sm "
                        >
                          {t("UseTemplate")}
                        </button>
                      </div>
                    )}
    
                    <div className="basis-1/3 text-end">
                      <button
                        type="button"
                        onClick={() => _handleLetsGoButton()}
                        className="text-white bg-primary font-medium  mt-4 px-6 py-2.5 text-center inline-flex items-center  ml-10 rounded border-white border-1 border-solid border-white roundborderlightgray"
                      >
                        {t("lETS_GO_BUTTON")}
                      </button>
                    </div>
                  </div>
                </select>
              </div>
              <div className="flex items-center mt-4 basis-1/6">
                <input
                  id="wdo"
                  type="checkbox"
                  checked={filters.wdo !== "-"}
                  value={filters.wdo === "-" ? false : "-"}
                  onChange={(e) => {
                    dispatch(
                      handleChangeFilterValue({
                        key: "wdo",
                        value: e.target.value,
                      })
                    );
                  }}
                  name="Driver"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label style={{fontSize : "0.8rem"}} for="default-radio-1" className="mx-2">
                  {id === "2"
                    ? t("WITHOPERATOR")
                    : id === "3"
                    ? t("WITHCREW")
                    : t("WITHDRIVER")}
                </label>
              </div>
              <div className="basis-1/3">
                <div style={{ marginTop: "10px" }}>
                  <PriceRangex />
                </div>
              </div>
              <div className="basis-1/6 text-end">
                <button
                  style={{fontSize : "0.7rem"}}
                  onClick={() => _handleAdvanceFilterOpen()}
                  className="mt-2 text-sm "
                >
                  {t("ADVANCEFILTERS")}
                </button>
              </div>
              <div  className="basis-1/6 text-end">
                 <center className="ndtd">
                        <div className='' ><button style={{ backgroundColor : "orange",  borderRadius : "2px", fontSize : "0.6rem", width : "10vw", fontWeight : "bold"}}
                             className='findmyperfectridebutton shadow montserrat-400 ' 
                             onClick={c => {c.preventDefault(); navigate(`/listings/${id}/listings`)}}>{t("Find My Perfect Ride")}</button>
                        </div>
                        <div >
                            <button className="shadow montserrat-400 clearfilterbutton" style={{position : "relative",   top : "10px",  fontWeight : "bold",  width : "10vw"}} 
                                onClick={c => {c.preventDefault(); _handleClearAllFilters()}}>{t("CLEARALLFILTERS")}</button>
                        </div>
                        </center>
              </div>
            </div>
    
            
          </div>
        );
      };


      const _handleAdvanceFilterOpen = () => {
        setAdvanceFilterIsOpen(true);
        
      };

      const handleSaveQuery = () => {
        dispatch(
          saveFilterQuery({
            filters: filters,
          })
        )
          .unwrap()
          .then(() => {
            successToast("Query Saved Successfully");
          })
          .catch((errorData) => {
            errorToast(errorData.error);
          });
      };
    
      const handleSavedTemplate = () => {
        dispatch(implementSavedTemplate({}));
      };

      const _handleLetsGoButton = () => {
        
        dispatch(handleListingValChange({ key: "page", value: 0 }));
    
      };


      const ocrv = (g, val) => {
        let newstate = advanceFilter.map((item) => ({ ...item })); // Clone objects within advanceFilter
    
        const foundIndex = newstate.findIndex(
          (h) => h.featureCode === g.featureCode
        );
    
        if (foundIndex !== -1) {
          if (g.fib) {
            newstate[foundIndex].rangevalueL = val === "true" ? false : true;
          } else {
            newstate[foundIndex].rangevalueL = val;
          }
    
          // Assuming setAdvanceFilter is a function to update the state
          setAdvanceFilter(newstate);
        } else {
         // console.error("Object with the provided featureCode not found.");
        }
      };

    return (
      <div  >
      <Toaster />

      <Modal
      ariaHideApp={false}
        isOpen={loginModel}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setLoginModel(false)} 
        style={{ ...customStyles }}
        contentLabel="Login Modal"
      >
        
            <div style={{display : "flex"}}>
                          <div>
                                    <button onClick={g => {g.preventDefault();setLoginModel(false)}} 
                                              style={{ color: "gray", fontSize : "1rem", marginTop : "10px"}}>
                                                &#x2716;
                                    </button>
                          </div>
                            &nbsp; &nbsp; &nbsp;
                          <div><h1>{t("Sign In")}</h1></div>
                      </div>
                    
        <form onSubmit={signinUser}>
          <div>
           
            <p className="mt-4 mb-8">
              {t("If you don't have an account You can")}
              <br />
              <button
                onClick={() => {
                  setLoginModel(false);
                  setSignupModel(true);
                }}
                className="link"
              >
                {t("Register here")} !
              </button>
            </p>
          </div>
          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Email")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="email"
                placeholder={t("Enter your Email")}
                id="email"
                ref={(e) => {
                  email = e;
                }}
                type="email"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-primary-grey text-[13px] font-medium pb-1">
              {t("Password")}
            </label>
            <div className="relative">
              <input
                className="inputField w-full mb-8"
                name="password"
                placeholder={t("Enter your Passowrd")}
                id="password"
                ref={(e) => {
                  password = e;
                }}
                type="password"
                required
              />
            </div>
          </div>
          <center>
            <button
              type="submit"
              className="primaryButton mt-4"
              style={{ fontSize: "0.8rem" }}
            >
              {loading ? `${t("Loading")}...` : t("Sign In")}
            </button>
            
            <p
              style={{
                marginTop: "25px",
                fontSize: "0.7rem",
                cursor: "pointer",
              }}
              onClick={(t) => {
                resetup(email.value);
                setLoginModel(false);
              }}
            >
              Forgot your password ?
            </p>
          </center>
        </form>

        <br />
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            
            oAuth("GOOGLE", credentialResponse);
            
          }}
          onError={() => {
         
          }}
        />
      </Modal>

      <Modal
      ariaHideApp={false}
        isOpen={signupModel}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setSignupModel(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
            <div style={{display : "flex"}}>
                          <div>
                                    <button onClick={g => {g.preventDefault();setSignupModel(false);}} 
                                              style={{ color: "gray", fontSize : "1rem", marginTop : "10px"}}>
                                                &#x2716;
                                    </button>
                          </div>
                            &nbsp; &nbsp; &nbsp;
                          <div><h1>{t("Sign Up")}</h1></div>
                      </div>
                    
            {otpmodal ?
            <form onSubmit={e => activateUser(e, fullName)}>
             <div>
              <h5 className="block text-primary-grey text-[13px] font-medium pb-1">
                OTP Code from your email.
              </h5>
              <div className="relative">
                <input
                  className="inputField mb-8 w-full"
                  name="fullName"
                  placeholder="Enter OTP "
                  id="fullName"
                  onChange={() => setValidationError("")}
                  ref={(e) => {
                    fullName = e;
                  }}
                  type="text"
                  required
                />
              </div>
            </div>   
            <button type="submit" className="button-55">
              {loading ? `${t("Loading")}...` : "Activate"}
            </button>
            </form>
            :
            <form onSubmit={signUpUser}>
            <div>
              <p className="mt-4 mb-8">
                {t("If you already have an account registered You can")}
                <br />
                <button
                  onClick={() => {
                    setSignupModel(false);
                    setLoginModel(true);
                  }}
                  className="link"
                >
                  {t("Login here")} !
                </button>
              </p>
            </div>
            <div>
              <label className="block text-primary-grey text-[13px] font-medium pb-1">
                {t("Full Name")}
              </label>
              <div className="relative">
                <input
                  className="inputField mb-8 w-full"
                  name="fullName"
                  placeholder={t("Enter your full name")}
                  id="fullName"
                  onChange={() => setValidationError("")}
                  ref={(e) => {
                    fullName = e;
                  }}
                  type="text"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-primary-grey text-[13px] font-medium pb-1">
                {t("Email")}
              </label>
              <div className="relative">
                <input
                  className="inputField mb-8 w-full"
                  name="email"
                  placeholder={t("Enter your Email")}
                  id="email"
                  onChange={(e) => {setValidationError("");setEmailx(e.target.value)}}
                  ref={(e) => {
                    email = e;
                  }}
                  type="email"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-primary-grey text-[13px] font-medium pb-1">
                {t("Phone")}
              </label>
              <div className="relative">
                <input
                  className="inputField w-full mb-8"
                  name="cell"
                  placeholder={t("Enter your Phone Number")}
                  id="cell"
                  ref={(e) => {
                    cell = e;
                  }}
                  type="tel"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-primary-grey text-[13px] font-medium pb-1">
                {t("Password")}
              </label>
              <div className="relative">
                <input
                  className="inputField mb-8 w-full"
                  name="password"
                  placeholder={t("Enter your Passowrd")}
                  id="password"
                  onChange={() => setValidationError("")}
                  ref={(e) => {
                    password = e;
                  }}
                  type="password"
                  required
                />
              </div>
            </div>
            {validationError && (
              <p className="text-left text-red-500">{validationError}</p>
            )}
            <button type="submit" className="button-55">
              {loading ? `${t("Loading")}...` : t("Sign Up")}
            </button>
            </form>
            } 
            
      </Modal>

      <Modal
        isOpen={advanceFilterIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setAdvanceFilterIsOpen(false);
        }}
        style={customStyles}
        contentLabel="Advance Filter Modal"
        ariaHideApp={false}
      >
        <section className="z-50">
          <div className="text-center">
            <p className="text-xl font-bold mb-5 border-bottom">
              {t("ADVANCE_SEARCH")}
            </p>
          </div>

          <div className="flex flex-col justify-center">
            <PerfectScrollbar style={{ height: 500 }}>
              {(advanceFilter || [])?.map((g, key) => {
                return (
                  <div
                    id={key}
                    key={key}
                    className="flex flex-row gap-1 justify-between border-b my-2 pr-5 border-black-900"
                    style={{ paddingBottom: 10, marginLeft: 5 }}
                  >
                    <div className="flex flex-row gap-1 items-center">
                      <img
                        alt = {g?.ficon}
                        src={`${process.env.REACT_APP_API_URL}/fet/${g?.ficon}`}
                        style={{
                          width: 20,
                          objectFit: "contain",
                        }}
                      />{" "}
                      <p>{g?.[selected_lang]}</p>
                    </div>
                    <div style={{ padding: 2, marginRight: 10 }}>
                      {g.fib ? (
                        <input
                          type="checkbox"
                          value={g.rangevalueL}
                          checked={g.rangevalueL === true}
                          defaultValue={false}
                          onChange={(x) => {
                            ocrv(g, x.target.value);
                          }}
                        />
                      ) : (
                        <select
                        aria-label="State"
                          id="feature_value"
                          onChange={(v) => ocrv(g, v?.target.value)}
                          value={g.rangevalueL}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          <option value={"-"}>Choose an Option</option>
                          {g?.valueRange?.map((value) => (
                            <option value={value?.len}>
                              {value?.len || ""}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>
                );
              })}
            </PerfectScrollbar>
          </div>

          <button
            type="button"
            onClick={() => {
              
              setAdvanceFilterIsOpen(false);
              _handleAdvanceFilterHandler();
            }}
            className="text-white bg-primary font-medium  mt-4 px-6 py-2.5 text-center inline-flex items-center float-end rounded border-white border-1 border-solid border-white roundborderlightgray"
          >
            {t("lETS_GO_BUTTON")}
          </button>
        </section>
      </Modal>


       <>
            <div style={{backgroundColor : "#f1f1f1"}}>
              <div className={selected_lang === "en" ? "vttop_bar marginenn" : "vttop_bar marginarr" }> 
                    <a
                        target="_blank"
                        href={process.env.REACT_APP_FACEBOOK_URL}
                        rel="noreferrer"
                      >
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/facebook_.webp`} alt="facebook_icon" />
                    </a>
                    <a
                    target="_blank"
                    href={process.env.REACT_APP_INSTAGRAM_URL}
                    rel="noreferrer"
                  >
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/instagram_.webp`} alt="instagram_icon" />
                    </a>
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_TIKTOK_URL}
                      rel="noreferrer"
                    >
                        <img  src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/tiktok.webp`} alt="tiktok_icon"  />
                    </a>
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_SNAPCHAT_URL}
                      rel="noreferrer"
                    >
                    <img  src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/snapchat_.webp`} alt="snapchat_icon" />
                    </a>
                </div>
            
                <div className={selected_lang === "en" ? "vttop_bar_r marginen" : "vttop_bar_r marginar" } style={{float : selected_lang === "en" ? "right" : "left"}}> 
                    <div className="qs"><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/globe.png`} alt="Globe"  /><span className={selected_lang === "en" ? "marginen25" : "margin25"}>{t("topbar_uae")}</span>
                     <span className="popover">
                    {
                      distvals[0]?.cityCode?.length >= 0 ? 
                                  <ul>
                                   { /* eslint-disable-next-line */}
                                  {distvals[0]?.cityCode?.map((city, index) => {
                                    if(city?.city[selected_lang] !== ""){
                                      return (
                                        <li style={{marginBottom : "10px" , marginTop : "10px"}}>
                                        <button key={"city" + index} id={city.cityCode} 
                                        onClick={x => {
                                          dispatch(
                                            handleChangeFilterValue({
                                              key: "citycode",
                                              value: city.city.cityCode,
                                            })
                                          );
                                        }}
                                        >
                                          {city?.city[selected_lang]}
                                        </button><br />
                                        </li>
                                      ) 
                                    }
                                   
                                      })}
                                  </ul>
                      :
                      <p>{distvals[0]?.cityCode?.length}</p>
                    }   
                      
                    </span> 
                    </div>
                    <div onClick={() => _handleChangeCurrency()}><img  style={{width : "25px", height:  "25px", marginTop : "2px" }} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/Currency.png`} alt="Currency icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25" }>
                    {selected_curr === "AED" ? 
                    t("AED")
                   : 
                   t("USD")
                  }
                      </span></div>
                    <div ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/lang.png`} alt="Language Icon" onClick={l =>_handleChangeTranslate() }/><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>{selected_lang === "en" ? t("ARABIC") : t("ENGLISH")}</span></div>
                    <div style={{cursor : "pointer"}} onClick={u => appRef.current.scrollIntoView({ behavior: "smooth", block: "start" })} ><img style={{width : "25px", height:  "25px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/App.png`} alt="App Icon" /><span className={selected_lang === "en" ? "marginen25" : "marginar25"}>DOWNLOAD OUR MOBILE APP</span>
                    
                    </div>
                    
                </div>
            </div>

            <div>
          <Menuvtwo 
              user={user} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} isTabletOrMobile={isTabletOrMobile} search={search}
              _handleSearch={_handleSearch} setLoginModel={setLoginModel} brandsr={brandsr} aboutusr={aboutusr} navlink1={navlink1} navlink2={navlink2} navlink3={navlink3} 
              t={t}
               showsearch={"1"} changetype={() => {}} selected_lang={selected_lang}
          />
            </div>

            <div >
              <div
                        className="totop"
                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                      >
                        &#8673;
              </div>
             <div className="homepremiumcolors">
                    <>
                    <div style={{alignContent : "center", marginRight : selected_lang !== "en" && "5%"}}>
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/BannerBoat.webp`} alt="Boat_Banner" 
                            width={"80%"} height={"85%"} /></div>
                      <div style={{color : "white" , alignContent : "center",justifyContent : "center"}}>
                        <center>
                        <h4 className="montserrat-400" style={{fontWeight : "bolder"}} >{t("ELOWV")} </h4>
                          <p className="montserrat-400" >{t("ELOWV2")}</p>
                          <p className="searchinputbox">
                              <div style={{display : "flex"}} >
                              <input type="text"  placeholder={t("Find My Perfect Ride")} style={{  width : "100%", lineHeight: "35px", backgroundColor : "#16254e",  borderRadius: "5px" , outline: "2px solid white",
                                    paddingLeft :  "10px", paddingRight :"10px"
                               }}
                              onChange={(e) => _handleSearchText(e)}
                              onKeyDown={(x) => {
                                if(x.key === "Enter"){
                                  _handleSearch()
                                  navigate(`/listings/${id}/listings` )
                                }
                              }}
                              onBlur={() => {
                                if(search === "") {
                                  _handleClearAllFilters()
                                } 
                              }}
                              
                              value={search}
                              />
                              <span style={{width : "20%" , marginLeft : "-28%" , marginTop  : "11px", fontSize : "0.5rem"}} >{selected_lang === "en" && t("QuickSearch")}</span>
                              <span style={{width : "2%", marginTop  : "7px"}}><img style={{width : "80%" , minWidth : "20px", backgroundColor : "orange", borderRadius : "5px", padding : "2px"}} src={`${process.env.REACT_APP_PUBLIC_URL}/images/icons/search_.png`} alt="search_icon" width="20" height="20"
                              onClick={() => _handleSearch()}
                              /></span>
                              </div>
                          </p>
                          <div style={{  width : "40vw" , maxWidth : "40vw", display:  "flex", paddingLeft : "11%" }}>
                          <div
                                className={type === "car" ? "buttonunderbarisactive" : "buttonunderbar"} 
                                style={{paddingTop : "3px"}}
                                >
                                <NavLink
                                ref={navlink1}
                                className={({ isActive }) =>
                                  isActive
                                    ? "bactive no-underline capitalize montserrat-200 toupper"
                                    : "no-underline capitalize montserrat-200 toupper "
                                }
                                to="/car"
                                style={{fontWeight : "bold"}}
                                onClick={(x) => {
                                  dispatch(handleClearSearch({}));
                                
                                }}
                                 >
                                {t("RENTACAR")}
                               </NavLink>
                          </div>
                          <div 
                            className={type === "yatch" ? "buttonunderbarisactive" : "buttonunderbar"} 
                            style={{paddingTop : "3px"}} >
                            <NavLink
                                ref={navlink3}
                                className={({ isActive }) =>
                                  isActive
                                ? "bactive no-underline capitalize montserrat-200 toupper"
                                : "no-underline capitalize montserrat-200 toupper"
                                }
                                to="/yatch"
                                onClick={(x) => {
                                  dispatch(handleClearSearch({}));
                                  
                                }} style={{fontWeight : "bold"}}
                              >
                                {t("YATCHRENTAL")}
                              </NavLink>
                          </div>
                          <div
                             className={type === "machines" ? "buttonunderbarisactive" : "buttonunderbar"}
                             style={{paddingTop : "3px"}} >
                            <NavLink
                              ref={navlink2}
                              to="/machines"
                              className={({ isActive }) =>
                                isActive
                              ? "bactive no-underline capitalize montserrat-200 toupper"
                              : "no-underline capitalize montserrat-200 toupper"
                              }   
                              onClick={(x) => {
                                dispatch(handleClearSearch({}));
                                
                              }}
                              style={{ fontWeight : "bold"}}
                            >
                              {t("MACHINES")}
                            </NavLink>
                          </div>
                          </div>

                          <h4 style={{marginTop : "2vh"}}>{t("Hashtag1")}<span style={{color : "orange"}}>{t("Hashtag2")}</span></h4>  
                        </center>
                        
                      </div>
                    <div style={{alignContent : "center",justifyContent : "center"}}><img src={`${process.env.REACT_APP_PUBLIC_URL}images/icons/Bannercar.webp`} alt="Car_Banner" width={"80%"} height={"85%"}  /></div>
                    </>
              </div>  
{/** top most looking to rent from top companies */}
                <div>
                <h1
                    className="text-xl font-medium"
                    style={{
                      fontSize: "1.25rem",
                      marginTop : "1vh",
                      paddingLeft : "10%",
                      paddingRight : "10%"
                    }}
                  >
                    {t("LOOKING_FOR_A")}{" "}
                    <span className="text-primary">{t("VEHICLE")}</span>?{" "}
                      <span >
                        {t("YOU_ARE_AT_RIGHT_PLACE")}.
                      </span>
                  </h1>
                <p style={{fontSize : "0.8rem" , fontWeight   :"bold" ,
                  paddingLeft : "10%",
                  paddingRight : "10%", color : "gray"
                }}>{t("chosefromvtype")}</p>
{/** mixed vehicle types here */}    
               <center>     
                {type === "car" &&  
                  <div className="atypexw montserrat-200">
                      <div id="xid1"
                         onClick = {t => {
                          dispatch(
                            handleChangeFilterValue({
                              key: "typecode",
                              value: "43",
                            })
                          ).then(() => {
                            setSeldistvals(t.target.id)
                            navigate(`/listings/${id}/listings` )
                          })
                          
                        }}>
              {/** Luxury is vtype */}
                          <img id="xid1i" width="300" height="200" src={`${process.env.REACT_APP_CDN}Br7fsv0.png`} alt="Luxury_img" />
                          <p id="xid1p"> {t("Luxury")} </p>
                      </div>     
                      <div id="xid2"
                        onClick = {t => {
                          
                          dispatch(
                            handleChangeFilterValue({
                              key: "typecode",
                              value: "44",
                            })
                          ).then(() => {
                            setSeldistvals(t.target.id)
                            navigate(`/listings/${id}/listings` )
                          })
                        }}
                        >
              {/** Sports is vtype */}              
                          <img id="xid2i" width="300" height="200" src={`${process.env.REACT_APP_CDN}59Sspk9.png`} alt="Sports_img" />
                          <p id="xidp" > {t("Sports") } </p>
                      </div>     
                      <div id="xid3"
                       onClick = {t => {
                     
                        dispatch(
                          handleChangeFilterValue({
                            key: "typecode",
                            value: "46",
                          })
                        ).then(() => {
                          setSeldistvals(t.target.id)
                          navigate(`/listings/${id}/listings` )
                        })
                      }}
                      >
            {/** Standard is vtype */}                         
                        <img id="xid3i" width="300" height="200" src={`${process.env.REACT_APP_CDN}BCtOGu7.png`} alt="Standard_img" />
                        <p id="xid3p" > {t("Standard")} </p>
                      </div>     
                      <div id="xid4" 
                       onClick = {t => {
                        dispatch(
                          handleChangeFilterValue({
                            key: "typecode",
                            value: "45",
                          })
                        ).then(() => {
                          setSeldistvals(t.target.id)
                          navigate(`/listings/${id}/listings` )
                        })
                      }}
                      >
            {/** Economy is vtype */}                         
                 
                          <img id="xid4i" width="300" height="200" src={`${process.env.REACT_APP_CDN}r65Qq50.png`} alt="Economy_img" />
                          <p id="xid4p" > {t("Economy")} </p>
                      </div>     
                      <div id="xid5"  
                      onClick = {t => {
                        dispatch(
                          handleChangeFilterValue({
                              key: "wdo",
                              value: filters.wdo === "-" ? "false" : "-",
                          })
                          ).then(() => {
                            setSeldistvals(t.target.id)
                            navigate(`/listings/${id}/listings` )
                          })
                      }}>
            {/** WD is vtype */}                         
                          <img id="xid5" width="300" height="200" style={{marginTop : "-12px"}} src={`${process.env.REACT_APP_CDN}kPlyLMd.png`} alt="WithDriver_img" />
                          <p id="xid5p"  > {t("WITHDRIVER")} </p>
                      </div>     
                      <div id="xid6"  
                      onClick = {t => {
                        dispatch(
                          handleChangeFilterValue({
                            key: "bodytype",
                            value: "8",
                          })
                        ).then(() => {
                          setSeldistvals(t.target.id)
                          navigate(`/listings/${id}/listings` )
                        })
                      }}>
            {/** Convertable is vtype */}                         
                          <img id="xid6i" width="300" height="200" src={`${process.env.REACT_APP_CDN}JfczgOH.png`} alt="Convertable_img" />
                          <p id="xid6"> {t("Convertable")} </p>
                      </div>
                      <div id="xid7" 
                       onClick = {t => {
                        dispatch(
                          handleChangeFilterValue({
                            key: "bodytype",
                            value: "7",
                          })
                        ).then(() => {
                          setSeldistvals(t.target.id)
                          navigate(`/listings/${id}/listings` )
                        })
                      }}
                      >
          {/** SUV is vtype */}                         
                          <img id="xid7i" width="300" height="200" src={`${process.env.REACT_APP_CDN}HIO5UPa.png`} alt="SUV_img" />             
                          <p id="xid7p" > {t("SUV")} </p>
                      </div>     
                      <div id="xid8" 
                        onClick = {t => {
                          
                          dispatch(
                            handleChangeFilterValue({
                              key: "bodytype",
                              value: "10",
                            })
                          ).then(() => {
                            setSeldistvals(t.target.id)
                            navigate(`/listings/${id}/listings` )
                          })
                        }}
                      >
          {/** Coupe is vtype */}                         
                              <img id="xid8i" width="300" height="200" src={`${process.env.REACT_APP_CDN}KhTkLnN.png`} alt="Coupe_img" />             
                              <p id="xid8p" > {t("Coupe")} </p>
                      </div>     
                      <div id="xid9" 
                      onClick = {t => {
                        dispatch(
                          handleChangeFilterValue({
                            key: "bodytype",
                            value: "5",
                          })
                        ).then(() => {
                          setSeldistvals(t.target.id)
                          navigate(`/listings/${id}/listings` )
                        })
                      }}>
          {/** Hatchback is vtype */}                         
                            <img  id="xid9i" width="300" height="200" src={`${process.env.REACT_APP_CDN}6b7vqvZ.png`} alt="Hatchback_img" />             
                            <p id="xid9p" >{t("Hatchback")}</p>
                      </div>     
                      <div id="xida10" 
                      onClick = {t => {
                        
                        dispatch(
                          handleChangeFilterValue({
                            key: "bodytype",
                            value: "11",
                          })
                        ).then(() => {
                          setSeldistvals(t.target.id)
                          navigate(`/listings/${id}/listings` )
                        })
                      }}
                      >
          {/** Van is vtype */}                         
                          <img id="xida10i" width="300" height="200" src={`${process.env.REACT_APP_CDN}2wKDjmi.png`} alt="Van_img" />    
                          <p id="xida10p" > {t("Van")} </p>         
                      </div>     
                      <div id="xid11" 
                        onClick = {t => {
                          
                          dispatch(
                            handleChangeFilterValue({
                              key: "bodytype",
                              value: "6",
                            })
                          ).then(() => {
                            setSeldistvals(t.target.id)
                            navigate(`/listings/${id}/listings` )
                          })
                        }}>
          {/** Sedan is vtype */}                         
                            <img id="xida11i" width="300" height="200" src={`${process.env.REACT_APP_CDN}12jBsEX.png`} alt="Sedan_img" />        
                            <p id="xida11p">  {t("Sedan")} </p>              
                      </div>     
                      <div id="xida12" 
                        onClick = {t => {
                          
                          dispatch(
                            handleChangeFilterValue({
                              key: "bodytype",
                              value: "9",
                            })
                          ).then(() => {
                            setSeldistvals(t.target.id)
                            navigate(`/listings/${id}/listings` )
                          })
                        }}
                      >
          {/** Crossover is vtype */}                         
                            <img id="xida12i" width="300" height="200" src={`${process.env.REACT_APP_CDN}YVq3k25.png`} alt="Crossover_img" />          
                            <p id="xida12p"> {t("Crossover")} </p>                 
                      </div>     
                  </div>  
                 }
                    <div  style={{backgroundColor : "#f1f1f1" , width : "82%", marginTop : "20px" , paddingLeft : "2vh", paddingTop : "2vh" , paddingBottom : "2vh"}}>
                      <p className="montserrat-400" style={{textAlign : "left", fontSize : "0.7rem"}}> 
                      {t("ptext1")}
                      </p>
                  </div>   
                </center>
                
              </div>   


              <div style={{marginTop :  "15px"}}>
                {type === "car" && 
                  <Homefilters isTabletOrMobile={isTabletOrMobile} t={t} filters_master={filters_master} filter_master={filter_master} filters={filters} 
                  handleChangeFilterValue={handleChangeFilterValue} dispatch={dispatch} selected_lang={selected_lang}
                  getfilters={() => {}} _handleSearchText={_handleSearchText} _handleSearch = {_handleSearch} id={id} 
                  allbrandslist={allbrandslist} _handleClearAllFilters={_handleClearAllFilters} distvals={distvals} distvbc={distvbc}
                  uniqlist={uniqlist}

                  /> 
                }  
                {type === "yatch"  && 
                <div  style={{ display :"flex", justifyContent : "center",  marginTop :"12vh", marginBottom :"6vh", width :  "100%"}}>
                  
                  {searchyacht()}
                </div>
                }  
                {type === "machines"  && 
                 <HomeMfilters isTabletOrMobile={isTabletOrMobile} t={t} filters_master={filters_master} filter_master={filter_master} filters={filters} 
                 handleChangeFilterValue={handleChangeFilterValue} dispatch={dispatch} selected_lang={selected_lang}
                 getfilters={() => {}} _handleSearchText={_handleSearchText} _handleSearch = {_handleSearch} id={id} 
                 allbrandslist={allbrandslist} _handleClearAllFilters={_handleClearAllFilters} distvals={distvals} distvbc={distvbc}
                 uniqlist={uniqlist}

                 /> 
                }

              </div>

                 
              
        {/** SHOWING PREMIUM*/}    
           {id !== "2" &&    
              <div style={{backgroundColor : "#f1f1f1", marginTop : "2vh", paddingBottom : "6vh", paddingLeft :  "115px", paddingRight :  "115px"}} ref={listRef0}>
                  <div className="montserrat-400" 
                  style={{width : "100%" ,  paddingLeft : selected_lang === "en" ? "50px" : "", paddingRight : selected_lang === "en" ? "0" : "50px", marginBottom : "20px", marginTop : "10px", paddingTop : "1px"}}>
                  <div style={{display : "flex" , marginTop : "50px" }}>
                     <div> 
                      <h1 style={{ paddingRight : "1vw", marginRight : "0px", fontSize : "1.25rem"}}>{t("rentaloffersin")} </h1>     
                     </div> 
                     <div style={{marginTop : "12px", marginLeft : "10px"}}>
                      </div>
                      </div>
                    <p style={{fontSize : "0.8rem", color : "black"}}>{t("FAA1")} </p>
                  </div>
                  
                  {!loading_premium_listings ? (
                  <center>    
                     <Homecarp numb={numbp} setNumb={setNumbp} selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                     showcall={showcall} _handleListingView={_handleListingView} typeis = {"Listings1"} _handleWaCount={_handleWaCount}
                     getsplist={getsplist} cwList = {premium_listings} filters={filters} setShowcall={setShowcall} 
                     _handleSaveCount={_handleSaveCount} 
                     />  
                     </center>
                
              ) : (
                <div key={"loading"} className="text-center">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: LoadingAnimation,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={300}
                    width={300}
                  />
                </div>
              )}
                <div style={{marginTop : "1vh", marginBottom : "1vh"}}>
                <center>
                   
                   <button  className='shadow montserrat-400 findbutton trasor'  style={{position : "relative",  top : "3vh", fontWeight : "bold", borderRadius : "5px", fontSize : "0.6rem" , height : "5vh"}}
                    onClick={c =>  scrollToMorelisting() }>{t("View More")}</button>
          
                </center>
                </div>
                </div>            
            }
          {id === "2" &&
            <div style={{backgroundColor : "#f1f1f1", marginTop : "2vh", paddingTop : "6vh", paddingBottom : "6vh", paddingLeft :  "115px", paddingRight :  "115px"}} ref={listRef0}>
            <div className="montserrat-400" >
       
                  <Homemachine numb={numbp} setNumb={setNumbp} selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                     showcall={showcall} _handleListingView={_handleListingView} typeis = {"Listings1"} _handleWaCount={_handleWaCount}
                     getmachines={getmachines} mList = {mdataone} filters={filters} setShowcall={setShowcall} 
                     _handleSaveCount={_handleSaveCount} />
                        <center>
                   
                   <button  className='shadow montserrat-400 findbutton trasor'  style={{position : "relative",  top : "3vh", fontWeight : "bold", borderRadius : "5px", fontSize : "0.6rem" , height : "5vh"}}
                    onClick={c =>  scrollToMorelisting() }>{t("View More")}</button>
          
                </center>
            </div>
            </div>
          }    
        {/** SHOWING BRANDS */}
                <div>
                    <p className="montserrat-400" style={{paddingLeft : selected_lang === "en" ? "10vw" : "", 
                        paddingRight : selected_lang === "en" ? "0" : "250px", marginBottom : "0", marginTop : "2%", 
                        fontWeight : "bold" , fontSize : "0.6rem", color : "gray"}}>{t("chosefrombrand")} </p>
                        <Suspense>
                        <Vnmbrands selected_lang={selected_lang} brands={filters_master?.brands} distvals={distvals} seldistvals={seldistvals} setSeldistvals={setSeldistvals} _handleClearAllFilters={_handleClearAllFilters} dispatch={dispatch} handleChangeFilterValue={handleChangeFilterValue}  
                        isTabletOrMobile={isTabletOrMobile} 
                        id={id}  navigate={navigate} />
                        </Suspense>
                </div>

        {/** SHOWING LISTING 1*/}
                <div ref={expmorepref} style={{backgroundColor : "#f1f1f1",  marginTop : "2vh", paddingBottom : "6vh",  paddingLeft :  "100px", paddingRight :  "100px"}} >
                
                  <div className="montserrat-400" style={{width : "100%" ,  paddingLeft : selected_lang === "en" ? "50px" : "", paddingRight : selected_lang === "en" ? "0" : "50px", marginBottom : "20px", marginTop : "10px", paddingTop : "1px"}}>
                  <div style={{display : "flex" , marginTop : "50px" }}>
                      <div>
                          <h1 style={{fontSize  : "1.25rem"}}>{id ===  "1" ? t("Bestluxury_heading"): t("Best_heading")} </h1>     
                      </div>
                      <div style={{marginTop : "12px", marginLeft : "10px"}}>
                          <select
                                    value={filters.citycode}
                                    id="as"
                                    aria-label="State"
                                    className="text-sm focus:ring-blue-500 block"
                                    style={{ color : "orange", fontWeight : "bold", width : "200px" , backgroundColor : "#f1f1f1"}}
                                    onChange={(e) => {
                                      dispatch(
                                        handleChangeFilterValue({
                                          key: "citycode",
                                          value: e.target.value,
                                        })
                                      ).then(o => {
                                        
                                         getsplist("city" , e.target.value , filters.sort.split("/")[1] ? filters.sort.split("/")[1] : "-" , filters.sort.split("/")[0] ? filters.sort.split("/")[0] : "-" ) 
                                      })
                                    }}
                                  >
                                    <option value={"-"}>{t("ALL_CITIES")}</option>
                                    { /* eslint-disable-next-line */}
                                    {distvbc?.cityCode?.map((city, index) => {
                                      if(city?.city?.[selected_lang] !== ""){
                                        return (
                                          <option key={"city" + index} value={city.city.cityCode}>
                                          {city?.city?.[selected_lang]}
                                        </option>
                                        )
                                      }
                                      
                                      })}
                          </select>
                      </div>
                      <div style={{marginTop : "7px", marginLeft : selected_lang === "en" ? "auto" : "3vw", marginRight : selected_lang === "en" ? "3vw" : "auto"}}>
                      <   select
                                      value={filters?.sort}
                                      id="sortingSelect"
                                      aria-label="State"
                                      className="text-sm focus:ring-blue-500 block w-full"
                                      style={{ color : "gray",  width : "200px" , paddingRight : "0" , paddingLeft : "0", backgroundColor : "white", float : "right", fontSize : "0.7rem" }}
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "sort",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                    >
                                    <option value={"1/0"}>
                                      {t("Price Low to High")}
                                    </option>
                                    <option value={"1/1"}>
                                      {t("Price High to Low")}
                                    </option>
                                    <option value={"2/1"}>
                                      {t("Model Year High to Low")}
                                    </option>
                                    <option value={"2/0"}>
                                      {t("Model Year Low to High")}
                                    </option>
                                    <option value={"-/1"}>
                                      {t("Most Recently Updated")}
                                    </option>
                              </select>
                      </div>
                    </div>
                    <p style={{fontSize : "0.8rem", color : "black"}}>{t("Bestluxury_heading_p")} </p>
                  </div>
                   {showProcessing ? 
                     <Lottie
                     options={{
                       loop: true,
                       autoplay: true,
                       animationData: LoadingAnimation,
                       rendererSettings: {
                         preserveAspectRatio: "xMidYMid slice",
                       },
                     }}
                     height={150}
                     width={150}
                   />
                   :
                   <Homecar numb={numba} setNumb={setNumba} selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                   showcall={showcall} _handleListingView={_handleListingView} typeis = {"Listings1"} _handleWaCount={_handleWaCount}
                   getsplist={getsplist} cwList = {cwList} filters={filters} setShowcall={setShowcall} 
                   _handleSaveCount={_handleSaveCount} 
                   />
                  }
                      
                 <div style={{marginTop : "1vh", marginBottom : "1vh"}}>
                <center>
                   {expmore("listings")}
                </center>
                </div>
                
                </div>                 

        {/** SHOWING MARKETTING */}
                <div style={{width : "100%" ,  paddingLeft : selected_lang === "en" ? "100px" : "", paddingRight : selected_lang === "en" ? "100" : "50px", marginBottom : "10vh", marginTop : "10vh", paddingTop : "1px" }}>
                  <Suspense>
                  <Homemarket t={t} />
                  </Suspense>
                </div>

        {/** SHOWING LISTING 2*/}
             {id === "1" &&   <div style={{backgroundColor : "#f1f1f1",   marginTop : "2vh", paddingBottom : "6vh", paddingLeft :  "100px", paddingRight :  "100px"}} ref={listRef2}>
                  <div style={{display : "flex" , marginTop : "50px" }}>    
                        <div className="montserrat-400" style={{width : "100%" ,  paddingLeft : selected_lang === "en" ? "50px" : "", paddingRight : selected_lang === "en" ? "0" : "50px", marginBottom : "20px", marginTop : "10px", paddingTop : "1px"}}>
                        <div style={{display : "flex" , marginTop : "50px" }}>
                            <b style={{fontSize : "1.2rem"}}>{t("Bestluxurycarindubai_heading")}   </b>
                          
                          <div style={{marginTop : "7px", marginLeft : "10px"}}>
                            <select
                              aria-label="State"
                              value={filters.typecode}
                              id="filter"
                              className="text-sm focus:ring-blue-500 block w-full"
                              style={{ color : "orange", fontWeight : "bold", width : "200px" , paddingRight : "0" , paddingLeft : "0", backgroundColor : "#f1f1f1" }}
                              onChange={(e) => {
                                dispatch(
                                  handleChangeFilterValue({
                                    key: "typecode",
                                    value: e.target.value,
                                  })
                                ).then(o => {
                                  
                                  getsplist("type" , e.target.value , filters.sort.split("/")[1] ? filters.sort.split("/")[1] : "-" , filters.sort.split("/")[0] ? filters.sort.split("/")[0] : "-" )
                                })
                              }}
                            >
                              <option value={"-"}>{t("All Types")}</option>
                              {distvbc?.vtypeCode?.map((typex, index) => (
                                <option key={"typex" + index} value={typex.vtypeCode}>
                                  {typex?.vtname[selected_lang]}
                                </option>
                              ))}
                            </select>
                           </div>
                              <div style={{marginTop : "7px",  marginLeft : selected_lang === "en" ? "auto" : "3vw", marginRight : selected_lang === "en" ? "3vw" : "auto"}}>
                              <select
                                      aria-label="State"
                                      value={filters?.sort}
                                      id="sort"
                                      className="text-sm focus:ring-blue-500 block w-full"
                                      style={{ color : "gray",  width : "200px" , paddingRight : "0" , paddingLeft : "0", backgroundColor : "white", float : "right", fontSize : "0.7rem" }}
                                      onChange={(e) => {
                                        dispatch(
                                          handleChangeFilterValue({
                                            key: "sort",
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                    >
                                    <option value={"1/0"}>
                                      {t("Price Low to High")}
                                    </option>
                                    <option value={"1/1"}>
                                      {t("Price High to Low")}
                                    </option>
                                    <option value={"2/1"}>
                                      {t("Model Year High to Low")}
                                    </option>
                                    <option value={"2/0"}>
                                      {t("Model Year Low to High")}
                                    </option>
                                    <option value={"-/1"}>
                                      {t("Most Recently Updated")}
                                    </option>
                              </select>
                              </div> 
                          </div>
                          <p style={{fontSize : "0.8rem", color : "black"}}>{t("Bestluxurycarindubai_heading_p")} </p>
                        </div>
                  </div>
                  {showProcessing ? 
                     <Lottie
                     options={{
                       loop: true,
                       autoplay: true,
                       animationData: LoadingAnimation,
                       rendererSettings: {
                         preserveAspectRatio: "xMidYMid slice",
                       },
                     }}
                     height={150}
                     width={150}
                   />
                   :
                     <Homecar numb={numbb} setNumb={setNumbb} selected_lang={selected_lang} t={t} conversion_rate={conversion_rate} selected_curr={selected_curr}
                      showcall={showcall} _handleListingView={_handleListingView} typeis = {"Listings2"} range={true} _handleWaCount={_handleWaCount}
                      getsplist={getsplist} cwList = {vtList} filters={filters}  _handleSaveCount={_handleSaveCount} 
                      />}

                  <div style={{marginTop : "1vh", marginBottom : "1vh"}}>
                    <center>
                      {expmore("listings")}
                    </center>
                    </div>
                  </div>                 
              }
        {/** SHOWING User app */}
                  <div  ref={appRef}  className="nextbg" style={{ paddingBottom : "15px",  justifyContent : "center", alignContent : "center"}}>
                  <Userapp t={t}  iswhitea = {"Applestore.webp"} iswhiteg = {"googleplay.webp"} />         
                </div>


        {/** Our Services*/}
                <div style={{  justifyContent : "center", alignContent : "center" }}>
            
                <Vnmservices t ={t} selected_lang={selected_lang}/>
                </div>


        {/**What client Say*/}

                <div style={{  justifyContent : "center", alignContent : "center"}}>
                <Suspense>
                <Vnmwhatclient t ={t} selected_lang={selected_lang} isTabletOrMobile={isTabletOrMobile}/>
                </Suspense>
              </div>
            </div> 
            <Footer selected_lang={selected_lang} /> 
        </>

      </div>
    )
}
export { Home } ;

