import React, { useEffect, useState } from 'react';
import PriceRange from "../../../components/priceRangeSlider/range";
import { useDispatch } from 'react-redux';
import {
    useNavigate,
  } from "react-router-dom";

function HomePremium(props) {
var dispatch = useDispatch()
const [xfil , setXfil] = useState(props.filters)    
const navigate = useNavigate();

useEffect(() => {
      if(xfil !== props.filters){
        props.getfilters()
      }  
      // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.filters])





    return (
        <>
      
        <center>
         <div style={{display : "flex"}}>
            <div style={{width : "80%"}}>
                <div>
                    <div id="ndtable">
                        <div style={{fontSize  :"0.7rem"}} className="ndtr">
                            <div className="ndtd">
                                <label className='montserrat-400'>{props.t("Brand")}</label>
                                <select
                                    aria-label="State"
                                    value={props?.filters?.brandCode}
                                    id="bcx"
                                    className="border focus:ring-orange-500 focus:border-orange-500 block w-full py-1"
                                    onChange={(e) => {
                                    props.dispatch(
                                        props.handleChangeFilterValue({
                                        key: "brandCode",
                                        value: e.target.value,
                                        })
                                    ).then(x => {
                                        setXfil(props.filters)
                                    });
                                    }}
                                >
                                    <option value={"-"}>{props.t("Select Brand")}</option>
                                      {
                                      props?.distvals[0]?.bid?.map((brand, index) => {
                                        if(brand?.bname?.[props?.selected_lang]){
                                            return (
                                                <option key={"brand" + index} value={brand.bid}>
                                                       {brand?.bname?.[props?.selected_lang]}
                                                 </option>
                                            )   
                                        }
                                    } ) }
                                </select>
                                
                            </div>
                            <div className="ndtd">
                                <label className='montserrat-400'>{props.t("Model")}</label>
                                <select
                                aria-label="State"
                                value={props.filters.modelcode}
                                id="modelcode"
                                onChange={(e) => {
                                    props.dispatch(
                                    props.handleChangeFilterValue({
                                        key: "modelcode",
                                        value: e.target.value,
                                    })
                                    );
                                }}
                                className="border focus:ring-orange-500 focus:border-orange-500 block w-full py-1"
                                >
                                    <option value={"-"}>{props.t("ALL_MODELS")}</option>    
                                    {props.uniqlist?.model?.map((model, index) =>
                                    {
                                        if(model?.mname?.[props.selected_lang]){
                                            return (
                                                <option key={"model" + index} value={model.vid}>
                                                {model?.mname?.[props.selected_lang]}
                                                </option>
                                            )   
                                        }
                                    } 
                                    
                                   )}
                                </select>
                            </div>
                            <div className="ndtd">
                                <label className='montserrat-400'>{props.t("Body Type")}</label>
                                <select
                                    aria-label="State"
                                    value={props?.filters?.bodytype}
                                    id="body01"
                                    className="border focus:ring-orange-500 focus:border-orange-500 block w-full py-1"
                                    onChange={(e) => {
                                    props.dispatch(
                                        props?.handleChangeFilterValue({
                                        key: "bodytype",
                                        value: e.target.value,
                                        })
                                    );
                                    }}
                                >
                                    <option value={"-"}>{props.t("All Types")}</option>
                                    {props.uniqlist?.btname?.map((type, index) => {
                                        if(type.btname?.[props.selected_lang]){
                                            return (
                                                <option key={"type" + index} value={type.btypeCode}>
                                                {type.btname?.[props.selected_lang]}
                                               </option>
                                            )
                                        }
                                    } )}
                                </select>
                            </div>
                            <div className="ndtd">
                                <label className='montserrat-400'>{props.t("Vehicle Type")}</label>
                                <select
                                    aria-label="State"
                                    value={props?.filters?.typecode}
                                    id="tcxx"
                                    className="border focus:ring-orange-500 focus:border-orange-500 block w-full py-1"
                                    onChange={(e) => {
                                        dispatch(
                                            props.handleChangeFilterValue({
                                            key: "typecode",
                                            value: e.target.value,
                                            })
                                        );
                                    }}
                                >
                                    <option value={"-"}>{props.t("All Types")}</option>
                                    { props?.uniqlist?.uvtname?.map((type, index) => {
                                          if(type?.vtname[props?.selected_lang]){
                                            return (
                                                <option
                                                key={"type" + index}
                                                value={type.vtypeCode}
                                                >
                                                {type?.vtname[props?.selected_lang]}
                                                </option>
                                            )
                                          }      
                                           

                                    }
                                        


                                        
                                    )}
                                </select>
                            </div>
                        
                        </div>
                    </div>
                </div>    
            <div>
                <div style={{fontSize  :"0.7rem", display : "flex", width : "78%"}} >
                        <div style={{width : "60%", textAlign : "left"}}>
                        <label className='montserrat-400'>{props.t("Pricing")}</label>  <PriceRange />  
                        </div>
                        
                 {/**        <div style={{width : "25%", textAlign : "left" , marginTop : "39px", paddingLeft : "2vw"}}>
                            <input
                            id="insurence"
                            type="checkbox"
                            checked={props?.filters?.insurence !== "-"}
                            value={props.filters?.insurence === "-" ? true : "-"}
                            onChange={(e) => {
                                props?.dispatch(
                                props?.handleChangeFilterValue({
                                    key: "insurence",
                                    value: e.target.value,
                                })
                                );
                            }}
                            name="Insurance"
                            className="w-3 h-3 text-orange-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label className='montserrat-400' style={{paddingLeft : "5px", paddingRight : "5px"}}>{props.t("WITHINSURANCE")}</label>
                        </div>
                 */}           
                </div>
            </div>    
        </div>
{/** set button height from here */}         
         <div  style={{justifyContent : "center", marginTop : "3vh"}}>
         <div className="ndtd">
                        <center>
                        <div  ><button style={{  borderRadius : "2px", fontSize : "0.6rem", width : "10vw", fontWeight : "bold"}}
                             className='findmyperfectridebutton shadow montserrat-400 ' onClick={c => {c.preventDefault(); props.getfilters(); navigate(`/listings/${props?.id}/listings`)}}>{props.t("Find My Perfect Ride")}</button>
                        </div>
                        <div >
                            <button className="shadow montserrat-400 clearfilterbutton" style={{position : "relative",   top : "10px",  fontWeight : "bold", fontSize : "0.6rem",  width : "10vw"}} 
                                onClick={c => {c.preventDefault(); props._handleClearAllFilters()}}>{props.t("CLEARALLFILTERS")}</button>
                        </div>
                        </center>
                    </div>   
        </div>    
        
        </div>   
       



         
        </center>
           
        </>

    );
}

export default HomePremium;